import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import emailjs from "emailjs-com";

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "forms");

export default function Modal1({ open, onClose, children }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    guests: "",
    mobNo: "",
  });

  const [countries, setCountries] = useState();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({ name: "", email: "", guests: "", mobNo: "" });
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add a document to the 'forms' collection using 'addDoc' function
      await addDoc(formsCollectionRef, {
        form: formData,
      });
      const templateParams = {
        name: formData.name,
        email: formData.email,
      };
      // Next, send a request to trigger the Cloud Function
      const response = await emailjs.send(
        "service_4bymywe",
        "template_1z4vkmd",
        templateParams,
        "_y8URUrfpn2pG2TS8"
      );

      if (response.status === 200) {
        alert("Inquiry has been generated. We will get back to you soon.");
        resetFormAndCloseModal();
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    fetch("https://restcountries.com/v2/all")
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
      })
      .catch((error) => {
        console.error("error in fetching country data" + error);
      });
  }, []);

  return (
    <>
      <div
        onClick={onClose}
        className={`fixed z-10 inset-0 flex justify-center items-center transition-opacity ${
          open ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={`bg-white ml-[3%] w-[50%] rounded-xl shadow-lg p-6 transform transition-transform ${
            open ? "scale-100" : "scale-110"
          }`}
        >
          {children}
          <form
            onSubmit={handleSubmit}
            className="flex flex-col justify-center space-y-5"
          >
            <h1 className="text-2xl font-semibold text-center">Book Now</h1>
            <div className="flex justify-center">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="w-full p-2 border"
                required
              />
            </div>
            <div className="flex justify-center">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="w-full p-2 border"
                required
              />
            </div>
            <div className="flex justify-center">
              <input
                type="number"
                name="guests"
                value={formData.guests}
                onChange={handleChange}
                placeholder="No. of Guests"
                className="w-full p-2 border"
                required
              />
              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="w-full p-2 border text-zinc-400"
                required
              >
                <option value="">Select a country</option>
                {countries &&
                  countries.map((country) => (
                    <option key={country.alpha2Code} value={country.alpha2Code}>
                      {country.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="flex justify-center">
              <input
                type="tel"
                pattern="[0-9]{10}"
                name="mobNo"
                value={formData.mobNo}
                onChange={handleChange}
                placeholder="Mobile No."
                className="w-full p-2 border"
                required
              />
            </div>
            <div className="flex flex-col justify-center">
              <p className="font-palette-font-3 text-palette-color-4" >Select Date of Visit:</p>
              <input
                type="date"
                name="startdate"
                value={formData.startdate}
                placeholder="Start date"
                onChange={handleChange}
                className="w-full p-2 border text-zinc-400"
                required
              />
            </div>
            <div className="flex flex-col justify-center">
              <p className="font-palette-font-3 text-palette-color-4">Please let us know if we should consider any specifics about your trip:</p>
              <input
                type="text"
                placeholder="Additional Details"
                name="details"
                value={formData.details}
                onChange={handleChange}
                className="w-full p-2 border"
                required
              />
            </div>
            <center>
              <button
                type="submit"
                className="px-4 py-2 font-semibold text-white rounded-md bg-palette-color-4"
              >
                Submit
              </button>
            </center>
          </form>
        </div>
      </div>
    </>
  );
}
