import React from "react";

const HowWeWork = ({ howWeWorkRef }) => {
  return (
    <div ref={howWeWorkRef} className="justify-center">
      <div className="mb-2 text-4xl text-palette-color-18 font-palette-font-1">
        How Indian Treasure House Work
      </div>
      <br />
      <p className="text-justify">
        Our team have individuals who were born in India and are well-versed in
        the intricacies of history, art, architecture, philosophy, religion, and
        gastronomy, bringing their unique backgrounds to enrich your trip and
        turn it into a truly personal adventure.
        <br />
        <br />
        We specialize in a variety of travel experiences, including trips for
        Friends & Family, Solo Female travelers, and those seeking an
        educational adventure, corporate getaways, Tailor-Made Journeys.
        Additionally, we offer the convenience of renting a car with a personal
        driver, ensuring safe and worry-free travel throughout India. <br />
        <br />
        Our team is a harmonious blend of multilingual tour guides, art
        historians, skilled drivers, and western travel cultural curators. From
        the moment you contact us, our friendly, respectful, and supportive
        staff is at your service, ensuring your every need is met. Hospitality
        and open heartedness define our approach. Travel with us and let us
        introduce you to the very soul and essence of our culture, our
        millennia-old history, and our people. We're not just a travel company;
        we're your gateway to an unforgettable Indian experience.
      </p>
      <br/>
      <div className="mb-2 text-2xl text-center text-palette-color-18 font-palette-font-1">
        What Sets Us Apart
      </div>
      <p>
        We're a team of avid travelers with a deep connection to India. Our
        journey started with a strong belief in India's enduring qualities -
        timeless, progressive, sublime, and all-inclusive. Today, we're like a
        family, united by our shared passion for travel.
      </p>
      <br/><br/>
      <div className="mb-2 text-2xl text-center text-palette-color-18 font-palette-font-1">
        Crafting Your Unique Journey
      </div>
      <p>
        We're all about open communication and personalization. Your trip starts
        with an enthusiastic team member who shares your love for exploring
        India. We listen to your preferences and craft a trip tailored just for
        you, adding that personal touch that makes your journey special.
      </p>
      <br/><br/>
      <div className="mb-2 text-2xl text-center text-palette-color-18 font-palette-font-1">
        Selecting Your Stay
      </div>
      <p>
        We suggest a range of accommodations, from hotels to home stays, all
        carefully selected to enhance your experience. Our focus is on locations
        that offer convenience and delightful stays, not just the typical
        high-end choices.
      </p>
      <br/><br/>
      <div className="mb-2 text-2xl text-center text-palette-color-18 font-palette-font-1">
        Our Dedicated Support Team
      </div>
      <p>
        Our experienced team, including drivers and guides, ensures smooth
        travel. From vibrant festivals to one-on-one experiences, we make it
        happen, always with a personal touch.
      </p>
      <br/><br/>
      <div className="mb-2 text-2xl text-center text-palette-color-18 font-palette-font-1">
        Exceeding Your Expectations
      </div>
      <p>
        We're not just tour operators; we're your travel companions. Our
        understanding of your unique dreams helps us create a memorable journey
        that's tailored to you. Your experience is at the heart.
      </p>
      <br/><br/>
    </div>
  );
};

export default HowWeWork;
