import React, { useState } from "react";
import { Link } from "react-router-dom";

import logoImage from "../assets/Layer logo 2.png";

const Newnav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="sticky top-0 z-50 transition duration-300 bg-palette-color-4">
      <div className="container flex items-center justify-between px-4 py-2 mx-auto">
        <img src={logoImage} alt="Logo" className="h-10" />

        <div className="hidden space-x-12 md:flex">
          <Link
            to="/"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            Home
          </Link>
          <Link
            to="/bookform"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            Tours
          </Link>
          <Link
            to="/car"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            Car Rental
          </Link>
          <Link
            to="/protected"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            Blogs
          </Link>
          <Link
            to="/contactform"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            Contact
          </Link>
          <Link
            to="/paymentform"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            Payment
          </Link>
        <Link
            to="/requestform"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
            >
            Request Handmade Journey
          </Link>
            </div>
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-palette-color-3 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="py-2 md:hidden bg-palette-color-1">
          <Link to="/" className="block px-4 py-2 text-palette-color-3">
            Home
          </Link>
          <Link to="/bookform" className="block px-4 py-2 text-palette-color-3">
            Tours
          </Link>
          <Link
            to="/car"
            className="block px-4 py-2 text-palette-color-3"
          >
            Car Rental
          </Link>
          <Link
            to="/protected"
            className="block px-4 py-2 text-palette-color-3"
          >
            Blogs
          </Link>
          <Link
            to="/contactform"
            className="block px-4 py-2 text-palette-color-3"
          >
            Contact
          </Link>
          <Link
            to="/paymentform"
            className="block px-4 py-2 text-palette-color-3"
          >
            Payment
          </Link>
          <Link
            to="/requestform"
            className="block px-4 py-2 text-palette-color-3"
          >
            Request Handmade Journey
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Newnav;
