import React, { useState } from "react";
import Car from "../assets/car-rental.webp";
import ToyotoEtios from "../assets/carrent/Ethos.webp";
import SwiftDzire from "../assets/carrent/Swift.webp";
import ToyotoInnova from "../assets/carrent/Innova.webp";
import Tempotraveller from "../assets/carrent/Tempo.webp";
import LuxuryBus from "../assets/carrent/Bigbus.webp";
import Navbar from "../components/navbar";
import Modal2 from "../components/Modal2";

import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import emailjs from "emailjs-com";

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "carrent");

const carOptions = [
  {
    name: "Toyota Innova Crysta",
    description:
      "The Toyota Innova is a fantastic choice, combining good looks, comfortable interiors, and top-notch safety features for your travels.",
    maxPassengers: 4,
    driver: true,
    image: ToyotoInnova,
  },
  {
    name: "Toyota Etios",
    description:
      "Big on space, safety, and value for your money – a go-to pick for travelers.",
    maxPassengers: 3,
    driver: true,
    image: ToyotoEtios,
  },
  {
    name: "Swift Dzire",
    description:
      "Spacious, safe, and budget-friendly – the go-to choice for travelers, just like the Swift Dzire.",
    maxPassengers: 3,
    driver: true,
    image: SwiftDzire,
  },
  {
    name: "Bigger vehicle - Tempo Traveller",
    description:
      "The perfect choice for extended trips, it offers exceptional comfort, a wide range of amenities, and even an onboard icebox.",
    maxPassengers: "5 to 10",
    driver: true,
    image: Tempotraveller,
  },
  {
    name: "Luxury Bus",
    description:
      "These are the ideal choice for large family gatherings, friends' outings, or corporate groups. They offer supreme comfort, a variety of amenities, including an icebox, and a separate cabin with two seats for the driver and assistant.",
    maxPassengers: "15",
    driver: true,
    image: LuxuryBus,
  },
];

const CarRental = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    guests: "",
    mobNo: "",
    typeOfVehicle: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      guests: "",
      mobNo: "",
      typeOfVehicle: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add a document to the 'forms' collection using 'addDoc' function
      await addDoc(formsCollectionRef, {
        carrent: formData,
      });

      const templateParams = {
        name: formData.name,
        email: formData.email,
      };
      // Next, send a request to trigger the Cloud Function
      const response = await emailjs.send(
        "service_4bymywe",
        "template_1z4vkmd",
        templateParams,
        "_y8URUrfpn2pG2TS8"
      );

      if (response.status === 200) {
        alert("Request has been generated. We will get back to you soon.");
        resetFormAndCloseModal();
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const [Open, setOpen] = useState(false);
  return (
    <>
      <Navbar />
      <section
        className="relative h-screen overflow-hidden"
        style={{ top: "-57px" }}
      >
        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <div className="text-center">
            <h1 className="mb-6 text-5xl font-extrabold leading-tight md:text-6xl lg:text-7xl text-palette-color-3 font-palette-font-1">
              Car Rental
            </h1>
            <br />
          </div>
        </div>
        <img
          src={Car}
          alt="Car"
          className="absolute top-0 left-0 z-0 object-cover w-full h-full"
        />
      </section>
      <Modal2 open={Open} onClose={() => setOpen(false)}></Modal2>
      <div className="container mx-auto text-center mb-14">
        <h2 className="mb-8 text-4xl text-center text-palette-color-18 font-palette-font-2">
          Car Rental With Personal Driver
        </h2>
        <br />
        <p className="text-l font-palette-font-2">
          We provide a one-stop solution for your family, friends, business, or
          corporate travel needs, customized to suit you.
          <br /> Our cars are always in top-notch condition, and we regularly
          check them for safety.
          <br /> Our drivers are carefully chosen, well-trained, polite, and
          know the cities and destinations well. We're ready to greet you with a
          warm welcome.
          <p className="mt-5 text-right md:mr-24 text-palette-color-18">
            -Start your journey today with Indian Treasure House
          </p>
        </p>
        <br />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {carOptions.map((car, index) => (
            <div
              key={index}
              className="p-4 bg-white border border-gray-200 rounded-lg font-palette-font-1"
            >
              <img
                src={car.image}
                alt={car.name}
                className="object-cover mb-4 rounded-md"
              />
              <h3 className="mb-2 text-xl font-semibold">{car.name}</h3>
              <p className="mb-2 text-gray-600">{car.description}</p>
              <p className="text-gray-600 text-palette-color-4">
                {`Maximum ${car.maxPassengers} ${
                  car.maxPassengers > 1 ? "people" : "person"
                }${car.driver ? " + Driver" : ""}`}
              </p>
            </div>
          ))}
        </div>
        <center>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col justify-center w-[100%] md:w-[50%] space-y-5"
          >
            <h1 className="pt-24 text-2xl font-semibold text-center font-palette-font-1 text-palette-color-18">
              If you want to rent a car with us, please fill this form:
            </h1>
            <div className="flex flex-col justify-center space-y-2 md:space-x-2 md:flex-row">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="w-full p-2 border"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="w-full p-2 border"
                required
              />
            </div>
            <div className="flex flex-col justify-center space-y-2 md:space-x-2 md:flex-row">
              <input
                type="number"
                name="guests"
                value={formData.guests}
                onChange={handleChange}
                placeholder="No. of Guests"
                className="w-full p-2 border"
                required
              />
              <input
                type="tel"
                pattern="[0-9]{10}"
                name="mobNo"
                value={formData.mobNo}
                onChange={handleChange}
                placeholder="Mobile No."
                className="w-full p-2 border"
                required
              />
            </div>

            <div className="flex flex-col justify-center space-y-2 md:space-x-2 md:flex-row">
              <p className="font-palette-font-3 text-palette-color-4">From:</p>
              <input
                placeholder="Start date"
                type="date"
                name="startdate"
                value={formData.startdate}
                onChange={handleChange}
                className="w-full p-2 border text-zinc-400"
                required
              />
              <p className="font-palette-font-3 text-palette-color-4">To:</p>

              <input
                placeholder="End date"
                type="date"
                name="enddate"
                value={formData.enddate}
                onChange={handleChange}
                className="w-full p-2 border text-zinc-400"
                required
              />
            </div>

            <div className="flex justify-center space-y-2 md:space-x-2">
              <select
                name="typeOfVehicle"
                value={formData.typeOfVehicle}
                onChange={handleChange}
                className="w-full p-2 border text-zinc-400"
                required
              >
                <option value="">Select Type of Vehicle</option>
                <option value="Toyota Etios">Toyota Etios</option>
                <option value="Swift Drize">Swift Drize</option>
                <option value="Toyota Innova">Toyota Innova</option>
                <option value="Tempo Traveller - 10 seater">
                  Tempo Traveller - 10 seater
                </option>
                <option value="Tempo Traveller - 12 seater">
                  Tempo Traveller - 12 seater
                </option>
              </select>
            </div>

            <center>
              <button
                type="submit"
                className="px-4 py-2 font-semibold text-white rounded-md bg-palette-color-4"
              >
                Submit
              </button>
            </center>
          </form>
        </center>
      </div>
    </>
  );
};

export default CarRental;
