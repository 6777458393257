import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import Navbar from "./navbar";
import Contact from "../assets/contact.webp";
import emailjs from "emailjs-com";

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "request");

function Request() {
  const [countries, setCountries] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    guests: "",
    mobNo: "",
    startdate: "",
    details: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      guests: "",
      mobNo: "",
      startdate: "",
      details: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // First, add the form data to Firestore as you were doing
      await addDoc(formsCollectionRef, {
        request: formData,
      });

      const templateParams = {
        name: formData.name,
        email: formData.email,
      };
      // Next, send a request to trigger the Cloud Function
      const response = await emailjs.send(
        "service_4bymywe",
        "template_1z4vkmd",
        templateParams,
        "_y8URUrfpn2pG2TS8"
      );

      if (response.status === 200) {
        alert("Inquiry has been generated. We will get back to you soon.");
        resetFormAndCloseModal();
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    fetch("https://restcountries.com/v2/all")
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
      })
      .catch((error) => {
        console.error("error in fetching country data" + error);
      });
  }, []);

  return (
    <>
      <Navbar />
      <section
        className="relative h-screen overflow-hidden"
        style={{ top: "-57px" }}
      >
        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <div className="text-center">
            <h1 className="mb-6 text-4xl font-extrabold leading-tight md:text-6xl lg:text-7xl text-palette-color-3 font-palette-font-1">
              Request Tailor Made Journey
            </h1>
            <br />
          </div>
        </div>
        <img
          src={Contact}
          alt="Contact"
          className="absolute top-0 left-0 z-0 object-cover w-full h-full"
        />
      </section>
      <div className="flex items-center justify-center">
        <div className="flex flex-col w-[50%]  p-4">
          <h1 className="text-3xl text-palette-color-4 font-palette-font-1">
            Let us know about your dream or need.
          </h1>
          <p className="mt-5 text-md font-palette-font-2">
            We will convert it into a memorable journey. Please fill in this
            form so we can start planning your dream journey.
          </p>
        </div>
      </div>

      <div className="p-5 mt-5">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-center mx-auto space-y-5 md:w-1/2"
        >
          <div className="text-2xl text-palette-color-4 font-palette-font-1">
            Personal Details
          </div>
          <div className="flex justify-center space-x-2">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              className="w-full p-2 border"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="w-full p-2 border"
              required
            />
            <select
             name="country"
             value={formData.country}
             onChange={handleChange}
             className="w-full p-2 border text-zinc-400"
             required
            
            >
              <option value="">Select a country</option>
              {countries &&
                countries.map((country) => (
                  <option key={country.alpha2Code} value={country.alpha2Code}>
                    {country.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="flex flex-row justify-center space-x-2">
            <input
              type="tel"
              pattern="[0-9]{10}"
              name="mobNo"
              value={formData.mobNo}
              onChange={handleChange}
              placeholder="Mobile No."
              className="w-full p-2 border"
              required
            />
            <input
              type="date"
              name="startdate"
              value={formData.startdate}
              onChange={handleChange}
              className="w-full p-2 border text-zinc-400"
              required
            />
          </div>

          <div className="flex justify-center space-x-2">
            <textarea
              placeholder="Additional Details"
              name="details"
              value={formData.details}
              onChange={handleChange}
              className="w-full p-2 border"
              required
            />
          </div>
          <div className="text-2xl text-palette-color-4 font-palette-font-14">
            About your dream journey
          </div>

          <div className="flex justify-center space-x-2">
            <input
              type="number"
              name="guests"
              value={formData.guests}
              onChange={handleChange}
              placeholder="No. of Guests"
              className="w-full p-2 border"
              required
            />
            <select
              name="typeOfVehicle"
              value={formData.triptype}
              onChange={handleChange}
              className="w-full p-2 border text-zinc-400"
              required
            >
              <option value="">Select Type of Trip</option>
              <option value="family and friends">Friends and Family</option>
              <option value="solo female">Solo Female</option>
              <option value="educational">Educational</option>
              <option value="corporate">Corporate</option>
            </select>
          </div>
          <div className="flex justify-center space-x-2">
            <textarea
              placeholder="Desired places to visit"
              name="details"
              value={formData.details}
              onChange={handleChange}
              className="w-full p-2 border"
              required
            />
          </div>
          <div className="text-2xl text-palette-color-4 font-palette-font-14">
            Prefered month and year of travel
          </div>
          <div className="flex flex-row justify-center space-x-2">
            <select
              name="month"
              value={formData.month}
              onChange={handleChange}
              className="w-full p-2 border text-zinc-400"
              required
            >
              <option value="">Month</option>
              <option value="January">January</option>
              <option value="Febuary">Febuary</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
            <select
              name="year"
              value={formData.year}
              onChange={handleChange}
              className="w-full p-2 border text-zinc-400"
              required
            >
              <option value="">Year</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
            </select>
            <select
              name="daysoftravel"
              value={formData.daysoftravel}
              onChange={handleChange}
              className="w-full p-2 border text-zinc-400"
              required
            >
              <option value="">Number of Days you want to travel</option>
              <option value="1-5">1-5</option>
              <option value="5-10">5-10</option>
              <option value="10-20">10-20</option>
              <option value="20-30">20-30</option>
              <option value="30-40">30-40</option>
              <option value="40-50">40-50</option>
              <option value="50-60">50-60</option>
              <option value="60-70">60-70</option>
              <option value="70-80">70-80</option>
              <option value="80-90">80-90</option>
              <option value="90-100">90-100</option>
            </select>
          </div>

          <div className="flex justify-center space-x-2">
            <select
              name="styleoftravel"
              value={formData.styleoftravel}
              onChange={handleChange}
              className="w-full p-2 border text-zinc-400"
              required
            >
              <option value="">Desired style of travel</option>
              <option value="luxury">Luxury</option>
              <option value="semiluxury">Semi-luxury</option>
              <option value="affordable">Affordable</option>
            </select>
          </div>

          <div className="flex justify-center space-x-2">
            <textarea
              placeholder="Please let us know if we should consider any specifics about your trip"
              name="specifications"
              value={formData.specifications}
              onChange={handleChange}
              className="w-full p-2 border"
              required
            />
          </div>

          <center>
            <button
              type="submit"
              className="px-6 py-3 font-bold text-white rounded-md bg-palette-color-4"
            >
              Submit Your Request
            </button>
          </center>
        </form>
      </div>
    </>
  );
}

export default Request;
