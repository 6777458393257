import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useInView } from "react-intersection-observer";
import emailjs from "emailjs-com";
import Navbar from "../components/navbar";
import Disabled1 from "../assets/disabled1.webp";
import Disabled2 from "../assets/disabled2.webp";
import StreetDog1 from "../assets/street dog1.webp";
import StreetDog2 from "../assets/street dog2.webp";

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "contact");

function SocialImapact() {
  const [inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobNo: "",
    country: "",
    details: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      mobNo: "",
      country: "",
      details: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(formsCollectionRef, {
        contact: formData,
      });
      const templateParams = {
        name: formData.name,
        email: formData.email,
      };
      // Next, send a request to trigger the Cloud Function
      const response = await emailjs.send(
        "service_g8shg6p",
        "template_3qor7s8",
        templateParams,
        "fT4pN2G_pRf7AWKMk"
      );

      if (response.status === 200) {
        alert("Inquiry has been generated. We will get back to you soon.");
        resetFormAndCloseModal();
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <div className="relative sticky top-0 z-10 bg-palette-color-4">
        <Navbar />
      </div>
      <h1 className="pt-12 text-4xl leading-tight text-center text-palette-color-18 font-palette-font-1">
        SOCIAL IMPACT
      </h1>
      <h1 className="pt-12 text-3xl leading-tight text-center text-palette-color-18 font-palette-font-1">
        Empowering Especially abled kids: Transforming Lives Through Travel!
      </h1>
      <div className="p-4">
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          At Indian Treasure House, on every tour with us, a portion of your
          payment is dedicated to helping especially abled children. We are
          committed to making their lives better through your support.
        </p>
        <img src={Disabled1} alt="disabled" className="p-7" />
        <h1 className="mt-6 mb-8 text-3xl leading-tight text-center text-palette-color-18 font-palette-font-1">
          How We Support Especially Abled Children
        </h1>
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 md:order-1">
            <p
              className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
                inView ? "animate-fade-in" : ""
              }`}
            >
              <span className="text-xl text-palette-color-18 font-palette-font-1">
                • Summer Classes and Coaching:
              </span>{" "}
              We provide motivating summer classes and coaching to inspire a
              love for learning and empower these kids to do their best.
            </p>
            <p
              className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
                inView ? "animate-fade-in" : ""
              }`}
            >
              <span className="text-xl text-palette-color-18 font-palette-font-1">
                • Free Stationery Supplies:
              </span>
              We give them the tools they need with free stationery supplies to
              ensure nothing holds them back.
            </p>
            <p
              className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
                inView ? "animate-fade-in" : ""
              }`}
            >
              <span className="text-xl text-palette-color-18 font-palette-font-1">
                • Enriching Lives:
              </span>{" "}
              We work tirelessly to provide opportunities and support to
              especially abled children, by providing them foods & clothes.
            </p>
            <p
              className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
                inView ? "animate-fade-in" : ""
              }`}
            >
              <span className="text-xl text-palette-color-18 font-palette-font-1">
                • Creating Smiles:
              </span>{" "}
              Through your participation, we help bring smiles to the faces of
              these incredible children, making their days brighter.
            </p>
          </div>
          <div className="md:w-1/2 md:order-2">
            <img src={Disabled2} alt="disabled" />
          </div>
        </div>
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          You can also help us in providing education & enhancing their lives
          when you travel with us.
          <br />
          <br />
          Join our tours and be part of a mission that truly matters. You'll
          have fantastic adventures while knowing that your journey is helping
          especially abled children have a brighter future.
          <br />
          Join Us and Be Part of Something Beautiful!
        </p>
      </div>
      <div class="inline-flex items-center justify-center w-full">
        <hr class="w-64 h-1 my-8 bg-palette-color-4 border-0 rounded dark:bg-palette-color-18" />
        <div class="absolute px-4 -translate-x-1/2 bg-white left-1/2 dark:bg-palette-color-18 z-0">
          <svg
            class="w-4 h-4 text-gray-700 dark:text-gray-300"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 18 14"
          >
            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
          </svg>
        </div>
      </div>
      <h1 className="pt-12 text-3xl leading-tight text-center text-palette-color-18 font-palette-font-1">
        Providing Shelter to Stray Paws 🐾
      </h1>
      <div className="p-4">
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          Join Us in Making a Difference on Our Tours! <br />
          When you travel with us at Indian Treasure House, a part of your
          payment goes to help dogs. We are on a mission to find them homes,
          provide them with healthy food, and make sure they get better when
          they're not feeling well.
        </p>
        <img src={StreetDog1} alt="street dog" className="p-7" />
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 md:order-1">
            <p
              className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
                inView ? "animate-fade-in" : ""
              }`}
            >
              <span className="text-xl text-palette-color-18 font-palette-font-1">
                • Finding Homes:
              </span>{" "}
              We work hard to find dogs loving families.
            </p>
            <p
              className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
                inView ? "animate-fade-in" : ""
              }`}
            >
              <span className="text-xl text-palette-color-18 font-palette-font-1">
                • Feeding Them:
              </span>
              Dogs get healthy food to keep them happy and healthy.
            </p>
            <p
              className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
                inView ? "animate-fade-in" : ""
              }`}
            >
              <span className="text-xl text-palette-color-18 font-palette-font-1">
                • Helping Them Heal:
              </span>{" "}
              We make sure they get the care they need when they are not well.
            </p>
          </div>
          <div className="md:w-1/2 md:order-2">
            <img src={StreetDog2} alt="disabled" />
          </div>
        </div>
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          You can also help us in providing homes & food to dogs when you travel
          with us.
          <br />
          <br />
          Join our tours and be part of this important cause. You will have an
          awesome adventure and know you're helping dogs in need.
          <br />
          Thanks for being part of our mission!
          <br />
          Come and make difference with us!
        </p>
      </div>
      <div className="justify-center">
        <div className="h-full mt-6 shadow-md md:mt-0 ">
          <div className="p-5 mt-10">
            <h1 className="mb-8 text-3xl leading-tight text-center text-palette-color-18 font-palette-font-1">
              For more details:
            </h1>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center w-[100%] md:w-1/2 mx-auto space-y-5"
            >
              <div className="flex flex-col justify-center space-y-2 md:space-x-2 md:flex-row">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  className="w-full p-2 border"
                  required
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="w-full p-2 border"
                  required
                />
              </div>

              <div className="flex flex-col justify-center space-y-2 md:space-x-2 md:flex-row">
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  name="mobNo"
                  value={formData.mobNo}
                  onChange={handleChange}
                  placeholder="Mobile No."
                  className="w-full p-2 border"
                  required
                />
                <input
                  placeholder="Country"
                  type="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="w-full p-2 border"
                  required
                />
              </div>

              <div className="flex flex-col justify-center space-y-2 md:space-x-2 md:flex-row">
                <textarea
                  placeholder="Message"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  className="w-full p-2 border"
                  required
                />
              </div>
              <center>
                <button
                  type="submit"
                  className="px-6 py-3 font-bold text-white rounded-md bg-palette-color-4"
                >
                  Submit
                </button>
              </center>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialImapact;
