import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useInView } from "react-intersection-observer";
import emailjs from "emailjs-com";
import Navbar from "../../components/navbar";

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "contact");

function WomenSafety() {
  const [inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobNo: "",
    country: "",
    details: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      mobNo: "",
      country: "",
      details: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(formsCollectionRef, {
        contact: formData,
      });
      const templateParams = {
        name: formData.name,
        email: formData.email,
      };
      // Next, send a request to trigger the Cloud Function
      const response = await emailjs.send(
        "service_g8shg6p",
        "template_3qor7s8",
        templateParams,
        "fT4pN2G_pRf7AWKMk"
      );

      if (response.status === 200) {
        alert("Inquiry has been generated. We will get back to you soon.");
        resetFormAndCloseModal();
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <div className="bg-palette-color-4 relative sticky top-0">
        <Navbar />
      </div>
      <h1 className="pt-12 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-11 font-palette-font-1">
        Empower Your Wanderlust: Women's Safe Travel Adventures
      </h1>
      <div className="m-10 text-justify md:p-4">
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          India, with its vibrant tapestry of cultures, traditions, and
          warm-hearted people, welcomes travelers from across the globe with
          open arms. Stepping into this incredible country can be a sensory
          overload, and initially, you may experience a culture shock unlike any
          other. But fear not, for this shock soon transforms into a fascinating
          journey of discovery.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          The first thing that strikes most visitors is the sheer diversity.
          India is a melting pot of languages, religions, and customs. From the
          grandeur of the Taj Mahal to the serene Ganges River, each corner of
          India offers a unique experience. The cuisine is an adventure in
          itself, with tantalizing flavors and a wide range of dishes to savor.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          India's streets are a lively symphony of honking horns, street
          vendors, and colorful attire. The chaos and traffic might seem
          overwhelming at first, but they are an integral part of the Indian
          experience. Embrace it, and you'll find beauty in the organized chaos.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          The people of India are incredibly warm and hospitable. Don't be
          surprised if strangers invite you into their homes or offer help
          without expecting anything in return. This hospitality is a hallmark
          of Indian culture.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          Religious diversity is also striking, with temples, mosques, churches,
          and gurudwaras coexisting harmoniously. Witnessing the devotion and
          rituals can be a humbling experience, regardless of your faith.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          Haggling in markets is a common practice, and bargaining for goods is
          expected. It's all part of the shopping adventure, so don't hesitate
          to engage in friendly negotiations.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          India's festivals are a riot of colors, music, and celebrations.
          Whether it's Diwali, Holi, Eid, or Christmas, participating in these
          festivals will leave you with unforgettable memories.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          However, amidst the beauty, there can be moments of discomfort.
          Poverty is visible, and beggars are a common sight. It's essential to
          be compassionate while also practicing caution.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          India's public transport can be crowded and chaotic, but it's an
          authentic way to experience local life. Be prepared for crowded buses,
          bustling train stations, and the occasional delay.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          Cultural norms may differ significantly from your home country. For
          example, removing your shoes before entering someone's home is
          customary. Learning and respecting these traditions will help you
          navigate Indian society with ease.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          In India, spirituality and mysticism are intertwined with daily life.
          Exploring ashrams and meditation centers can provide a profound sense
          of tranquility amidst the bustling chaos.
        </p>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          In conclusion, while the initial cultural shock in India might be
          overwhelming, it quickly transforms into a deep appreciation for the
          country's diversity, warmth, and rich traditions. Embrace the chaos,
          savor the flavors, and immerse yourself in the beautiful mosaic of
          India's culture. Your journey here will be a transformative
          experience, leaving you with cherished memories that last a lifetime.
        </p>
        <h1 className="mb-8 mt-6 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-1">
          Why Choose Us
        </h1>
        <p
          className={`text-md justify-center pb-4 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          Welcome to the world of fearless exploration and boundless wanderlust.
          At Indian treasure House, we're on a mission to celebrate the spirit
          of women who dare to dream, who dare to travel, and who dare to
          conquer the world on their own terms. Our women's safe travel
          adventures are designed to make your globetrotting dreams a reality,
          all while prioritizing your safety and comfort.
        </p>
        <p
          className={`text-md justify-center p-6 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Unforgettable Destinations:
          </span>{" "}
          Our carefully curated destinations are renowned for their safety,
          culture, and women-friendly environments. Each location is handpicked
          to ensure your adventure is as secure as it is exhilarating.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Local Legends:
          </span>{" "}
          Meet our exceptional male/female local guides who are not just experts
          in history and culture but are also your personal guardians throughout
          your journey. They are trained to anticipate your needs and ensure
          your well-being.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Luxurious Retreats:
          </span>{" "}
          Your accommodations are more than just a place to rest your head.
          We've chosen establishments known for their safety, comfort, and
          charm, so you can relax and rejuvenate in style.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Bond with Like-Minded Travelers:
          </span>{" "}
          Join a community of like-minded women, each with her own unique story
          and passion for exploration. Our group travel experiences create bonds
          and friendships that last a lifetime.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Round-the-Clock Assistance:
          </span>{" "}
          Our support team is your travel lifeline, available 24/7 to address
          your questions and concerns. We are committed to your safety at every
          moment of your journey.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Empowerment through Knowledge:
          </span>{" "}
          We empower you with essential safety tips and travel guidelines to
          ensure your journey is not just incredible but also secure.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Exclusive Women-Centric Experiences:
          </span>{" "}
          Immerse yourself in unique activities tailored for women, from visits
          to women's cooperatives to wellness retreats and cultural interactions
          that celebrate the strength and creativity of women.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Solo Adventures:
          </span>{" "}
          If you're a solo adventurer, our tailor-made itineraries offer the
          same safety and excitement, custom-fit to your preferences.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          At Indian treasure House, we are not just tour organizers; we are
          dream enablers.
          <br />
          Join our community of fearless women and embark on a journey that will
          redefine your life. Travel with the assurance that you are secure,
          cherished, and embarking on an extraordinary adventure.
          <br />
          Adventure beckons, and we're here to make it safe, spectacular, and a
          story you'll treasure forever.
        </p>
      </div>
      <div className="justify-center">
        <div className="h-full mt-6 shadow-md md:mt-0 ">
          <div className="mt-10 p-5">
            <h1 className="mb-8 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-1">
              For more details:
            </h1>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center space-y-5 w-1/2 mx-auto"
            >
              <div className="flex justify-center">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  name="mobNo"
                  value={formData.mobNo}
                  onChange={handleChange}
                  placeholder="Mobile No."
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  placeholder="Country"
                  type="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <textarea
                  placeholder="Message"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <center>
                <button
                  type="submit"
                  className="py-3 px-6 bg-palette-color-4 text-white font-bold rounded-md"
                >
                  Submit
                </button>
              </center>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WomenSafety;
