import React from "react";
import About1 from "../../assets/aboutus1.webp";
import About2 from "../../assets/aboutus2.webp";

const MandawaKothi = ({ mandawaKothiRef }) => {
  return (
    <div ref={mandawaKothiRef} className="justify-center">
      <div className="mb-2 text-4xl text-palette-color-18 font-palette-font-1">
        Mandawa Kothi: Where Heritage Meets Hospitality
      </div>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="md:w-1/2 md:mt-6 justify-center md:order-1">
          <p className="text-justify md:pr-6">
            Our traditional art and culture & Havelis are slowly fading away &
            abandoned & its charm is destroying because of all the new modern
            stuff and buildings. Sadly, we couldn't save many historical places,
            but we did manage to protect one special haveli called Mandawa
            Kothi. We really worked hard to keep it looking old and historic.
            We’ve put our heart & soul into it and made sure it stays true to
            its original style. It's our way of keeping a bit of the past safe
            and sound.
            <br />
            <br />
            When you visit, you'll feel a strong and peaceful vibe. We're happy
            that we saved this old building with lots of grace. It's not just a
            place to see – it's an experience that connects you to the past. We
            want to make sure our history stays alive even as things change
            around us.
          </p>
        </div>
        <div className="md:w-1/2 md:mt-6 md:order-2 flex justify-center">
          <img src={About1} alt="About2" className="max-w-full md:max-w-3/4" />
        </div>
      </div>
      <br />
      <p className="text-justify">
        Art and culture shape society and preserving Shekhawati heritage not
        only safeguards our identity but also brings economic opportunities to
        the local community. Mandawa Kothi, a unique boutique hotel and cultural
        project in the heart of Mandawa, celebrates Rajasthan's traditions.
        Originally built in the 18th century, it was home to the prosperous
        merchant Shri Maan Seth Kedar Mal Ladia, known for his Silk Route trade
        in pearls and jewels.
        <br />
      </p>
      <br />
      <div className="flex flex-col-reverse md:flex-row">
        <div className="md:w-1/2 md:mt-6 justify-center md:order-2">
          <p className="text-justify md:pl-6">
            Mandawa Kothi aims to revive the historical richness of these
            mansions, contributing to the community's well-being. The term
            "Haveli," derived from Arabic and Persian words meaning a large,
            enclosed space, characterizes many structures in the Indian
            subcontinent. Featuring Islamic, Persian, Turkish, Sikh, and Hindu
            influences, these Havelis boast central courtyards, fountains,
            spacious rooms, kitchens, balconies, verandas, and galleries with
            enduring wall paintings. Our commitment to preserving cultural
            heritage involves restoring Mandawa Kothi with traditional craftsmen
            and handmade materials.
            <br />
            <br />
            Discover a cozy haven that feels like home, with peaceful nights and
            a relaxed atmosphere. Enjoy delicious local dishes to satisfy your
            taste buds, and join us in Indian celebrations, art workshops, and
            community activities to uplift your spirit.
          </p>
        </div>
        <div className="md:w-1/2 md:order-1 flex justify-center">
          <img src={About2} alt="About2" className="max-w-full md:max-w-3/4" />
        </div>
      </div>
    </div>
  );
};

export default MandawaKothi;
