import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import Navbar from "./navbar";
import Contact from "../assets/Contact us.webp";
import emailjs from "emailjs-com";

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "contact");

function Contactus() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobNo: "",
    country: "",
    details: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      mobNo: "",
      country: "",
      details: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(formsCollectionRef, {
        contact: formData,
      });
      const templateParams = {
        name: formData.name,
        email: formData.email,
      };
      // Next, send a request to trigger the Cloud Function
      const response = await emailjs.send(
        "service_4bymywe",
        "template_1z4vkmd",
        templateParams,
        "_y8URUrfpn2pG2TS8"
      );

      if (response.status === 200) {
        alert("Inquiry has been generated. We will get back to you soon.");
        resetFormAndCloseModal();
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Navbar />
      <section
        className="relative h-screen overflow-hidden"
        style={{ top: "-57px" }}
      >
        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <div className="text-center">
            <h1 className="mb-6 text-4xl font-extrabold leading-tight md:text-6xl lg:text-7xl text-palette-color-3 font-palette-font-1">
              Contact Us
            </h1>
            <br />
          </div>
        </div>
        <img
          src={Contact}
          alt="Contact"
          className="absolute top-0 left-0 z-0 object-cover w-full h-full"
        />
      </section>

      <div className="p-5 mt-2">
        <div className="flex flex-col items-center justify-center mb-9">
          <p className="mt-4">
            Be our guest! Let us know about your dream or need. We will convert
            it into a memorable journey.
          </p>
       

        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col justify-center w-[100%] md:w-1/2 mx-auto space-y-5"
        >
          <div className="flex flex-col justify-center space-y-2 md:space-x-2 md:flex-row">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              className="w-full p-2 border"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="w-full p-2 border"
              required
            />
          </div>
          <div className="flex flex-col justify-center space-y-2 md:space-x-2 md:flex-row">
            <input
              type="tel"
              pattern="[0-9]{10}"
              name="mobNo"
              value={formData.mobNo}
              onChange={handleChange}
              placeholder="Mobile No."
              className="w-full p-2 border"
              required
            />
            <input
              placeholder="Country"
              type="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="w-full p-2 border"
              required
            />
          </div>

          <div className="flex flex-col justify-center space-y-2 md:space-x-2 md:flex-row">
            <textarea
              placeholder="Message"
              name="details"
              value={formData.details}
              onChange={handleChange}
              className="w-full p-2 border"
              required
            />
          </div>
          <center>
            <button
              type="submit"
              className="px-6 py-3 font-bold text-white rounded-md bg-palette-color-4"
            >
              Send
            </button>
          </center>
        </form>
      </div>
    </>
  );
}

export default Contactus;
