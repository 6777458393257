/* eslint-disable import/first */
import React, { useState } from "react";
import Navbar from "../components/navbar";
import Yoga from "../assets/Health journey/yoga/main.jpeg.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faCheck,
  faPeopleGroup,
  faPersonWalkingLuggage,
} from "@fortawesome/free-solid-svg-icons";
import rickshaw from "../assets/rickshaw.png";
import {
  faHotel,
  faCar,
  faUtensils,
  faMoneyBillAlt,
  faWifi,
  faUtensilSpoon,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";
import spritual from "../assets/spiritual.png";
import Modal1 from "../components/Modal1";
import Delhi from "../assets/Health journey/yoga/delhi.webp";
import Agra from "../assets/Health journey/yoga/agra.webp";
import Jaipur from "../assets/Health journey/yoga/jaipur.webp";
import Rishikesh from "../assets/Health journey/yoga/rishikesh.webp";
import JaipurDelhi from "../assets/Health journey/yoga/jaipur -delhi.webp";
import Departure from "../assets/delhi departure1.webp";
import Map from "../assets/Health journey/yoga/map.png";
import Citydelhi from "../assets/Tours/Cities/Golden Tours/Colors of Rajasthan Expedition/delhi.jpg";
import Cityjaipur from "../assets/Tours/Cities/Rajasthan Tours/A Journey Through Heritage _ Wildlife/jaipur.jpg";
import Cityagra from "../assets/Festivals/cities/holi/Agra1.webp";

const Yogatour = () => {
  const [Open, setOpen] = useState(false);

  const [inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const excludedServices = [
    "Flights: International or Domestic",
    "Drinks: Unless specified.",
    "Visa: Obtain your Indian visa independently.",
    "Meals: Not specified.",
    "Tips: Housekeeping, bellboys, at your discretion.",
    "Insurance: Comprehensive travel insurance required.",
    "Expenses: Laundry, telephone bills.",
    "Entrance: Monument fees.",
    "Camera: Video camera fees.",
    "Health: Medical, baggage insurance.",
    "Taxes: Airport departure taxes.",
    "Others: Unspecified services.",
  ];

  return (
    <>
      <Navbar />
      <section
        className="relative h-screen overflow-hidden"
        style={{ top: "-57px" }}
      >
        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <div className="text-center">
            <h1 className="mb-6 text-5xl font-extrabold leading-tight md:text-6xl lg:text-7xl text-palette-color-3 font-palette-font-1">
              Yoga Escapes: Discover Balance & Rejuvenate
            </h1>
          </div>
        </div>
        <img
          src={Yoga}
          alt="Yoga Tour"
          className="absolute top-0 left-0 z-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 z-0 opacity-50 bg-palette-color-5"></div>
        <div className="absolute inset-x-0 bottom-0 p-2 font-palette-font-2 font-semibold text-center bg-palette-color-4 hidden md:block">
          <span className="mx-2">
            Delhi | Agra | Jaipur | Delhi | Rishikesh | Delhi
          </span>
        </div>
      </section>
      <div className="container mx-auto text-center mb-14">
        <h2 className="text-4xl text-palette-color-1 font-palette-font-1">
          Suitable for
        </h2>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4 mb-7 md:grid-cols-4">
        <div className="text-center">
          <FontAwesomeIcon
            icon={faBriefcase}
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Corporate Travel
          </p>
        </div>
        <div className="text-center">
          <img
            src={spritual}
            alt="Inclusion Icon"
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Spiritual, Cultural, Artistic & Gastronomic Interests
          </p>
        </div>

        <div className="text-center">
          <FontAwesomeIcon
            icon={faPeopleGroup}
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Friends & Family Travel
          </p>
        </div>
        <div className="text-center">
          <FontAwesomeIcon
            icon={faPersonWalkingLuggage}
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Solo Travel
          </p>
        </div>
      </div>
      <div className="mb-8 text-3xl text-center text-palette-color-18 font-palette-font-1">
        Discover peace through yoga practice!
      </div>
      <p
        className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
          inView ? "animate-fade-in" : ""
        }`}
      >
        Yoga is an ancient and intricate practice deeply rooted in Indian
        philosophy. It originally served as a spiritual discipline but has
        evolved into a popular approach for enhancing physical and mental
        well-being. It combines physical postures, breath control, meditation,
        and ethical principles to enhance flexibility, strength, and mental
        clarity. It is renowned for its stress-reduction benefits, promoting
        relaxation and mindfulness.
        <br />
        <br />
        Beyond the physical, yoga serves as a spiritual path, facilitating
        self-discovery and self-realization. Moreover, it acts as a cultural
        bridge between Eastern and Western traditions. The word "yoga" first
        appears in the Rig Veda, originating from the Sanskrit "yuj," meaning
        "union" or "to join." The roots of yoga trace back to ancient India,
        with a history spanning over 5,000 years. The "Yoga Sutras," penned by
        Sage Patanjali around 200 CE, offer a foundational exposition of yoga's
        principles, and it has evolved into diverse schools and practices over
        time.
        <br />
        <br />
        Yoga predominantly focuses on physical postures (asanas), breathing
        techniques (pranayama), and meditation (dhyana), even though classical
        yoga encompasses more components.
        <br />
        <br />
        Yoga comes in various forms, catering to diverse needs, some of them are
        mentioned here which will help you to understand yoga.
        <br />
        <br />
        <b>Hatha Yoga:</b> Basic postures and breathing exercises.
        <br />
        <b>Vinyasa Yoga:</b> Fluid, dynamic movements with breath.
        <br />
        <b>Bikram Yoga:</b> Intense yoga practiced in a heated room.
        <br />
        <b>Ashtanga Yoga:</b> Fast-paced, set sequence of postures.
        <br />
        <b>Iyengar Yoga:</b> Precise alignment with props for support.
        <br />
        <b>Kundalini Yoga:</b> Focuses on awakening inner energy.
        <br />
        <b>Yin Yoga:</b> Deep stretches held for longer periods.
        <br />
        <b>Restorative Yoga:</b> Gentle poses with props for relaxation.
        <br />
      </p>
      <div className="flex flex-wrap justify-center bg-palette-color-2">
        <div className="flex flex-wrap">
          <div className="w-full overflow-y-auto lg:w-3/4">
            <section className="relative w-full px-4 pt-8 pb-14 itinerary-section md:px-12 lg:px-24">
              <div className="flex items-center pt-4 mb-10 max-w-5xl mx-auto">
                <div className="flex-shrink-0 mr-4 arrow-box">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                    <span className="text-2xl transform rotate-45 text-palette-color-4">
                      &#8594;
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Delhi
                  </h2>
                  <p
                    className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Discover Delhi, where history and modernity intertwine in a
                    captivating dance. Vibrant, contemporary, and bursting with
                    colors. In Old Delhi, you'll experience the rich tapestry of
                    humanity in all its hues and sensations.
                  </p>
                </div>
              </div>
              <div className="max-w-5xl mx-auto">
                <img
                  src={Citydelhi}
                  alt="Citydelhi"
                  className="w-full h-auto my-4 rounded-lg"
                />
              </div>
              <div className="flex items-center pt-8 mb-10 max-w-5xl mx-auto">
                <div className="flex-shrink-0 mr-4 arrow-box">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                    <span className="text-2xl transform rotate-45 text-palette-color-4">
                      &#8594;
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Agra
                  </h2>
                  <p
                    className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Enter a realm where the echoes of the Mughal empire still
                    linger, and uncover a marvel born from a timeless love tale:
                    The Taj Mahal. Explore intriguing tombs, stately forts, and
                    vibrant marketplaces that eagerly await your presence.
                  </p>
                </div>
              </div>
              <div className="max-w-5xl mx-auto">
                <img
                  src={Cityagra}
                  alt="Cityagra"
                  className="w-full h-auto my-4 rounded-lg"
                />
              </div>
              <div className="flex items-center pt-8 mb-10 max-w-5xl mx-auto">
                <div className="flex-shrink-0 mr-4 arrow-box">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                    <span className="text-2xl transform rotate-45 text-palette-color-4">
                      &#8594;
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Jaipur
                  </h2>
                  <p
                    className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Discover the heart of Rajasthan, where the historical and
                    vibrant Pink City, once home to the Jaipur Royal Family,
                    seamlessly blends the past with the present in a truly
                    unique experience.
                  </p>
                </div>
              </div>
              <div className="max-w-5xl mx-auto">
                <img
                  src={Cityjaipur}
                  alt="Cityjaipur"
                  className="w-full h-auto my-4 rounded-lg"
                />
              </div>
              <div className="flex items-center pt-8 mb-10 max-w-5xl mx-auto">
                <div className="flex-shrink-0 mr-4 arrow-box">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                    <span className="text-2xl transform rotate-45 text-palette-color-4">
                      &#8594;
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Rishikesh
                  </h2>
                  <p
                    className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Rishikesh is a serene town in northern India, nestled in the
                    Himalayan foothills. It's renowned as the "Yoga Capital of
                    the World," attracting yoga enthusiasts and spiritual
                    seekers. Situated along the sacred Ganges River, it's also a
                    hub for adventure tourism, offering activities like
                    white-water rafting and trekking. The city's spiritual
                    significance and breathtaking natural beauty make it a
                    popular destination for those seeking both inner peace and
                    outdoor adventure.
                  </p>
                </div>
              </div>
              <div className="max-w-5xl mx-auto">
                <img
                  src={Rishikesh}
                  alt="Rishikesh"
                  className="w-full h-auto my-4 rounded-lg"
                />
              </div>
            </section>
            <div className="flex flex-wrap bg-palette-color-2">
              {/* Highlights Section */}
              <section className="w-full px-4 pt-4 md:px-12 lg:px-10 lg:w-1/2">
                {" "}
                <div className="max-w-3xl pt-4 mx-auto mb-8">
                  <div className="p-4 bg-white rounded-lg shadow-md">
                    <h2 className="mb-4 text-2xl text-center font-semibold font-palette-font-1">
                      Highlights
                    </h2>
                    <ul className="list-none">
                      <li className="flex text-sm items-center mb-2 font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-1 text-palette-color-4"
                        />
                        Delhi’s vibrant culture, historic landmarks
                      </li>
                      <li className="flex text-sm items-center mb-2 font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-1 text-palette-color-4"
                        />
                        Taj Mahal's enchantment at sunrise and sunset.
                      </li>
                      <li className="flex text-sm items-center mb-2 font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-1 text-palette-color-4"
                        />
                        Jaipur’s royal palaces, markets, and architectural
                        wonders.
                      </li>
                      <li className="flex text-sm items-center mb-2 font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-1 text-palette-color-4"
                        />
                        Yoga and meditation in the Yoga Capital of the World.
                      </li>
                    </ul>
                  </div>
                </div>
              </section>

              {/* Inclusions Section */}
              <section className="w-full px-4 pt-4 md:px-12 lg:px-10 lg:w-1/2">
                {" "}
                <div className="max-w-3xl pt-4 mx-auto mb-8 text-center">
                  <div className="p-4 bg-white rounded-lg shadow-md">
                    <h2 className="mb-4 text-2xl font-semibold font-palette-font-1">
                      Inclusions
                    </h2>
                    <div className="grid grid-cols-2 gap-2 sm:grid-cols-4 sm:gap-4">
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faHotel}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">6 nights, chosen hotels</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCar}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">Local driver, guide</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faUtensils}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">Daily breakfast</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faMoneyBillAlt}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">All costs covered</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faWifi}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">In-car Wi-Fi</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faUtensilSpoon}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">Cooking class, dinner</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faPlaneDeparture}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">Airport transfer</p>
                      </div>

                      <div className="flex flex-col items-center font-palette-font-2">
                        <img
                          src={rickshaw}
                          alt="Inclusion Icon"
                          className="h-6 mb-2"
                        />

                        <p className="text-xs">Delhi rickshaw</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <section className="w-full px-4 pt-10 itinerary-section md:px-12 lg:px-20">
              <div className="mx-auto">
                <h2 className="mb-8 text-4xl text-palette-color-1 font-palette-font-1">
                  Detailed Itinerary
                </h2>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md font-palette-font-2">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 1-3: Delhi
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Delhi}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        <br />
                        Arriving at Delhi Airport, we'll welcome you and escort
                        you to your comfy hotel. If you're an early riser,
                        explore Delhi's rich history and culture with iconic
                        sights like India Gate, Qutub Minar, and a rickshaw ride
                        through Old Delhi. Visit important landmarks like Red
                        Fort and Humayun's Tomb. Immerse in spirituality at
                        Lotus Temple and Akshardham Temple. Enjoy the vibrant
                        Chandni Chowk market. Let us customize your journey to
                        experience Delhi's perfect blend of history and modern
                        vibrancy.
                      </p>
                    </div>
                  </div>
                  <p className="text-md">
                    Certain places will be explored at your convenience and
                    based on your personal preferences and schedule.
                    <br />
                    <b>Next Stop: </b>Agra <br />
                    Just a 4-hour drive (230 km) from Delhi, a world of wonder
                    awaits you in the City of Love.
                  </p>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 3-4: Agra
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Agra}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        <br />
                        Explore Agra's romantic and historic world with a sunset
                        view of the Taj Mahal from the serene Yamuna Riverbanks.
                        Visit the Red Fort if time permits. Witness the Taj
                        Mahal's magic at sunrise and sunset, but remember it's
                        closed on Fridays. Discover more treasures like Fatehpur
                        Sikri, Akbar's Tomb, Jama Masjid, and Itimad-ud-Daula's
                        Tomb. Agra is a city intertwined with the Mughal legacy,
                        from the grand Agra Fort to the enchanting Fatehpur
                        Sikri, showcasing India's imperial history.
                      </p>
                    </div>
                  </div>
                  <p className="text-md">
                    <b>Next Stop: </b>Jaipur <br />A seamless transition in
                    4.5-Hour Journey (250 km)
                  </p>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 4-6: Jaipur
                  </h3>
                  <p>
                    Experience Jaipur, Rajasthan's enchanting capital founded in
                    1727 by Maharaja Sawai Jai Singh II. Marvel at the
                    architectural splendor of Hawa Mahal, City Palace, and the
                    planned layout based on Vastu Shastra.
                  </p>
                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Jaipur}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        This Pink City seamlessly melds its royal past with
                        modern vibrancy. Explore historic treasures like City
                        Palace, Jal Mahal, Hawa Mahal, and the 17th-century
                        Jantar Mantar Observatory. Wander through the
                        captivating Old Walled City, Amber Fort, and Birla
                        Temple. Indulge in the local markets of Johri Bazar and
                        Choti Chopper, renowned for unique jewelry. Jaipur –
                        where history, culture, and markets intertwine for an
                        unforgettable journey.
                      </p>
                    </div>
                  </div>
                  <p className="text-md">
                    <b>Next Stop: </b>Delhi <br />
                    Come back to Delhi to go to Rishikesh for beginning yoga
                    journey
                  </p>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 6: Delhi
                  </h3>

                  <div style={{ display: "flex" }}>
                    <div className="w-full text-md">
                      <p>
                        In the morning we’ll be back to Delhi & have rest for
                        some hours then travel to Rishikesh for experiencing
                        Yoga World.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 7: Rishikesh
                  </h3>

                  <div style={{ display: "flex" }}>
                    <div className="w-full text-md">
                      <p>
                        In the morning, next day we’ll go to Rishikesh- The Land
                        o fYoga. Upon arrival, we'll take you to your hotel. In
                        the afternoon, you'll enjoy a city tour, including a
                        visit to some well-known ashrams that are famous for
                        philosophy, yoga, and meditation. Rishikesh hosts an
                        international yoga week every year from February 2nd to
                        7th, organized by Uttaranchal Tourism. You'll check in
                        at one of these ashrams.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 8-12: Beginning of Yoga Journey- Rishikesh
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Rishikesh}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        <br />
                        During your stay, you'll have the opportunity to engage
                        in a variety of activities. Your day will begin with
                        meditation classes followed by invigorating yoga
                        sessions to promote physical and mental well-being.
                        Afterward, you'll enjoy a wholesome breakfast and later
                        savor a delicious lunch. In the afternoon, there will be
                        enlightening lectures to enhance your knowledge. You'll
                        have a tea break before engaging in more yoga and
                        meditation classes, lasting into the early evening. Your
                        day will conclude with a hearty dinner, allowing you to
                        fully immerse yourself in this holistic and rejuvenating
                        experience.
                      </p>
                    </div>
                  </div>
                  <p className="text-md">
                    <b>Next Stop: </b>Delhi <br />A 3.5-hour drive covering 255
                    km.
                  </p>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 13: Delhi
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={JaipurDelhi}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        <br />
                        You'll begin your day with an advanced yoga session,
                        which includes a question-and-answer session to gauge
                        the positive transformation during the course. Following
                        breakfast, we'll provide a comfortable car transfer to
                        Delhi. Upon reaching Delhi, you'll check in at the hotel
                        for an overnight stay.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 14: Departure
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Departure}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        You have the choice to unwind at your hotel, preparing
                        for your departure the next day. Alternatively, if
                        you're feeling energetic and wish to explore, Delhi
                        offers a variety of markets to visit: Chandni Chowk
                        Market, the vibrant Kinari Bazaar (Wedding Market), the
                        bustling Textile Market (Wholesale Market), the Book
                        Market, and the fascinating Spice Market, known for both
                        ground and whole spices.
                        <br />
                        If your interests lean towards art and architecture, you
                        might consider visiting the Fateh Puri Mosque, Sis Ganj
                        Gurudwara, Jain Temple, and the Shiva Temple. The choice
                        is yours, tailored to your pace and preferences.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 15
                  </h3>

                  <div style={{ display: "flex" }}>
                    <div className="w-full text-md">
                      <p>
                        With a sense of relaxation and readiness to journey back
                        home, your dedicated driver will ensure you reach the
                        airport promptly, in accordance with your flight
                        schedule.
                        <br />
                        <b>Safe travel!</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* "Book the tour" section */}
            <div className="h-px my-6 bg-palette-color-4"></div>{" "}
            <div className="flex items-center justify-center my-6 space-x-4">
              <button className="px-4 py-2 text-white rounded-lg font-palette-font-3 bg-palette-color-4">
                Book this tour or modify this itinerary
              </button>
            </div>
            <div className="h-px my-6 bg-palette-color-4"></div>{" "}
            {/* Excluded Services Section */}
            <section className="px-4 py-8 mb-4 bg-palette-color-2 md:px-12 lg:px-24">
              <div className="max-w-3xl mx-auto text-center">
                <div className="flex flex-col items-center mb-4">
                  <h1 className="mb-4 font-semibold text-palette-color-4 font-palette-font-1">
                    Excluded Services in this Journey:
                  </h1>
                  <button
                    onClick={toggleCollapse}
                    className="flex items-center justify-center text-white rounded-full bg-palette-color-4 hover:bg-palette-color-5"
                  >
                    <svg
                      className={`w-6 h-6 transform ${
                        isCollapsed ? "rotate-90" : "rotate-180"
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d={isCollapsed ? "M9 5l7 7-7 7" : "M5 9l7 7 7-7"}
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                    isCollapsed ? "max-h-0" : "max-h-screen"
                  }`}
                >
                  <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
                    {excludedServices.map((service, index) => (
                      <div key={index} className="flex items-start">
                        <svg
                          className="flex-shrink-0 w-4 h-4 mr-2 text-palette-color-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                        <p className="text-left font-palette-font-2 text-palette-color-1">
                          {service}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="w-full px-4 lg:w-1/4">
            <section className="sticky overflow-y-scroll h-screen p-4 mb-8 bg-white rounded-lg shadow-md top-12">
              {/* Map */}
              <img src={Map} alt="map" />

              {/* Price */}
              <p className="mt-1 font-semibold text-l font-palette-font-1 text-palette-color-4">
                Yoga Escapes: Discover Balance & Rejuvenate
              </p>
              <div className="p-4 bg-white rounded-lg shadow-md">
                <h2 className="mb-4 text-xl font-palette-font-1 font-semibold">
                  Price-Per Person Starting From:
                </h2>
                <ul className="mb-4 space-y-2 font-palette-font-2 list-disc list-inside">
                  <li className="text-palette-color-4 lg:text-sm">Pocket Friendly: $1538USD</li>
                  <li className="text-palette-color-4 lg:text-sm">Semi Luxury: $2097USD</li>
                  <li className="text-palette-color-4 lg:text-sm">Luxury: $3962USD</li>
                </ul>
                <button
                  className="block px-4 py-2 mx-auto text-sm font-palette-font-3 font-semibold text-white rounded-full bg-palette-color-4 hover:bg-palette-color-5"
                  onClick={() => setOpen(true)}
                >
                  Book This Journey
                </button>
              </div>
            </section>
          </div>
          <Modal1 open={Open} onClose={() => setOpen(false)}></Modal1>
        </div>
      </div>
    </>
  );
};

export default Yogatour;
