/* eslint-disable import/first */
import React, { useState } from "react";
import Navbar from "../components/navbar";
import Ayurveda from "../assets/Health journey/ayurveda/main.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faCheck,
  faPeopleGroup,
  faPersonWalkingLuggage,
} from "@fortawesome/free-solid-svg-icons";
import rickshaw from "../assets/rickshaw.png";
import {
  faHotel,
  faCar,
  faUtensils,
  faMoneyBillAlt,
  faWifi,
  faUtensilSpoon,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";
import spritual from "../assets/spiritual.png";
import Modal1 from "../components/Modal1";
import Mumbai from "../assets/Health journey/ayurveda/mumbai.webp";
import Cochin from "../assets/Health journey/ayurveda/cochin.webp";
import Kumarakom from "../assets/Health journey/ayurveda/Kumarakom.webp";
import Alleppey from "../assets/Health journey/ayurveda/Alleppey.webp";
import Thekkady from "../assets/Health journey/ayurveda/thekkady.webp";
import Munnar from "../assets/Health journey/ayurveda/munnar.webp";
import Departure from "../assets/delhi departure1.webp";
import Map from "../assets/Health journey/ayurveda/map.png";
import CityAlleppey from "../assets/Tours/Cities/South Tours/Coastal Treasures _ Mountain Magic/alleppey.jpg";
import Citycochin from "../assets/Tours/Cities/South Tours/Coastal Treasures _ Mountain Magic/Cochin.jpg";
import Citymumbai from "../assets/Tours/Cities/South Tours/Coastal Treasures _ Mountain Magic/Mumbai.jpg";
import CityThekkedy from "../assets/Tours/Cities/South Tours/Coastal Treasures _ Mountain Magic/Thekkady.jpg";
import CityMunnar from "../assets/Tours/Cities/South Tours/Coastal Treasures _ Mountain Magic/Munnar.jpg";

const Ayurvedatour = () => {
  const [Open, setOpen] = useState(false);

  const [inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const excludedServices = [
    "Flights: International or Domestic",
    "Drinks: Unless specified.",
    "Visa: Obtain your Indian visa independently.",
    "Meals: Not specified.",
    "Tips: Housekeeping, bellboys, at your discretion.",
    "Insurance: Comprehensive travel insurance required.",
    "Expenses: Laundry, telephone bills.",
    "Entrance: Monument fees.",
    "Camera: Video camera fees.",
    "Health: Medical, baggage insurance.",
    "Taxes: Airport departure taxes.",
    "Others: Unspecified services.",
  ];

  return (
    <>
      <Navbar />
      <section
        className="relative h-screen overflow-hidden"
        style={{ top: "-57px" }}
      >
        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <div className="text-center">
            <h1 className="mb-6 text-5xl font-palette-font-1 font-extrabold leading-tight md:text-6xl lg:text-7xl text-palette-color-3 font-palette-font-1">
              Ayurveda Journey: Explore the Science of Wellness
            </h1>
          </div>
        </div>
        <img
          src={Ayurveda}
          alt="Ayurveda Tour"
          className="absolute top-0 left-0 z-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 z-0 opacity-50 bg-palette-color-5"></div>
        <div className="absolute inset-x-0 bottom-0 p-2 font-palette-font-2 font-semibold text-center bg-palette-color-4 hidden md:block">
          <span className="mx-2">
            Mumbai | Cochin | Kumarakom | Alleppey | Thekkedy | Munnar | Mumbai
          </span>
        </div>
      </section>
      <div className="container mx-auto text-center mb-14">
        <h2 className="text-4xl text-palette-color-1 font-palette-font-1">
          Suitable for
        </h2>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4 mb-7 md:grid-cols-4">
        <div className="text-center">
          <FontAwesomeIcon
            icon={faBriefcase}
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Corporate Travel
          </p>
        </div>
        <div className="text-center">
          <img
            src={spritual}
            alt="Inclusion Icon"
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Spiritual, Cultural, Artistic & Gastronomic Interests
          </p>
        </div>

        <div className="text-center">
          <FontAwesomeIcon
            icon={faPeopleGroup}
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Friends & Family Travel
          </p>
        </div>
        <div className="text-center">
          <FontAwesomeIcon
            icon={faPersonWalkingLuggage}
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Solo Travel
          </p>
        </div>
      </div>
      <div className="mb-8 text-3xl text-center text-palette-color-18 font-palette-font-1">
        Discover Wellness Through Ayurveda
      </div>
      <p
        className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
          inView ? "animate-fade-in" : ""
        }`}
      >
        Ayurveda, a natural health system, originated more than 3,000 years ago
        in India. The term "Ayurveda" combines "ayur" (life) and "veda"
        (knowledge), meaning "knowledge of life." It believes that disease
        results from imbalances in one's consciousness, emphasizing lifestyle
        changes and natural therapies to restore harmony between body, mind,
        spirit, and the environment.
        <br />
        <br />
        Ayurvedic treatment begins with internal cleansing, followed by tailored
        diets, herbal remedies, massages, yoga, and meditation.
        <br />
        <br />
        Central to Ayurveda are the ideas of interconnectedness, individual body
        constitution (prakriti), and life forces (doshas). Treatment goals
        include eliminating impurities, reducing symptoms, enhancing disease
        resistance, reducing stress, and promoting life balance. Ayurvedic
        remedies extensively use herbs, oils, and spices.
        <br />
        <br />
        In India, Ayurveda is a recognized medical practice, on par with
        Western, Chinese, naturopathic, and homeopathic medicine. Indian
        Ayurvedic practitioners undergo formal, state-recognized training. In
        the United States, Ayurvedic practitioners lack national licensing, and
        standards vary. However, some states have approved Ayurvedic schools as
        educational institutions.
        <br />
        <br />
        Join us in this journey to discover more about Ayurveda!
      </p>
      <div className="flex flex-wrap justify-center bg-palette-color-2">
        <div className="flex flex-wrap">
          <div className="w-full overflow-y-auto lg:w-3/4">
            <section className="relative w-full px-4 pt-8 pb-14 itinerary-section md:px-12 lg:px-24">
              <div className="flex items-center pt-4 mb-10 max-w-5xl mx-auto">
                <div className="flex-shrink-0 mr-4 arrow-box">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                    <span className="text-2xl transform rotate-45 text-palette-color-4">
                      &#8594;
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Mumbai
                  </h2>
                  <p
                    className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Mumbai, often referred to as Bombay, is the heartbeat of
                    India's urban landscape. This bustling metropolis on the
                    western coast is a captivating fusion of diverse cultures
                    and pulsating energy. With its iconic landmarks like the
                    Gateway of India, a rich cosmopolitan lifestyle, and an
                    endless array of experiences, Mumbai is a city that never
                    ceases to amaze, inviting you to explore its vibrant soul.
                  </p>
                </div>
              </div>
              <div className="max-w-5xl mx-auto">
                <img
                  src={Citymumbai}
                  alt="Citymumbai"
                  className="w-full h-auto my-4 rounded-lg"
                />
              </div>
              <div className="flex items-center pt-8 mb-10 max-w-5xl mx-auto">
                <div className="flex-shrink-0 mr-4 arrow-box">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                    <span className="text-2xl transform rotate-45 text-palette-color-4">
                      &#8594;
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Cochin
                  </h2>
                  <p
                    className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Cochin, often hailed as the Queen of the Arabian Sea, is a
                    bustling commercial hub steeped in a rich trading legacy
                    spanning over six centuries. Here, the echoes of Arabian,
                    Dutch, Phoenician, Portuguese, Chinese, and British
                    influences are woven into the city's vibrant tapestry. As
                    the financial, commercial, and industrial heart of Kerala,
                    Cochin invites you to immerse yourself in its captivating
                    blend of history and commerce.
                  </p>
                </div>
              </div>
              <div className="max-w-5xl mx-auto">
                <img
                  src={Citycochin}
                  alt="Citycochin"
                  className="w-full h-auto my-4 rounded-lg"
                />
              </div>
              <div className="flex items-center pt-8 mb-10 max-w-5xl mx-auto">
                <div className="flex-shrink-0 mr-4 arrow-box">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                    <span className="text-2xl transform rotate-45 text-palette-color-4">
                      &#8594;
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Kumarakom
                  </h2>
                  <p
                    className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Kumarakom is a serene village in Kerala, India, known for
                    its mesmerizing backwaters. Visitors can explore these
                    tranquil waterways on houseboat cruises and enjoy the rich
                    birdlife in the Kumarakom Bird Sanctuary. The village is
                    also a center for Ayurvedic wellness and offers a peaceful
                    retreat amid nature's beauty.
                  </p>
                </div>
              </div>
              <div className="max-w-5xl mx-auto">
                <img
                  src={Kumarakom}
                  alt="Kumarakom"
                  className="w-full h-auto my-4 rounded-lg"
                />
              </div>
              <div className="flex items-center pt-8 mb-10 max-w-5xl mx-auto">
                <div className="flex-shrink-0 mr-4 arrow-box">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                    <span className="text-2xl transform rotate-45 text-palette-color-4">
                      &#8594;
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Alleppey
                  </h2>
                  <p
                    className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    <div>
                      In the coastal town of Alleppey, a world of unforgettable
                      experiences unfolds. From idyllic houseboat stays to
                      basking on pristine beaches, the options for creating
                      lasting memories are abundant, especially for first-time
                      visitors.
                      <br />
                      Nestled amid lush greenery, where coconut trees gracefully
                      bow over the serene waters, Alleppey's backwaters offer a
                      unique journey. These tranquil channels are navigated by a
                      variety of traditional boats, most notably the iconic
                      Kerala River boats, promising an enchanting exploration
                      like no other.
                    </div>
                  </p>
                </div>
              </div>
              <div className="max-w-5xl mx-auto">
                <img
                  src={CityAlleppey}
                  alt="CityAlleppey"
                  className="w-full h-auto my-4 rounded-lg"
                />
              </div>
              <div className="flex items-center pt-8 mb-10 max-w-5xl mx-auto">
                <div className="flex-shrink-0 mr-4 arrow-box">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                    <span className="text-2xl transform rotate-45 text-palette-color-4">
                      &#8594;
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Thekkedy
                  </h2>
                  <p
                    className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    In Thekkady, prepare for a sensory feast that includes the
                    majestic presence of elephants, fragrant spice plantations,
                    and picturesque hills. The Periyar forest, a true national
                    treasure, cradles within it the most vibrant array of flora
                    and fauna found anywhere on Earth, including captivating
                    migratory species that grace these lands. Your journey here
                    promises an immersive encounter with the awe-inspiring
                    wonders of nature.
                  </p>
                </div>
              </div>
              <div className="max-w-5xl mx-auto">
                <img
                  src={CityThekkedy}
                  alt="CityThekkedy"
                  className="w-full h-auto my-4 rounded-lg"
                />
              </div>
              <div className="flex items-center pt-8 mb-10 max-w-5xl mx-auto">
                <div className="flex-shrink-0 mr-4 arrow-box">
                  <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                    <span className="text-2xl transform rotate-45 text-palette-color-4">
                      &#8594;
                    </span>
                  </div>
                </div>
                <div>
                  <h2
                    className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    Munnar
                  </h2>
                  <p
                    className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                      inView ? "animate-fade-in" : ""
                    }`}
                  >
                    <div>
                      Munnar, the cherished hill-station of Kerala, rests
                      gracefully at an elevation of 1600 meters amidst the
                      Western Ghats. Often referred to as the "Kashmir of South
                      India", it's a place of sheer natural splendor.
                      <br />
                      Perched along the banks of three pristine rivers -
                      Madupetti, Nallathanni, and Periavaru, Munnar also boasts
                      of natural bird sanctuaries and vast tea plantations.
                      Prepare to be enchanted by the alpine wonders of this
                      picturesque destination.
                    </div>
                  </p>
                </div>
              </div>
              <div className="max-w-5xl mx-auto">
                <img
                  src={CityMunnar}
                  alt="CityMunnar"
                  className="w-full h-auto my-4 rounded-lg"
                />
              </div>
            </section>
            <div className="flex flex-wrap bg-palette-color-2">
              {/* Highlights Section */}
              <section className="w-full px-4 pt-4 md:px-12 lg:px-10 lg:w-1/2">
                {" "}
                <div className="max-w-3xl pt-4 mx-auto mb-8 text-center">
                  <div className="p-4 bg-white rounded-lg shadow-md">
                    <h2 className="mb-4 text-2xl font-semibold font-palette-font-1">
                      Highlights
                    </h2>
                    <ul className="list-none">
                      <li className="flex text-md items-center mb-2 font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-2 text-palette-color-4"
                        />
                        Mumbai landmarks and the cinematic charm
                      </li>
                      <li className="flex text-sm items-center mb-2 font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-2 text-palette-color-4"
                        />
                        Personalized wellness in Kumarakom for body and soul.
                      </li>
                      <li className="flex text-sm items-center mb-2 font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-2 text-palette-color-4"
                        />
                        Cruise through Alleppey's enchanting Backwaters.
                      </li>
                      <li className="flex text-sm items-center mb-2 font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-2 text-palette-color-4"
                        />
                        Thekkady's sanctuary and spice-rich exploration.
                      </li>
                      <li className="flex text-sm items-center mb-2 font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="mr-2 text-palette-color-4"
                        />
                        Munnar's tea gardens and serene landscapes.
                      </li>
                    </ul>
                  </div>
                </div>
              </section>

              {/* Inclusions Section */}
              <section className="w-full px-4 pt-4 md:px-12 lg:px-10 lg:w-1/2">
                {" "}
                <div className="max-w-3xl pt-4 mx-auto mb-8 text-center">
                  <div className="p-4 bg-white rounded-lg shadow-md">
                    <h2 className="mb-4 text-2xl font-semibold font-palette-font-1">
                      Inclusions
                    </h2>
                    <div className="grid grid-cols-2 gap-2 sm:grid-cols-4 sm:gap-4">
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faHotel}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">6 nights, chosen hotels</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faCar}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">Local driver, guide</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faUtensils}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">Daily breakfast</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faMoneyBillAlt}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">All costs covered</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faWifi}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">In-car Wi-Fi</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faUtensilSpoon}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">Cooking class, dinner</p>
                      </div>
                      <div className="flex flex-col items-center font-palette-font-2">
                        <FontAwesomeIcon
                          icon={faPlaneDeparture}
                          className="h-4 mb-2 text-palette-color-4"
                        />

                        <p className="text-xs">Airport transfer</p>
                      </div>

                      <div className="flex flex-col items-center font-palette-font-2">
                        <img
                          src={rickshaw}
                          alt="Inclusion Icon"
                          className="h-6 mb-2"
                        />

                        <p className="text-xs">Delhi rickshaw</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <section className="w-full px-4 pt-10 itinerary-section md:px-12 lg:px-20">
              <div className="mx-auto">
                <h2 className="mb-8 text-4xl text-palette-color-1 font-palette-font-1">
                  Detailed Itinerary
                </h2>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md font-palette-font-2">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 1-3: Mumbai
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Mumbai}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        <br />
                        Immerse yourself in the vibrant pulse of this dynamic
                        metropolis and explore the mesmerizing Elephanta Caves.
                        Journey through the heart of Mumbai to discover Dharavi,
                        a locale that has served as the backdrop for iconic
                        Bollywood movies, including the Oscar-winning
                        masterpiece, <b>"Slumdog Millionaire"</b>.
                      </p>
                    </div>
                  </div>
                  <p className="text-md">
                    Join us for an unforgettable experience as we unlock the
                    essence of this incredible city, blending tradition and
                    modernity in a way that's truly captivating.
                    <br />
                    <b>Next Stop: </b>Cochin <br />A mere 2-hour flight from
                    Mumbai
                  </p>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 3-5: Cochin
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Cochin}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        <br />
                        Upon arrival at Cochin Airport, our team will warmly
                        welcome you with a personalized sign, ensuring a smooth
                        transition to your journey.
                        <br />
                        <br />
                        For those with early arrivals, the option to explore
                        Cochin awaits, tailored to your preferences and
                        schedule. We'll curate your visit to match your
                        interests, making the most of your time.
                      </p>
                    </div>
                  </div>
                  <p className="text-md">
                    <b>Next Stop: </b>Kumarakom <br />
                    Your journey continues with a 45 mins drive, covering 48 km
                  </p>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 5-11: Kumarakom
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Kumarakom}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        Days 5 to 11 are dedicated to rejuvenating your mind,
                        body, and soul through Ayurveda practices. A resident
                        doctor will personalize your program, therapies, and
                        meals during your stay. Your diet will consist of
                        vegetarian food only, with no alcohol or tobacco
                        permitted.
                        <br />
                        You'll experience herbal body massages, yoga, meditation
                        sessions, and nutritious yogic meals. Enjoy rejuvenating
                        activities like steam baths and beauty treatments. If
                        desired, you can consult with the resident doctor. These
                        7 days will leave you completely refreshed, and you'll
                        have free time to read Ayurveda books. We'll guide you
                        to a suitable location for this Ayurveda experience.
                      </p>
                    </div>
                  </div>
                  <p className="text-md">
                    <b>Next Stop: </b>Alleppey <br />
                    Your journey continues with a scenic 1-hour and 20-minute
                    drive, covering 55 km
                  </p>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 12-13: Alleppey
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Alleppey}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        <br />
                        Embark on a captivating boat ride along Vembanad Lake,
                        providing an intimate glimpse into the enchanting life
                        of Kerala's Backwaters. As you traverse this narrow
                        strip of land, you'll witness the livelihoods of fishing
                        communities, children gleefully swimming, and women
                        immersed in their daily routines.
                        <br />
                        Feel free to explore any intriguing spot you encounter
                        along the way, immersing yourself in the genuine magic
                        of these surroundings.
                      </p>
                    </div>
                  </div>
                  <p className="text-md">
                    <b>Next Stop: </b>Thekkady <br />
                    Your journey continues with a scenic 3-hour and 30-minute
                    drive, spanning 140 kilometers, leading you to the pristine
                    allure of Thekkady.
                  </p>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 13-14: Thekkady
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Thekkady}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        Embark on a thrilling boat expedition on Lake Periyar,
                        where you'll have the chance to witness captivating
                        wildlife and explore one of India's exceptional animal
                        sanctuaries. Immerse yourself in the untamed beauty of
                        this unique destination.
                        <br />
                        Venture into the fragrant spice plantations, where
                        cardamom, cinnamon, pepper, and coffee thrive. Don't
                        forget to savor the flavors of the tea estates that
                        abound in the region. For a taste of Kerala to take
                        home, explore the local market and discover an array of
                        natural seasonings.
                      </p>
                    </div>
                  </div>
                  <p className="text-md">
                    <b>Next Stop: </b>Munnar <br />
                    Your journey continues with a scenic 3-hour drive, covering
                    90 kilometers.
                  </p>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 14-16: Munnar
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Munnar}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        <br />
                        Explore one of the world's highest tea-growing regions
                        as you immerse yourself in the charm of Munnar. Discover
                        the wonders of Eravikulam National Park, where the
                        endangered Nilgiri Thar (Ibex) roams freely. Visit the
                        iconic Mattupetty Dam, the Indo Swiss Dairy farm, and
                        the serene Kundala Lake.
                      </p>
                    </div>
                  </div>
                  <p className="text-md">
                    <b>Next Stop: </b>Mumbai <br />A quick 2-hour flight to the
                    bustling metropolis of Mumbai.
                  </p>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 16-17: Mumbai
                  </h3>

                  <div style={{ display: "flex" }}>
                    <div className="w-full text-md">
                      <p>
                        Immerse yourself in the boundless energy of Mumbai, an
                        impressive metropolis that never sleeps. Explore the
                        awe-inspiring Elephanta Caves, where history whispers
                        through the intricate rock-cut temples.
                        <br />
                        Venture into Dharavi, an area famed for its role in
                        Bollywood movies, including the Oscar-winning "Slumdog
                        Millionaire." Here, you'll witness the heart and soul of
                        Mumbai's vibrant cinematic culture.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-6 mb-8 bg-white rounded-lg shadow-md">
                  <h3 className="mb-4 text-lg font-semibold text-palette-color-12">
                    Day 18: Departure
                  </h3>

                  <div className="flex flex-col md:flex-row">
                      {" "}
                    <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                      <div className="flex items-center justify-center h-full">
                        <img
                          src={Departure}
                          alt="itineray"
                          className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                        />
                      </div>
                    </div>
                    <div className="w-full text-md md:w-2/3">
                      <p>
                        <br />
                        As your remarkable journey draws to a close, you'll find
                        yourself relaxed and rejuvenated, ready for your
                        homeward journey. Your dedicated driver will ensure a
                        seamless transfer to the airport, ensuring you depart
                        according to your flight schedule. We bid you farewell
                        with warm memories of your incredible adventure and look
                        forward to hosting you again in the future.
                        <br />
                        <b>Safe travel!</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* "Book the tour" section */}
            <div className="h-px my-6 bg-palette-color-4"></div>{" "}
            <div className="flex items-center justify-center my-6 space-x-4">
              <button className="px-4 py-2 text-white font-palette-font-3 rounded-lg bg-palette-color-4">
                Book this tour or modify this itinerary
              </button>
            </div>
            <div className="h-px my-6 bg-palette-color-4"></div>{" "}
            {/* Excluded Services Section */}
            <section className="px-4 py-8 mb-4 bg-palette-color-2 md:px-12 lg:px-24">
              <div className="max-w-3xl mx-auto text-center">
                <div className="flex flex-col items-center mb-4">
                  <h1 className="mb-4 font-semibold text-palette-color-4 font-palette-font-1">
                    Excluded Services in this Journey:
                  </h1>
                  <button
                    onClick={toggleCollapse}
                    className="flex items-center justify-center text-white rounded-full bg-palette-color-4 hover:bg-palette-color-5"
                  >
                    <svg
                      className={`w-6 h-6 transform ${
                        isCollapsed ? "rotate-90" : "rotate-180"
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d={isCollapsed ? "M9 5l7 7-7 7" : "M5 9l7 7 7-7"}
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                    isCollapsed ? "max-h-0" : "max-h-screen"
                  }`}
                >
                  <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
                    {excludedServices.map((service, index) => (
                      <div key={index} className="flex items-start">
                        <svg
                          className="flex-shrink-0 w-4 h-4 mr-2 text-palette-color-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                        <p className="text-left font-palette-font-2 text-palette-color-1">
                          {service}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="w-full px-4 lg:w-1/4">
            <section className="sticky overflow-y-scroll h-screen p-4 mb-8 bg-white rounded-lg shadow-md top-12">
              {/* Map */}
              <img src={Map} alt="map" />

              {/* Price */}
              <p className="mt-1 font-palette-font-1 font-semibold text-l text-palette-color-4">
                Ayurveda Journey: Explore the Science of Wellness
              </p>
              <div className="p-4 bg-white rounded-lg shadow-md">
                <h2 className="mb-4 text-xl font-palette-font-1 font-semibold">
                  Price-Per Person Starting From:
                </h2>
                <ul className="mb-4 space-y-2 font-palette-font-2 list-disc list-inside">
                  <li className="text-palette-color-4 lg:text-sm">Pocket Friendly: $1765USD</li>
                  <li className="text-palette-color-4 lg:text-sm">Semi Luxury: $2672USD</li>
                  <li className="text-palette-color-4 lg:text-sm">Luxury: $5061USD</li>
                </ul>
                <button
                  className="block px-4 py-2 mx-auto text-sm font-palette-font-3 font-semibold text-white rounded-full bg-palette-color-4 hover:bg-palette-color-5"
                  onClick={() => setOpen(true)}
                >
                  Book This Journey
                </button>
              </div>
            </section>
          </div>
          <Modal1 open={Open} onClose={() => setOpen(false)}></Modal1>
        </div>
      </div>
    </>
  );
};

export default Ayurvedatour;
