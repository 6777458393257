import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logoImage from "../assets/Layer logo 2.png";
// import SampleNavbar from "./SampleNavbar";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [isCuratedOpen, setIsCuratedOpen] = useState(false);
  const [isFestivalOpen, setIsFestivalOpen] = useState(false);
  const [isHealthOpen, setIsHealthOpen] = useState(false);
  const toursDropdownRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
      setIsCuratedOpen(false);
      setIsFestivalOpen(false);
      setIsHealthOpen(false);
    } else {
      setIsScrolled(false);
    }
  };

  const toggleTour = () => {
    setIsTourOpen(!isTourOpen);
  };

  const toggleCurated = () => {
    setIsCuratedOpen(!isCuratedOpen);
    setIsFestivalOpen(false);
    setIsHealthOpen(false);
  };

  const toggleFestival = () => {
    setIsFestivalOpen(!isFestivalOpen);
    setIsCuratedOpen(false);
    setIsHealthOpen(false);
  };

  const toggleHealth = () => {
    setIsHealthOpen(!isHealthOpen);
    setIsCuratedOpen(false);
    setIsFestivalOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      toursDropdownRef.current &&
      !toursDropdownRef.current.contains(event.target)
    ) {
      setIsTourOpen(false);
      setIsCuratedOpen(false);
      setIsFestivalOpen(false);
      setIsHealthOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <nav
      className={`sticky top-0 z-50 transition duration-300 ${
        isScrolled || isMenuOpen ? "bg-palette-color-4" : "bg-opacity-75"
      }`}
    >
      <div className="container text-md font-palette-font-2 flex items-center justify-between px-5 py-2 mx-auto">
        <Link to="/">
          <img src={logoImage} alt="Logo" className="h-10" />
        </Link>
        <div className="hidden space-x-12 md:flex">
          {/* Dropdown menu for Tours */}
          <div
            ref={toursDropdownRef}
            className="relative group"
            onClick={(e) => {
              e.stopPropagation();
              toggleTour();
            }}
          >
            <span className="cursor-pointer text-palette-color-3 font-palette-font-2 transition duration-150 ease-in-out motion-reduce:transition-none hover:text-palette-color-3">
              Journeys
            </span>
            <div
              className={`${
                isTourOpen ? "block" : "hidden"
              } absolute top-full left-0 w-48 bg-palette-color-4 text-palette-color-3 border border-palette-color-3 mt-2 [&[data-te-dropdown-show]]:block transition ease-out duration-200 p-2 z-10`}
            >
              <div className="relative group">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCurated();
                  }}
                  className="block cursor-pointer hover:text-palette-color-1"
                >
                  Curated Tours
                </span>
                <div
                  className={`${
                    isCuratedOpen ? "block" : "hidden"
                  } absolute left-full top-0 w-48 bg-palette-color-4 text-palette-color-3 border border-palette-color-3 ml-2 mt-0 p-2 z-10`}
                >
                  <Link
                    to="/?scrollTo=trips"
                    className="block hover:text-palette-color-1"
                  >
                    The Royal Rajasthan
                  </Link>
                  <Link
                    to="/?scrollTo=trips"
                    className="block hover:text-palette-color-1"
                  >
                    Golden Triangle Splendor
                  </Link>
                  <Link
                    to="/?scrollTo=trips"
                    className="block hover:text-palette-color-1"
                  >
                    South India Getaways
                  </Link>
                </div>
              </div>
              <div className="relative group">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFestival();
                  }}
                  className="block cursor-pointer hover:text-palette-color-1"
                >
                  Festivals
                </span>
                <div
                  className={`${
                    isFestivalOpen ? "block" : "hidden"
                  } absolute left-full top-0 w-48 bg-palette-color-4 text-palette-color-3 border border-palette-color-3 ml-2 mt-0 p-2 z-10`}
                >
                  <Link
                    to="/festival/holi"
                    className="block hover:text-palette-color-1"
                  >
                    Holi
                  </Link>
                  <Link
                    to="/festival/diwali"
                    className="block hover:text-palette-color-1"
                  >
                    Diwali
                  </Link>
                  <Link
                    to="/festival/pushkar"
                    className="block hover:text-palette-color-1"
                  >
                    Pushkar Camel Carnival
                  </Link>
                  <Link
                    to="/festival/kumbh"
                    className="block hover:text-palette-color-1"
                  >
                    Kumbh Mela
                  </Link>
                </div>
              </div>
              <div className="relative group">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleHealth();
                  }}
                  className="block cursor-pointer hover:text-palette-color-1"
                >
                  Health Based Journey
                </span>
                <div
                  className={`${
                    isHealthOpen ? "block" : "hidden"
                  } absolute left-full top-0 w-48 bg-palette-color-4 text-palette-color-3 border border-palette-color-3 ml-2 mt-0 p-2 z-10`}
                >
                  <Link
                    to="/yogatour"
                    className="block hover:text-palette-color-1"
                  >
                    Yoga Escapes
                  </Link>
                  <Link
                    to="/ayurvedatour"
                    className="block hover:text-palette-color-1"
                  >
                    Ayurveda Journey
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Link
            to="/all-blogs"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            Blogs
          </Link>
          <Link
            to="/socialimpact"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            Social Impact
          </Link>
          <Link
            to="/about"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            About Us
          </Link>
          <Link
            to="/contactus"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            Contact Us
          </Link>

          <Link
            to="/carrental"
            className="text-palette-color-3 font-palette-font-2 hover:text-palette-color-3"
          >
            Rent-a-Car
          </Link>
        </div>
        <div className="items-center hidden space-x-6 md:flex">
          <Link
            to="/request"
            className="px-3 py-1 transition duration-300 rounded-md bg-palette-color-5 text-palette-color-3 hover:bg-palette-color-3 hover:text-palette-color-1"
          >
            Customize your Journey
          </Link>
        </div>
        {/* <div className=""> <SampleNavbar/> </div> */}
        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-palette-color-3 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="py-2 font-palette-font-2 md:hidden bg-palette-color-1">
          <div
            ref={toursDropdownRef}
            className="relative group"
            onClick={(e) => {
              e.stopPropagation();
              toggleTour();
            }}
          >
            <span className="block px-4 py-2 cursor-pointer text-palette-color-3">
              Journeys
            </span>
            <div
              className={`${
                isTourOpen ? "block" : "hidden"
              } absolute w-full bg-palette-color-4 px-4 py-2 cursor-pointer text-palette-color-3 z-10`}
            >
              <div className="relative group">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCurated();
                  }}
                  className="block cursor-pointer hover:text-palette-color-3"
                >
                  Curated Tours
                </span>
                <div
                  className={`${
                    isCuratedOpen ? "block" : "hidden"
                  } absolute w-full bg-palette-color-4 py-2 cursor-pointer text-palette-color-3 z-10`}
                >
                  <Link
                    to="/?scrollTo=trips"
                    className="block cursor-pointer hover:text-palette-color-3 z-10"
                  >
                    The Royal Rajasthan
                  </Link>
                  <Link
                    to="/?scrollTo=trips"
                    className="block hover:text-palette-color-3"
                  >
                    Golden Triangle Splendor
                  </Link>
                  <Link
                    to="/?scrollTo=trips"
                    className="block hover:text-palette-color-3"
                  >
                    South India Getaways
                  </Link>
                </div>
              </div>
              <div className="relative group">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleFestival();
                  }}
                  className="block cursor-pointer hover:text-palette-color-3"
                >
                  Festivals
                </span>
                <div
                  className={`${
                    isFestivalOpen ? "block" : "hidden"
                  } absolute w-full bg-palette-color-4 py-2 cursor-pointer text-palette-color-3 z-10`}
                >
                  <Link
                    to="/festival/holi"
                    className="block hover:text-palette-color-3"
                  >
                    Holi
                  </Link>
                  <Link
                    to="/festival/diwali"
                    className="block hover:text-palette-color-3"
                  >
                    Diwali
                  </Link>
                  <Link
                    to="/festival/pushkar"
                    className="block hover:text-palette-color-3"
                  >
                    Pushkar Camel Carnival
                  </Link>
                  <Link
                    to="/festival/kumbh"
                    className="block hover:text-palette-color-3"
                  >
                    Kumbh Mela
                  </Link>
                </div>
              </div>
              <div className="relative group">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleHealth();
                  }}
                  className="block cursor-pointer hover:text-palette-color-3"
                >
                  Health Based Journey
                </span>
                <div
                  className={`${
                    isHealthOpen ? "block" : "hidden"
                  } absolute w-full bg-palette-color-4 py-2 cursor-pointer text-palette-color-3 z-10`}
                >
                  <Link
                    to="/yogatour"
                    className="block hover:text-palette-color-3"
                  >
                    Yoga Escapes
                  </Link>
                  <Link
                    to="/ayurvedatour"
                    className="block hover:text-palette-color-3"
                  >
                    Ayurveda Journey
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Link
            to="/all-blogs"
            className="block px-4 py-2 text-palette-color-3"
          >
            Blogs
          </Link>
          <Link
            to="/socialimpact"
            className="block px-4 py-2 text-palette-color-3"
          >
            Social Impact
          </Link>
          <Link
            to="/about"
            onClick={(e) => {
              e.preventDefault();
              const aboutSection = document.getElementById("about-us");
              if (aboutSection) {
                aboutSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
            className="block px-4 py-2 text-palette-color-3"
          >
            About Us
          </Link>
          <Link
            to="/contactus"
            className="block px-4 py-2 text-palette-color-3"
          >
            Contact Us
          </Link>
          <Link
            to="/carrental"
            className="block px-4 py-2 text-palette-color-3"
          >
            Rent-a-Car
          </Link>
          <Link
            to="/request"
            className="block px-2 py-2 rounded-md bg-palette-color-4 text-palette-color-3 hover:bg-palette-color-3 hover:text-palette-color-1"
          >
            Customize your Journey
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
