import React from "react";
import yoga from "../assets/yoga.webp";
import ayurveda from "../assets/ayurveda.webp";
import { Link } from "react-router-dom";
// import { useTranslator } from "./Translator";

const ZigzagImageText = () => {
  // const { sample1 } = useTranslator();
  return (
    <>
      <div className="py-16 px-4 md:px-6 text-center bg-palette-color-8">
        <h2 className="mt-2 text-4xl text-palette-color-18 font-palette-font-1">
        Health Based Journeys
        </h2>

        <div className="flex flex-col-reverse md:flex-row py-16 bg-palette-color-8 px-4 md:px-6">
          <div className="md:w-1/2 mt-4 md:mt-0 md:order-1">
            <img src={yoga} alt="yoga" className="w-full" />
          </div>
          <div className="md:w-1/2 p-8 justify-center md:order-2">
            <h2 className="text-3xl text-palette-color-18 font-palette-font-1 mb-4">
            Yoga Escapes: Discover Balance & Rejuvenate
            </h2>

            <p className="mb-8 font-palette-font-2 text-justify">
            Yoga is an ancient Indian practice rooted in philosophy, combining physical postures, breath control, meditation, and ethics. It enhances physical and mental well-being, reduces stress, and promotes mindfulness. It's also a spiritual path for self-discovery and a cultural bridge between East and West. The word 'yoga' means 'union' and has a 5,000-year history, with the foundational 'Yoga Sutras' written by Sage Patanjali in 200 CE
            </p>
            <Link to="/yogatour">
              <button className="bg-white hover:bg-gray-100 text-gray-800 font-palette-font-3 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
              See Itinerary
              </button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col md:flex-row py-16 bg-palette-color-8 px-4 md:px-8">
          <div className="md:w-1/2 p-8 justify-center md:order-1">
            <h2 className="text-3xl text-palette-color-18 font-palette-font-1 mb-4">
            Ayurveda Journey: Explore the Science of Wellness
            </h2>

            <p className="mb-8 font-palette-font-2 text-justify">
            Ayurveda, a natural health system, originated more than 3,000 years ago in India. The term 'Ayurveda' combines 'ayur' (life) and 'veda' (knowledge), meaning 'knowledge of life.' It focuses on restoring balance in body, mind, and spirit. It uses natural therapies like diets, herbs, massages, yoga, and meditation to address imbalances. Key concepts include doshas (life forces), individual constitution (prakriti), and interconnectedness. The aim is to eliminate impurities, reduce symptoms, and promote well-being using herbs, oils, and spices
            </p>
            <Link to="/ayurvedatour">
              <button className="bg-white hover:bg-gray-100 text-gray-800 font-palette-font-3 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
              See Itinerary
              </button>
            </Link>
          </div>
          <div className="md:w-1/2 mt-4 md:mt-0 md:order-2">
            <img src={ayurveda} alt="ayurveda" className="w-full" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ZigzagImageText;
