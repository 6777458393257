import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useInView } from "react-intersection-observer";
import emailjs from "emailjs-com";
import Navbar from "../../components/navbar";

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "contact");

function Hygenic() {
  const [inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobNo: "",
    country: "",
    details: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      mobNo: "",
      country: "",
      details: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(formsCollectionRef, {
        contact: formData,
      });
      const templateParams = {
        name: formData.name,
        email: formData.email,
      };
      // Next, send a request to trigger the Cloud Function
      const response = await emailjs.send(
        "service_g8shg6p",
        "template_3qor7s8",
        templateParams,
        "fT4pN2G_pRf7AWKMk"
      );

      if (response.status === 200) {
        alert("Inquiry has been generated. We will get back to you soon.");
        resetFormAndCloseModal();
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <div className="bg-palette-color-4 relative sticky top-0">
        <Navbar />
      </div>
      <h1 className="pt-12 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-1">
        Savor Hygienic Delights with Indian Treasure House
      </h1>
      <div className="p-4 text-justify">
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          At Indian Treasure House, your culinary experience is as important to
          us as the destinations you visit. We take great care in ensuring that
          your journey is not only adventurous and culturally rich but also
          marked by hygienic and delectable dining experiences.
        </p>
        <h1 className="pb-4 mt-6 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-1">
          Why Choose Our Hygienic Food Services
        </h1>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Your Health Matters:
          </span>{" "}
          We understand the importance of safe and hygienic food, especially
          when you're on the trip. Your health and well-being are our utmost
          priorities.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Chosen hotels:
          </span>
          Your well-being is our top priority. We understand the importance of
          clean and hygienic food for a delightful dining experience. We take
          pride in our rigorous selection process, carefully curating a list of
          hotels that not only offer delicious meals but also adhere to the
          highest standards of hygiene and cleanliness.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Local Ingredients:
          </span>{" "}
          We source our ingredients from trusted local suppliers, ensuring that
          the food you savor is fresh, safe, and authentic to the region.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Culinary Diversity:
          </span>{" "}
          Just like India's diverse destinations, our culinary offerings span
          the entire spectrum of Indian cuisine. Enjoy the flavors of the north,
          south, east, and west, all prepared with the highest hygiene
          standards.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Safe Handling:
          </span>{" "}
          From food preparation to serving, we follow strict guidelines for the
          safe handling of food. Our practices are aligned with international
          food safety standards.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Dietary Preferences:
          </span>{" "}
          Whether you have dietary restrictions or specific preferences, our
          food services are flexible and accommodating. We ensure that your
          dietary needs are met without compromising on hygiene.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Safe Dining Environments:
          </span>{" "}
          When you dine at our recommended restaurants, you can trust that we've
          chosen establishments known for their commitment to hygiene and
          cleanliness.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          At Indian Treasure House, we believe that hygienic food is an integral
          part of your travel experience. We've gone to great lengths to ensure
          that every meal you enjoy on your journey meets the highest standards
          of safety and cleanliness.
          <br />
          <br />
          Savor the culinary treasures of India with complete peace of mind.
          Your taste buds will thank you, and your health will be in safe hands.
        </p>
      </div>
      <div className="justify-center">
        <div className="h-full mt-6 shadow-md md:mt-0 ">
          <div className="mt-10 p-5">
            <h1 className="mb-8 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-18">
              For more details:
            </h1>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center space-y-5 w-1/2 mx-auto"
            >
              <div className="flex justify-center">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  name="mobNo"
                  value={formData.mobNo}
                  onChange={handleChange}
                  placeholder="Mobile No."
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  placeholder="Country"
                  type="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <textarea
                  placeholder="Message"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <center>
                <button
                  type="submit"
                  className="py-3 px-6 bg-palette-color-4 text-white font-bold rounded-md"
                >
                  Submit
                </button>
              </center>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hygenic;
