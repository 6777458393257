import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import emailjs from 'emailjs-com'; 

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "carrent");

export default function Modal2({ open, onClose, children }) {
  const [formData, setFormData] = useState({ name: "", email: "", guests: "",mobNo:"", typeOfVehicle: "", });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({ name: "", email: "", guests: "" ,mobNo:"", typeOfVehicle: "",});
    onClose();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add a document to the 'forms' collection using 'addDoc' function
      await addDoc(formsCollectionRef, {
        carrent: formData,
      });

      const templateParams = {
        name: formData.name,
        email: formData.email,
      };
      // Next, send a request to trigger the Cloud Function
      const response = await emailjs.send(
        'service_4bymywe',
        'template_1z4vkmd',
        templateParams,
        '_y8URUrfpn2pG2TS8'
      );

      if (response.status === 200) {
        alert('Request has been generated. We will get back to you soon.');
        resetFormAndCloseModal();
      } else {
        console.error('Error sending email:', response);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
<div
        onClick={onClose}
        className={`fixed z-10 inset-0 flex justify-center items-center transition-opacity ${
          open ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={`bg-white ml-[3%] w-[50%] rounded-xl shadow-lg p-6 transform transition-transform ${
            open ? "scale-100" : "scale-110"
          }`}
        >
          {children}
          <form
            onSubmit={handleSubmit}
            className="flex flex-col justify-center space-y-5"
          >
            <h1 className="text-2xl font-palette-font-1 font-palette-font-4 text-palette-color-18 font-semibold text-center">
              Rent Car Now
            </h1>
            <div className="flex justify-center">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="border-b-2 w-full p-2"
                required
              />
            </div>
            <div className="flex justify-center">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="border-b-2 w-full p-2"
                required
              />
            </div>
            <div className="flex justify-center">
              <input
                type="number"
                name="guests"
                value={formData.guests}
                onChange={handleChange}
                placeholder="No. of Guests"
                className="border-b-2 w-full p-2"
                required
              />
            </div>
            <div className="flex justify-center">
              <input
                type="tel"
                pattern="[0-9]{10}"
                name="mobNo"
                value={formData.mobNo}
                onChange={handleChange}
                placeholder="Mobile No."
                className="border-b-2 w-full p-2"
                required
              />
            </div>
            <div className="flex justify-center">
              <input
                placeholder="Start date"
                type="date"
                name="startdate"
                value={formData.startdate}
                onChange={handleChange}
                className="border-b-2 w-full p-2"
                required
              />
            </div>
            <div className="flex justify-center">
  <select
    name="typeOfVehicle"
    value={formData.typeOfVehicle}
    onChange={handleChange}
    className="border-b-2 w-full p-2"
    required
  >
    <option value="">Select Type of Vehicle</option>
    <option value="Toyota Etios">Toyota Etios</option>
    <option value="Swift Drize">Swift Drize</option>
    <option value="Toyota Innova">Toyota Innova</option>
    <option value="Tempo Traveller - 10 seater">Tempo Traveller - 10 seater</option>
    <option value="Tempo Traveller - 12 seater">Tempo Traveller - 12 seater</option>
  </select>
</div>

            <center>
              <button
                type="submit"
                className="py-2 px-4 bg-palette-color-4 text-white font-semibold rounded-md"
              >
                Submit
              </button>
            </center>
          </form>
        </div>
      </div>
    </>
  );
}
