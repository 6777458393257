import React from "react";
import icon1 from "../assets/icon1.png";
import icon2 from "../assets/food-safety.png";
import icon3 from "../assets/24-hours-support.png";
import icon4 from "../assets/destination.png";
import { Link } from "react-router-dom";
// import { useTranslator } from "./Translator";

const IconsRow = () => {
  // const { sample1 } = useTranslator();
  return (
    <div className="py-8 m-5 " id="trending">
      <div className="container mb-14 mx-auto text-center">
        <h2 className="text-4xl text-palette-color-18 font-palette-font-1">
        How We Make Difference
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="text-center">
          <Link to="/womensafety">
          <img src={icon1} alt="Icon 1" className="w-12 h-12 mx-auto" />
            <p className="mt-2 text-md text-palette-color-1 font-palette-font-2">
            Safe and Secure Women Travel
            </p>
          </Link>
        </div>
        <div className="text-center">
          <Link to="/diverse-destination">
          <img src={icon4} alt="Icon 4" className="w-12 h-12 mx-auto" />
            <p className="mt-2 text-md text-palette-color-1 font-palette-font-2">
            100+ Diverse Destinations
            </p>
          </Link>
        </div>
        <div className="text-center">
          <Link to="/aroundtheclock">
          <img src={icon3} alt="Icon 3" className="w-12 h-12 mx-auto" />
            <p className="mt-2 text-md text-palette-color-1 font-palette-font-2">
            24×7 Round the Clock Support
            </p>
          </Link>
        </div>
        <div className="text-center">
          <Link to="/hygenicfood">
          <img src={icon2} alt="Icon 2" className="w-12 h-12 mx-auto" />
            <p className="mt-2 text-md text-palette-color-1 font-palette-font-2">
            Hygienic Food with 50+ Cuisines
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default IconsRow;
