import React from "react";
import { auth } from "../src/firebase";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { rajasthanTours, goldenTours, southTours } from "./data/tourdata";
import Main from "./pages/main";
import TourDetails from "./components/tourdetails";
import Footer from "./components/footer";
import CarRental from "./pages/carrental";
import Contactus from "./components/Contactus";
import Login from "./components/Login";
import Protected from "./components/Protected";
import Payment from "./pages/payment";
import ScrollToTop from "./components/ScrollToTop";
import AllBlogs from "./pages/Blogs";
import Request from "./components/Request";
import FestivalDetails from "./pages/Festivaldetails";
import { festivalscomponent } from "./data/festivaldata";
import BlogPage from "./pages/individual_blog";
import CarrentalForm from "./admin/carrentalform";
import ContactForm from "./admin/contactform";
import RequestForm from "./admin/requestform";
import PaymentForm from "./admin/paymentForm";
import BookForm from "./admin/bookform";
import ProtectedRoute from "./admin/protectedroute";
import Destination from "./pages/difference/destinations";
import WomenSafety from "./pages/difference/women";
import AroundTheClock from "./pages/difference/aroundtheclock";
import Hygenic from "./pages/difference/hygenicfood";
import FAQAccordion from "./pages/Faq";
import Privacy from "./pages/Privacy";
import Visarules from "./pages/Visarules";
import Whatsapp from "./assets/Whatsapp.png";
import SocialImapact from "./pages/socialimpact";
import Yogatour from "./pages/Yogatour";
import Ayurvedatour from "./pages/ayurvedatour";
import TravelTips from "./pages/travel_tips";
import About from "./pages/aboutus";
// import { TranslatorProvider } from "./components/Translator";

const NotFound = () => {
  return <div>Page not found</div>;
};

const App = () => {
  const allTours = [...rajasthanTours, ...goldenTours, ...southTours];
  const allFestivals = [...festivalscomponent];
  // eslint-disable-next-line no-unused-vars
  const user = auth.currentUser;

  return (
    <>
    {/* <TranslatorProvider> */}

      <Router>
        <ScrollToTop />
        <a
          href="https://wa.me/7062101914"
          className="fixed z-50 whatsapp_float bottom-14 right-10"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Whatsapp} alt="Whatsapp" className="animate-bounce w-12 h-12" />
        </a>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/visarules" element={<Visarules />} />
          <Route path="/traveltips" element={<TravelTips/>} />
          <Route path="/faqs" element={<FAQAccordion />} />
          <Route path="/about" element={<About/>} />
          <Route
            path="/tour/:tourId"
            element={<TourDetails tours={allTours} />}
          />
          <Route
            path="/festival/:festivalId"
            element={<FestivalDetails festivals={allFestivals} />}
          />
          <Route path="/yogatour" element={<Yogatour />} />
          <Route path="/ayurvedatour" element={<Ayurvedatour />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/carrental" element={<CarRental />} />
          <Route path="/request" element={<Request />} />
          <Route path="/diverse-destination" element={<Destination />} />
          <Route path="/womensafety" element={<WomenSafety />} />
          <Route path="/aroundtheclock" element={<AroundTheClock />} />
          <Route path="/hygenicfood" element={<Hygenic />} />
          <Route path="/socialimpact" element={<SocialImapact />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/login" element={<Login />} />

          <Route
            path="/car"
            element={
              <ProtectedRoute>
                <CarrentalForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contactform"
            element={
              <ProtectedRoute>
                <ContactForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/requestform"
            element={
              <ProtectedRoute>
                <RequestForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/paymentform"
            element={
              <ProtectedRoute>
                <PaymentForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bookform"
            element={
              <ProtectedRoute>
                <BookForm />
              </ProtectedRoute>
            }
          />

          <Route
            path="/protected"
            element={
              <ProtectedRoute>
                <Protected />
              </ProtectedRoute>
            } 
          />

          <Route path="/payment" element={<Payment />} />
          <Route path="/all-blogs" element={<AllBlogs />} />
          <Route path="/blog/:id" element={<BlogPage />} />
        </Routes>
        <Footer />
      </Router>
    {/* </TranslatorProvider> */}

    </>
  );
};

export default App;
