import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import IconsRow from "../components/difference";
import TourColumn from "../components/tours";
import HeroSection from "../components/herosection";

import FestivalsSection from "../components/festival";
import MapContainer from "../components/map";
import PartnersSection from "../components/partners";
import ZigzagImageText from "../components/yoga";
import { rajasthanTours, goldenTours, southTours } from "../data/tourdata";
import YourComponent from "../components/blogSlider";
import Socialwork from "../components/socialwork";
import Navbar from "../components/navbar";
import IntroPage from "../components/intro";
// import { useTranslator } from "../components/Translator";

const Main = () => {
  // const { sample1 } = useTranslator();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get('scrollTo');

    if (scrollTo === 'trips') {
      const aboutSection = document.getElementById('trips');
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.search]);
  return (
    <div>
      <Navbar />
      <HeroSection/>
      <IconsRow />

      <IntroPage />
      <div className="p-8 mt-8" id="tours">
        <div className="w-full max-w-screen-xl pl-6 pr-6 mx-auto">
          <div
            className="pb-4 mb-2 text-4xl text-center text-palette-color-18 font-palette-font-1"
            id="trips"
          >
            Choose Your Adventure
          </div>
          <div
            className="mb-8 text-xl text-center text-palette-color-4 font-palette-font-2"
            id="trips"
          >
            Experience the Beauty and Diversity of India with Our Unforgettable Tours
          </div>
          <div className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
            <TourColumn title="The Royal Rajasthan" tours={rajasthanTours} />
            <TourColumn title="Golden Triangle Splendor" tours={goldenTours} />
            <TourColumn title="South India Gateways" tours={southTours} />
          </div>
        </div>
      </div>
      <FestivalsSection />
      <div className="p-8 mt-8">
        <div className="mb-8 text-4xl text-center text-palette-color-18 font-palette-font-1">
        Blogs
        </div>
        <YourComponent />
      </div>
      <Socialwork />
      <ZigzagImageText />
      <MapContainer />
      <PartnersSection />
    </div>
  );
};

export default Main;
