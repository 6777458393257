import React, { useState } from "react";
import { Link } from "react-router-dom";

const AccordionItem = ({ tour, isFirst }) => {
  const [isOpen, setIsOpen] = useState(isFirst);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-2">
      <div
        className={`group relative cursor-pointer bg-palette-color-8 rounded-lg p-4 border border-palette-color-4 ${
          isOpen ? "mb-2" : ""
        }`}
        onClick={toggleAccordion}
      >
        <div className="flex justify-between items-center">
          <div className="text-md font-palette-font-2 text-palette-color-1">
            {tour.title}
          </div>
          <div className="text-palette-color-7">
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            )}
          </div>
        </div>
        {isOpen && (
          <Link to={`/tour/${tour.id}`} className="tour-link">
            <div className="relative">
              <img src={tour.image} alt={tour.title} className="rounded-lg h-56 w-full" />

              <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-80 transition-opacity duration-300"></div>

              <div className="absolute inset-0 flex flex-col justify-center items-center text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <p className="text-xl font-bold mb-2">Days: {tour.days}</p>
                <p className="text-sm">See Itinerary</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 mt-1 animate-bounce"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
            <p className="mt-2 text-sm text-palette-color-6">{tour.one}</p>
          </Link>
          
        )}
      </div>
    </div>
  );
};

export default AccordionItem;
