/* eslint-disable jsx-a11y/img-redundant-alt */
// Protected.js
import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { auth } from "../firebase";
import Newnav from "./Newnav";

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "blog");

function Protected() {
  const [coverImage, setCoverImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  function generateAlphanumericCode(length) {
    const characters = "0123456789";
    const prefix = "ITH_BLOG_";
    let code = prefix;

    for (let i = 0; i < length - prefix.length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  }
  const [headings, setHeadings] = useState([""]); 
  const [paragraphs, setParagraphs] = useState([""]); // Initialize with one empty paragraph

  const handleHeadingChange = (index, content) => {
    const updatedHeadings = [...headings];
    updatedHeadings[index] = content;
    setHeadings(updatedHeadings);
  };

  const handleParagraphChange = (index, content) => {
    const updatedParagraphs = [...paragraphs];
    updatedParagraphs[index] = content;
    setParagraphs(updatedParagraphs);
  };

  const addParagraph = () => {
    setHeadings([...headings, ""]);
    setParagraphs([...paragraphs, ""]); // Add a new empty paragraph
  };

  const removeParagraph = (index) => {
    const updatedHeadings = [...headings];
    updatedHeadings.splice(index, 1);
    const updatedParagraphs = [...paragraphs];
    updatedParagraphs.splice(index, 1); // Remove the paragraph at the given index
    setHeadings(updatedHeadings);
    setParagraphs(updatedParagraphs);
  };

  const codeLength = 15;
  const alphanumericCode = generateAlphanumericCode(codeLength);
  console.log(alphanumericCode);

  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    description: "",
    paragraphs: [],
    author: "",
    latitude: "",
    longitude: "",
  });
  const storage = getStorage(firebaseApp);
  const handleCoverImageChange = (e) => {
    const coverImageFile = e.target.files[0];
    setCoverImage(coverImageFile);
  };

  const handleAdditionalImagesChange = (e) => {
    const additionalImagesArray = Array.from(e.target.files);
    setAdditionalImages(additionalImagesArray);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({
      title: "",
      subtitle: "",
      description: "",
      paragraphs: [],
      author: "",
      latitude: "",
      longitude: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const downloadURLs = [];
      if (coverImage) {
        const coverImageRef = ref(storage, `images/${coverImage.name}`);
        await uploadBytes(coverImageRef, coverImage);
        const coverImageURL = await getDownloadURL(coverImageRef);
        downloadURLs.push(coverImageURL);
      }

      // Upload the image to Firebase Storage
      for (const selectedImage of additionalImages) {
        const imageRef = ref(storage, `images/${selectedImage.name}`);
        await uploadBytes(imageRef, selectedImage);
        const downloadURL = await getDownloadURL(imageRef);
        downloadURLs.push(downloadURL);
      }
      for (let i = 0; i < paragraphs.length; i++) {
        const paragraphData = {
          heading: headings[i], // Add the heading for this paragraph
          content: paragraphs[i],
        };
        formData.paragraphs.push(paragraphData);
      }
      // Add a document to the 'forms' collection using 'addDoc' function
      await addDoc(formsCollectionRef, {
        blog: formData,
        imageUrls: downloadURLs, // Store the image URLs in Firestore
      });

      alert("Blog post has been submitted successfully.");

      // Reset the form data and close the modal
      resetFormAndCloseModal();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <Newnav />

      <div className="flex items-center justify-center bg-gray-800">
        <div className="w-full p-6 m-10 bg-white rounded-lg shadow-md">
          <div className="text-center">
            <i className="text-5xl text-transparent fa fa-book bg-gradient-to-r bg-clip-text from-palette-color-4 to-palette-color-15"></i>
            <h1 className="mt-4 text-3xl font-bold font-palette-font-2 font-palette-font-3 font-palette-font-13 text-gray-800">
              POST BLOG
            </h1>
          </div>
          <form onSubmit={handleSubmit} className="mt-6">
            <div className="mb-6">
              <label
                htmlFor="title"
                className="block text-sm font-bold text-gray-700"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Title"
                className="w-full p-2 mt-2 text-gray-200 bg-gray-800 border-b-2 border-palette-color-4 focus:outline-none focus:border-palette-color-4"
                required
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="subtitle"
                className="block text-sm font-bold text-gray-700"
              >
                Sub-Title
              </label>
              <input
                type="text"
                id="subtitle"
                name="subtitle"
                value={formData.subtitle}
                onChange={handleChange}
                placeholder="Sub-Title"
                className="w-full p-2 mt-2 text-gray-200 bg-gray-800 border-b-2 border-palette-color-4 focus:outline-none focus:border-palette-color-4"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="description"
                className="block text-sm font-bold text-gray-700"
              >
                Description
              </label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Description"
                className="w-full h-32 p-2 mt-2 text-gray-200 bg-gray-800 border-b-2 resize-none border-palette-color-4 focus:outline-none focus:border-palette-color-4"
                
              ></textarea>
            </div>
            <div className="mb-6">
              <label
                htmlFor="author"
                className="block text-sm font-bold text-gray-700"
              >
                Author
              </label>
              <input
                type="text"
                id="author"
                name="author"
                value={formData.author}
                onChange={handleChange}
                placeholder="Author"
                className="w-full p-2 mt-2 text-gray-200 bg-gray-800 border-b-2 border-palette-color-4 focus:outline-none focus:border-palette-color-4"
                required
              />
            </div>
            {paragraphs.map((paragraph, index) => (
              <div key={index} className="mb-6">
                <label
                  htmlFor={`heading-${index}`}
                  className="block text-sm font-bold text-gray-700"
                >
                  Heading {index + 1}
                </label>
                <input
                  type="text"
                  id={`heading-${index}`}
                  name={`heading-${index}`}
                  value={headings[index]}
                  onChange={(e) => handleHeadingChange(index, e.target.value)}
                  placeholder={`Heading ${index + 1}`}
                  className="w-full p-2 mt-2 text-gray-200 bg-gray-800 border-b-2 border-palette-color-4 focus:outline-none focus:border-palette-color-4"
                  required
                />
                <label
                  htmlFor={`paragraph-${index}`}
                  className="block text-sm font-bold text-gray-700"
                >
                  Paragraph {index + 1}
                </label>
                <textarea
                  id={`paragraph-${index}`}
                  name={`paragraph-${index}`}
                  value={paragraph}
                  onChange={(e) => handleParagraphChange(index, e.target.value)}
                  placeholder={`Paragraph ${index + 1}`}
                  className="w-full h-32 p-2 mt-2 text-gray-200 bg-gray-800 border-b-2 resize-none border-palette-color-4 focus:outline-none focus:border-palette-color-4"
                  required
                ></textarea>
                {index > 0 && (
                  <button type="button" onClick={() => removeParagraph(index)}>
                    Remove Paragraph
                  </button>
                )}
              </div>
            ))}
            <button type="button" onClick={addParagraph}>
              Add Paragraph
            </button>

            <div className="mb-6">
              <label
                htmlFor="latitude"
                className="block text-sm font-bold text-gray-700"
              >
                Latitude
              </label>
              <input
                type="text"
                id="latitude"
                name="latitude"
                value={formData.latitude}
                onChange={handleChange}
                placeholder="Latitude"
                className="w-full p-2 mt-2 text-gray-200 bg-gray-800 border-b-2 border-palette-color-4 focus:outline-none focus:border-palette-color-4"
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="longitude"
                className="block text-sm font-bold text-gray-700"
              >
                Longitude
              </label>
              <input
                type="text"
                id="longitude"
                name="longitude"
                value={formData.longitude}
                onChange={handleChange}
                placeholder="Longitude"
                className="w-full p-2 mt-2 text-gray-200 bg-gray-800 border-b-2 border-palette-color-4 focus:outline-none focus:border-palette-color-4"
              />
            </div>

            {/* Cover Image */}
            <div className="mb-6">
              <label
                htmlFor="coverImage"
                className="block text-sm font-bold text-gray-700"
              >
                Cover Image
              </label>
              <input
                type="file"
                id="coverImage"
                name="coverImage"
                accept="image/*"
                onChange={handleCoverImageChange}
                className="hidden"
              />
              {/* Display selected cover image */}
              {coverImage && (
                <div className="w-20 h-20 bg-center bg-no-repeat bg-cover">
                  <img
                    src={URL.createObjectURL(coverImage)}
                    alt="Cover"
                    className="w-full h-full"
                  />
                </div>
              )}
              <label
                htmlFor="coverImage"
                className="block mt-2 cursor-pointer text-palette-color-4 hover:underline"
              >
                Select Cover Image
              </label>
            </div>
            {/* Additional Images */}
            <div className="mb-6">
              <label
                htmlFor="additionalImages"
                className="block text-sm font-bold text-gray-700"
              >
                Additional Images
              </label>
              <input
                type="file"
                id="additionalImages"
                name="additionalImages"
                accept="image/*"
                multiple
                onChange={handleAdditionalImagesChange}
                className="hidden"
              />
              {/* Display selected additional images */}
              <div className="flex flex-wrap gap-4">
                {additionalImages.map((image, index) => (
                  <div
                    key={index}
                    className="w-20 h-20 bg-center bg-no-repeat bg-cover"
                  >
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Image ${index}`}
                      className="w-full h-full"
                    />
                  </div>
                ))}
              </div>
              <label
                htmlFor="additionalImages"
                className="block mt-2 cursor-pointer text-palette-color-4 hover:underline"
              >
                Select Additional Images
              </label>
            </div>

            <div className="flex justify-center">
              <button
                type="submit"
                className="px-4 py-2 font-bold text-white rounded-md bg-palette-color-4 hover:bg-palette-color-14"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Protected;
