import React from "react";
import { Link } from "react-router-dom";
import disabled from "../assets/disabled.webp";
import dog from "../assets/street dog.png";
// import { useTranslator } from "./Translator";

const Socialwork = () => {
  // const { sample1 } = useTranslator();
  return (
    <div className="py-8 m-5 " id="trending">
      <div className="container mx-auto text-center mb-14">
        <h2 className="text-4xl text-palette-color-18 font-palette-font-1">
        Social Impact
        </h2>
      </div>
      <div className="grid grid-cols-1 gap-7 md:grid-cols-2">
        <div className="text-center">
          <Link to="/socialimpact">
            <img src={disabled} alt="Icon 1" className="h-44 mx-auto" />
            <p className="mt-2 text-2xl text-palette-color-1 font-palette-font-1">
            Empowering Especially abled kids
            </p>
            <p className="mt-2 md:ml-28 md:mr-28 text-md text-palette-color-6 font-palette-font-2">
            For every tour, we dedicate resources to support especially abled children, creating opportunities for them to lead more fulfilling lives. Join us in making a lasting difference in improving lives and spreading hope
            </p>
          </Link>
        </div>
        <div className="text-center">
          <Link to="/socialimpact">
            <img src={dog} alt="Icon 4" className="h-44 mx-auto" />
            <p className="mt-2 text-2xl text-palette-color-1 font-palette-font-1">
            Providing Shelter to Stray Paws
            </p>
            <p className="mt-2 md:ml-28 md:mr-28 text-md text-palette-color-6 font-palette-font-2">
            When you travel with us, you not only create your own unforgettable memories, but also contribute to making the world a better place, Join us in leaving a lasting paw print and spreading love and compassion
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Socialwork;
