import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import marker from '../assets/marker.png';
import { Link } from "react-router-dom";
// import { useTranslator } from "./Translator";

const db = getFirestore();

const LocationMap = () => {
  // const { sample1 } = useTranslator();
  const [blogData, setBlogData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const fetchBlogData = async () => {
    try {
      const blogsCollectionRef = collection(db, 'blog');
      const querySnapshot = await getDocs(blogsCollectionRef);
      const blogData = [];
      querySnapshot.forEach((doc) => {
        blogData.push({
          id: doc.id,
          ...doc.data().blog,
          imageURL: doc.data().imageURL,
        });
      });
      setBlogData(blogData);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  useEffect(() => {
    fetchBlogData();
  }, []);

  const markerIcon = L.icon({
    iconUrl: marker,
    iconSize: [32, 32],
  });

  

  return (
    <>
    <div className="mt-8 pt-10 text-4xl text-center text-palette-color-18 font-palette-font-1" id="trips">
    Explore Cities Through Blogs
          </div>
    <div className="flex flex-col items-center justify-center h-screen md:flex-row" id="where-to-go">
      
      <div className="border-4 border-palette-color-4 w-full max-w-4xl bg-palette-color-4 relative z-0 h-[70vh]">
        <MapContainer center={[26.9124, 75.7873]} zoom={6} style={{ width: '100%', height: '100%' }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='<a href="https://www.openstreetmap.org/copyright"></a>'
          />
          {blogData.map((blog) => (
            <Marker
              key={blog.id}
              position={[blog.latitude, blog.longitude]}
              eventHandlers={{
                click: () => setSelectedLocation(blog),
              }}
              icon={markerIcon}
            >
              <Popup>
                {blog.title}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
      <div className="w-full md:w-1/4 bg-palette-color-4 p-4 overflow-y-auto h-[70vh]">
      <h3 className="text-2xl mb-2 text-center font-semibold font-palette-font-1 text-palette-color-8">
      Blogs
            </h3>
        {blogData.map((blog) => (
          <div
            key={blog.id}
            className={`p-2 mb-4 ${
              selectedLocation && selectedLocation.id === blog.id ? 'bg-palette-color-18' : 'bg-palette-color-8'
            }`}
            onClick={() => setSelectedLocation(blog)}
          >
            <Link to={`/blog/${blog.id}`}>
            <h3 className="text-md font-semibold font-palette-font-2 text-palette-color-1">
              {blog.title}
            </h3>
            </Link>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default LocationMap;
