import React from 'react';
import { Link } from 'react-router-dom';
import { festivalscomponent } from '../data/festivaldata';
// import { useTranslator } from "./Translator";

const FestivalsSection = () => {
  // const { sample1 } = useTranslator();
  if (!festivalscomponent || festivalscomponent.length === 0) {
    
    return (
      <div className="py-16 px-4 md:px-15 bg-palette-color-8" id="festivals">
        <h2 className="text-4xl text-center text-palette-color-18 font-palette-font-1 mb-8">
        Immerse in Festive Celebrations
        </h2>
        <p className="text-palette-color-1">No festivals available.</p>
      </div>
    );
  }

  return (
    <div className="py-16 px-4 md:px-15 bg-palette-color-8" id="festivals">
      <h2 className="text-4xl text-center text-palette-color-18 font-palette-font-1 mb-8">
      Immerse in Festive Celebrations
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-2 md:justify-center">
        {festivalscomponent.map((festival) => (
          <Link to={`/festival/${festival.id}`} className="tour-link" key={festival.id}>
            <div className="border border-palette-color-4 h-full text-center rounded-lg p-4 relative m-2">
              <div
                className="h-48 mb-2 md:h-64 bg-cover bg-center rounded-lg transition-transform hover:scale-105 transform-origin-center"
                style={{ backgroundImage: `url(${festival.image})` }}
              />
              <h3 className="font-semibold text-xl text-palette-color-4 font-palette-font-1">
                {festival.title}
              </h3>
              <p className="mb-2 font-semibold text-xl text-palette-color-4 font-palette-font-1">Days:{festival.days}</p>
              <p className="font-palette-font-2 text-palette-color-6">{festival.subtitle}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FestivalsSection;
