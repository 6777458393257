import React from "react";

const WelcomeToITH = ({welcomeToITHRef}) => {
  return (
    <div ref={welcomeToITHRef} className="justify-center">
      <div className="mb-2 text-4xl text-palette-color-18 font-palette-font-1">
        Namaste!
      </div>
      <br/>
      <p className="text-justify">
        It's a real honor to introduce you to India and all its wonders. India
        has fascinated people for centuries. They've all added to the unique
        blend of culture, history, and spirituality you'll discover here.
        <br />
        <br />
        Our promise is simple: We'll make your travel dreams come true. From the
        moment you embark on a journey with us, you become a part of our family.
        We'll take care of all the details so you can focus on creating
        beautiful memories. Our promise is to give you a taste of India's rich
        heritage. You'll discover ancient buildings, hear diverse music,
        experience our many languages, and delve into mystic traditions. Plus,
        get ready for a delicious culinary journey.
        <br />
        <br />
        We're here to offer you a travel experience that's more than just a
        vacation. We want you to explore India like a local, immersing yourself
        in our captivating culture.
        <br />
        <br />
        Welcome to Indian Treasure House, where you'll uncover the hidden
        treasures of this incredible land.
        <br />
        <br />
        Our moto is:{" "}
        <span className="text-2xl text-palette-color-18 font-palette-font-1">
          Athithi Devo Bhava
        </span>{" "}
        means{" "}
        <span className="text-2xl text-palette-color-18 font-palette-font-1">
          The guest is God.
        </span>
      </p>
    </div>
  );
};

export default WelcomeToITH;
