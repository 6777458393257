import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useInView } from "react-intersection-observer";
import emailjs from "emailjs-com";
import Navbar from "../../components/navbar";

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "contact");

function AroundTheClock() {
  const [inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobNo: "",
    country: "",
    details: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      mobNo: "",
      country: "",
      details: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(formsCollectionRef, {
        contact: formData,
      });
      const templateParams = {
        name: formData.name,
        email: formData.email,
      };
      // Next, send a request to trigger the Cloud Function
      const response = await emailjs.send(
        "service_g8shg6p",
        "template_3qor7s8",
        templateParams,
        "fT4pN2G_pRf7AWKMk"
      );

      if (response.status === 200) {
        alert("Inquiry has been generated. We will get back to you soon.");
        resetFormAndCloseModal();
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <div className="bg-palette-color-4 relative sticky top-0">
        <Navbar />
      </div>
      <h1 className="pt-12 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-1">
        Around-the-Clock Support with Indian Treasure House
      </h1>
      <div className="p-4 text-justify">
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          At Indian Treasure House, we believe that your travel experience
          should be seamless, and your peace of mind should be unwavering.
          That's why we offer 24/7 round-the-clock support, ensuring you're
          never alone on your journey.
        </p>
        <h1 className="pb-2 mt-6 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-1">
          What we’ve in it
        </h1>
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Your Travel Companion:
          </span>{" "}
          Our support team is your trusted travel companion, ready to assist you
          at any hour of the day or night. No matter where you are in the world
          or what time it is, we're here to help.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Immediate Assistance:
          </span>
          From last-minute itinerary changes to unexpected travel hiccups, we
          understand that travel can sometimes be unpredictable. With our 24/7
          support, you'll receive immediate assistance and guidance.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Local Knowledge:
          </span>{" "}
          Our team is well-versed in the destinations we offer. They have
          in-depth local knowledge, which means they can provide insights,
          recommendations, and solutions that are specific to your location.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Emergency Response:
          </span>{" "}
          In case of emergencies or urgent situations, our team is prepared to
          respond swiftly. We're here to help you navigate any unforeseen
          challenges with calm and professionalism.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Peace of Mind:
          </span>{" "}
          With our 24/7 support, you can travel with confidence, knowing that
          help is just a phone call or message away. Your peace of mind is our
          priority.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Communication Options:
          </span>{" "}
          Whether you prefer to reach out via phone, email, or messaging apps,
          we offer multiple communication channels for your convenience.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Post-Trip Support:
          </span>{" "}
          Our commitment doesn't end when your journey does. If you have
          questions or require assistance after your trip, we're here to provide
          post-trip support as well.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-1 font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          At Indian Treasure House, we're dedicated to ensuring that your travel
          experience is not just extraordinary but also hassle-free. With our
          24/7 support, you can explore the world with the confidence that we've
          got your back, no matter what.
          <br />
          <br />
          Your journey is important to us, and we're available round the clock
          to make it unforgettable.
        </p>
      </div>
      <div className="justify-center">
        <div className="h-full mt-6 shadow-md md:mt-0 ">
          <div className="mt-10 p-5">
            <h1 className="mb-8 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-1">
              For more details:
            </h1>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center space-y-5 w-1/2 mx-auto"
            >
              <div className="flex justify-center">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  name="mobNo"
                  value={formData.mobNo}
                  onChange={handleChange}
                  placeholder="Mobile No."
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  placeholder="Country"
                  type="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <textarea
                  placeholder="Message"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <center>
                <button
                  type="submit"
                  className="py-3 px-6 bg-palette-color-4 text-white font-bold rounded-md"
                >
                  Submit
                </button>
              </center>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AroundTheClock;
