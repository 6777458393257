import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import firebaseApp from "../firebase";
import Newnav from "../components/Newnav";

const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "forms");

function BookForm() {
  const [formResponses, setFormResponses] = useState([]);

  useEffect(() => {
    // Fetch form responses from Firebase Firestore when the component mounts
    const fetchFormResponses = async () => {
      try {
        const querySnapshot = await getDocs(formsCollectionRef);
        const responses = [];
        querySnapshot.forEach((doc) => {
          responses.push(doc.data().form);
        });
        setFormResponses(responses);
      } catch (error) {
        console.error("Error fetching form responses: ", error);
      }
    };

    fetchFormResponses();
  }, []);

  return (
    <div>
      <Newnav />
      <div className="flex items-center justify-center bg-gray-800">
      <div className="w-full p-6 m-10 bg-white rounded-lg shadow-md">
        <div className="text-center">
            <i className="text-5xl text-transparent fa fa-book bg-gradient-to-r bg-clip-text from-palette-color-4 to-palette-color-15"></i>
            <h1 className="mt-4 text-3xl font-bold font-palette-font-2 font-palette-font-3 font-palette-font-13 text-gray-800">Book Request</h1>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {formResponses.map((response, index) => (
              <div key={index} className="bg-gray-100 rounded-lg shadow-md p-4">
                <h2 className="text-xl font-semibold">{response.name}</h2>
                <p className="text-gray-600 mb-4">{response.email}</p>
                <p className="mb-4">{response.details}</p>
                <div className="flex justify-between">
                  <div>
                    <p className="font-semibold">No. of Guests: {response.guests}</p>
                    <p className="font-semibold">Mobile No: {response.mobNo}</p>
                    <p className="font-semibold">Start Date: {response.startdate}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookForm;
