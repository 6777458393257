import React from 'react';
import logopartners from '../assets/logos-footer.png'

const PartnersSection = () => {
  

  return (
    <section className="bg-palette-color-4 py-2">
      <div className="container mx-auto">
            <div className="flex items-center justify-center">
              <img src={logopartners} alt='partners'/>
            </div>
      </div>
    </section>
  );
};

export default PartnersSection;
