/* eslint-disable import/first */
import React, { useState } from "react";
import Navbar from "../components/navbar";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faCheck,
  faPeopleGroup,
  faPersonWalkingLuggage,
} from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";
import spritual from "../assets/spiritual.png";
import Modal1 from "./Modal1";
import { Link } from "react-router-dom";

const TourDetails = ({ tours }) => {
  const { tourId } = useParams();
  const selectedTour = tours.find((tour) => tour.id === tourId);
  const [Open, setOpen] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const excludedServices = [
    "Flights: International or Domestic",
    "Drinks: Unless specified.",
    "Visa: Obtain your Indian visa independently.",
    "Meals: Not specified.",
    "Tips: Housekeeping, bellboys, at your discretion.",
    "Insurance: Comprehensive travel insurance required.",
    "Expenses: Laundry, telephone bills.",
    "Entrance: Monument fees.",
    "Camera: Video camera fees.",
    "Health: Medical, baggage insurance.",
    "Taxes: Airport departure taxes.",
    "Others: Unspecified services.",
    "Safaris: Game safaris not included, unless specified.",
  ];

  if (!selectedTour) {
    return <div>Tour not found.</div>;
  }

  return (
    <>
      <Navbar />
      <section
        className="relative h-screen overflow-hidden"
        style={{ top: "-57px" }}
      >
        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <div className="text-center">
            <h1 className="mb-6 text-5xl font-extrabold leading-tight md:text-6xl lg:text-7xl font-palette-font-1 text-palette-color-3">
              {selectedTour.title}
            </h1>
          </div>
        </div>
        <img
          src={selectedTour.image}
          alt={selectedTour.title}
          className="absolute top-0 left-0 z-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 z-0 opacity-50 bg-palette-color-5"></div>
        <div className="absolute inset-x-0 bottom-0 p-2 font-semibold text-center bg-palette-color-4 hidden md:block">
          {selectedTour.places.split("-").map((place, index, array) => (
            <span key={index} className="mx-2">
              {place}
              {index < array.length - 1 && <>&nbsp;&nbsp;&nbsp;|</>}
            </span>
          ))}
        </div>
      </section>
      <div className="container mx-auto text-center mb-14">
        <h2 className="text-4xl text-palette-color-1 font-palette-font-1">
          Suitable for
        </h2>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4 mb-7 md:grid-cols-4">
      <div className="text-center">
          <FontAwesomeIcon
            icon={faBriefcase}
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Corporate Travel
          </p>
        </div>
        <div className="text-center">
          <img
            src={spritual}
            alt="Inclusion Icon"
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Spiritual, Cultural, Artistic & Gastronomic Interests
          </p>
        </div>
        
        <div className="text-center">
          <FontAwesomeIcon
            icon={faPeopleGroup}
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Friends & Family Travel
          </p>
        </div>
        <div className="text-center">
          <FontAwesomeIcon
            icon={faPersonWalkingLuggage}
            className="w-12 h-12 mx-auto text-palette-color-4"
          />
          <p className="mt-2 text-l text-palette-color-1 font-palette-font-2">
            Solo Travel
          </p>
        </div>
      </div>
      <div className="flex flex-wrap justify-center bg-palette-color-2">
        <div className="flex flex-wrap">
          <div className="w-full overflow-y-auto lg:w-3/4">
            {Object.keys(selectedTour.description).map((city, index) => (
              <section
                key={index}
                className="relative w-full px-4 pt-8 pb-14 itinerary-section md:px-12 lg:px-24"
                ref={ref}
              >
                <div className="flex items-center mb-10 max-w-5xl mx-auto">
                  <div className="flex-shrink-0 mr-4 arrow-box">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-palette-color-1 arrow">
                      <span className="text-2xl transform rotate-45 text-palette-color-4">
                        &#8594;
                      </span>
                    </div>
                  </div>
                  <div>
                    <h2
                      className={`text-2xl font-semibold mb-2 font-palette-font-1 ${
                        inView ? "animate-fade-in" : ""
                      }`}
                    >
                      {city} - {selectedTour.heading[city]}
                    </h2>
                    <p
                      className={`text-md text-palette-color-1 city-description font-palette-font-2 ${
                        inView ? "animate-fade-in" : ""
                      }`}
                    >
                      {selectedTour.description[city]}
                    </p>
                  </div>
                </div>
                <div className="max-w-5xl mx-auto">
                  <img
                    src={selectedTour.cityImages[city]}
                    alt={`${city}`}
                    className="w-full h-auto my-4 rounded-lg"
                  />
                </div>
              </section>
            ))}
            <div className="flex flex-wrap bg-palette-color-2">
              {/* Highlights Section */}
              <section className="w-full px-4 pt-4 md:px-12 lg:px-10 lg:w-1/2">
                {" "}
                <div className="max-w-3xl pt-4 mx-auto mb-8">
                  <div className="p-4 bg-white rounded-lg shadow-md">
                    <h2 className="mb-4 text-2xl text-center font-semibold font-palette-font-1">
                      Highlights
                    </h2>
                    <ul className="list-none">
                      {selectedTour.highlights.map((highlight, index) => (
                        <li
                          key={index}
                          className="flex items-center mb-2 font-palette-font-2"
                        >
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="mr-2 text-palette-color-4"
                          />
                          {highlight}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </section>

              {/* Inclusions Section */}
              <section className="w-full px-4 pt-4 md:px-12 lg:px-10 lg:w-1/2">
                {" "}
                <div className="max-w-3xl pt-4 mx-auto mb-8 text-center">
                  <div className="p-4 bg-white rounded-lg shadow-md">
                    <h2 className="mb-4 text-2xl font-semibold font-palette-font-1">
                      Inclusions
                    </h2>
                    <div className="grid grid-cols-2 gap-2 sm:grid-cols-4 sm:gap-4">
                      {selectedTour.inclusions.map((inclusion, index) => (
                        <div
                          key={index}
                          className="flex flex-col items-center font-palette-font-2"
                        >
                          {typeof inclusion.icon === "object" ? (
                            <FontAwesomeIcon
                              icon={inclusion.icon}
                              className="h-5 mb-2 text-4xl text-palette-color-4"
                            />
                          ) : (
                            <img
                              src={inclusion.icon}
                              alt="Inclusion Icon"
                              className="h-6 mb-2"
                            />
                          )}
                          <p className="text-sm">{inclusion.text}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <section className="w-full px-4 pt-10 itinerary-section md:px-12 lg:px-20">
              <div className="mx-auto">
                <h2 className="mb-8 text-4xl text-palette-color-1 font-palette-font-1">
                  Detailed Itinerary
                </h2>

                {selectedTour.itinerary.map((day, index) => (
                  <div
                    key={index}
                    className="p-6 mb-8 bg-white rounded-lg shadow-md font-palette-font-2"
                  >
                    <h3 className="mb-4 text-xl font-semibold text-palette-color-12">
                      {day.day}: {day.city}
                    </h3>
                    {day.detailsaboveimage && (
                      <p className="text-md">{day.detailsaboveimage}</p>
                    )}

                    {/* Display the image if available */}
                    <div className="flex flex-col md:flex-row">
                      {" "}
                      {/* Apply flex classes */}
                      {day.image && (
                        <div className="w-full mb-4 md:w-1/3 md:mb-0 md:mr-4">
                          <div className="flex items-center justify-center h-full">
                            <img
                              src={day.image}
                              alt={`Day ${index + 1}`}
                              className="h-auto max-w-full my-4 transition-transform rounded-lg shadow-md hover:scale-105 transform-origin-center"
                            />
                          </div>
                        </div>
                      )}
                      <div className="w-full text-md md:w-2/3">
                        {day.details && <p>{day.details}</p>}
                      </div>
                    </div>
                    {day.detailsbelowimage && (
                      <p className="text-md">{day.detailsbelowimage}</p>
                    )}
                  </div>
                ))}
              </div>
            </section>
            {/* "Book the tour" section */}
            <div className="h-px my-6 bg-palette-color-4"></div>{" "}
            <div className="flex items-center justify-center my-6 space-x-4">
              <button
                className="px-4 py-2 text-white rounded-lg bg-palette-color-4"
                onClick={() => setOpen(true)}
              >
                Book this tour or modify this itinerary
              </button>
            </div>
            <Modal1 open={Open} onClose={() => setOpen(false)}></Modal1>
            <div className="h-px my-6 bg-palette-color-4"></div>{" "}
            {/* Excluded Services Section */}
            <section className="px-4 py-8 mb-4 bg-palette-color-2 md:px-12 lg:px-24">
              <div className="max-w-3xl mx-auto text-center">
                <div className="flex flex-col items-center mb-4">
                  <h1 className="mb-4 font-semibold text-palette-color-4 font-palette-font-1">
                    Excluded Services in this Journey:
                  </h1>
                  <button
                    onClick={toggleCollapse}
                    className="flex items-center justify-center text-white rounded-full bg-palette-color-4 hover:bg-palette-color-5"
                  >
                    <svg
                      className={`w-6 h-6 transform ${
                        isCollapsed ? "rotate-90" : "rotate-180"
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d={isCollapsed ? "M9 5l7 7-7 7" : "M5 9l7 7 7-7"}
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className={`overflow-hidden transition-max-height duration-300 ease-in-out ${
                    isCollapsed ? "max-h-0" : "max-h-screen"
                  }`}
                >
                  <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
                    {excludedServices.map((service, index) => (
                      <div key={index} className="flex items-start">
                        <svg
                          className="flex-shrink-0 w-4 h-4 mr-2 text-palette-color-4"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="4"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                        <p className="text-left font-palette-font-2 text-palette-color-1">
                          {service}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="w-full px-4 lg:w-1/4">
            <section className="sticky overflow-y-scroll p-4 mb-8 bg-white rounded-lg shadow-md top-12">
              {/* Map */}
              <img src={selectedTour.map} alt={selectedTour.title} />

              {/* Price */}
              <center>
                <p className="mt-2 font-semibold text-l text-palette-color-4">
                  {selectedTour.title}
                </p>
              </center>

              <div className="p-4 bg-white rounded-lg shadow-md">
                <h2 className="mb-4 text-xl font-semibold">
                  Price-Per Person Starting From:
                </h2>
                <ul className="mb-4 space-y-2 list-disc list-inside">
                  <li className="text-palette-color-4 lg:text-sm">Pocket Friendly: ${selectedTour.Affordable}USD</li>
                  <li className="text-palette-color-4 lg:text-sm">Semi Luxury: ${selectedTour.Semi}USD</li>
                  <li className="text-palette-color-4 lg:text-sm">Luxury: ${selectedTour.Luxury}USD</li>
                </ul>
                <button
                  className="block px-4 py-2 mx-auto text-sm font-semibold text-white rounded-full bg-palette-color-4 hover:bg-palette-color-5"
                  onClick={() => setOpen(true)}
                >
                  Book This Journey
                </button>
              </div>
            </section>
          </div>
          <Modal1 open={Open} onClose={() => setOpen(false)}></Modal1>
          <section className="w-full px-4 py-12 bg-gray-100 itinerary-section md:px-12 lg:px-24">
            <div className="max-w-6xl mx-auto">
              <h2 className="mb-8 text-4xl text-palette-color-1 font-palette-font-1">
                Recommended Tours
              </h2>
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                {selectedTour.recommendedTours.map((tour, index) => (
                  <Link to={tour.id} className="tour-link" key={index}>
                    <div
                      key={index}
                      className="overflow-hidden bg-white rounded-lg shadow-md"
                    >
                      <img
                        src={tour.image}
                        alt={tour.name}
                        className="object-cover w-full h-48 transition-transform hover:scale-105 transform-origin-center"
                      />
                      <div className="p-4">
                        <h3 className="mb-2 text-xl font-semibold text-palette-color-12">
                          {tour.name}
                        </h3>
                        <p className="text-palette-color-4">
                          {tour.description}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default TourDetails;
