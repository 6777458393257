import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import firebaseApp from "../firebase";
import Newnav from "../components/Newnav";

const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "order");

function PaymentForm() {
  const [formResponses, setFormResponses] = useState([]);

  useEffect(() => {
    // Fetch form responses from Firebase Firestore when the component mounts
    const fetchFormResponses = async () => {
      try {
        const querySnapshot = await getDocs(formsCollectionRef);
        const responses = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data().order;
          if (data && data.name) {
            responses.push(data);
          }
        });
        setFormResponses(responses);
      } catch (error) {
        console.error("Error fetching form responses: ", error);
      }
    };

    fetchFormResponses();
  }, []);

  return (
    <div>
      <Newnav />
      <div className="flex items-center justify-center bg-gray-800">
      <div className="w-full p-6 m-10 bg-white rounded-lg shadow-md">
          <div className="text-center">
            <i className="text-5xl text-transparent fa fa-book bg-gradient-to-r bg-clip-text from-palette-color-4 to-palette-color-15"></i>
            <h1 className="mt-4 text-3xl font-bold font-palette-font-2 font-palette-font-3 font-palette-font-13 text-gray-800">Payment</h1>
          </div>
      <div className="container mx-auto p-4">
        <table className="w-full border-collapse border rounded-lg">
          <thead>
            <tr className="bg-gray-200">
              <th className="p-2">Name</th>
              <th className="p-2">Email</th>
              <th className="p-2">Mobile No.</th>
              <th className="p-2">Address</th>
              <th className="p-2">Pincode</th>
              <th className="p-2">Date</th>
            </tr>
          </thead>
          <tbody>
            {formResponses.map((response, index) => (
              <tr key={index} className={index % 2 === 0 ? " text-center bg-white" : " text-center bg-gray-100"}>
                <td className="p-2">{response.name}</td>
                <td className="p-2">{response.email}</td>
                <td className="p-2">{response.mobNo}</td>
                <td className="p-2">{response.address}</td>
                <td className="p-2">{response.pincode}</td>
                <td className="p-2">{response.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
      </div>
    </div>
  );
}

export default PaymentForm;
