import React, { useState } from "react";
import Navbar from "../components/navbar";

const FAQAccordion = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    if (activeAccordion === index) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(index);
    }
  };

  const faqData = [
    {
      question: "Is India a Family-Friendly Destination?",
      answer:
        "India offers a diverse range of unique activities, experiences, and attractions, making it an ideal destination for family travel. The country's warm hospitality and rich cultural traditions create an enriching environment for young travelers, and children are warmly welcomed everywhere they go. Whether you're seeking natural beauty, historical sites, bustling cities, or vibrant festivals like Holi or the Pushkar Fair, India has it all.",
    },
    {
      question:
        "Is India a Safe Country for Traveling with Family, Friends, Solo Female, or Business?",
      answer:
        "When it comes to personal safety and the well-being of your travel group, India is generally considered a safe destination. English is widely spoken and easily accessible, making navigation straightforward. However, it's advisable to take common-sense safety precautions, as you would when traveling anywhere in the world. Having a local tour guide can be invaluable for identifying safe areas to explore and recommending hygienic dining options. It's essential for international tourists to avoid drinking tap water and opt for bottled water during their stay.",
    },
    {
      question: "What Types of Accommodations Are Available in India?",
      answer:
        "India offers a wide range of accommodations to suit various budgets and preferences. From opulent palace hotels to Western-style chain hotels and budget-friendly hostels, you'll find numerous options. Note that overnight stays are typically not permitted in wildlife sanctuaries and national parks, but you can easily find private accommodations on the outskirts of these areas. Hotel rates vary depending on the season and location. At ITH, we select accommodations that are not only comfortable but also maintain high hygiene standards. We provide boutique experiences for international guests and luxury hotels to ensure your stay is memorable.",
    },
    {
      question:
        "Booking and Payment with Indian Treasure House Tours and Journeys",
      answer:
        "To confirm a booking with us, we require an advance payment, typically a percentage of the total cost, to secure your reservation. The remaining balance can be paid according to the agreed-upon payment plan for your journey. In some cases, such as during peak or holiday seasons, full payment may be required to confirm your reservation. We accept payments via Visa, MasterCard, and American Express, with a small bank processing fee for credit card payments. Alternatively, you can make a direct payment through a wire transfer to our bank account, with no additional fees. We will provide you with our billing and bank details at the time of invoicing, which you will receive after confirming your bookings.",
    },
    {
      question: "Is a Visa Required for Traveling to India?",
      answer:
        "Most international travelers need to obtain a visa before visiting India. It's essential to contact your local Indian embassy for guidance on the visa application process. Tourist visas are typically valid for six months, and it's necessary to have at least two blank pages in your passport when applying. India also offers e-visa options for convenience.",
    },
    {
      question: "Do I Need Travel Insurance for India?",
      answer:
        "Before traveling to India, ensure your travel insurance covers loss of personal items, personal accidents, medical emergencies, emergency travel expenses, and trip cancellation. We require all guests traveling with Incredible Real India to have travel insurance.",
    },
    {
      question: "Are Specific Vaccinations Required for Traveling to India?",
      answer:
        "We recommend discussing vaccination requirements with your healthcare provider, as we cannot provide medical advice.",
    },
    {
      question: "How Safe Am I When Traveling with Incredible Real India?",
      answer:
        "In the event of a medical emergency or any unfavorable incident, our team is readily available to assist you. Your safety is our top priority, and we maintain constant communication with our vehicles on the road. Additionally, we provide contact information for private doctors and medical facilities in your journey documents, and most hotels partner with doctors for 24-hour on-call services.",
    },
    {
      question: "Are Dietary Restrictions Accommodated in India?",
      answer:
        "Our restaurant and hotel selections can cater to specific dietary requirements.",
    },
    {
      question: "Tipping Guidelines",
      answer:
        "Tipping practices vary by location. On some journeys, certain gratuities are included. Typically, it's customary to tip the car or bus driver, driver's assistant (if applicable), local tour guide, as well as waitstaff, hotel housekeeping, and hotel porters.",
    },
    {
      question: "Weather in India",
      answer:
        "India's climate varies from region to region. Winters are generally cool and fresh from November to March, while hot and dry temperatures, along with dust storms and coastal humidity, prevail from April to June. The best time to visit the Himalayas is during the monsoon season when the rest of the country experiences heavy rainfall.",
    },
    {
      question: "Currency in India",
      answer:
        "Major international airports in India have currency exchange counters. Most hotels can also assist with currency exchange. It's a good practice to carry sufficient local currency for basic expenses, as some wildlife resorts may not offer currency exchange facilities. There are no specific restrictions on the amount of foreign currency or traveler's checks you can bring into India, but you'll need to fill out a declaration form upon arrival.",
    },
    {
      question: "Credit Card Usage in India",
      answer:
        "While major cities accept MasterCard and Visa, Diner's Club and American Express cards may not be as widely accepted. ATMs are readily available in major cities, but carrying some Indian currency is advisable for smaller locations and for tipping.",
    },
    {
      question: "Internet and Mobile Coverage in India",
      answer:
        "India has extensive telecom coverage, with mobile phones working in most areas. However, not all mobile operators have the same coverage quality. Some remote areas, such as national parks and mountainous regions, may have limited network access. Most hotels offer internet facilities, and you can find internet cafes in cities. Acquiring a local prepaid mobile number can be cost- effective, and our cars and buses provide free Wi-Fi access for your convenience.",
    },
  ];

  return (
    <div>
      <div
        className="bg-palette-color-4 relative sticky top-0"
        style={{ zIndex: 2 }}
      >
        <Navbar />
      </div>
      <h1 className="mt-6 mb-8 text-4xl leading-tight text-center text-palette-color-18 font-palette-font-1">
        Frequently Asked Questions
      </h1>
      <section className="mb-32" style={{ zIndex: 1 }}>
        <div id="accordionFlushExample font-palette-font-2">
          {faqData.map((item, index) => (
            <div
              key={index}
              className="rounded-none border border-t-0 border-l-0 border-r-0 border-neutral-200"
            >
              <h2 className="mb-0" id={`flush-heading${index}`}>
                <button
                  className={`group relative flex w-full items-center rounded-none border-0 py-4 px-5 text-left text-base font-bold transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none ${
                    activeAccordion === index ? "text-primary" : ""
                  } ${
                    activeAccordion === index
                      ? "box-shadow:inset_0_-1px_0_rgba(229,231,235)"
                      : ""
                  } ${
                    activeAccordion === index ? "dark:text-primary-400" : ""
                  }`}
                  type="button"
                  data-te-collapse-init
                  onClick={() => toggleAccordion(index)}
                  aria-expanded={activeAccordion === index}
                  aria-controls={`flush-collapse${index}`}
                >
                  {item.question}
                  <span
                    className={`ml-auto h-5 w-5 shrink-0 rotate-${
                      activeAccordion === index ? "0" : "-180deg"
                    } fill-[#336dec] transition-transform duration-200 ease-in-out ${
                      activeAccordion === index
                        ? "group-[[data-te-collapse-collapsed]]:rotate-0"
                        : ""
                    } ${
                      activeAccordion === index
                        ? "group-[[data-te-collapse-collapsed]]:fill-[#212529]"
                        : ""
                    } ${
                      activeAccordion === index
                        ? "motion-reduce:transition-none"
                        : ""
                    } ${
                      activeAccordion === index ? "dark:fill-[#8FAEE0]" : ""
                    } ${
                      activeAccordion === index
                        ? "dark:group-[[data-te-collapse-collapsed]]:fill-[#eee]"
                        : ""
                    }`}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <path
                        fill-rule="evenodd"
                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </span>
                </button>
              </h2>
              <div
                id={`flush-collapse${index}`}
                className={`!visible border-0 ${
                  activeAccordion === index ? "" : "hidden"
                }`}
                data-te-collapse-item
                aria-labelledby={`flush-heading${index}`}
                data-te-parent="#accordionFlushExample"
              >
                <div className="py-4 px-5 text-neutral-500 dark:text-neutral-300">
                  {item.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FAQAccordion;
