import React from "react";
import Navbar from "../components/navbar";
import traveltip from "../assets/traveltip.webp";
import traveltip1 from "../assets/Travel Tips/traveltip1.webp";
import Visa from "../assets/Travel Tips/visa.webp";
import Insurance from "../assets/Travel Tips/insurance.svg"
import Clothes from "../assets/Travel Tips/clothes.png"
import Health from "../assets/Travel Tips/health.png"
import Money from "../assets/Travel Tips/money.png"
import Food from "../assets/Travel Tips/food.jpeg"
import Water from "../assets/Travel Tips/water.png"
import Safety from "../assets/Travel Tips/Safety.png"
import Driver from "../assets/Travel Tips/driver.jpg"
import Solo from "../assets/Travel Tips/solo.png"
import Temple from "../assets/Travel Tips/temple.png"
import Tip from "../assets/Travel Tips/tip.png"
import Beggar from "../assets/Travel Tips/beggar.png"
import TravelTip from "../assets/Travel Tips/traveltip.png"
import Culture from "../assets/Travel Tips/cultural.jpg"
import Scam from "../assets/Travel Tips/scam.avif"
import Bargain from "../assets/Travel Tips/bargain.png"
import Delay from "../assets/Travel Tips/Delay.png"
import Nopda from "../assets/Travel Tips/NoPDA.jpg"
import Go from "../assets/Travel Tips/Go.png"

function TravelTips() {
  return (
    <div>
      <Navbar />
      <section
        className="relative h-screen overflow-hidden"
        style={{ top: "-57px" }}
      >
        <img
          src={traveltip}
          alt="TravelTips"
          className="absolute top-0 left-0 z-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 z-10 flex items-center justify-center text-white text-center">
          <div>
            <h1 className="mb-6 text-4xl font-extrabold leading-tight md:text-6xl lg:text-7xl text-palette-color-3 font-palette-font-1">
              Travel Tips
            </h1>
          </div>
        </div>
      </section>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="md:w-1/3 mt-4 md:mt-0 md:order-1 flex flex-col justify-center items-center">
          <img src={traveltip1} alt="traveltip" />
        </div>
        <div className="md:w-2/3 p-8 justify-center md:order-2">
          <p className="mb-8 font-palette-font-2 text-justify">
            India is a very busy, overwhelming, and occasionally confusing
            country. You'll need some time to get used to the hectic energy of
            this confusing place and figure out how to keep yourself safe,
            healthy, and sane. India is among the most rewarding destinations to
            visit, despite not being the easiest. It provides some genuinely
            incredible views that are unmatched by anything else in the entire
            globe. It also has mouthwatering cuisine, breathtaking scenery,
            friendly and endearing locals, and a rich cultural history that is
            fascinating to discover.
            <br />
            So, before you travel to India, here are some shrewd tips and tricks
            to help you make the most of your experience there.
          </p>
        </div>
      </div>
      <div className="container pt-10 mb-14 mx-auto text-center">
        <h2 className="text-4xl text-palette-color-18 font-palette-font-1">
          THE PREPARATIONS FOR YOUR INDIA TRAVEL
        </h2>
      </div>
      {renderSection(
        "VISA",
        "Must be for Nepal and India. Visas are available for citizens of more than 150 countries at the time of arrival in India. To obtain a visa approval through mail and obtain a stamp upon arrival in India, you must apply online. Please verify the names of the countries and the procedure online.",
        Visa
      )}
      {renderSection(
        "INSURANCE",
        "Travel insurance (It is recommended that you insure yourself against medical claims, theft, loss, and emergencies).",
        Insurance
      )}
      {renderSection(
        "CLOTHES",
        "Travel as light as you can, rely on the local laundromat to clean your stuff, and visit charming Indian cotton stores to round out your outfit. It is customary to cover your body to avoid getting sunburned or bitten by mosquitoes. Both sexes cover their legs and shoulders. Of course, in more contemporary areas, especially with younger people, you will notice different customs.",
        Clothes
      )}
      {renderSection(
        "HEALTH",
        "Bring your own prescription drugs. There's no need to take any extra medication when it's not monsoon season. All the necessary resources are available on site, in case you require a physician or pharmacist.",
        Health
      )}
      {renderSection(
        "MONEY",
        "You can exchange cash with your driver or guide, who will get you the best rate, or withdraw money from ATMs. Naturally, avoid doing this around strangers. You'll require a large number of tiny notes for small purchases, tips, toilets, etc.",
        Money
      )}
      {renderSection(
        "FOOD",
        "Food tastes good and is unique. When starting a trip, use caution when handling meat and dairy products. Fruit is safe to eat if you peel it yourself, and you can even get peeled fruit in hotels. Papayas taste great when paired with a small squeeze of lime. If your dish isn't spicy, ask to have the chillies removed. Request less salt overall and less sugar in your chai. Try everything, even if just a little bit. There are many crowded, spotless areas where street food is served. You shouldn't miss this aspect of Indian culture.Reduce your intake and consume boiled rice, bananas, and coke to prevent dehydration if you have an upset stomach.",
        Food
      )}
      {renderSection(
        "WATER",
        "Drink mineral water at all times. You can even use it to brush your teeth.",
        Water
      )}
      {renderSection(
        "SAFETY",
        "The safety regulations are the same as those in other nations. Keep an eye on your possessions and exercise street smarts, but don't get fixated on them. If you're traveling by train or car, never accept food offered by other passengers. There may be sleeping pills in it. In trains, always travel in the reserved class. It is always advisable to refrain from driving at night on Indian highways.",
        Safety
      )}
      {renderSection(
        "DRIVER",
        "When you drive, you have someone to help you at all times, and your luggage is always secure. Telling him to drive more slowly or to call us right away is always preferable if you're uncomfortable with the way he's driving or if you think his speeding is unsafe.",
        Driver
      )}
      {renderSection(
        "SOLO WOMEN",
        "In some desolate areas, women who travel alone should refrain from going for walks during strange hours. Avoid appearing confused when traveling. Maintain the demeanor of a local authority.",
        Solo
      )}
      {renderSection(
        "TEMPLES",
        "You shouldn't miss any of India's temples because they are an integral part of the country's culture. Whether you believe in God or not, it is very moving to watch and listen to the people going about their daily prayers and to the songs and dances. You will see a great deal of religious reverence in mosques belonging to Muslims, Sikhs, Hindus, and Jainites, among others. In India, visiting places of worship is a must. You should bring old socks that you can throw away or buy a pack of inexpensive socks because you will need to take off your shoes.",
        Temple
      )}
      {renderSection(
        "TIPPING",
        "Tipping is a widely accepted custom in India. Some suggestions for how much to tip for face photos at tourist attractions are 20 to 50 rupees (less than a Euro). It should be between 5 and 10% of your total at restaurants. Your driver should receive a tip of at least 5 to 10 euros per day, as he receives tips only once or twice a month. The amount you tip will depend on your budget and the quality of service the driver provides.",
        Tip
      )}
      {renderSection(
        "BEGGARS",
        "You will come across a lot of mendacity when begging, which makes sense given that India is still a developing nation with high rates of poverty. Give as you are moved to do so. Remember that some beggars in tourist areas don't really keep much of their gatherings and are actually working for someone.",
        Beggar
      )}
      {renderSection(
        "A TIP FOR YOU",
        "Do some research on the places you are visiting before you leave or while using the Wi-Fi at your hotel. Bring a guidebook or two. You're curious about the current events in India, a nation rich in culture and traditions. Go with a receptive mindset.",
        TravelTip
      )}
      {renderSection(
        "CULTURAL DIFFERENCES",
        "You can pretty much count on experiencing cultural shock when you first get to India. Attempting to resist it is futile. Prepare yourself and acknowledge that things will not be the same as they are at home. Leave your expectations at home and try to keep an open mind.",
        Culture
      )}
      {renderSection(
        "RELEGIOUS SCAMS",
        "You might feel under pressure to purchase a sacred offering or to make a donation at a lot of the mosques and temples. A holy man might, for instance, tie a string around your wrist or place a red dot on your forehead before requesting money from you. Many tourists feel compelled to comply because they don't want to offend the native faith. But if you don't want to, you don't have to. If it seems like it would cost a lot for a local to perform, it is most likely a tourist scam rather than a genuine spiritual offering.",
        Scam
      )}
      {renderSection(
        "LEARN HOW TO BARGAIN",
        "In India, Bargaining is an inherent aspect of shopping; however, if you come from a culture where haggling is not common, you may find it unfamiliar and unsettling at first. It's crucial to keep in mind that this is a cordial negotiation rather than a war.",
        Bargain
      )}
      {renderSection(
        "DON'T EXPECT THINGS ON TIME",
        "Keep in mind that events take place on Indian Time. Things can take far longer than anticipated, trains may not depart when they are supposed to, and friends from India may not show up when they promised. In order to avoid becoming anxious, it's critical to remember this and to budget extra time for delays. Bring along a good book to read while you wait, and know that what you are waiting for will materialize eventually.",
        Delay
      )}
      {renderSection(
        "AVOID AFFECTION IN PUBLIC",
        "When visiting India with a partner, refrain from publicly hugging, kissing, or holding hands. These kinds of open expressions of love are frowned upon and deemed improper in Indian culture. Show respect for the locals by keeping your romantic feelings under wraps.",
        Nopda
      )}
      {renderSection(
        "JUST GO FOR IT",
        "It's a fact that traveling within India will be far more difficult than traveling abroad. It's possible that you will occasionally feel overburdened and worn out, so it's critical to look after yourself and be ready for this. Nonetheless, traveling to India is a truly worthwhile experience. You will encounter some truly memorable people, take in some breathtaking scenery, and learn about an intriguing ancient culture. You'll be happy that you visited India.",
        Go
      )}
    </div>
  );
}

function renderSection(title, content, image) {
  return (
    <div className="flex flex-col-reverse sm:flex-row mb-6">
      <div className="sm:w-1/3 md:w-1/4 md:order-1 md:pl-4 flex items-center justify-center">
        <img
          src={image}
          alt={title}
          className="w-40 h-40 md:w-56 md:h-56 rounded-full shadow-lg"
        />
      </div>
      <div className="sm:w-2/3 md:w-3/4 p-4 md:p-8 md:order-2">
        <h2 className="text-xl text-center md:text-2xl text-palette-color-18 font-palette-font-1 mb-2 md:mb-4">
          {title}
        </h2>
        <p className="text-sm md:text-base font-palette-font-2 text-justify">
          {content}
        </p>
      </div>
    </div>
  );
}

export default TravelTips;
