import gt1 from "../assets/Tours/Banners_/Golden Tours/GoldenTour 1.webp";
import gt2 from "../assets/Tours/Banners_/Golden Tours/GoldenTour 2.webp";
import gt3 from "../assets/Tours/Banners_/Golden Tours/GoldenTour 3.webp";
import gt4 from "../assets/Tours/Banners_/Golden Tours/GoldenTour 4.webp";
import gt5 from "../assets/Tours/Banners_/Golden Tours/GoldenTour 5.webp";
import gt6 from "../assets/Tours/Banners_/Golden Tours/GoldenTour 6.webp";
import rt1 from "../assets/Tours/Banners_/Rajasthan Tours/Rajasthan Tour 1.webp";
import rt2 from "../assets/Tours/Banners_/Rajasthan Tours/Rajasthan Tour 2.webp";
import rt3 from "../assets/Tours/Banners_/Rajasthan Tours/Rajasthan Tour 3.webp";
import rt4 from "../assets/Tours/Banners_/Rajasthan Tours/Rajasthan Tour 4.webp";
import st1 from "../assets/Tours/Banners_/South Tours/South Tour 1.webp";
import st2 from "../assets/Tours/Banners_/South Tours/South Tour 2.webp";
import st3 from "../assets/Tours/Banners_/South Tours/South Tour 3.webp";
import st4 from "../assets/Tours/Banners_/South Tours/South Tour 4.webp";
import rickshaw from "../assets/rickshaw.png";
import {
  faHotel,
  faCar,
  faUtensils,
  faMoneyBillAlt,
  faWifi,
  faUtensilSpoon,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import delhideparture from "../assets/delhi departure1.webp";
import departure2 from "../assets/departure 2.webp";
import departure3 from "../assets/departure 3.webp";
import delhiitinary from "../assets/delhi-itenary.webp";
import jaipuritinary from "../assets/jaipur itinerary.webp";
import agraitinary from "../assets/agra itinerary.webp";
import delhiitinary2 from "../assets/delhi itinary 2.webp";
import jaipuritinary2 from "../assets/jaipur itinerary 2.webp";
import agraitinary2 from "../assets/agra itinerary 2.webp";
import udaipuritinary from "../assets/udaipur itinary.webp";
import rtdelhiitinary1 from "../assets/rt-delhi ittanary-1.webp";
import rtjaipuritinary1 from "../assets/rt-jaipur itanary-1.webp";
import rtmandawaitinary1 from "../assets/rt-ittanary mandawa-1.png";
import rtorchhaitinary1 from "../assets/rt-orcha ittanary-1.webp";
import rtkhajrahoitinary1 from "../assets/rt-khajrao ittanary-1.avif";
import rtvaranasiitinary1 from "../assets/rt-ittanary varanasi-1.webp";
import gtmumbaiitinary1 from "../assets/gt-itanary-mumbai1.webp";
import gthampiitinary1 from "../assets/gt-hampi-itianary.webp";
import gtajantaitinary from "../assets/gt-ajanta-ellora-itianary.webp";
import gtdelhiitinary3 from "../assets/gt-delhi-itianary3.webp";
import rtagraitinary2 from "../assets/rt-agra-itianary2.webp";
import rtbikaneritinary2 from "../assets/rt-bikaner-itianary2.webp";
import rtdelhiitinary2 from "../assets/rt-delhi-itianary2.webp";
import rtjaipuritinary2 from "../assets/rt-jaipur itanary2.webp";
import rtjaisalmeritinary2 from "../assets/rt-jasalmer-itianary2.webp";
import rtjodhpuritinary2 from "../assets/rt-jodhpur-itinary2.webp";
import rtmandawaitinary2 from "../assets/rt-mandawa-itianary2.webp";
import rtpuskaritinary2 from "../assets/rt-pushkar-itianary2.webp";
import rtudaipuritinary2 from "../assets/rt-udaipur-itianary-2.webp";
import stcochinitinary1 from "../assets/st-cochin-itanary1.webp";
import stalleppeyitinary1 from "../assets/st-allepey-itanary1.webp";
import stthekkadyitinary1 from "../assets/st-thekkady-itanary1.webp";
import stmunnaritinaary1 from "../assets/st-munnar-itanary1.webp";
import stmumbaiitinary1 from "../assets/st-mumbai-itinary1.webp";
import stcochinitinary2 from "../assets/st-cochin-ittanary2.webp";
import stalleppeyitinary2 from "../assets/st-alleppey-ittanary2.webp";
import stmunnaritinaary2 from "../assets/st-munnar-itanary2.webp";
import stmaduraiitinary2 from "../assets/st-madurai-itanary2.webp";
import stthanjavuritinary2 from "../assets/st-tanjore-itanary2.webp";
import stmumbaiitinary2 from "../assets/st-mumbai-itanary2.webp";
import stpondicharryitinary2 from "../assets/st-pondicherry-itanary2.webp";
import stmahabalipuramitinary2 from "../assets/st-mahabalipuram-itinary2.webp";
import stagraitinary3 from "../assets/st-agra-ittanary3.webp";
import stajantaitinary3 from "../assets/st-ajanta-ittnanary3.webp";
import stcochinittanary3 from "../assets/st-cochin-ittanary3.webp";
import stdelhiittanary3 from "../assets/st-delhi-ittanary3.webp";
import stelleppeyittnanary3 from "../assets/st-elleppey-ittnanary3.webp";
import stjaipurittanary3 from "../assets/st-jaipur-ittanary3.webp";
import stmumbaiittanary3 from "../assets/st-mumbai-ittanary3.webp";
import stmunnarittnanary3 from "../assets/st-munnar-ittnanary3.webp";
import stthekkadyittanary3 from "../assets/st-thekkady-ittanary3.webp";
import stcochinitinary4 from "../assets/st-cochin-ittnary4.webp";
import stalleppeyitinary4 from "../assets/st-alleppy-ittanary4.webp";
import sthampyitinary4 from "../assets/st-hampi-ittanary4.webp";
import stmumbaiitinary4 from "../assets/st-mumbia-ittnanary4.webp";
import sthassanitinary4 from "../assets/st-hassan-ittanary4.webp";
import stmunnaritinary4 from "../assets/st-munnar-ittanary4.webp";
import stootyitinary4 from "../assets/st-otty-itanary4.webp";
import stmysoreitinary4 from "../assets/st-mysore-ittanary4.webp";
import stthekkadyitinary4 from "../assets/st-thekkady-ittanary4.webp";
import rtjaisalmeritinary3 from "../assets/rt-jaisalmer-itanary3.webp";
import rtjodhpuritinary3 from "../assets/rt-jodhpur-itanary3.webp";
import rtkhajrahoitinary3 from "../assets/rt-khajrao-itanary3.avif";
import rtmandawaitinary3 from "../assets/rt-mandawa-itnanary3.png";
import rtagraitinary3 from "../assets/rt-agra-itanary3.webp";
import rtbikaneritinnary3 from "../assets/rt-bikaner-itanary3.webp";
import rtjaipuritinary3 from "../assets/rt-jaipur-itanary3.webp";
import rtorchaitinary3 from "../assets/rt-orcha-itanary3.webp";
import rtpuskaritinary3 from "../assets/rtpushkar-itanary3.webp";
import rtudaipuritinary3 from "../assets/rt-udaipur-itanary3.webp";
import rtvaranashiitinary3 from "../assets/rt-varanasi-itanary3.webp";
import rtdelhiitinary3 from "../assets/rt-delhi-itinary3.webp";
import gtdelhiitinary4 from "../assets/gt-delhi-itinary4.webp";
import gtmandawaitinary4 from "../assets/gt-mandawa-itnanary4.png";
import gtjaipuritinary4 from "../assets/gt-jaipur-ittanary4.webp";
import gtagraitinary4 from "../assets/gt-agra-itinary-4.webp";
import gtdelhiitinary5 from "../assets/gt-delhi-itinarery5.webp";
import gtjaipuritinary5 from "../assets/gt-jaipuritinarery5.webp";
import gtmandawaitinary5 from "../assets/gt-mandawa-itinary5.png";
import gtvaranasiitinary5 from "../assets/gt-varanasi-itinarery5.webp";
import gtagraitinary5 from "../assets/gt-agra-itinarery5.webp";
import gtagraitinary6 from "../assets/gt-agra-itinarery6.webp";
import gtdelhiitinary6 from "../assets/gt-delhi-itinarery6.webp";
import gtjaipuritinary6 from "../assets/gt-jaipur-itinarery6.webp";
import gtmandawaitinary6 from "../assets/gt-mandawa-itinarery6.webp";
import gtpuskaritinary6 from "../assets/gt-pushkar-itinarery6.webp";
import rtagraitinary4 from "../assets/rt-agra-itanary4.webp";
import rtbundiitinary4 from "../assets/rt-bundi-itinary4.webp";
import rtdelhiitinary4 from "../assets/rt-delhi-itinerary4.webp";
import rtjaipuritinary4 from "../assets/rt-jaipur-itinerary4.webp";
import rtmandawaitinary4 from "../assets/rt-mandawa-itinerary4.webp";
import rtpuskaritinary4 from "../assets/rt-pushkar-itinerary4.webp";
import rtranthamboreitinary4 from "../assets/rt-ranthambore-itinerary4.webp";
import rtdelhi1 from "../assets/Tours/Cities/Rajasthan Tours/Rajasthani Heritage Retreat/delhi.jpg";
import rtmandawa1 from "../assets/Tours/Cities/Rajasthan Tours/Rajasthani Heritage Retreat/mandawa.jpg";
import rtbikaner1 from "../assets/Tours/Cities/Rajasthan Tours/Rajasthani Heritage Retreat/bikaner.jpg";
import rtjaisalmer1 from "../assets/Tours/Cities/Rajasthan Tours/Rajasthani Heritage Retreat/jasalmer.jpg";
import rtjodhpur1 from "../assets/Tours/Cities/Rajasthan Tours/Rajasthani Heritage Retreat/jodhpur.jpg";
import rtudaipur1 from "../assets/Tours/Cities/Rajasthan Tours/Rajasthani Heritage Retreat/udaipur.jpg";
import rtpuskar1 from "../assets/Tours/Cities/Rajasthan Tours/Rajasthani Heritage Retreat/pushkar.jpg";
import rtjaipur1 from "../assets/Tours/Cities/Rajasthan Tours/Rajasthani Heritage Retreat/jaipur.jpg";
import rtagra1 from "../assets/Tours/Cities/Rajasthan Tours/Rajasthani Heritage Retreat/agra.jpg";
import rtdelhi2 from "../assets/Tours/Cities/Rajasthan Tours/Mystical North India Discovery/delhi.jpg";
import rtmandawa2 from "../assets/Tours/Cities/Rajasthan Tours/Mystical North India Discovery/mandawa.jpg";
import rtjaipur2 from "../assets/Tours/Cities/Rajasthan Tours/Mystical North India Discovery/jaipur.jpg";
import rtagra2 from "../assets/Tours/Cities/Rajasthan Tours/Mystical North India Discovery/agra.jpg";
import rtorchha2 from "../assets/Tours/Cities/Rajasthan Tours/Mystical North India Discovery/orchaa.jpg";
import rtkhajraho2 from "../assets/Tours/Cities/Rajasthan Tours/Mystical North India Discovery/khajuraho.jpg";
import rtvaranasi2 from "../assets/Tours/Cities/Rajasthan Tours/Mystical North India Discovery/varanasi.jpg";
import rtdelhi3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/delhi.jpg";
import rtmandawa3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/Mandawa.jpg";
import rtbikaner3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/bikaner.jpg";
import rtjaisalmer3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/jaisalmer.jpg";
import rtjodhpur3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/jodhpur.jpg";
import rtudaipur3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/udaipur.jpg";
import rtpuskar3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/pushkar.jpg";
import rtjaipur3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/jaipur.jpg";
import rtagra3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/agra.jpg";
import rtorcha3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/orcha.jpg";
import rtkhajraho3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/Khajuaho.jpg";
import rtvaranasi3 from "../assets/Tours/Cities/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/varansai.jpg";
import rtdelhi4 from "../assets/Tours/Cities/Rajasthan Tours/A Journey Through Heritage _ Wildlife/delhi.jpg";
import rtmandawa4 from "../assets/Tours/Cities/Rajasthan Tours/A Journey Through Heritage _ Wildlife/mandawa.jpg";
import rtpushkar4 from "../assets/Tours/Cities/Rajasthan Tours/A Journey Through Heritage _ Wildlife/pushkar.jpg";
import rtbundi4 from "../assets/Tours/Cities/Rajasthan Tours/A Journey Through Heritage _ Wildlife/Bundi.jpg";
import rtranthambore4 from "../assets/Tours/Cities/Rajasthan Tours/A Journey Through Heritage _ Wildlife/ranthambour.jpg";
import rtjaipur4 from "../assets/Tours/Cities/Rajasthan Tours/A Journey Through Heritage _ Wildlife/jaipur.jpg";
import rtagra4 from "../assets/Tours/Cities/Rajasthan Tours/A Journey Through Heritage _ Wildlife/agra.jpg";
import gtdelhi1 from "../assets/Tours/Cities/Golden Tours/Majestic Golden Triangle/delhi.jpg";
import gtjaipur1 from "../assets/Tours/Cities/Golden Tours/Majestic Golden Triangle/Jaipur.jpg";
import gtagra1 from "../assets/Tours/Cities/Golden Tours/Majestic Golden Triangle/agra.jpg";
import gtdelhi2 from "../assets/Tours/Cities/Golden Tours/The Royal Heritage Trail/delhi.jpg";
import gtmandawa2 from "../assets/Tours/Cities/Golden Tours/The Royal Heritage Trail/mandawa.jpg";
import gtjaipur2 from "../assets/Tours/Cities/Golden Tours/The Royal Heritage Trail/jaipur.jpg";
import gtagra2 from "../assets/Tours/Cities/Golden Tours/The Royal Heritage Trail/agra.jpg";
import gtdelhi3 from "../assets/Tours/Cities/Golden Tours/Royal Triangle Odessey/delhi.jpg";
import gtmandawa3 from "../assets/Tours/Cities/Golden Tours/Royal Triangle Odessey/mandawa.jpg";
import gtjaipur3 from "../assets/Tours/Cities/Golden Tours/Royal Triangle Odessey/jaipur.jpg";
import gtagra3 from "../assets/Tours/Cities/Golden Tours/Royal Triangle Odessey/agra.jpg";
import gtvaranasi3 from "../assets/Tours/Cities/Golden Tours/Royal Triangle Odessey/varansai.jpg";
import gtdelhi4 from "../assets/Tours/Cities/Golden Tours/Cultural Tapestry Expidition/delhi.jpg";
import gtmandawa4 from "../assets/Tours/Cities/Golden Tours/Cultural Tapestry Expidition/mandawa.jpg";
import gtpuskar4 from "../assets/Tours/Cities/Golden Tours/Cultural Tapestry Expidition/pushkar.jpg";
import gtjaipur4 from "../assets/Tours/Cities/Golden Tours/Cultural Tapestry Expidition/jaipur.jpg";
import gtagra4 from "../assets/Tours/Cities/Golden Tours/Cultural Tapestry Expidition/agra.jpg";
import gtdelhi5 from "../assets/Tours/Cities/Golden Tours/Colors of Rajasthan Expedition/delhi.jpg";
import gtjaipur5 from "../assets/Tours/Cities/Golden Tours/Colors of Rajasthan Expedition/jaipur.jpg";
import gtagra5 from "../assets/Tours/Cities/Golden Tours/Colors of Rajasthan Expedition/agra.jpg";
import gtudaipur5 from "../assets/Tours/Cities/Golden Tours/Colors of Rajasthan Expedition/udaipur.jpg";
import gtmumbai6 from "../assets/Tours/Cities/Golden Tours/Expedition Through India/mumbai.jpg";
import gthampi6 from "../assets/Tours/Cities/Golden Tours/Expedition Through India/hampi.jpg";
import gtajanta6 from "../assets/Tours/Cities/Golden Tours/Expedition Through India/ajantaaellora.jpg";
import gtjaipur6 from "../assets/Tours/Cities/Golden Tours/Expedition Through India/jaipur.jpg";
import gtagra6 from "../assets/Tours/Cities/Golden Tours/Expedition Through India/agra.jpg";
import gtdelhi6 from "../assets/Tours/Cities/Golden Tours/Expedition Through India/delhi.jpg";
import stcochin1 from "../assets/Tours/Cities/South Tours/Coastal Treasures _ Mountain Magic/Cochin.jpg";
import stalleppey1 from "../assets/Tours/Cities/South Tours/Coastal Treasures _ Mountain Magic/alleppey.jpg";
import stthekkady1 from "../assets/Tours/Cities/South Tours/Coastal Treasures _ Mountain Magic/Thekkady.jpg";
import stmunnar1 from "../assets/Tours/Cities/South Tours/Coastal Treasures _ Mountain Magic/Munnar.jpg";
import stmumbai1 from "../assets/Tours/Cities/South Tours/Coastal Treasures _ Mountain Magic/Mumbai.jpg";
import stcochin2 from "../assets/Tours/Cities/South Tours/Southern Delights_ A Journey Through Time/cochin.jpg";
import stalleppey2 from "../assets/Tours/Cities/South Tours/Southern Delights_ A Journey Through Time/alleppey.jpg";
import stthekkady2 from "../assets/Tours/Cities/South Tours/Southern Delights_ A Journey Through Time/Thekkady.jpg";
import stmunnar2 from "../assets/Tours/Cities/South Tours/Southern Delights_ A Journey Through Time/munnar.jpg";
import stmadurai2 from "../assets/Tours/Cities/South Tours/Southern Delights_ A Journey Through Time/madurai.jpg";
import stthanjavur2 from "../assets/Tours/Cities/South Tours/Southern Delights_ A Journey Through Time/tanjore.jpg";
import stpondicharry2 from "../assets/Tours/Cities/South Tours/Southern Delights_ A Journey Through Time/pondicharry.jpg";
import stmahabalipuram2 from "../assets/Tours/Cities/South Tours/Southern Delights_ A Journey Through Time/mahabalipur.jpg";
import stmumbai2 from "../assets/Tours/Cities/South Tours/Southern Delights_ A Journey Through Time/mumbai.jpg";
import stdelhi3 from "../assets/Tours/Cities/South Tours/Heritage Odyssey/delhi.jpg";
import stjaipur3 from "../assets/Tours/Cities/South Tours/Heritage Odyssey/jaipur.jpg";
import stagra3 from "../assets/Tours/Cities/South Tours/Heritage Odyssey/Agra.jpg";
import stajanta3 from "../assets/Tours/Cities/South Tours/Heritage Odyssey/Ajanta.jpg";
import stmumbai3 from "../assets/Tours/Cities/South Tours/Heritage Odyssey/mumbai.jpg";
import stcochin3 from "../assets/Tours/Cities/South Tours/Heritage Odyssey/cochin.jpg";
import stelleppey3 from "../assets/Tours/Cities/South Tours/Heritage Odyssey/elleppey.jpg";
import stthekkady3 from "../assets/Tours/Cities/South Tours/Heritage Odyssey/thekkady.jpg";
import stmunnar3 from "../assets/Tours/Cities/South Tours/Heritage Odyssey/munnar.jpg";
import stmumbai4 from "../assets/Tours/Cities/South Tours/Southern Splendors_ Culture _ Nature Trail/mumbai.jpg";
import stcochin4 from "../assets/Tours/Cities/South Tours/Southern Splendors_ Culture _ Nature Trail/cochin.jpg";
import stalleppey4 from "../assets/Tours/Cities/South Tours/Southern Splendors_ Culture _ Nature Trail/alleppey.jpg";
import stthekkady4 from "../assets/Tours/Cities/South Tours/Southern Splendors_ Culture _ Nature Trail/thekkady.jpg";
import stmunnar4 from "../assets/Tours/Cities/South Tours/Southern Splendors_ Culture _ Nature Trail/munnar.jpg";
import stooty4 from "../assets/Tours/Cities/South Tours/Southern Splendors_ Culture _ Nature Trail/ooty.jpg";
import stmysore4 from "../assets/Tours/Cities/South Tours/Southern Splendors_ Culture _ Nature Trail/mysore.jpg";
import sthassan4 from "../assets/Tours/Cities/South Tours/Southern Splendors_ Culture _ Nature Trail/hassan.jpg";
import sthampy4 from "../assets/Tours/Cities/South Tours/Southern Splendors_ Culture _ Nature Trail/hampi.jpg";
import rtmap1 from "../assets/Tours/Maps/Rajasthan Tours/Rajasthani Heritage Retreat/Rajasthani Heritage Retreat.png";
import rtmap2 from "../assets/Tours/Maps/Rajasthan Tours/Mystical North India Discovery/Mystical North India Discovery.png";
import rtmap3 from "../assets/Tours/Maps/Rajasthan Tours/Royal Rajasthan _ Sacred Gangas/Royal Rajasthan _ Sacred Gangas.png";
import rtmap4 from "../assets/Tours/Maps/Rajasthan Tours/A Journey Through Heritage _ Wildlife/A Journey Through Heritage _ Wildlife.png";
import gtmap1 from "../assets/Tours/Maps/Golden Tours/Majestic Golden Triangle/Majestic Golden Triangle.png";
import gtmap2 from "../assets/Tours/Maps/Golden Tours/The Royal Heritage Trail/The Royal Heritage trail.png";
import gtmap3 from "../assets/Tours/Maps/Golden Tours/Royal Triangle Odessey/Royal Triangle Odessey.png";
import gtmap4 from "../assets/Tours/Maps/Golden Tours/Cultural Tapestry Expidition/Cultural Tapestry Expedition.png";
import gtmap5 from "../assets/Tours/Maps/Golden Tours/Colors of Rajasthan Expedition/Colors of Rajasthan Expedition.png";
import gtmap6 from "../assets/Tours/Maps/Golden Tours/Expedition Through India/Expedition Through India.png";
import stmap1 from "../assets/Tours/Maps/South Tours/Coastal Treasures _ Mountain Magic/Coastal Treasures _ Mountain Magic.png";
import stmap2 from "../assets/Tours/Maps/South Tours/Southern Delights_ A Journey Through Time/Southern Delights_ A Journey Through Time.png";
import stmap3 from "../assets/Tours/Maps/South Tours/Heritage Odyssey/Heritage Odyssey.png";
import stmap4 from "../assets/Tours/Maps/South Tours/Southern Splendors_ Culture _ Nature Trail/Southern Splendors_ Culture _ Nature Trail.png";

export const rajasthanTours = [
  {
    id: "Rajasthani-Heritage-Retreat",
    title: "Rajasthani Heritage Retreat Tour",
    one: "Discover Rajasthan's culture and history in its palaces, forts, and warm hospitality.",
    places:
      "Delhi-Mandawa-Bikaner-Jaisalmer-Jodhpur-Udaipur-Pushkar-Jaipur-Agra",
    image: rt1,
    days: "16",
    heading: {
      Delhi: "A captivating blend of history and vitality",
      Mandawa: "A town of timeless heritage and beauty",
      Bikaner: "A desert oasis of history and camels",
      Jaisalmer: "Where desert tales come to life",
      Jodhpur: "Where history meets the azure skyline",
      Udaipur: "Where Romance and Royalty Reign",
      Pushkar: "Where desert charm meets sacred serenity",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
    },
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Mandawa:
        "Mandawa: where walls whisper tales of artistry. This Rajasthani gem invites you to stroll through its streets adorned with stunning frescoes and havelis, each a canvas of history and beauty. Step into a living gallery of Rajasthan's architectural splendor and immerse yourself in the stories etched on its walls.",
      Bikaner:
        "Bikaner, a desert gem with history woven into its fortresses and palaces, promises a mesmerizing journey through Rajasthan's northeast. Known as Camel Country, it's a place where camels gracefully traverse the landscape, adding to its unique allure.",
      Jaisalmer:
        "Jaisalmer, where humans and animals have conquered the desert together for centuries. A camel ride transports you to a bygone era, showcasing the resilience and resourcefulness of its people who thrive amidst challenging conditions, turning nature's offerings into treasures.",
      Jodhpur:
        "Jodhpur, where the grand Mehrangarh Fort looms over the Blue City, a 15th-century marvel of indigo-washed buildings. Experience the city's vibrant spectrum, from majestic elephant processions in the hills to the enchanting labyrinth of its historic core.",
      Udaipur:
        "In the heart of India lies Udaipur, a city that combines love and luxury. Discover majestic palaces, ancient temples, and exquisite Havelis. Explore bustling markets, and savor breathtaking sunsets on Lake Pichola. Join us in exploring the magic of Udaipur.",
      Pushkar:
        "Pushkar, a picturesque Rajasthan town, graced by Thar Desert's sand dunes, a sacred lake, intricate temples, lush gardens, and a plethora of camels. The final quarter of each year paints a vibrant tapestry of colors, festivities, and time-honored customs, with its renowned Camel Fair as a highlight.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
    },
    cityImages: {
      Delhi: rtdelhi1,
      Mandawa: rtmandawa1,
      Bikaner: rtbikaner1,
      Jaisalmer: rtjaisalmer1,
      Jodhpur: rtjodhpur1,
      Udaipur: rtudaipur1,
      Pushkar: rtpuskar1,
      Jaipur: rtjaipur1,
      Agra: rtagra1,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        detailsaboveimage: `Touch down at Delhi Airport, where we'll greet you with a sign and take you to your well-prepared hotel for a comfortable stay. `,
        details: (
          <div>
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality – from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels. <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop: </b>Mandawa <br />A mere 5.5-hour drive (260 km) from
            Delhi.
          </div>
        ),
        image: rtdelhiitinary2,
      },
      {
        day: "Day 3-5",
        city: "Mandawa",
        details: (
          <div>
            <br />
            <br />
            An authentic Rajasthan town that immerses you in rural and
            traditional life. It's an open-air museum where each step reveals
            unique architecture and vibrant mural paintings. Don't miss the
            Heritage Walk, where you'll encounter the Silk Route-era mansions
            known as Havelis. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Bikaner
            <br />
            JJust 3.5 hours’ drive (200 km) from Mandawa
          </div>
        ),
        image: rtmandawaitinary2,
      },
      {
        day: "Day 5-6",
        city: "Bikaner",
        details: (
          <div>
            <br />
            Bikaner was once a vital stop on ancient caravan routes, and its Old
            Town is still surrounded by massive walls. Bikaner's Fort is unique
            in Rajasthan as it wasn't built on a hill. It has witnessed the
            influence of Rajput, Mughal, and British eras. You can also visit
            the Rat's Temple if you're comfortable with a 35km trip. Don't miss
            strolling through the old heritage buildings in Kote Gate Market.
            <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaisalmer
            <br />
            It's a 5-hour drive (covering 220 km) from Bikaner to the golden
            city of Jaisalmer.
          </div>
        ),
        image: rtbikaneritinary2,
      },
      {
        day: "Day 6-8",
        city: "Jaisalmer",
        details: (
          <div>
            <br />
            Known as one of India's treasures, Jaisalmer Fort is a must-see,
            offering a glimpse into vibrant local life within its ancient walls.
            <br />
            Visit the Bara Bagh Cenotaphs, Gadisar Lake, and explore the Patwa
            and Salim Singh Havelis. As the sun sets, venture into the Thar
            Desert for a magical camel ride among the sand dunes. Experience the
            starry night sky while enjoying a bonfire, traditional Marwari
            music, and folk dance performances at an outdoor camp. It's a night
            you won't forget.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jodhpur
            <br />
            Embark on a 4.5-hour journey (covering 285 km) from Jaisalmer to the
            captivating city of Jodhpur.
          </div>
        ),
        image: rtjaisalmeritinary2,
      },
      {
        day: "Day 8-9",
        city: "Jodhpur",
        details: (
          <div>
            <br />
            Jodhpur, known as the Blue City of Rajasthan, boasts charming
            indigo-hued houses in its Old Town. Perched majestically atop a
            hill, Jodhpur's Fort is a massive and awe-inspiring sight.
            <br />
            <br />
            Stroll near the Clock Tower, where you can admire old buildings and
            savor the fragrant spices in the market. Depending on your schedule
            and interests, explore the Bishnoi Village, Ummaid Bhawan Palace,
            Jaswant Thada, or Mandore Garden.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Udaipur
            <br />
            Set off on a 5-hour journey (covering 250 km) from Jodhpur to the
            enchanting city of Udaipur.
          </div>
        ),
        image: rtjodhpuritinary2,
      },
      {
        day: "Day 9-11",
        city: "Udaipur",
        details: (
          <div>
            <br />
            Udaipur, Rajasthan's Lake City, stands as one of India's most
            stunning and romantic destinations. Immerse yourself in the
            enchantment of its lakes and the awe-inspiring City Palace, where
            grandeur and nostalgia intertwine.
            <br />
            Discover the serene and distinctive Princess Garden, Shilp Gram, and
            the Jagdish Temple. During your Udaipur visit, don't miss a scenic
            boat ride on Lake Pichola, offering captivating views of the city
            and the Jag Mandir, known as the "Lake Garden Palace", gracefully
            perched on an island.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Pushkar
            <br />
            Embark on a 5-hour journey (covering 280 km) from Udaipur to the
            spiritual haven of Pushkar.
          </div>
        ),
        image: rtudaipuritinary2,
      },
      {
        day: "Day 11-12",
        city: "Pushkar",
        details: (
          <div>
            <br />
            Pushkar, one of India's oldest cities, has long been a pilgrimage
            site for devotees nationwide. It boasts a captivating mythological
            history and a heritage of enduring architectural beauty.
            <br />
            Don't miss the opportunity to visit the world's only Lord Brahma
            (The Universe Creator) Temple. Explore the mystical beauty of
            Pushkar's Holy Lake. While the local market may be small, it's
            filled with unique treasures, including handmade gifts, aromatic
            spices, and vibrant clothing.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur
            <br />
            Set out on a 3-hour journey (covering 150 km) from Pushkar to the
            vibrant city of Jaipur.
          </div>
        ),
        image: rtpuskaritinary2,
      },
      {
        day: "Day 12-14",
        city: "Jaipur",
        details: (
          <div>
            Jaipur, the Pink City and the capital of Rajasthan, is a city
            adorned with historic monuments, palaces, fortresses, and
            enchantment. Explore the majestic City Palace, the serene Jal Mahal,
            the iconic Hawa Mahal (Wind Palace), and the 17th-century Jantar
            Mantar, an astronomical observatory.
            <br />
            Experience the awe-inspiring Amber Fort, steeped in countless tales,
            and the exquisite Birla Temple. Take a leisurely stroll through the
            vibrant markets of Johri Bazar and Choti Chopper, known for their
            unique jewelry.
            <br />
            In the evening, immerse yourself in the charm of the Old Walled City
            and explore the local market.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra
            <br />
            Embark on a 4.5-hour journey (covering 240 km) from Jaipur to the
            historic city of Agra.
          </div>
        ),
        image: rtjaipuritinary2,
      },
      {
        day: "Day 14-15",
        city: "Agra",
        details: (
          <div>
            <br />
            A visit to Agra is like stepping into the most romantic story ever
            told, unfolding right before your eyes. Please note that many
            attractions, including the Taj Mahal, are closed on Fridays.
            <br />
            Take in the stunning sunset view of the Taj Mahal from the tranquil
            banks of the Yamuna River. If time allows, you can explore the
            magnificent Red Fort on the same day. Alternatively, consider an
            early morning visit the next day to witness the sunrise at the Taj
            Mahal.
            <br />
            Following your exploration of Agra, you'll be driven to your next
            destination.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi
            <br />
            Embark on a 4-hour journey (covering 230 km) from Agra to the
            vibrant city of Delhi.
          </div>
        ),
        image: rtagraitinary2,
      },
      {
        day: "Day 15",
        city: "Delhi",
        details: (
          <div>
            <br />
            <br />
            You have the choice to unwind at your hotel, preparing for your
            departure the next day. Alternatively, if you're feeling energetic
            and wish to explore, Delhi offers a variety of markets to visit:
            Chandni Chowk Market, the vibrant Kinari Bazaar (Wedding Market),
            the bustling Textile Market (Wholesale Market), the Book Market, and
            the fascinating Spice Market, known for both ground and whole
            spices.
            <br />
            <br />
            If your interests lean towards art and architecture, you might
            consider visiting the Fateh Puri Mosque, Sis Ganj Gurudwara, Jain
            Temple, and the Shiva Temple. The choice is yours, tailored to your
            pace and preferences.
          </div>
        ),
        image: delhiitinary2,
      },
      {
        day: "Day 16",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            <br />
            With a sense of relaxation and readiness to journey back home, your
            dedicated driver will ensure you reach the airport promptly, in
            accordance with your flight schedule.
            <br />
            <b>Safe travels!</b>
          </div>
        ),
        image: departure2,
      },
    ],
    map: rtmap1,
    highlights: [
      "Witness the Taj Mahal at sunrise in Agra",
      "Explore Delhi's bustling markets",
      "Roam Jaisalmer's golden sands and forts",
      "Experience Jaipur's royal history",
      "Discover Udaipur's serene lakes and palaces",
      "Marvel at Jodhpur's Mehrangarh Fort",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/The-Royal-Heritage-Trail",
        name: "The Royal Heritage Trail",
        image: gt2,
        description:
          "An 8-day journey through Delhi, Mandawa, Jaipur, and Agra, immersing you in a captivating blend of history, timeless heritage, regal splendor, and historical grandeur.",
      },
      {
        id: "/tour/Royal-Rajasthan-&-Sacred-Ganges",
        name: "Royal Rajasthan & Sacred Ganges",
        image: rt3,
        description:
          "Experience a 20-day journey through the heart of India on the 'Royal Rajasthan & Sacred Ganges' tour. From historic Delhi to spiritual Varanasi, explore a rich tapestry of heritage and culture.",
      },
      {
        id: "/tour/Southern-Delights",
        name: "Southern Delights: A Journey Through Time",
        image: st2,
        description:
          "Discover the essence of Southern India on the 'Southern Delights' 17-day tour, from Cochin's history to Mumbai's vibrant dreams, with coastal delights, natural majesty, and timeless wonders in between.",
      },
    ],
    Affordable: 1390,
    Semi: 1902,
    Luxury: 3396,
  },
  {
    id: "Mystical-North-India-Discovery",
    title: "Mystical North India Discovery Tour",
    one: "Discover, where spirituality thrives in temples, cities, and the holy Ganga River.",
    places: "Delhi-Mandawa-Jaipur-Agra-Orchha-Khajuraho-Varanasi",
    image: rt2,
    days: "12",
    heading: {
      Delhi: "A kaleidoscope of culture and contrasts",
      Mandawa: "An enchanting step back in time",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
      Orchha: "Echoes of history in its architecture",
      Khajuraho: "Sculpted wonders in stone",
      Varanasi: "The spiritual heart of India by the Ganges",
    },
    description: {
      Delhi:
        "A vibrant, bustling tapestry of modernity and rich history. In Old Delhi, humanity's myriad hues come to life.",
      Mandawa:
        "An 18th-century gem nestled in Rajasthan's Shekhawati region, where every step unveils an open-air museum. Embark on heritage walks amidst its Silk Route-era Havelis (ornate traditional mansions) and rejuvenate at our Mandawa Kothi property.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
      Orchha:
        "A rustic village steeped in 300 years of history, adorned with Mughal-inspired Rajput architecture seen in its palaces, temples, and cenotaphs (Chhatris). A sacred hub for Lord Rama devotees.",
      Khajuraho:
        "A captivating town renowned for its intricate and sensual stone sculptures adorning World Heritage Temples, a true delight for art enthusiasts.",
      Varanasi:
        "Where the sacred Ganges River holds the promise of spiritual liberation, and every cremation and puja ceremony is a mystic ritual. This Hindu sanctuary is where devotees seek the renewal of their souls.",
    },
    cityImages: {
      Delhi: rtdelhi2,
      Mandawa: rtmandawa2,
      Jaipur: rtjaipur2,
      Agra: rtagra2,
      Orchha: rtorchha2,
      Khajuraho: rtkhajraho2,
      Varanasi: rtvaranasi2,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        detailsaboveimage: `Touch down at Delhi Airport, where we'll greet you with a sign and take you to your well-prepared hotel for a comfortable stay. `,
        details: (
          <div>
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality – from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels. <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop: </b>Mandawa <br />A mere 5.5-hour drive (260 km) from
            Delhi.
          </div>
        ),
        image: rtdelhiitinary1,
      },
      {
        day: "Day 3-4",
        city: "Mandawa",
        details: (
          <div>
            <br />
            <br />
            An authentic Rajasthan town that immerses you in rural and
            traditional life. It's an open-air museum where each step reveals
            unique architecture and vibrant mural paintings. Don't miss the
            Heritage Walk, where you'll encounter the Silk Route-era mansions
            known as Havelis. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur
            <br />
            JJust 3.5 hours’ drive (175 km) from Mandawa
          </div>
        ),
        image: rtmandawaitinary1,
      },
      {
        day: "Day 4-6",
        city: "Jaipur",
        detailsaboveimage: `Jaipur, the Pink City of Rajasthan, awaits with its regal charm and captivating monuments.
        Explore the opulent City Palace, the tranquil Jal Mahal, the delicate intricacies of Hawa Mahal
        (Wind Palace), and the celestial wonders of the 17th-century Jantar Mantar.`,
        details: (
          <div>
            <br />
            As evening descends, take a leisurely stroll through the Old Walled
            City, immersing yourself in the local market's vibrant atmosphere.
            Discover the rich history of the Amber Fort, with its countless
            tales, and find serenity at the Birla Temple.
            <br />
            Jaipur is also renowned for its unique Jewelry Market, so don't
            forget to explore the treasures of Johri Bazar and Choti Chopper.
            <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra
            <br />
            Your journey continues with a 4.5-hour drive covering 240
            kilometers. Prepare to uncover the iconic city of Agra.
          </div>
        ),
        image: rtjaipuritinary1,
      },
      {
        day: "Day 6-7",
        city: "Agra",
        details: (
          <div>
            <br />
            Agra, where history's most romantic tales come alive before your
            eyes. Please note, it's closed on Fridays, so plan accordingly. You
            can savor the enchanting sunset view of the Taj Mahal from the
            tranquil banks of the Yamuna River. If time permits, explore the
            majestic Red Fort on the same day, or save it for the day after,
            combining it with a sunrise visit to the Taj Mahal.
            <br />
            Afterward, you'll be on your way to your next destination.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Orchha
            <br />
            Your journey continues with a 5-hour drive, covering 255 kilometers.
          </div>
        ),
        image: agraitinary,
      },
      {
        day: "Day 7-8",
        city: "Orchha",
        details: (
          <div>
            <br />
            Orchha, a historic and one-of-a-kind town frozen in time. It exudes
            a sense of nostalgia that can rekindle your dreams of fairytales.{" "}
            <br />
            <br />
            Explore the enchanting Fort and Palace, step into fascinating
            temples, and wander through royal graveyards that will transport you
            to the grandeur of a bygone era.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Khajuraho
            <br />
            Your journey continues with a 4.5-hour drive, covering 200
            kilometers, to the mesmerizing destination of Khajuraho.
          </div>
        ),
        image: rtorchhaitinary1,
      },
      {
        day: "Day 8-9",
        city: "Khajuraho",
        details: (
          <div>
            <br />
            Discover the awe-inspiring Kama Sutra Temples of Khajuraho, nestled
            within the Eastern, Western, and Southern groups of this UNESCO
            World Heritage sanctuary. Marvel at the intricate and sensual
            artistry that ranks among the world's finest.
            <br />
            As evening descends, choose between two captivating experiences: a
            mesmerizing Cultural Dance Performance or the enchanting Light and
            Sound Show at the renowned erotic shrine.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Varanasi
            <br />
            Your journey continues with a quick 1-hour flight, transporting you
            to the mystical city of Varanasi.
          </div>
        ),
        image: rtkhajrahoitinary1,
      },
      {
        day: "Day 9-11",
        city: "Varanasi",
        detailsaboveimage: `Varanasi, a city steeped in spirituality and culture, offers a profound journey. Begin by visiting
        the Temple of Mother India, inaugurated by Mahatma Gandhi, featuring a vast marble map of
        India on the ground. Explore the Buddhist city of Sarnath and the prestigious Hindu University.`,
        details: (
          <div>
            <br />
            Start your day early with a boat ride along the Ganges Ghats, where
            you'll witness the morning rituals of bathing and prayer. Consider a
            visit to the Ram Nagar Fort, known for its impressive collection of
            vintage cars.
            <br />
            In the evening, another boat ride will allow you to experience the
            Ghats and the mesmerizing Aarti and rituals.
            <br />
            On your final day, relish one more sunrise boat ride before aligning
            your activities with your departure schedule.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi
            <br />
            Your journey continues with a quick 1.6-hour flight to Delhi, where
            you can explore the vibrant capital city before concluding your
            unforgettable adventure.
          </div>
        ),
        image: rtvaranasiitinary1,
      },
      {
        day: "Day 11",
        city: "Delhi",
        details: (
          <div>
            Your final day in Delhi offers flexibility. You can either relax at
            your hotel, preparing for your departure tomorrow, or dive into the
            vibrant city. If you're feeling active, explore the bustling
            markets, from Chandni Chowk to the unique Spice Market, where you
            can discover a world of aromas and flavors.
            <br />
            For those inclined towards art and architecture, explore the
            serenity of Fateh Puri Mosque, Sis Ganj Gurudwara, Jain Temple, and
            the tranquil Shiva Temple. Tailor your day to your desires, finding
            peace and comfort in the heart of Delhi.
          </div>
        ),
        image: delhideparture,
      },
      {
        day: "Day 12",
        city: "Departure",
        details: (
          <div>
            <br />
            <br />
            <br />
            Feeling refreshed and prepared for your journey back home!
            <br />
            Your driver will ensure you arrive at the airport in accordance with
            your flight schedule.
          </div>
        ),
        image: departure2,
      },
    ],
    map: rtmap2,
    highlights: [
      "Explore Delhi, Agra, and Jaipur's rich heritage",
      "Discover timeless beauty and sensuous artistry",
      "Immerse in Ganges Ghats and ancient temples",
      "Enjoy performances and vibrant markets",
      "Relax with seamless logistics and accommodations",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/A-Journey-Through-Heritage-&-Wildlife",
        name: "A Journey Through Heritage & Wildlife",
        image: rt4,
        description:
          "Embark on a 13-day odyssey through heritage and wildlife on the 'A Journey Through Heritage & Wildlife' tour, from the captivating blend of history in Delhi to the wildlife wonders of Ranthambore, all amidst timeless heritage, desert charm, and regal splendor.",
      },
      {
        id: "/tour/Royal-Triangle-Odyssey",
        name: "Royal-Triangle-Odyssey",
        image: gt3,
        description:
          "Experience a 10-day 'Royal Triangle Odyssey' from the kaleidoscope of culture in Delhi to the spiritual heart of Varanasi, with enchanting heritage in Mandawa, regal splendor in Jaipur, and the historical magnificence of Agra in between.",
      },
      {
        id: "/tour/Heritage-Odyssey",
        name: "Heritage Odyssey: North to South India",
        image: st3,
        description:
          "Explore North to South India in 16 days on the 'Heritage Odyssey' tour. From Delhi's history to Ajanta's ancient rock art and Mumbai's vibrant charm, experience a rich tapestry of heritage and culture along the way.",
      },
    ],
    Affordable: 1270,
    Semi: 1669,
    Luxury: 2710,
  },
  {
    id: "Royal-Rajasthan-&-Sacred-Ganges",
    title: "Royal Rajasthan & Sacred Ganges Tour",
    one: "Explore historical sites, culture, majestic palaces, spiritual Varanasi along the Ganges.",
    places:
      "Delhi-Mandawa-Bikaner-Jaisalmer-Jodhpur-Udaipur-Pushkar-Jaipur-Agra-Orchha-Khajuraho-Varanasi",
    image: rt3,
    days: "20",
    heading: {
      Delhi: "A captivating blend of history and modern flair",
      Mandawa: "A town of timeless heritage and beauty",
      Bikaner: "A desert oasis of history and camels",
      Jaisalmer: "Where desert tales come to life",
      Jodhpur: "Where history meets the azure skyline",
      Udaipur: "Where Romance and Royalty Reign",
      Pushkar: "Where desert charm meets sacred serenity",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
      Orchha: "Echoes of history in its architecture",
      Khajuraho: "Sculpted wonders in stone",
      Varanasi: "The spiritual heart of India by the Ganges",
    },
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Mandawa:
        "Mandawa: where walls whisper tales of artistry. This Rajasthani gem invites you to stroll through its streets adorned with stunning frescoes and havelis, each a canvas of history and beauty. Step into a living gallery of Rajasthan's architectural splendor and immerse yourself in the stories etched on its walls.",
      Bikaner:
        "Bikaner, a desert gem with history woven into its fortresses and palaces, promises a mesmerizing journey through Rajasthan's northeast. Known as Camel Country, it's a place where camels gracefully traverse the landscape, adding to its unique allure.",
      Jaisalmer:
        "Jaisalmer, where humans and animals have conquered the desert together for centuries. A camel ride transports you to a bygone era, showcasing the resilience and resourcefulness of its people who thrive amidst challenging conditions, turning nature's offerings into treasures.",
      Jodhpur:
        "Jodhpur, where the grand Mehrangarh Fort looms over the Blue City, a 15th-century marvel of indigo-washed buildings. Experience the city's vibrant spectrum, from majestic elephant processions in the hills to the enchanting labyrinth of its historic core.",
      Udaipur:
        "In the heart of India lies Udaipur, a city that combines love and luxury. Discover majestic palaces, ancient temples, and exquisite Havelis. Explore bustling markets, and savor breathtaking sunsets on Lake Pichola. Join us in exploring the magic of Udaipur.",
      Pushkar:
        "Pushkar, a picturesque Rajasthan town, graced by Thar Desert's sand dunes, a sacred lake, intricate temples, lush gardens, and a plethora of camels. The final quarter of each year paints a vibrant tapestry of colors, festivities, and time-honored customs, with its renowned Camel Fair as a highlight.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
      Orchha:
        "A rustic village steeped in 300 years of history, adorned with Mughal-inspired Rajput architecture seen in its palaces, temples, and cenotaphs (Chhatris). A sacred hub for Lord Rama devotees.",
      Khajuraho:
        "A captivating town renowned for its intricate and sensual stone sculptures adorning World Heritage Temples, a true delight for art enthusiasts.",
      Varanasi:
        "Where the sacred Ganges River holds the promise of spiritual liberation, and every cremation and puja ceremony is a mystic ritual. This Hindu sanctuary is where devotees seek the renewal of their souls.",
    },
    cityImages: {
      Delhi: rtdelhi3,
      Mandawa: rtmandawa3,
      Bikaner: rtbikaner3,
      Jaisalmer: rtjaisalmer3,
      Jodhpur: rtjodhpur3,
      Udaipur: rtudaipur3,
      Pushkar: rtpuskar3,
      Jaipur: rtjaipur3,
      Agra: rtagra3,
      Orchha: rtorcha3,
      Khajuraho: rtkhajraho3,
      Varanasi: rtvaranasi3,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        detailsaboveimage: `Touch down at Delhi Airport, where we'll greet you with a sign and take you to your well-prepared hotel for a comfortable stay. `,
        details: (
          <div>
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality – from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels. <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop: </b>Mandawa <br />A mere 5.5-hour drive (260 km) from
            Delhi.
          </div>
        ),
        image: rtdelhiitinary3,
      },
      {
        day: "Day 3-5",
        city: "Mandawa",
        details: (
          <div>
            <br />
            <br />
            An authentic Rajasthan town that immerses you in rural and
            traditional life. It's an open-air museum where each step reveals
            unique architecture and vibrant mural paintings. Don't miss the
            Heritage Walk, where you'll encounter the Silk Route-era mansions
            known as Havelis. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Bikaner
            <br />
            JJust 3.5 hours’ drive (200 km) from Mandawa
          </div>
        ),
        image: rtmandawaitinary3,
      },
      {
        day: "Day 5-6",
        city: "Bikaner",
        details: (
          <div>
            <br />
            Bikaner was once a vital stop on ancient caravan routes, and its Old
            Town is still surrounded by massive walls. Bikaner's Fort is unique
            in Rajasthan as it wasn't built on a hill. It has witnessed the
            influence of Rajput, Mughal, and British eras. You can also visit
            the Rat's Temple if you're comfortable with a 35km trip. Don't miss
            strolling through the old heritage buildings in Kote Gate Market.
            <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaisalmer
            <br />
            It's a 5-hour drive (covering 220 km) from Bikaner to the golden
            city of Jaisalmer.
          </div>
        ),
        image: rtbikaneritinnary3,
      },
      {
        day: "Day 6-8",
        city: "Jaisalmer",
        details: (
          <div>
            <br />
            Known as one of India's treasures, Jaisalmer Fort is a must-see,
            offering a glimpse into vibrant local life within its ancient walls.
            <br />
            Visit the Bara Bagh Cenotaphs, Gadisar Lake, and explore the Patwa
            and Salim Singh Havelis. As the sun sets, venture into the Thar
            Desert for a magical camel ride among the sand dunes. Experience the
            starry night sky while enjoying a bonfire, traditional Marwari
            music, and folk dance performances at an outdoor camp. It's a night
            you won't forget.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jodhpur
            <br />
            Embark on a 4.5-hour journey (covering 285 km) from Jaisalmer to the
            captivating city of Jodhpur.
          </div>
        ),
        image: rtjaisalmeritinary3,
      },
      {
        day: "Day 8-9",
        city: "Jodhpur",
        details: (
          <div>
            <br />
            Jodhpur, known as the Blue City of Rajasthan, boasts charming
            indigo-hued houses in its Old Town. Perched majestically atop a
            hill, Jodhpur's Fort is a massive and awe-inspiring sight.
            <br />
            <br />
            Stroll near the Clock Tower, where you can admire old buildings and
            savor the fragrant spices in the market. Depending on your schedule
            and interests, explore the Bishnoi Village, Ummaid Bhawan Palace,
            Jaswant Thada, or Mandore Garden.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Udaipur
            <br />
            Set off on a 5-hour journey (covering 250 km) from Jodhpur to the
            enchanting city of Udaipur.
          </div>
        ),
        image: rtjodhpuritinary3,
      },
      {
        day: "Day 9-11",
        city: "Udaipur",
        details: (
          <div>
            <br />
            Udaipur, Rajasthan's Lake City, stands as one of India's most
            stunning and romantic destinations. Immerse yourself in the
            enchantment of its lakes and the awe-inspiring City Palace, where
            grandeur and nostalgia intertwine.
            <br />
            Discover the serene and distinctive Princess Garden, Shilp Gram, and
            the Jagdish Temple. During your Udaipur visit, don't miss a scenic
            boat ride on Lake Pichola, offering captivating views of the city
            and the Jag Mandir, known as the "Lake Garden Palace", gracefully
            perched on an island.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Pushkar
            <br />
            Embark on a 5-hour journey (covering 280 km) from Udaipur to the
            spiritual haven of Pushkar.
          </div>
        ),
        image: rtudaipuritinary3,
      },
      {
        day: "Day 11-12",
        city: "Pushkar",
        details: (
          <div>
            <br />
            Pushkar, one of India's oldest cities, has long been a pilgrimage
            site for devotees nationwide. It boasts a captivating mythological
            history and a heritage of enduring architectural beauty.
            <br />
            Don't miss the opportunity to visit the world's only Lord Brahma
            (The Universe Creator) Temple. Explore the mystical beauty of
            Pushkar's Holy Lake. While the local market may be small, it's
            filled with unique treasures, including handmade gifts, aromatic
            spices, and vibrant clothing.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur
            <br />
            Set out on a 3-hour journey (covering 150 km) from Pushkar to the
            vibrant city of Jaipur.
          </div>
        ),
        image: rtpuskaritinary3,
      },
      {
        day: "Day 12-14",
        city: "Jaipur",
        details: (
          <div>
            Jaipur, the Pink City and the capital of Rajasthan, is a city
            adorned with historic monuments, palaces, fortresses, and
            enchantment. Explore the majestic City Palace, the serene Jal Mahal,
            the iconic Hawa Mahal (Wind Palace), and the 17th-century Jantar
            Mantar, an astronomical observatory.
            <br />
            Experience the awe-inspiring Amber Fort, steeped in countless tales,
            and the exquisite Birla Temple. Take a leisurely stroll through the
            vibrant markets of Johri Bazar and Choti Chopper, known for their
            unique jewelry.
            <br />
            In the evening, immerse yourself in the charm of the Old Walled City
            and explore the local market.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra
            <br />
            Embark on a 4.5-hour journey (covering 240 km) from Jaipur to the
            historic city of Agra.
          </div>
        ),
        image: rtjaipuritinary3,
      },
      {
        day: "Day 14-15",
        city: "Agra",
        details: (
          <div>
            <br />
            A visit to Agra is like stepping into the most romantic story ever
            told, unfolding right before your eyes. Please note that many
            attractions, including the Taj Mahal, are closed on Fridays.
            <br />
            Take in the stunning sunset view of the Taj Mahal from the tranquil
            banks of the Yamuna River. If time allows, you can explore the
            magnificent Red Fort on the same day. Alternatively, consider an
            early morning visit the next day to witness the sunrise at the Taj
            Mahal.
            <br />
            Following your exploration of Agra, you'll be driven to your next
            destination.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Orchha
            <br />
            Embark on a 4-5-hour journey (covering 253 km)
          </div>
        ),
        image: rtagraitinary3,
      },
      {
        day: "Day 15-16",
        city: "Orchha",
        details: (
          <div>
            <br />
            Orchha, a historic and one-of-a-kind town frozen in time. It exudes
            a sense of nostalgia that can rekindle your dreams of fairytales.{" "}
            <br />
            <br />
            Explore the enchanting Fort and Palace, step into fascinating
            temples, and wander through royal graveyards that will transport you
            to the grandeur of a bygone era.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Khajuraho
            <br />
            Your journey continues with a 4.5-hour drive, covering 200
            kilometers, to the mesmerizing destination of Khajuraho.
          </div>
        ),
        image: rtorchaitinary3,
      },
      {
        day: "Day 16-17",
        city: "Khajuraho",
        details: (
          <div>
            <br />
            Discover the awe-inspiring Kama Sutra Temples of Khajuraho, nestled
            within the Eastern, Western, and Southern groups of this UNESCO
            World Heritage sanctuary. Marvel at the intricate and sensual
            artistry that ranks among the world's finest.
            <br />
            As evening descends, choose between two captivating experiences: a
            mesmerizing Cultural Dance Performance or the enchanting Light and
            Sound Show at the renowned erotic shrine.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Varanasi
            <br />
            Your journey continues with a quick 1-hour flight, transporting you
            to the mystical city of Varanasi.
          </div>
        ),
        image: rtkhajrahoitinary3,
      },
      {
        day: "Day 17-19",
        city: "Varanasi",
        detailsaboveimage: `Varanasi, a city steeped in spirituality and culture, offers a profound journey. Begin by visiting
        the Temple of Mother India, inaugurated by Mahatma Gandhi, featuring a vast marble map of
        India on the ground. Explore the Buddhist city of Sarnath and the prestigious Hindu University.`,
        details: (
          <div>
            <br />
            Start your day early with a boat ride along the Ganges Ghats, where
            you'll witness the morning rituals of bathing and prayer. Consider a
            visit to the Ram Nagar Fort, known for its impressive collection of
            vintage cars.
            <br />
            In the evening, another boat ride will allow you to experience the
            Ghats and the mesmerizing Aarti and rituals.
            <br />
            On your final day, relish one more sunrise boat ride before aligning
            your activities with your departure schedule.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi
            <br />
            Your journey continues with a quick 1.6-hour flight to Delhi, where
            you can explore the vibrant capital city before concluding your
            unforgettable adventure.
          </div>
        ),
        image: rtvaranashiitinary3,
      },
      {
        day: "Day 19",
        city: "Delhi",
        details: (
          <div>
            <br />
            <br />
            Your final day in Delhi offers flexibility. You can either relax at
            your hotel, preparing for your departure tomorrow, or dive into the
            vibrant city. If you're feeling active, explore the bustling
            markets, from Chandni Chowk to the unique Spice Market, where you
            can discover a world of aromas and flavors.
            <br />
            <br />
            For those inclined towards art and architecture, explore the
            serenity of Fateh Puri Mosque, Sis Ganj Gurudwara, Jain Temple, and
            the tranquil Shiva Temple. Tailor your day to your desires, finding
            peace and comfort in the heart of Delhi.
          </div>
        ),
        image: delhiitinary2,
      },
      {
        day: "Day 20",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            <br />
            Feeling refreshed and prepared for your journey back home!
            <br />
            Your driver will ensure you arrive at the airport in accordance with
            your flight schedule.
            <br />
            <b>Safe travels!</b>
          </div>
        ),
        image: departure2,
      },
    ],
    map: rtmap3,
    highlights: [
      "Witness the Taj Mahal at sunrise in Agra",
      "Explore Delhi's bustling markets",
      "Jaipur's royal history",
      "Discover Udaipur's serene lakes and palaces",
      "Marvel at Jodhpur's Mehrangarh Fort",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/Rajasthani-Heritage-Retreat",
        name: "Rajasthani Heritage Retreat",
        image: rt1,
        description:
          "Indulge in a 16-day 'Rajasthani Heritage Retreat' through Delhi's history, timeless beauty in Mandawa, desert tales in Jaisalmer, and a journey of romance and regal splendor in Udaipur, all while exploring the rich heritage of Rajasthan.",
      },
      {
        id: "/tour/Cultural-Tapestry-Expedition",
        name: "Cultural Tapestry Expedition Tour",
        image: gt4,
        description:
          "Embark on a 9-day 'Cultural Tapestry Expedition' weaving through the captivating history and vitality of Delhi, the timeless heritage of Mandawa, desert charm and sacred serenity in Pushkar, regal splendor in Jaipur, and the historical grandeur of Agra.",
      },
      {
        id: "/tour/Southern-Splendors",
        name: "Southern Splendors: Culture & Nature Trail",
        image: st4,
        description:
          "Embark on a 19-day 'Southern Splendors: Culture & Nature Trail' from Mumbai's vibrant dreams to the rich cultural tapestry of Hampi, experiencing history, coastal delights, nature's majesty, and regal splendor in between.",
      },
    ],
    Affordable: 1821,
    Semi: 2480,
    Luxury: 4279,
  },
  {
    id: "A-Journey-Through-Heritage-&-Wildlife",
    title: "A Journey Through Heritage & Wildlife ",
    one: "Experience India's cultural riches, iconic sites &amp; wild beauty of Ranthambore.",
    places: "Delhi-Mandawa-Pushkar-Bundi-Ranthambore-Jaipur-Agra",
    image: rt4,
    days: "13",
    heading: {
      Delhi: "A captivating blend of history and vitality",
      Mandawa: "A town of timeless heritage and beauty",
      Pushkar: "Where desert charm meets sacred serenity",
      Bundi: "Rajasthan's Hidden Gem",
      Ranthambore: "Where Wildlife Roams Free",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
    },
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Mandawa:
        "Mandawa: where walls whisper tales of artistry. This Rajasthani gem invites you to stroll through its streets adorned with stunning frescoes and havelis, each a canvas of history and beauty. Step into a living gallery of Rajasthan's architectural splendor and immerse yourself in the stories etched on its walls.",
      Pushkar:
        "Pushkar, a picturesque Rajasthan town, graced by Thar Desert's sand dunes, a sacred lake, intricate temples, lush gardens, and a plethora of camels. The final quarter of each year paints a vibrant tapestry of colors, festivities, and time-honored customs, with its renowned Camel Fair as a highlight.",
      Bundi:
        "This enchanting town combines regal splendor with a sea of blue-painted houses, creating a unique visual tapestry. Wander through its historic palaces, intricate step wells, and vibrant markets to immerse yourself in the rich cultural tapestry of Rajasthan. A traveler's paradise waiting to be explored!",
      Ranthambore:
        "In the heart of Rajasthan lies this wildlife haven, where tigers rule the lush forests. Explore the rugged beauty, spot majestic creatures, and embark on thrilling safaris. Ranthambore, where nature's raw power captivates all who visit!",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
    },
    cityImages: {
      Delhi: rtdelhi4,
      Mandawa: rtmandawa4,
      Pushkar: rtpushkar4,
      Bundi: rtbundi4,
      Ranthambore: rtranthambore4,
      Jaipur: rtjaipur4,
      Agra: rtagra4,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        detailsaboveimage: `Touch down at Delhi Airport, where we'll greet you with a sign and take you to your well-prepared hotel for a comfortable stay. `,
        details: (
          <div>
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality – from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels. <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop: </b>Mandawa <br />A mere 5.5-hour drive (260 km) from
            Delhi.
          </div>
        ),
        image: rtdelhiitinary4,
      },
      {
        day: "Day 3-5",
        city: "Mandawa",
        details: (
          <div>
            <br />
            <br />
            An authentic Rajasthan town that immerses you in rural and
            traditional life. It's an open-air museum where each step reveals
            unique architecture and vibrant mural paintings. Don't miss the
            Heritage Walk, where you'll encounter the Silk Route-era mansions
            known as Havelis. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Pushkar
            <br />
            Just 3.5 hours’ drive (221 km) from Mandawa
          </div>
        ),
        image: rtmandawaitinary4,
      },
      {
        day: "Day 4-5",
        city: "Pushkar",
        details: (
          <div>
            <br />
            Pushkar, one of India's oldest cities, has long been a pilgrimage
            site for devotees nationwide. It boasts a captivating mythological
            history and a heritage of enduring architectural beauty.
            <br />
            Don't miss the opportunity to visit the world's only Lord Brahma
            (The Universe Creator) Temple. Explore the mystical beauty of
            Pushkar's Holy Lake. While the local market may be small, it's
            filled with unique treasures, including handmade gifts, aromatic
            spices, and vibrant clothing.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Bundi
            <br />
            Set out on a 3-hour journey (covering 179 km) from Pushkar.
          </div>
        ),
        image: rtpuskaritinary4,
      },
      {
        day: "Day 5-6",
        city: "Bundi",
        details: (
          <div>
            <br />
            Arrive in Bundi, explore the stunning Bundi Palace, and savor local
            Rajasthani cuisine. Wander through the old town's blue-painted
            houses and visit Rani Ji ki Baori.
            <br />
            Discover Taragarh Fort and take a boat ride on Nawal Sagar Lake.
            Enjoy your final meal by the lake and, if time allows, explore Sukh
            Mahal. Depart from Bundi, enriched by its heritage and natural
            charm.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Ranthambore
            <br />
            Set out on a 3-hour journey (covering 179 km) from Pushkar.
          </div>
        ),
        image: rtbundiitinary4,
      },
      {
        day: "Day 6-8",
        city: "Ranthambore",
        details: (
          <div>
            <br />
            Dive into wildlife adventure with an exhilarating jungle safari in
            Ranthambore National Park. Witness the majestic tigers and diverse
            wildlife in their natural habitat.
            <br />
            Begin your day with another thrilling safari, exploring different
            zones of the park. After the safari, visit the historic Ranthambore
            Fort perched atop a hill, offering panoramic views of the park.
            Depart from Ranthambore with unforgettable memories of wildlife
            encounters and natural beauty.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur
            <br />
            Set out on a 2-hour journey (covering 149 km) from Pushkar.
          </div>
        ),
        image: rtranthamboreitinary4,
      },
      {
        day: "Day 8-10",
        city: "Jaipur",
        details: (
          <div>
            Jaipur, the Pink City and the capital of Rajasthan, is a city
            adorned with historic monuments, palaces, fortresses, and
            enchantment. Explore the majestic City Palace, the serene Jal Mahal,
            the iconic Hawa Mahal (Wind Palace), and the 17th-century Jantar
            Mantar, an astronomical observatory.
            <br />
            Experience the awe-inspiring Amber Fort, steeped in countless tales,
            and the exquisite Birla Temple. Take a leisurely stroll through the
            vibrant markets of Johri Bazar and Choti Chopper, known for their
            unique jewelry.
            <br />
            In the evening, immerse yourself in the charm of the Old Walled City
            and explore the local market.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra
            <br />
            Embark on a 4.5-hour journey (covering 240 km) from Jaipur to the
            historic city of Agra.
          </div>
        ),
        image: rtjaipuritinary4,
      },
      {
        day: "Day 10-11",
        city: "Agra",
        details: (
          <div>
            <br />
            A visit to Agra is like stepping into the most romantic story ever
            told, unfolding right before your eyes. Please note that many
            attractions, including the Taj Mahal, are closed on Fridays.
            <br />
            Take in the stunning sunset view of the Taj Mahal from the tranquil
            banks of the Yamuna River. If time allows, you can explore the
            magnificent Red Fort on the same day. Alternatively, consider an
            early morning visit the next day to witness the sunrise at the Taj
            Mahal.
            <br />
            Following your exploration of Agra, you'll be driven to your next
            destination.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi
            <br />
            Embark on a 4-hour journey (covering 230 km) from Agra to the
            vibrant city of Delhi.
          </div>
        ),
        image: rtagraitinary4,
      },
      {
        day: "Day 12",
        city: "Delhi",
        details: (
          <div>
            <br />
            <br />
            You have the choice to unwind at your hotel, preparing for your
            departure the next day. Alternatively, if you're feeling energetic
            and wish to explore, Delhi offers a variety of markets to visit:
            Chandni Chowk Market, the vibrant Kinari Bazaar (Wedding Market),
            the bustling Textile Market (Wholesale Market), the Book Market, and
            the fascinating Spice Market, known for both ground and whole
            spices.
            <br />
            <br />
            If your interests lean towards art and architecture, you might
            consider visiting the Fateh Puri Mosque, Sis Ganj Gurudwara, Jain
            Temple, and the Shiva Temple. The choice is yours, tailored to your
            pace and preferences.
          </div>
        ),
        image: delhiitinary2,
      },
      {
        day: "Day 13",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            <br />
            With a sense of relaxation and readiness to journey back home, your
            dedicated driver will ensure you reach the airport promptly, in
            accordance with your flight schedule.
            <br />
            <b>Safe travels!</b>
          </div>
        ),
        image: departure2,
      },
    ],
    map: rtmap4,
    highlights: [
      "Red Fort, Qutub Minar, bustling markets",
      "Ornate havelis, Rajasthan's artistry",
      "Sacred lake, spiritual vibes, lively markets",
      "Bundi Palace, step wells, blue houses",
      "Tiger safaris, historic fort, natural beauty",
      "Amber Fort, City Palace, shopping delights",
      "Taj Mahal, Agra Fort, Mughal history",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/Rajasthani-Heritage-Retreat",
        name: "Rajasthani Heritage Retreat",
        image: rt1,
        description:
          "Explore the beautiful landscapes and rich culture of this region.",
      },
      {
        id: "/tour/Royal-Triangle-Odyssey",
        name: "Royal-Triangle-Odyssey",
        image: gt2,
        description:
          "Experience adventure and breathtaking views on this thrilling tour.",
      },
      {
        id: "/tour/Cultural-Tapestry-Expedition",
        name: "Cultural-Tapestry-Expedition",
        image: gt2,
        description:
          "Discover hidden gems and local cuisine on this immersive journey.",
      },
    ],
    Affordable: 1111,
    Semi: 1745,
    Luxury: 33924,
  },
];

export const goldenTours = [
  {
    id: "Majestic-Golden-Triangle",
    title: "Majestic Golden Triangle Tour",
    one: "Rich blend of history, architecture, and culture in three iconic Indian cities.",
    places: "Delhi-Jaipur-Agra",
    image: gt1,
    days: "7",
    heading: {
      Delhi: "A captivating blend of history and modern flair",
      Jaipur: "Where the past paints the present",
      Agra: "Where Romance Meets Royalty",
    },
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
    },
    cityImages: {
      Delhi: gtdelhi1,
      Jaipur: gtjaipur1,
      Agra: gtagra1,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        detailsaboveimage: `Touch down at Delhi Airport, where we'll greet you with a sign and take you to your well-prepared hotel for a comfortable stay. `,
        details: (
          <div>
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality – from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels. <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Onwards to Jaipur!</b> <br />
            Just a 5-hour drive (280 km) from Delhi, a new adventure awaits in
            the Pink City.
          </div>
        ),
        image: delhiitinary,
      },
      {
        day: "Day 3-5",
        city: "Jaipur",
        detailsaboveimage: `Experience Jaipur, Rajasthan's enchanting capital founded in 1727 by Maharaja Sawai Jai Singh II. Marvel at the architectural splendor of Hawa Mahal, City Palace, and the planned layout based on Vastu Shastra.`,
        details: (
          <div>
            <br />
            This Pink City seamlessly melds its royal past with modern vibrancy.
            Explore historic treasures like City Palace, Jal Mahal, Hawa Mahal,
            and the 17th-century Jantar Mantar Observatory. Wander through the
            captivating Old Walled City, Amber Fort, and Birla Temple. Indulge
            in the local markets of Johri Bazar and Choti Chopper, renowned for
            unique jewelry. Jaipur – where history, culture, and markets
            intertwine for an unforgettable journey. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Moving ahead to Agra!</b> <br />
            Just a 4-hour and 20-minute drive (240 km) from Jaipur, get ready to
            discover the magic of this historic city.
          </div>
        ),
        image: jaipuritinary,
      },
      {
        day: "Day 5-6",
        city: "Agra",
        detailsaboveimage: `Exploring Agra means stepping into a world of romance and history.
        Behold the Taj Mahal's sunset view from the tranquil Yamuna
        Riverbanks. If time allows, delve into the Red Fort, either on the
        same day or the following.`,
        details: (
          <div>
            <br />
            Witness the Taj Mahal's enchantment at sunrise and sunset; this
            Wonder of the World unveils its magic in unique ways. Keep in mind
            it's closed on Fridays. Shape your sightseeing plan accordingly.{" "}
            <br />
            More treasures await: Fatehpur Sikri, Akbar's Tomb, Jama Masjid, and
            Itimad-ud-Daula’s Tomb. Agra, the realm of the iconic Taj Mahal,
            intricately weaves the Mughal legacy. From the majestic Agra Fort to
            the intricate allure of Fatehpur Sikri, the city's rich heritage
            unfolds. Agra's timeless allure encapsulates India's imperial
            history. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Back to Delhi!</b>
          </div>
        ),
        image: agraitinary,
      },
      {
        day: "Day 6",
        city: "Delhi",
        detailsaboveimage: `In Delhi, you've got two options.`,
        details: (
          <div>
            <br />
            <b>For relaxation</b>, stay at the hotel and recharge.
            <br />
            <b>Feeling adventurous?</b> Explore vibrant markets like Chandni
            Chowk or delve into Delhi's heritage at Fateh Puri Mosque or Jain
            Temple. Whether you seek tranquility or excitement, Delhi has
            something for everyone. Enjoy your time here!
          </div>
        ),
        detailsbelowimage: `Certain places will be explored at your convenience and based on
        your personal preferences and schedule.`,
        image: delhideparture,
      },
      {
        day: "Day 7",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            Feeling refreshed and prepared for your journey back home! <br />
            <br />
            Your driver will ensure you arrive at the airport in accordance with
            your flight schedule.
          </div>
        ),
        image: departure2,
      },
    ],
    map: gtmap1,
    highlights: [
      "Delhi's History and Vibrancy",
      "Jaipur's Regal Charms",
      "Taj Mahal's Timeless Beauty",
      "Seamless Travel",
      "Personalized Adventures",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/Rajasthani-Heritage-Retreat",
        name: "Rajasthani Heritage Retreat",
        image: rt1,
        description:
          "Embark on a 16-day 'Rajasthani Heritage Retreat' through Delhi's captivating history and vitality, the timeless heritage of Mandawa, the desert oasis of Bikaner, and the regal splendor of Jaipur, all while exploring the rich heritage of Rajasthan.",
      },
      {
        id: "/tour/Colours-of-Rajasthan-Expedition",
        name: "Colors of Rajasthan Expedition",
        image: gt5,
        description:
          "Embark on a 10-day 'Colors of Rajasthan Expedition' from Delhi, where a living tale of two worlds unfolds, to Agra, where history reigns supreme, and then to the timeless sandstone beauty of Jaipur and the romantic royalty of Udaipur.",
      },
      {
        id: "/tour/Coastal-Treasures-&-Mountain-Magic",
        name: "Coastal Treasures & Mountain Magic",
        image: gt2,
        description:
          "Experience a 10-day journey of 'Coastal Treasures & Mountain Magic' from Cochin's historical charm to the lush greenery of Munnar, all while enjoying coastal delights, the majesty of nature in Thekkady, and the vibrant mosaic of life and dreams in Mumbai.",
      },
    ],
    Affordable: 579,
    Semi: 899,
    Luxury: 1698,
  },
  {
    id: "The-Royal-Heritage-Trail",
    title: "The Royal Heritage Trail Tour",
    one: "A cultural journey where history, art, and architectural wonders await.",
    places: "Delhi-Mandawa-Jaipur-Agra",
    image: gt2,
    days: "8",
    heading: {
      Delhi: "A captivating blend of history and vitality",
      Mandawa: "A town of timeless heritage and beauty",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
    },
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Mandawa:
        "Mandawa: where walls whisper tales of artistry. This Rajasthani gem invites you to stroll through its streets adorned with stunning frescoes and havelis, each a canvas of history and beauty. Step into a living gallery of Rajasthan's architectural splendor and immerse yourself in the stories etched on its walls.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
    },
    cityImages: {
      Delhi: gtdelhi2,
      Mandawa: gtmandawa2,
      Jaipur: gtjaipur2,
      Agra: gtagra2,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        detailsaboveimage: `Touch down at Delhi Airport, where we'll greet you with a sign and take you to your well-prepared hotel for a comfortable stay. `,
        details: (
          <div>
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality – from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels. <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Onwards to Jaipur!</b> <br />
            Just a 5-hour drive (280 km) from Delhi, a new adventure awaits in
            the Pink City.
          </div>
        ),
        image: gtdelhiitinary4,
      },
      {
        day: "Day 3-4",
        city: "Mandawa",
        details: (
          <div>
            <br />
            <br />
            An authentic Rajasthan town that immerses you in rural and
            traditional life. It's an open-air museum where each step reveals
            unique architecture and vibrant mural paintings. Don't miss the
            Heritage Walk, where you'll encounter the Silk Route-era mansions
            known as Havelis. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur
            <br />
            JJust 3 hours’ drive (170 km) from Mandawa
          </div>
        ),
        image: gtmandawaitinary4,
      },
      {
        day: "Day 4-6",
        city: "Jaipur",
        details: (
          <div>
            Jaipur, the Pink City and the capital of Rajasthan, is a city
            adorned with historic monuments, palaces, fortresses, and
            enchantment. Explore the majestic City Palace, the serene Jal Mahal,
            the iconic Hawa Mahal (Wind Palace), and the 17th-century Jantar
            Mantar, an astronomical observatory.
            <br />
            Experience the awe-inspiring Amber Fort, steeped in countless tales,
            and the exquisite Birla Temple. Take a leisurely stroll through the
            vibrant markets of Johri Bazar and Choti Chopper, known for their
            unique jewelry.
            <br />
            In the evening, immerse yourself in the charm of the Old Walled City
            and explore the local market.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra
            <br />
            Embark on a 4.5-hour journey (covering 240 km) from Jaipur to the
            historic city of Agra.
          </div>
        ),
        image: gtjaipuritinary4,
      },
      {
        day: "Day 6-7",
        city: "Agra",
        details: (
          <div>
            <br />
            A visit to Agra is like stepping into the most romantic story ever
            told, unfolding right before your eyes. Please note that many
            attractions, including the Taj Mahal, are closed on Fridays.
            <br />
            Take in the stunning sunset view of the Taj Mahal from the tranquil
            banks of the Yamuna River. If time allows, you can explore the
            magnificent Red Fort on the same day. Alternatively, consider an
            early morning visit the next day to witness the sunrise at the Taj
            Mahal.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi
            <br />
            Embark on a 4-hour journey (covering 230 km) from Agra to the
            vibrant city of Delhi.
          </div>
        ),
        image: gtagraitinary4,
      },
      {
        day: "Day 7",
        city: "Delhi",
        details: (
          <div>
            <br />
            You have the choice to unwind at your hotel, preparing for your
            departure the next day. Alternatively, if you're feeling energetic
            and wish to explore, Delhi offers a variety of markets to visit:
            Chandni Chowk Market, the vibrant Kinari Bazaar (Wedding Market),
            the bustling Textile Market (Wholesale Market), the Book Market, and
            the fascinating Spice Market, known for both ground and whole
            spices.
          </div>
        ),
        detailsbelowimage:
          "If your interests lean towards art and architecture, you might consider visiting the Fateh Puri Mosque, Sis Ganj Gurudwara, Jain Temple, and the Shiva Temple. The choice is yours, tailored to your pace and preferences.",
        image: gtdelhiitinary3,
      },
      {
        day: "Day 8",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            With a sense of relaxation and readiness to journey back home, your
            dedicated driver will ensure you reach the airport promptly, in
            accordance with your flight schedule.
            <br />
            <b>Safe travels!</b>
          </div>
        ),
        image: departure3,
      },
    ],
    map: gtmap2,
    highlights: [
      "Witness the Taj Mahal at sunrise in Agra",
      "Explore Delhi's bustling markets",
      "Experience Jaipur's royal history",
      "Open art gallery of Mandawa",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/Expedition-Through-India",
        name: "Expedition Through India",
        image: gt6,
        description:
          "Embark on a 15-day 'Expedition Through India,' from Mumbai's history and modern flair to the ethereal Taj Mahal in Agra, exploring a timeless tapestry of history and modernity in Delhi and more, with a blend of past and present in Hampi and Ajanta & Ellora.",
      },
      {
        id: "/tour/Rajasthani-Heritage-Retreat",
        name: "Rajasthani Heritage Retreat",
        image: rt1,
        description:
          "Indulge in a 16-day 'Rajasthani Heritage Retreat' through Delhi's history, timeless beauty in Mandawa, desert tales in Jaisalmer, and a journey of romance and regal splendor in Udaipur, all while exploring the rich heritage of Rajasthan.",
      },
      {
        id: "/tour/Coastal-Treasures-&-Mountain-Magic",
        name: "Coastal Treasures & Mountain Magic",
        image: st1,
        description:
          "Experience a 10-day journey of 'Coastal Treasures & Mountain Magic' from Cochin's historical charm to the lush greenery of Munnar, all while enjoying coastal delights, the majesty of nature in Thekkady, and the vibrant mosaic of life and dreams in Mumbai.",
      },
    ],
    Affordable: 675,
    Semi: 1084,
    Luxury: 1991,
  },
  {
    id: "Royal-Triangle-Odyssey",
    title: "Royal Triangle Odyssey Tour",
    one: "Discover art, history, and spirituality from the Taj Mahal to Varanasi's ghats.",
    places: "Delhi-Mandawa-Jaipur-Agra-Varanasi",
    image: gt3,
    days: "10",
    heading: {
      Delhi: "A kaleidoscope of culture and contrasts",
      Mandawa: "An enchanting step back in time",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
      Varanasi: "The spiritual heart of India by the Ganges",
    },
    description: {
      Delhi:
        "A vibrant, bustling tapestry of modernity and rich history. In Old Delhi, humanity's myriad hues come to life.",
      Mandawa:
        "An 18th-century gem nestled in Rajasthan's Shekhawati region, where every step unveils an open-air museum. Embark on heritage walks amidst its Silk Route-era Havelis (ornate traditional mansions) and rejuvenate at our Mandawa Kothi property.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
      Varanasi:
        "Where the sacred Ganges River holds the promise of spiritual liberation, and every cremation and puja ceremony is a mystic ritual. This Hindu sanctuary is where devotees seek the renewal of their souls.",
    },
    cityImages: {
      Delhi: gtdelhi3,
      Mandawa: gtmandawa3,
      Jaipur: gtjaipur3,
      Agra: gtagra3,
      Varanasi: gtvaranasi3,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        detailsaboveimage: `Touch down at Delhi Airport, where we'll greet you with a sign and take you to your well-prepared hotel for a comfortable stay. `,
        details: (
          <div>
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality – from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels. <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Onwards to Jaipur!</b> <br />
            Just a 5-hour drive (280 km) from Delhi, a new adventure awaits in
            the Pink City.
          </div>
        ),
        image: gtdelhiitinary5,
      },
      {
        day: "Day 3-4",
        city: "Mandawa",
        details: (
          <div>
            <br />
            <br />
            An authentic Rajasthan town that immerses you in rural and
            traditional life. It's an open-air museum where each step reveals
            unique architecture and vibrant mural paintings. Don't miss the
            Heritage Walk, where you'll encounter the Silk Route-era mansions
            known as Havelis. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur
            <br />
            JJust 3 hours’ drive (170 km) from Mandawa
          </div>
        ),
        image: gtmandawaitinary5,
      },
      {
        day: "Day 4-6",
        city: "Jaipur",
        details: (
          <div>
            Jaipur, the Pink City and the capital of Rajasthan, is a city
            adorned with historic monuments, palaces, fortresses, and
            enchantment. Explore the majestic City Palace, the serene Jal Mahal,
            the iconic Hawa Mahal (Wind Palace), and the 17th-century Jantar
            Mantar.
            <br />
            As evening descends, take a leisurely stroll through the Old Walled
            City, immersing yourself in the local market's vibrant atmosphere.
            Discover the rich history of the Amber Fort, with its countless
            tales, and find serenity at the Birla Temple.
            <br />
            Jaipur is also renowned for its unique Jewelry Market, so don't
            forget to explore the treasures of Johri Bazar and Choti Chopper.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra
            <br />
            Your journey continues with a 4.5-hour drive covering 240
            kilometers. Prepare to uncover the iconic city of Agra.
          </div>
        ),
        image: gtjaipuritinary5,
      },
      {
        day: "Day 6-7",
        city: "Agra",
        details: (
          <div>
            <br />
            Agra, where history's most romantic tales come alive before your
            eyes. Please note, it's closed on Fridays, so plan accordingly. You
            can savor the enchanting sunset view of the Taj Mahal from the
            tranquil banks of the Yamuna River. If time permits, explore the
            majestic Red Fort on the same day, or save it for the day after,
            combining it with a sunrise visit to the Taj Mahal.
            <br />
            Afterward, you'll be on your way to your next destination.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Varanasi
            <br />
            Your journey continues with a 2-hour convenient flight, covering 648
            kilometer
          </div>
        ),
        image: gtagraitinary5,
      },
      {
        day: "Day 7-9",
        city: "Varanasi",
        detailsaboveimage: `Varanasi, a city steeped in spirituality and culture, offers a profound journey. Begin by visiting
        the Temple of Mother India, inaugurated by Mahatma Gandhi, featuring a vast marble map of
        India on the ground. Explore the Buddhist city of Sarnath and the prestigious Hindu University.`,
        details: (
          <div>
            <br />
            Start your day early with a boat ride along the Ganges Ghats, where
            you'll witness the morning rituals of bathing and prayer. Consider a
            visit to the Ram Nagar Fort, known for its impressive collection of
            vintage cars.
            <br />
            In the evening, another boat ride will allow you to experience the
            Ghats and the mesmerizing Aarti and rituals.
            <br />
            On your final day, relish one more sunrise boat ride before aligning
            your activities with your departure schedule.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi
            <br />
            Your journey continues with a quick 1.6-hour flight to Delhi, where
            you can explore the vibrant capital city before concluding your
            unforgettable adventure.
          </div>
        ),
        image: gtvaranasiitinary5,
      },
      {
        day: "Day 9",
        city: "Delhi",
        details: (
          <div>
            Your final day in Delhi offers flexibility. You can either relax at
            your hotel, preparing for your departure tomorrow, or dive into the
            vibrant city. If you're feeling active, explore the bustling
            markets, from Chandni Chowk to the unique Spice Market, where you
            can discover a world of aromas and flavors.
            <br />
            For those inclined towards art and architecture, explore the
            serenity of Fateh Puri Mosque, Sis Ganj Gurudwara, Jain Temple, and
            the tranquil Shiva Temple. Tailor your day to your desires, finding
            peace and comfort in the heart of Delhi.
          </div>
        ),
        image: delhideparture,
      },
      {
        day: "Day 10",
        city: "Departure",
        details: (
          <div>
            <br />
            <br />
            <br />
            Feeling refreshed and prepared for your journey back home!
            <br />
            Your driver will ensure you arrive at the airport in accordance with
            your flight schedule.
          </div>
        ),
        image: departure2,
      },
    ],
    map: gtmap3,
    highlights: [
      "Explore Delhi, Agra, and Jaipur's rich heritage",
      "Discover timeless beauty and sensuous artistry",
      "Immerse in Ganges Ghats and ancient temples",
      "Enjoy performances and vibrant markets",
      "Relax with seamless logistics and accommodations",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/Cultural-Tapestry-Expedition",
        name: "Cultural Tapestry Expedition",
        image: gt4,
        description:
          "Embark on a 9-day 'Cultural Tapestry Expedition' weaving through the captivating history and vitality of Delhi, the timeless heritage of Mandawa, desert charm and sacred serenity in Pushkar, regal splendor in Jaipur, and the historical grandeur of Agra.",
      },
      {
        id: "/tour/Mystical-North-India-Discovery",
        name: "Mystical North India Discovery",
        image: rt2,
        description:
          "Discover the mystical North India in 12 days with 'Mystical North India Discovery,' from the cultural vibrancy of Delhi to the spiritual heart of Varanasi, with timeless heritage and sculpted wonders in between.",
      },
      {
        id: "/tour/Southern-Delights",
        name: "Southern Delights: A Journey Through Time",
        image: st2,
        description:
          "Discover the essence of Southern India on the 'Southern Delights' 17-day tour, from Cochin's history to Mumbai's vibrant dreams, with coastal delights, natural majesty, and timeless wonders in between.",
      },
    ],
    Affordable: 979,
    Semi: 1449,
    Luxury: 2662,
  },
  {
    id: "Cultural-Tapestry-Expedition",
    title: "Cultural Tapestry Expedition Tour",
    one: "Uncover art, history, spirituality, and architectural marvels.",
    places: "Delhi-Mandawa-Pushkar-Jaipur-Agra",
    image: gt4,
    days: "9",
    heading: {
      Delhi: "A captivating blend of history and vitality",
      Mandawa: "A town of timeless heritage and beauty",
      Pushkar: "Where desert charm meets sacred serenity",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
    },
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Mandawa:
        "Mandawa: where walls whisper tales of artistry. This Rajasthani gem invites you to stroll through its streets adorned with stunning frescoes and havelis, each a canvas of history and beauty. Step into a living gallery of Rajasthan's architectural splendor and immerse yourself in the stories etched on its walls.",
      Pushkar:
        "Pushkar, a picturesque Rajasthan town, graced by Thar Desert's sand dunes, a sacred lake, intricate temples, lush gardens, and a plethora of camels. The final quarter of each year paints a vibrant tapestry of colors, festivities, and time-honored customs, with its renowned Camel Fair as a highlight. ",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
    },
    cityImages: {
      Delhi: gtdelhi4,
      Mandawa: gtmandawa4,
      Pushkar: gtpuskar4,
      Jaipur: gtjaipur4,
      Agra: gtagra4,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        detailsaboveimage: `Touch down at Delhi Airport, where we'll greet you with a sign and take you to your well-prepared hotel for a comfortable stay. `,
        details: (
          <div>
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality – from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels. <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Onwards to Jaipur!</b> <br />
            Just a 5-hour drive (280 km) from Delhi, a new adventure awaits in
            the Pink City.
          </div>
        ),
        image: gtdelhiitinary6,
      },
      {
        day: "Day 3-4",
        city: "Mandawa",
        details: (
          <div>
            <br />
            <br />
            An authentic Rajasthan town that immerses you in rural and
            traditional life. It's an open-air museum where each step reveals
            unique architecture and vibrant mural paintings. Don't miss the
            Heritage Walk, where you'll encounter the Silk Route-era mansions
            known as Havelis. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Pushkar
            <br />
            Just 4.5 hours’ drive (226 km) from Mandawa
          </div>
        ),
        image: gtmandawaitinary6,
      },
      {
        day: "Day 4-6",
        city: "Pushkar",
        details: (
          <div>
            <br />
            Pushkar, one of India's oldest cities, has long been a pilgrimage
            site for devotees nationwide. It boasts a captivating mythological
            history and a heritage of enduring architectural beauty.
            <br />
            Don't miss the opportunity to visit the world's only Lord Brahma
            (The Universe Creator) Temple. Explore the mystical beauty of
            Pushkar's Holy Lake. While the local market may be small, it's
            filled with unique treasures, including handmade gifts, aromatic
            spices, and vibrant clothing.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur
            <br />
            Set out on a 3-hour journey (covering 150 km) from Pushkar to the
            vibrant city of Jaipur.
          </div>
        ),
        image: gtpuskaritinary6,
      },
      {
        day: "Day 6-7",
        city: "Jaipur",
        details: (
          <div>
            Jaipur, the Pink City and the capital of Rajasthan, is a city
            adorned with historic monuments, palaces, fortresses, and
            enchantment. Explore the majestic City Palace, the serene Jal Mahal,
            the iconic Hawa Mahal (Wind Palace), and the 17th-century Jantar
            Mantar, an astronomical observatory.
            <br />
            Experience the awe-inspiring Amber Fort, steeped in countless tales,
            and the exquisite Birla Temple. Take a leisurely stroll through the
            vibrant markets of Johri Bazar and Choti Chopper, known for their
            unique jewelry.
            <br />
            In the evening, immerse yourself in the charm of the Old Walled City
            and explore the local market.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra
            <br />
            Embark on a 4.5-hour journey (covering 240 km) from Jaipur to the
            historic city of Agra.
          </div>
        ),
        image: gtjaipuritinary6,
      },
      {
        day: "Day 7-8",
        city: "Agra",
        details: (
          <div>
            <br />
            A visit to Agra is like stepping into the most romantic story ever
            told, unfolding right before your eyes. Please note that many
            attractions, including the Taj Mahal, are closed on Fridays.
            <br />
            Take in the stunning sunset view of the Taj Mahal from the tranquil
            banks of the Yamuna River. If time allows, you can explore the
            magnificent Red Fort on the same day. Alternatively, consider an
            early morning visit the next day to witness the sunrise at the Taj
            Mahal.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi
            <br />
            Embark on a 4-hour journey (covering 230 km) from Agra to the
            vibrant city of Delhi.
          </div>
        ),
        image: gtagraitinary6,
      },
      {
        day: "Day 8",
        city: "Delhi",
        details: (
          <div>
            <br />
            You have the choice to unwind at your hotel, preparing for your
            departure the next day. Alternatively, if you're feeling energetic
            and wish to explore, Delhi offers a variety of markets to visit:
            Chandni Chowk Market, the vibrant Kinari Bazaar (Wedding Market),
            the bustling Textile Market (Wholesale Market), the Book Market, and
            the fascinating Spice Market, known for both ground and whole
            spices.
          </div>
        ),
        detailsbelowimage:
          "If your interests lean towards art and architecture, you might consider visiting the Fateh Puri Mosque, Sis Ganj Gurudwara, Jain Temple, and the Shiva Temple. The choice is yours, tailored to your pace and preferences.",
        image: gtdelhiitinary3,
      },
      {
        day: "Day 9",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            With a sense of relaxation and readiness to journey back home, your
            dedicated driver will ensure you reach the airport promptly, in
            accordance with your flight schedule.
            <br />
            <b>Safe travels!</b>
          </div>
        ),
        image: departure3,
      },
    ],
    map: gtmap4,
    highlights: [
      "Witness the Taj Mahal at sunrise in Agra",
      "Explore Delhi's bustling markets",
      "Experience Jaipur's royal history",
      "Mandawa’s Open art Gallery ",
      "Sacred Ganga ghats of pushkar",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/Majestic-Golden-Triangle",
        name: "Majestic Golden Triangle",
        image: gt1,
        description:
          "Experience the 'Majestic Golden Triangle' in 7 days, where Delhi's history and modern flair meet, Jaipur paints a vibrant past and present, and Agra's Taj Mahal embodies the essence of romance and royalty.",
      },
      {
        id: "/tour/Mystical-North-India-Discovery",
        name: "Mystical North India Discovery",
        image: rt2,
        description:
          "Discover the mystical North India in 12 days with 'Mystical North India Discovery,' from the cultural vibrancy of Delhi to the spiritual heart of Varanasi, with timeless heritage and sculpted wonders in between.",
      },
      {
        id: "/tour/Heritage-Odyssey",
        name: "Heritage Odyssey: North to South India",
        image: st3,
        description:
          "Explore North to South India in 16 days on the 'Heritage Odyssey' tour. From Delhi's history to Ajanta's ancient rock art and Mumbai's vibrant charm, experience a rich tapestry of heritage and culture along the way.",
      },
    ],
    Affordable: 772,
    Semi: 1198,
    Luxury: 2260,
  },
  {
    id: "Colours-of-Rajasthan-Expedition",
    title: "Colors of Rajasthan Expedition Tour",
    one: "North India's heritage with the Taj Mahal, royal palaces, and Udaipur's romance.",
    places: "Delhi-Agra-Jaipur-Udaipur",
    image: gt5,
    days: "10",
    heading: {
      Delhi: "A living tale of two worlds",
      Agra: "Where history reigns supreme",
      Jaipur: "A timeless tale etched in sandstone",
      Udaipur: "Where Romance and Royalty Reign",
    },
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience",
      Udaipur:
        "In the heart of India lies Udaipur, a city that combines love and luxury. Discover majestic palaces, ancient temples, and exquisite Havelis. Explore bustling markets, and savor breathtaking sunsets on Lake Pichola. Join us in exploring the magic of Udaipur.",
    },
    cityImages: {
      Delhi: gtdelhi5,
      Jaipur: gtjaipur5,
      Agra: gtagra5,
      Udaipur: gtudaipur5,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        detailsaboveimage: `Touch down at Delhi Airport, where we'll greet you with a sign and take you to your well-prepared hotel for a comfortable stay. `,
        details: (
          <div>
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality – from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels. <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Agra
            <br />
            <b>Discover the Taj Mahal!</b>
            Just a 4-hour drive (230 km) from Delhi, a world of wonder awaits
            you in the City of Love.
          </div>
        ),
        image: delhiitinary2,
      },
      {
        day: "Day 3-5",
        city: "Agra",
        detailsaboveimage: `Exploring Agra means stepping into a world of romance and history.
        Behold the Taj Mahal's sunset view from the tranquil Yamuna
        Riverbanks. If time allows, delve into the Red Fort, either on the
        same day or the following.`,
        details: (
          <div>
            <br />
            <br />
            Witness the Taj Mahal's enchantment at sunrise and sunset; this
            Wonder of the World unveils its magic in unique ways. Keep in mind
            it's closed on Fridays. Shape your sightseeing plan accordingly.{" "}
            <br />
            More treasures await: Fatehpur Sikri, Akbar's Tomb, Jama Masjid, and
            Itimad-ud-Daula’s Tomb. Agra, the realm of the iconic Taj Mahal,
            intricately weaves the Mughal legacy. From the majestic Agra Fort to
            the intricate allure of Fatehpur Sikri, the city's rich heritage
            unfolds. Agra's timeless allure encapsulates India's imperial
            history. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Jaipur
            <br />A seamless transition in 4.5-Hour Journey (250 km)
          </div>
        ),
        image: agraitinary2,
      },
      {
        day: "Day 5-7",
        city: "Jaipur",
        detailsaboveimage: `Experience Jaipur, Rajasthan's enchanting capital founded in 1727 by Maharaja Sawai Jai Singh II. Marvel at the architectural splendor of Hawa Mahal, City Palace, and the planned layout based on Vastu Shastra.`,
        details: (
          <div>
            <br />
            This Pink City seamlessly melds its royal past with modern vibrancy.
            Explore historic treasures like City Palace, Jal Mahal, Hawa Mahal,
            and the 17th-century Jantar Mantar Observatory. Wander through the
            captivating Old Walled City, Amber Fort, and Birla Temple. Indulge
            in the local markets of Johri Bazar and Choti Chopper, renowned for
            unique jewelry. Jaipur – where history, culture, and markets
            intertwine for an unforgettable journey. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Udaipur
            <br />
            Connect the Pink City to the City of Lakes with a swift and
            convenient one-hour flight.
          </div>
        ),
        image: jaipuritinary2,
      },
      {
        day: "Day 7-9",
        city: "Udaipur",
        details: (
          <div>
            <br />
            <br />
            Experience
            <b>Udaipur's Beauty</b> known for its lakes. You'll see the stunning
            City Palace, Princess Garden, Shilp Gram, and Jagdish Temple.
            <br />
            <br />
            Spend your afternoons shopping for colorful Rajasthan souvenirs and
            spices in local markets.
            <br />
            <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule.
          </div>
        ),
        image: udaipuritinary,
      },
      {
        day: "Day 9",
        city: "Delhi",
        details: (
          <div>
            <br />
            On your last day in Delhi, you have choices to suit your style. If
            you're feeling relaxed, take it easy at your hotel and prepare for
            your departure. But if you're up for adventure, Delhi's got plenty
            to offer. Explore the lively Chandni Chowk Market, wander through
            the wedding wonders of Kinari Bazaar, or check out the wholesale
            Textile Market. Bookworms can dive into the Book Market, and spice
            lovers can't miss the aromatic Spice Market.
            <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            If you're more into art and architecture, consider visits to{" "}
            <b>Fateh Puri Mosque, Sis Ganj Gurudwara, Jain Temple,</b> and{" "}
            <b>The tranquil Shiva Temple</b>. Tailor your day to your comfort
            and interests for a delightful end to your Delhi journey.
          </div>
        ),
        image: delhideparture,
      },
      {
        day: "Day 10",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            <br />
            As your journey ends, feeling refreshed and prepared for your trip
            back home, your driver will ensure a timely transfer to the airport,
            aligning with your flight schedule. <br />
          </div>
        ),
        image: departure2,
      },
    ],
    map: gtmap5,
    highlights: [
      "Timeless Beauty of Old Delhi ",
      "Royal Grandeur of Jaipur",
      "Love Story in Marble",
      "Love and Luxury of Udaipur",
      "Cultural Treasures",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/The-Royal-Heritage-Trail",
        name: "The Royal Heritage Trail",
        image: gt2,
        description:
          "An 8-day journey through Delhi, Mandawa, Jaipur, and Agra, immersing you in a captivating blend of history, timeless heritage, regal splendor, and historical grandeur.",
      },
      {
        id: "/tour/A-Journey-Through-Heritage-&-Wildlife",
        name: "A Journey Through Heritage & Wildlife",
        image: rt4,
        description:
          "Embark on a 13-day odyssey through heritage and wildlife on the 'A Journey Through Heritage & Wildlife' tour, from the captivating blend of history in Delhi to the wildlife wonders of Ranthambore, all amidst timeless heritage, desert charm, and regal splendor.",
      },
      {
        id: "/tour/Southern-Splendors",
        name: "Southern Splendors: Culture & Nature Trail",
        image: st4,
        description:
          "Embark on a 19-day 'Southern Splendors: Culture & Nature Trail' from Mumbai's vibrant dreams to the rich cultural tapestry of Hampi, experiencing history, coastal delights, nature's majesty, and regal splendor in between.",
      },
    ],
    Affordable: 993,
    Semi: 1473,
    Luxury: 2672,
  },
  {
    id: "Expedition-Through-India",
    title: "Expedition Through India Tour",
    one: "Explore India's cultural diversity with ancient wonders, royal splendor, and historic grandeur.",
    places: "Mumbai-Hampi-Ajanta & Ellora-Jaipur-Agra-Delhi",
    image: gt6,
    days: "15",
    heading: {
      Mumbai: "A captivating blend of history and modern flair",
      Hampi: "Where the past paints the present",
      Ajanta: "Where Romance Meets Royalty",
      Jaipur: "A timeless tale etched in sandstone",
      Agra: "Home to the Ethereal Taj Mahal",
      Delhi: "A Timeless Tapestry of History and Modernity",
    },
    description: {
      Mumbai:
        "Mumbai, often referred to as Bombay, is the heartbeat of India's urban landscape. This bustling metropolis on the western coast is a captivating fusion of diverse cultures and pulsating energy. With its iconic landmarks like the Gateway of India, a rich cosmopolitan lifestyle, and an endless array of experiences, Mumbai is a city that never ceases to amaze, inviting you to explore its vibrant soul.",
      Hampi:
        "Experience the enchanting ruins of Hampi, where centuries-old history unfolds amidst a surreal landscape. You'll find yourself transported to a realm of historical marvels and natural beauty in this ancient city. Wander through majestic temples, captivating bazaars, and awe-inspiring stone monuments that transport you to a bygone era. Let Hampi's timeless charm captivate your soul.",
      Ajanta:
        "The mystical allure of Ajanta &amp; Ellora Caves is UNESCO World Heritage Site. These ancient rock-cut caves in India's heartland are a testament to unparalleled craftsmanship and devotion, showcasing intricate sculptures and breathtaking frescoes that date back to as early as the 2nd century BC. Embark on a captivating journey through time and artistry with us.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
      Delhi:
        "Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
    },
    cityImages: {
      Mumbai: gtmumbai6,
      Hampi: gthampi6,
      Ajanta: gtajanta6,
      Jaipur: gtjaipur6,
      Agra: gtagra6,
      Delhi: gtdelhi6,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Mumbai",
        details: (
          <div>
            <br />
            Immerse yourself in the vibrant pulse of this dynamic metropolis and
            explore the mesmerizing Elephanta Caves. Journey through the heart
            of Mumbai to discover Dharavi, a locale that has served as the
            backdrop for iconic Bollywood movies, including the Oscar-winning
            masterpiece,
            <b>"Slumdog Millionaire."</b>
            <br />
            Join us for an unforgettable experience as we unlock the essence of
            this incredible city, blending tradition and modernity in a way
            that's truly captivating.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b>Hampi <br />
            Unveil the wonders of Hampi!
            <br />A mere 1-hour and 45-minute flight from Mumbai
          </div>
        ),
        image: gtmumbaiitinary1,
      },
      {
        day: "Day 3-6",
        city: "Hampi",
        details: (
          <div>
            <br />
            Get ready for an adventure that takes you back in time. Hampi's
            ancient ruins will leave you spellbound. Explore the Zenana
            Enclosure, wander through lush palm groves, banana plantations, and
            peaceful paddy fields. <br />
            Discover the beauty of Hindu Temples like Vittala, Virupashka, and
            Achyutaraya. Take a leisurely walk in Sule Bazaar, soaking up the
            local atmosphere and finding hidden gems. Hampi has it all, waiting
            for you to explore.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Aurangabad <br />A quick 1-hour and 45-minute
            flight from Hampi
          </div>
        ),
        image: gthampiitinary1,
      },
      {
        day: "Day 6-9",
        city: "Ajanta  & Ellora",
        details: (
          <div>
            <br />
            Embark on a journey to the UNESCO World Heritage Ajanta and Ellora
            Caves. These ancient wonders will transport you to a world of
            artistry and spirituality. While in Aurangabad, don't miss the
            chance to visit Bibi-Ka-Maqbara, the <b>"Taj of the Deccan,"</b> a
            testament to eternal love.
            <br />
            Savor Mughal-inspired cuisine, indulging in dishes like Pulao and
            Biriyani that have stood the test of time.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Jaipur
            <br />
            Just a 5-hour and 30-minute flight away from Aurangabad.
          </div>
        ),
        image: gtajantaitinary,
      },
      {
        day: "Day 9-11",
        city: "Jaipur",
        detailsaboveimage: `Welcome to the enchanting Pink City of Rajasthan, where every corner is a living testament to history.`,
        details: (
          <div>
            <br />
            Explore the grandeur of the City Palace, the serenity of Jal Mahal,
            the intricate beauty of Hawa Mahal (Wind Palace), and the scientific
            marvel of Jantar Mantar, dating back to the 17th century.
            <br />
            As the sun sets, take a leisurely stroll through the Old Walled City
            and explore local markets bursting with vibrant energy. Dive into
            the captivating tales of Amber Fort and find tranquility at the
            Birla Temple.
            <br />
            Don't forget to immerse yourself in the world of unique jewelry at
            Johri Bazar and Choti Chopper, as Jaipur is renowned for its
            one-of-a-kind jewelry market.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Agra
            <br />
            Your next destination is just a 4-hour drive, covering 240
            kilometers. Get ready to explore the iconic city of Agra.
          </div>
        ),
        image: agraitinary,
      },
      {
        day: "Day 11-13",
        city: "Agra",
        detailsaboveimage: "Agra, a city that breathes romance and history.",
        details: (
          <div>
            Witness the mesmerizing sunset view of the Taj Mahal from the serene
            banks of the Yamuna River. If time permits, explore the majestic Red
            Fort on the same day, or save it for tomorrow.
            <br />
            The Taj Mahal, a Wonder of the World, beckons at sunrise and sunset,
            offering a different kind of magic at each visit. Remember that it's
            closed on Fridays, so plan your schedule accordingly. While in Agra,
            immerse yourself in the rich history with visits to Fatehpur Sikri,
            Akbar's Tomb, Jama Masjid, and the exquisite Itimad-ud-Daula's Tomb.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Delhi
            <br />
            Your journey continues with a 4-hour drive, covering 230 kilometers.
          </div>
        ),
        image: agraitinary,
      },
      {
        day: "Day 13-14",
        city: "Delhi",
        details: (
          <div>
            <br />
            In Delhi, the choice is yours: prepare for your next adventure in
            comfort at your hotel, or dive into the city's vibrant pulse. If
            you're up for exploration, discover the kaleidoscope of markets like
            Chandni Chowk, the bustling Wedding Market at Kinari Bazaar, and the
            Textile Market offering a glimpse into India's craftsmanship. Don't
            miss the Book Market or the aromatic Spice Market with its array of
            ground and whole spices.
          </div>
        ),
        detailsbelowimage:
          "For those with a penchant for art and architecture, visit Fateh Puri Mosque, Sis Ganj Gurudwara, Jain Temple, and the serene Shiva Temple. Delhi offers experiences tailored to your pace and preferences, ensuring your journey ends on a note of peace and comfort.",
        image: gtdelhiitinary3,
      },
      {
        day: "Day 15",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            As your remarkable journey ends, you can depart feeling refreshed
            and ready to return home. Your dedicated driver will ensure a
            seamless transfer to the airport, aligning with your flight
            schedule. We bid you farewell with warm memories of your incredible
            adventure, and we look forward to hosting you again in the future.
            <br />
            <b>Safe travels!</b>
          </div>
        ),
        image: departure3,
      },
    ],
    map: gtmap6,
    highlights: [
      "Mumbai's Cinematic Charm.",
      "Hampi's Ancient Wonders.",
      "Aurangabad's UNESCO Treasures",
      "Jaipur's Regal Majesty",
      "Agra's Taj Mahal Elegance.",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/A-Journey-Through-Heritage-&-Wildlife",
        name: "A Journey Through Heritage & Wildlife",
        image: rt4,
        description:
          "Embark on a 13-day odyssey through heritage and wildlife on the 'A Journey Through Heritage & Wildlife' tour, from the captivating blend of history in Delhi to the wildlife wonders of Ranthambore, all amidst timeless heritage, desert charm, and regal splendor.",
      },
      {
        id: "/tour/Royal-Triangle-Odyssey",
        name: "Royal-Triangle-Odyssey",
        image: gt3,
        description:
          "Experience a 10-day 'Royal Triangle Odyssey' from the kaleidoscope of culture in Delhi to the spiritual heart of Varanasi, with enchanting heritage in Mandawa, regal splendor in Jaipur, and the historical magnificence of Agra in between.",
      },
      {
        id: "/tour/Heritage-Odyssey",
        name: "Heritage Odyssey: North to South India",
        image: st3,
        description:
          "Explore North to South India in 16 days on the 'Heritage Odyssey' tour. From Delhi's history to Ajanta's ancient rock art and Mumbai's vibrant charm, experience a rich tapestry of heritage and culture along the way.",
      },
    ],
    Affordable: 1875,
    Semi: 2250,
    Luxury: 3500,
  },
];

export const southTours = [
  {
    id: "Coastal-Treasures-&-Mountain-Magic",
    title: "Coastal Treasures & Mountain Magic Tour",
    one: "Explore God's Own Country with Ayurveda, wildlife, and scenic hill stations.",
    places: "Cochin-Alleppey-Thekkady-Munnar-Mumbai",
    image: st1,
    days: "10",
    heading: {
      Cochin: "Where History and Commerce Unite",
      Alleppey: "Coastal Delights Await",
      Thekkady: "Where Nature Unveils its Majesty",
      Munnar: "South India's Alpine Haven",
      Mumbai: "A vibrant mosaic of life and dreams",
    },
    description: {
      Cochin:
        "Cochin, often hailed as the Queen of the Arabian Sea, is a bustling commercial hub steeped in a rich trading legacy spanning over six centuries. Here, the echoes of Arabian, Dutch, Phoenician, Portuguese, Chinese, and British influences are woven into the city's vibrant tapestry. As the financial, commercial, and industrial heart of Kerala, Cochin invites you to immerse yourself in its captivating blend of history and commerce.",
      Alleppey: (
        <div>
          In the coastal town of Alleppey, a world of unforgettable experiences
          unfolds. From idyllic houseboat stays to basking on pristine beaches,
          the options for creating lasting memories are abundant, especially for
          first-time visitors.
          <br />
          Nestled amid lush greenery, where coconut trees gracefully bow over
          the serene waters, Alleppey's backwaters offer a unique journey. These
          tranquil channels are navigated by a variety of traditional boats,
          most notably the iconic Kerala River boats, promising an enchanting
          exploration like no other.
        </div>
      ),
      Thekkady:
        "In Thekkady, prepare for a sensory feast that includes the majestic presence of elephants, fragrant spice plantations, and picturesque hills. The Periyar forest, a true national treasure, cradles within it the most vibrant array of flora and fauna found anywhere on Earth, including captivating migratory species that grace these lands. Your journey here promises an immersive encounter with the awe-inspiring wonders of nature.",
      Munnar: (
        <div>
          Munnar, the cherished hill-station of Kerala, rests gracefully at an
          elevation of 1600 meters amidst the Western Ghats. Often referred to
          as the "Kashmir of South India", it's a place of sheer natural
          splendor.
          <br />
          Perched along the banks of three pristine rivers - Madupetti,
          Nallathanni, and Periavaru, Munnar also boasts of natural bird
          sanctuaries and vast tea plantations. Prepare to be enchanted by the
          alpine wonders of this picturesque destination.
        </div>
      ),
      Mumbai:
        "Mumbai, often referred to as Bombay, is the heartbeat of India's urban landscape. This bustling metropolis on the western coast is a captivating fusion of diverse cultures and pulsating energy. With its iconic landmarks like the Gateway of India, a rich cosmopolitan lifestyle, and an endless array of experiences, Mumbai is a city that never ceases to amaze, inviting you to explore its vibrant soul.",
    },
    cityImages: {
      Cochin: stcochin1,
      Alleppey: stalleppey1,
      Thekkady: stthekkady1,
      Munnar: stmunnar1,
      Mumbai: stmumbai1,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Cochin",
        details: (
          <div>
            <br />
            Upon arrival at Cochin Airport, our team will warmly welcome you
            with a personalized sign, ensuring a smooth transition to your
            journey.
            <br />
            For those with early arrivals, the option to explore Cochin awaits,
            tailored to your preferences and schedule. We'll curate your visit
            to match your interests, making the most of your time.
            <br />
            <b>Next Stop:</b> Alleppey
            <br />
            Your journey continues with a scenic 1-hour and 20-minute drive,
            covering 55 kilometers, as you set your sights on the tranquil
            beauty of Alleppey.
          </div>
        ),
        image: stcochinitinary1,
      },
      {
        day: "Day 3-4",
        city: "Alleppey",
        details: (
          <div>
            <br />
            Embark on a captivating boat ride along Vembanad Lake, providing an
            intimate glimpse into the enchanting life of Kerala's Backwaters. As
            you traverse this narrow strip of land, you'll witness the
            livelihoods of fishing communities, children gleefully swimming, and
            women immersed in their daily routines.
            <br />
            Feel free to explore any intriguing spot you encounter along the
            way, immersing yourself in the genuine magic of these surroundings.
            <br />
            <b>Next Stop:</b> Thekkady
            <br />
            Your journey continues with a scenic 3-hour and 30-minute drive,
            spanning 140 kilometers, leading you to the pristine allure of
            Thekkady.
          </div>
        ),
        image: stalleppeyitinary1,
      },
      {
        day: "Day 4-6",
        city: "Thekkady",
        details: (
          <div>
            <br />
            Embark on a thrilling boat expedition on Lake Periyar, where you'll
            have the chance to witness captivating wildlife and explore one of
            India's exceptional animal sanctuaries. Immerse yourself in the
            untamed beauty of this unique destination.
            <br />
            Venture into the fragrant spice plantations, where cardamom,
            cinnamon, pepper, and coffee thrive. Don't forget to savor the
            flavors of the tea estates that abound in the region. For a taste of
            Kerala to take home, explore the local market and discover an array
            of natural seasonings.
            <br />
            <b>Next Stop:</b> Munnar
            <br />
            Your journey continues with a scenic 3-hour drive, covering 90
            kilometers, leading you to the breathtaking landscapes of Munnar.
          </div>
        ),
        image: stthekkadyitinary1,
      },
      {
        day: "Day 6-8",
        city: "Munnar",
        details: (
          <div>
            <br />
            Explore one of the world's highest tea-growing regions in Munnar.
            Visit Eravikulam National Park, the sanctuary for the endangered
            Nilgiri Thar (Ibex). Discover the iconic Mattupetty Dam, the Indo
            Swiss Dairy farm, and the serene Kundala Lake.
            <br />
            <b>Next Stop:</b> Mumbai
            <br />
            Bid adieu to the lush landscapes of Munnar and prepare for a quick
            2-hour flight to the bustling metropolis of Mumbai, where your
            adventure takes on a new dimension.
          </div>
        ),
        image: stmunnaritinaary1,
      },
      {
        day: "Day 8-9",
        city: "Mumbai",
        details: (
          <div>
            <br />
            Immerse yourself in the boundless energy of Mumbai, an impressive
            metropolis that never sleeps. Explore the awe-inspiring Elephanta
            Caves, where history whispers through the intricate rock-cut
            temples.
            <br />
            Venture into Dharavi, an area famed for its role in Bollywood
            movies, including the Oscar-winning "Slumdog Millionaire". Here,
            you'll witness the heart and soul of Mumbai's vibrant cinematic
            culture.
          </div>
        ),
        image: stmumbaiitinary1,
      },
      {
        day: "Day 10",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            <br />
            As your remarkable journey draws to a close, you'll find yourself
            relaxed and rejuvenated, ready for your homeward journey. Your
            dedicated driver will ensure a seamless transfer to the airport,
            ensuring you depart according to your flight schedule. We bid you
            farewell with warm memories of your incredible adventure and look
            forward to hosting you again in the future.
            <br />
            <b>Safe travel!</b>
          </div>
        ),
        image: departure2,
      },
    ],
    map: stmap1,
    highlights: [
      "Journey from tranquil backwaters to thrilling wildlife",
      "Munnar’s Tea Gardens & Tranquility",
      "Mumbai's Enigmatic Charm",
      "Cultural & Scenic Odyssey",
      "Kerala Unveiled",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/Cultural-Tapestry-Expedition",
        name: "Cultural Tapestry Expedition",
        image: gt4,
        description:
          "Embark on a 9-day 'Cultural Tapestry Expedition' weaving through the captivating history and vitality of Delhi, the timeless heritage of Mandawa, desert charm and sacred serenity in Pushkar, regal splendor in Jaipur, and the historical grandeur of Agra.",
      },
      {
        id: "/tour/Mystical-North-India-Discovery",
        name: "Mystical North India Discovery",
        image: rt2,
        description:
          "Discover the mystical North India in 12 days with 'Mystical North India Discovery,' from the cultural vibrancy of Delhi to the spiritual heart of Varanasi, with timeless heritage and sculpted wonders in between.",
      },
      {
        id: "/tour/Southern-Delights",
        name: "Southern Delights: A Journey Through Time",
        image: st2,
        description:
          "Discover the essence of Southern India on the 'Southern Delights' 17-day tour, from Cochin's history to Mumbai's vibrant dreams, with coastal delights, natural majesty, and timeless wonders in between.",
      },
    ],
    Affordable: 1200,
    Semi: 1560,
    Luxury: 2270,
  },
  {
    id: "Southern-Delights",
    title: "Southern Delights: A Journey Through Time Tour",
    one: "Explore South India's rich culture, temples, beaches, and traditions for an unforgettable journey.",
    places:
      "Cochin-Alleppey-Thekkady-Munnar-Madurai-Thanjavur-Pondicherry-Mahabalipuram-Mumbai",
    image: st2,
    days: "17",
    heading: {
      Cochin: "Where History and Commerce Unite",
      Alleppey: "Coastal Delights Await",
      Thekkady: "Where Nature Unveils its Majesty",
      Munnar: "South India's Alpine Haven",
      Madurai: "",
      Thanjavur: "",
      Pondicherry: "",
      Mahabalipuram: "",
      Mumbai: "A vibrant mosaic of life and dreams",
    },
    description: {
      Cochin:
        "Cochin, often hailed as the Queen of the Arabian Sea, is a bustling commercial hub steeped in a rich trading legacy spanning over six centuries. Here, the echoes of Arabian, Dutch, Phoenician, Portuguese, Chinese, and British influences are woven into the city's vibrant tapestry. As the financial, commercial, and industrial heart of Kerala, Cochin invites you to immerse yourself in its captivating blend of history and commerce.",
      Alleppey: (
        <div>
          In the coastal town of Alleppey, a world of unforgettable experiences
          unfolds. From idyllic houseboat stays to basking on pristine beaches,
          the options for creating lasting memories are abundant, especially for
          first-time visitors.
          <br />
          Nestled amid lush greenery, where coconut trees gracefully bow over
          the serene waters, Alleppey's backwaters offer a unique journey. These
          tranquil channels are navigated by a variety of traditional boats,
          most notably the iconic Kerala River boats, promising an enchanting
          exploration like no other.
        </div>
      ),
      Thekkady:
        "In Thekkady, prepare for a sensory feast that includes the majestic presence of elephants, fragrant spice plantations, and picturesque hills. The Periyar forest, a true national treasure, cradles within it the most vibrant array of flora and fauna found anywhere on Earth, including captivating migratory species that grace these lands. Your journey here promises an immersive encounter with the awe-inspiring wonders of nature.",
      Munnar: (
        <div>
          Munnar, the cherished hill-station of Kerala, rests gracefully at an
          elevation of 1600 meters amidst the Western Ghats. Often referred to
          as the "Kashmir of South India", it's a place of sheer natural
          splendor.
          <br />
          Perched along the banks of three pristine rivers - Madupetti,
          Nallathanni, and Periavaru, Munnar also boasts of natural bird
          sanctuaries and vast tea plantations. Prepare to be enchanted by the
          alpine wonders of this picturesque destination.
        </div>
      ),
      Madurai:
        "A city steeped in spirituality, is renowned for its towering Hindu shrines and is one of India's most iconic pilgrimage destinations. This sacred city is also famous for its fragrant flowers, including the sambac jasmine, which fills Nandan market with hundreds of strings. It's a place where the essence of devotion mingles with the scents of ylang-ylangs, marjorams, tulsi, roses, tuberose, and lotuses, creating a sensory journey like no other.",
      Thanjavur: (
        <div>
          A city steeped in history, stands as the ochre foundation blocks of a
          remarkable Dravidian civilization. It was a kingdom that expanded the
          reach of Hinduism beyond India and served as the bedrock for aesthetic
          styles that influenced regions from Madurai to the Mekong.
          <br />A treasure trove of Dravidian heritage, where the mighty
          Brihadishwara Temple stands as a testament to a remarkable
          civilization, and the labyrinthine royal palace preserves the memories
          of powerful dynasties that left an indelible mark on history.
        </div>
      ),
      Pondicherry:
        "The enchanting French Riviera of the East, where the fusion of Indian traditions and French architecture creates a captivating and dreamlike destination, offering the best of both worlds in a fairytale setting.",
      Mahabalipuram:
        "Renowned for its intricately carved temples and rock-cut caves, this historical gem rests along the picturesque Coromandel Coast by the Bay of Bengal. With serene beaches framed by Casuarina trees, it's a captivating destination of tranquility and beauty.",
      Mumbai:
        "Mumbai, often referred to as Bombay, is the heartbeat of India's urban landscape. This bustling metropolis on the western coast is a captivating fusion of diverse cultures and pulsating energy. With its iconic landmarks like the Gateway of India, a rich cosmopolitan lifestyle, and an endless array of experiences, Mumbai is a city that never ceases to amaze, inviting you to explore its vibrant soul.",
    },
    cityImages: {
      Cochin: stcochin2,
      Alleppey: stalleppey2,
      Thekkady: stthekkady2,
      Munnar: stmunnar2,
      Madurai: stmadurai2,
      Thanjavur: stthanjavur2,
      Pondicherry: stpondicharry2,
      Mahabalipuram: stmahabalipuram2,
      Mumbai: stmumbai2,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Cochin",
        details: (
          <div>
            <br />
            We'll be there to greet you at Cochin Airport with a personalized
            sign, ensuring a seamless arrival experience. For those arriving
            early, Cochin exploration tailored to your interests awaits.
            <br />
            <b>Next Stop:</b> Alleppey
            <br />
            <b>Discover the Taj Mahal!</b>
            Your journey continues with a scenic 1-hour and 20-minute drive,
            covering 55 kilometers.
          </div>
        ),
        image: stcochinitinary2,
      },
      {
        day: "Day 3-4",
        city: "Alleppey",
        details: (
          <div>
            <br />
            Embark on a captivating boat ride along Vembanad Lake, offering an
            intimate glimpse into Keralan Backwater life. Observe fishing
            fishermen, playful children, and women immersed in their daily
            chores. Lose yourself in the enchanting surroundings, savoring the
            magic of the place.
            <br />
            <b>Next Stop:</b> Thekkady
            <br />
            Your journey continues with a scenic 3-hour and 30-minute drive.
          </div>
        ),
        image: stalleppeyitinary2,
      },
      {
        day: "Day 4-6",
        city: "Thekkady",
        details: (
          <div>
            <br />
            Embark on a thrilling boating adventure on Lake Periyar, where
            you'll encounter captivating wildlife and explore one of India's
            unique animal sanctuaries. Discover fragrant spice plantations
            teeming with cardamom, cinnamon, pepper, and coffee. Delight in the
            tea estates and shop at the local market for exquisite natural
            seasonings to bring home as souvenirs.
            <br />
            <b>Next Stop:</b> Munnar
            <br />
            Your journey continues with a scenic 3-hour drive, spanning 90
            kilometers.
          </div>
        ),
        image: stthekkadyitinary1,
      },
      {
        day: "Day 6-8",
        city: "Munnar",
        details: (
          <div>
            <br />
            Explore one of the world's highest tea-growing regions in Munnar.
            Visit Eravikulam National Park, the sanctuary for the endangered
            Nilgiri Thar (Ibex). Discover the iconic Mattupetty Dam, the Indo
            Swiss Dairy farm, and the serene Kundala Lake.
            <br />
            <b>Next Stop:</b> Madurai
            <br />
            Your journey continues with a scenic 4-hour and 20-minute drive,
            spanning 155 kilometers.
          </div>
        ),
        image: stmunnaritinaary2,
      },
      {
        day: "Day 8-10",
        city: "Madurai",
        details: (
          <div>
            <br />
            Explore the architectural marvel of the Meenakshi Amman Temple,
            dedicated to the Goddess of Wealth. Immerse yourself in the vibrant
            atmosphere of the city's Mattuthavani flower market. Witness the art
            of crafting fragrant creations at Raja Palaniswamy's facility.
            <br />
            <b>Next Stop:</b> Thanjavur
            <br />
            Your journey continues with a scenic 3-hour drive, spanning 190
            kilometers, leading you to the historic city of Thanjavur.
          </div>
        ),
        image: stmaduraiitinary2,
      },
      {
        day: "Day 10-11",
        city: "Thanjavur",
        details: (
          <div>
            <br />
            Explore the magnificent Brihadeshwara Temple, an 11th-century
            masterpiece built by the Chola King. Delve into the treasure trove
            of knowledge at the Saraswati Mahal Library, home to a rare
            collection of books and manuscripts on paper and palm leaves.
            <br />
            <b>Next Stop:</b> Pondicherry
            <br />
            Your journey continues with a picturesque 4-hour and 20-minute
            drive, spanning 170 kilometers.
          </div>
        ),
        image: stthanjavuritinary2,
      },
      {
        day: "Day 11-13",
        city: "Pondicherry",
        details: (
          <div>
            <br />
            Indulge in the tranquil ambiance and diverse cuisine of this Indian
            Union Territory. Pay a visit to the Navagraha Temple, where all nine
            celestial bodies are revered for their influence on our lives.
            <br />
            Explore Auroville, the City of Dawn, where people from various
            nationalities, beliefs, and traditions coexist harmoniously.
            <br />
            <b>Next Stop:</b> Mahabalipuram
            <br />
            Your journey continues with a pleasant 2-hour drive, covering 100
            kilometers.
          </div>
        ),
        image: stpondicharryitinary2,
      },
      {
        day: "Day 13-15",
        city: "Mahabalipuram",
        details: (
          <div>
            <br />
            Explore the remarkable stone sculptures and monolithic monuments,
            showcasing the splendor of Pallava architecture. Admire the Five
            Rathas, the colossal rock bas-relief known as Arjuna's Penance,
            Mahisasura Madapam, and the iconic Shore Temple, set against the
            backdrop of the sea and surrounded by lush gardens.
            <br />
            <b>Next Stop:</b> Mumbai
            <br />
            Your journey continues with a swift 2-hour flight to the vibrant
            city of Mumbai.
          </div>
        ),
        image: stmahabalipuramitinary2,
      },
      {
        day: "Day 15-16",
        city: "Mumbai",
        details: (
          <div>
            <br />
            <br />
            Immerse yourself in the vibrant energy of Mumbai as you explore the
            historic Elephanta Caves and venture into Dharavi, the iconic
            setting for the Oscar-winning film "Slumdog Millionaire".
          </div>
        ),
        image: stmumbaiitinary2,
      },
      {
        day: "Day 17",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            <br />
            As your journey ends, feeling refreshed and prepared for your trip
            With a sense of relaxation and cherished memories, your dedicated
            driver will ensure a smooth journey to the airport for your homeward
            flight, concluding this unforgettable adventure.
            <br />
            <b>Safe travels!</b>
          </div>
        ),
        image: departure2,
      },
    ],
    map: stmap2,
    highlights: [
      "Cochin’s Culture Unveiled",
      "Alleppey’s Backwater Bliss",
      "Thekkady’s Wild Wonders",
      "Munnar’s Tea Paradise",
      "Madurai’s Temple Treasures",
      "Thanjavur’s Cultural Gem",
      "Pondicherry’s Serene Fusion",
      "Mahabalipuram’s Ancient Marvels",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/The-Royal-Heritage-Trail",
        name: "The Royal Heritage Trail",
        image: gt2,
        description:
          "An 8-day journey through Delhi, Mandawa, Jaipur, and Agra, immersing you in a captivating blend of history, timeless heritage, regal splendor, and historical grandeur.",
      },
      {
        id: "/tour/A-Journey-Through-Heritage-&-Wildlife",
        name: "A Journey Through Heritage & Wildlife",
        image: rt4,
        description:
          "Embark on a 13-day odyssey through heritage and wildlife on the 'A Journey Through Heritage & Wildlife' tour, from the captivating blend of history in Delhi to the wildlife wonders of Ranthambore, all amidst timeless heritage, desert charm, and regal splendor.",
      },
      {
        id: "/tour/Southern-Splendors",
        name: "Southern Splendors: Culture & Nature Trail",
        image: st4,
        description:
          "Embark on a 19-day 'Southern Splendors: Culture & Nature Trail' from Mumbai's vibrant dreams to the rich cultural tapestry of Hampi, experiencing history, coastal delights, nature's majesty, and regal splendor in between.",
      },
    ],
    Affordable: 1810,
    Semi: 2320,
    Luxury: 3420,
  },
  {
    id: "Heritage-Odyssey",
    title: "Heritage Odyssey: North to South India Tour",
    one: "Explore India's rich diversity, historic landmarks, serene backwaters and lush tea plantations.",
    places:
      "Delhi-Jaipur-Agra-Ajanta & Elora-Mumbai-Cochin-Alleppey-Thekkady-Munnar",
    image: st3,
    days: "16",
    heading: {
      Delhi: "A captivating blend of history and modern flair",
      Jaipur: "Where the past paints the present",
      Agra: "Where Romance Meets Royalty",
      Ajanta: "An ancient canvas carved in rock",
      Mumbai: "A vibrant mosaic of life and dreams",
      Cochin: "Where History and Commerce Unite",
      Alleppey: "Coastal Delights Await",
      Thekkady: "Where Nature Unveils its Majesty",
      Munnar: "South India's Alpine Haven",
    },
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
      Ajanta:
        "The mystical allure of Ajanta & Ellora Caves is UNESCO World Heritage Site. These ancient rock-cut caves in India's heartland are a testament to unparalleled craftsmanship and devotion, showcasing intricate sculptures and breathtaking frescoes that date back to as early as the 2nd century BC. Embark on a captivating journey through time and artistry with us.",
      Mumbai:
        "Mumbai, often referred to as Bombay, is the heartbeat of India's urban landscape. This bustling metropolis on the western coast is a captivating fusion of diverse cultures and pulsating energy. With its iconic landmarks like the Gateway of India, a rich cosmopolitan lifestyle, and an endless array of experiences, Mumbai is a city that never ceases to amaze, inviting you to explore its vibrant soul.",
      Cochin:
        "Cochin, often hailed as the Queen of the Arabian Sea, is a bustling commercial hub steeped in a rich trading legacy spanning over six centuries. Here, the echoes of Arabian, Dutch, Phoenician, Portuguese, Chinese, and British influences are woven into the city's vibrant tapestry. As the financial, commercial, and industrial heart of Kerala, Cochin invites you to immerse yourself in its captivating blend of history and commerce.",
      Alleppey: (
        <div>
          In the coastal town of Alleppey, a world of unforgettable experiences
          unfolds. From idyllic houseboat stays to basking on pristine beaches,
          the options for creating lasting memories are abundant, especially for
          first-time visitors.
          <br />
          Nestled amid lush greenery, where coconut trees gracefully bow over
          the serene waters, Alleppey's backwaters offer a unique journey. These
          tranquil channels are navigated by a variety of traditional boats,
          most notably the iconic Kerala River boats, promising an enchanting
          exploration like no other.
        </div>
      ),
      Thekkady:
        "In Thekkady, prepare for a sensory feast that includes the majestic presence of elephants, fragrant spice plantations, and picturesque hills. The Periyar forest, a true national treasure, cradles within it the most vibrant array of flora and fauna found anywhere on Earth, including captivating migratory species that grace these lands. Your journey here promises an immersive encounter with the awe-inspiring wonders of nature.",
      Munnar: (
        <div>
          Munnar, the cherished hill-station of Kerala, rests gracefully at an
          elevation of 1600 meters amidst the Western Ghats. Often referred to
          as the "Kashmir of South India", it's a place of sheer natural
          splendor.
          <br />
          Perched along the banks of three pristine rivers - Madupetti,
          Nallathanni, and Periavaru, Munnar also boasts of natural bird
          sanctuaries and vast tea plantations. Prepare to be enchanted by the
          alpine wonders of this picturesque destination.
        </div>
      ),
    },
    cityImages: {
      Delhi: stdelhi3,
      Jaipur: stjaipur3,
      Agra: stagra3,
      Ajanta: stajanta3,
      Mumbai: stmumbai3,
      Cochin: stcochin3,
      Alleppey: stelleppey3,
      Thekkady: stthekkady3,
      Munnar: stmunnar3,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        detailsaboveimage: `Touch down at Delhi Airport, where we'll greet you with a sign and take you to your well-prepared hotel for a comfortable stay. `,
        details: (
          <div>
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality – from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels. <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Jaipur
            <br />
            Just a 5-hour drive (270 km) from Delhi.
          </div>
        ),
        image: stdelhiittanary3,
      },
      {
        day: "Day 3-5",
        city: "Jaipur",
        detailsaboveimage: `Experience Jaipur, Rajasthan's enchanting capital founded in 1727 by Maharaja Sawai Jai Singh II. Marvel at the architectural splendor of Hawa Mahal, City Palace, and the planned layout based on Vastu Shastra.`,
        details: (
          <div>
            <br />
            This Pink City seamlessly melds its royal past with modern vibrancy.
            Explore historic treasures like City Palace, Jal Mahal, Hawa Mahal,
            and the 17th-century Jantar Mantar Observatory. Wander through the
            captivating Old Walled City, Amber Fort, and Birla Temple. Indulge
            in the local markets of Johri Bazar and Choti Chopper, renowned for
            unique jewelry. Jaipur – where history, culture, and markets
            intertwine for an unforgettable journey. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Agra
            <br />
            Just a 4-hour drive (230 km) from Jaipur.
          </div>
        ),
        image: stjaipurittanary3,
      },
      {
        day: "Day 5-6",
        city: "Agra",
        detailsaboveimage: `Exploring Agra means stepping into a world of romance and history.
        Behold the Taj Mahal's sunset view from the tranquil Yamuna
        Riverbanks. If time allows, delve into the Red Fort, either on the
        same day or the following.`,
        details: (
          <div>
            <br />
            <br />
            Witness the Taj Mahal's enchantment at sunrise and sunset; this
            Wonder of the World unveils its magic in unique ways. Keep in mind
            it's closed on Fridays. Shape your sightseeing plan accordingly.{" "}
            <br />
            More treasures await: Fatehpur Sikri, Akbar's Tomb, Jama Masjid, and
            Itimad-ud-Daula’s Tomb. Agra, the realm of the iconic Taj Mahal,
            intricately weaves the Mughal legacy. From the majestic Agra Fort to
            the intricate allure of Fatehpur Sikri, the city's rich heritage
            unfolds. Agra's timeless allure encapsulates India's imperial
            history. <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Aurangabad
            <br />A convenient 2-hour flight.
          </div>
        ),
        image: stagraitinary3,
      },
      {
        day: "Day 6-7",
        city: "Ajanta & Ellora",
        details: (
          <div>
            <br />
            <br />
            Explore UNESCO-listed Ajanta and Ellora Caves, a World Heritage
            treasure. Visit Bibi-Ka- Maqbara, the "Taj of the Deccan", and savor
            Mughal-inspired cuisine like Pulao and Biryani.
            <b>Next Stop:</b> Mumbai
            <br />
            Just a 1-hour flight from Aurangabad.
          </div>
        ),
        image: stajantaitinary3,
      },
      {
        day: "Day 7-9",
        city: "Mumbai",
        details: (
          <div>
            <br />
            Immerse yourself in the boundless energy of Mumbai, an impressive
            metropolis that never sleeps. Explore the awe-inspiring Elephanta
            Caves, where history whispers through the intricate rock-cut
            temples.
            <br />
            Venture into Dharavi, an area famed for its role in Bollywood
            movies, including the Oscar-winning "Slumdog Millionaire". Here,
            you'll witness the heart and soul of Mumbai's vibrant cinematic
            culture.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Cochin
            <br />A convenient 2-hour flight.
          </div>
        ),
        image: stmumbaiittanary3,
      },
      {
        day: "Day 9-10",
        city: "Cochin",
        details: (
          <div>
            <br />
            We'll be there to greet you at Cochin Airport with a personalized
            sign, ensuring a seamless arrival experience. For those arriving
            early, Cochin exploration tailored to your interests awaits.
            <br />
            <b>Next Stop:</b> Alleppey
            <br />
            Your journey continues with a scenic 1-hour and 20-minute drive,
            covering 55 kilometers.
          </div>
        ),
        image: stcochinittanary3,
      },
      {
        day: "Day 10-11",
        city: "Alleppey",
        details: (
          <div>
            <br />
            Embark on a captivating boat ride along Vembanad Lake, offering an
            intimate glimpse into Keralan Backwater life. Observe fishing
            fishermen, playful children, and women immersed in their daily
            chores. Lose yourself in the enchanting surroundings, savoring the
            magic of the place.
            <br />
            <b>Next Stop:</b> Thekkady
            <br />
            Your journey continues with a scenic 3-hour and 30-minute drive.
          </div>
        ),
        image: stelleppeyittnanary3,
      },
      {
        day: "Day 11-12",
        city: "Thekkady",
        details: (
          <div>
            <br />
            Embark on a thrilling boating adventure on Lake Periyar, where
            you'll encounter captivating wildlife and explore one of India's
            unique animal sanctuaries. Discover fragrant spice plantations
            teeming with cardamom, cinnamon, pepper, and coffee. Delight in the
            tea estates and shop at the local market for exquisite natural
            seasonings to bring home as souvenirs.
            <br />
            <b>Next Stop:</b> Munnar
            <br />
            Your journey continues with a scenic 3-hour drive, spanning 90
            kilometers.
          </div>
        ),
        image: stthekkadyittanary3,
      },
      {
        day: "Day 12-14",
        city: "Munnar",
        details: (
          <div>
            <br />
            <br />
            Explore one of the world's highest tea-growing regions in Munnar.
            Visit Eravikulam National Park, the sanctuary for the endangered
            Nilgiri Thar (Ibex). Discover the iconic Mattupetty Dam, the Indo
            Swiss Dairy farm, and the serene Kundala Lake.
            <br />
          </div>
        ),
        image: stmunnarittnanary3,
      },
      {
        day: "Day 15",
        city: "Delhi",
        details: (
          <div>
            <br />
            You have the choice to unwind at your hotel, preparing for your
            departure the next day. Alternatively, if you're feeling energetic
            and wish to explore, Delhi offers a variety of markets to visit:
            Chandni Chowk Market, the vibrant Kinari Bazaar (Wedding Market),
            the bustling Textile Market (Wholesale Market), the Book Market, and
            the fascinating Spice Market, known for both ground and whole
            spices.
            <br />
            If your interests lean towards art and architecture, you might
            consider visiting the Fateh Puri Mosque, Sis Ganj Gurudwara, Jain
            Temple, and the Shiva Temple. The choice is yours, tailored to your
            pace and preferences.
          </div>
        ),
        image: delhideparture,
      },
      {
        day: "Day 16",
        city: "Farewell and Departure",
        details: (
          <div>
            <br />
            <br />
            With a sense of relaxation and readiness to journey back home, your
            dedicated driver will ensure you reach the airport promptly, in
            accordance with your flight schedule.
            <br />
            <b>Safe travel!</b>
          </div>
        ),
        image: departure2,
      },
    ],
    map: stmap3,
    highlights: [
      "Royal heritage in the Pink City",
      "Love story at the Taj Mahal",
      "Ancient artistry in Ajanta-Ellora’s stone",
      "India's vibrant coastal gem in Mumbai",
      "Elephants grace Thekkady's lush landscapes",
      "Nature's alpine paradise in Munnar",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/Rajasthani-Heritage-Retreat",
        name: "Rajasthani Heritage Retreat",
        image: rt1,
        description:
          "Indulge in a 16-day 'Rajasthani Heritage Retreat' through Delhi's history, timeless beauty in Mandawa, desert tales in Jaisalmer, and a journey of romance and regal splendor in Udaipur, all while exploring the rich heritage of Rajasthan.",
      },
      {
        id: "/tour/Cultural-Tapestry-Expedition",
        name: "Cultural Tapestry Expedition",
        image: gt4,
        description:
          "Embark on a 9-day 'Cultural Tapestry Expedition' weaving through the captivating history and vitality of Delhi, the timeless heritage of Mandawa, desert charm and sacred serenity in Pushkar, regal splendor in Jaipur, and the historical grandeur of Agra.",
      },
      {
        id: "/tour/Southern-Splendors",
        name: "Southern Splendors: Culture & Nature Trail",
        image: st4,
        description:
          "Embark on a 19-day 'Southern Splendors: Culture & Nature Trail' from Mumbai's vibrant dreams to the rich cultural tapestry of Hampi, experiencing history, coastal delights, nature's majesty, and regal splendor in between.",
      },
    ],
    Affordable: 2212,
    Semi: 2710,
    Luxury: 4312,
  },
  {
    id: "Southern-Splendors",
    title: "Southern Splendors: Culture & Nature Trail Tour",
    one: "A beautiful tropical place that will amaze you with its beauty and relaxing atmosphere.",
    places: "Mumbai-Cochin-Alleppey-Thekkady-Munnar-Ooty-Mysore-Hassan-Hampi",
    image: st4,
    days: "19",
    heading: {
      Mumbai: "A vibrant mosaic of life and dreams",
      Cochin: "Where History and Commerce Unite",
      Alleppey: "Coastal Delights Await",
      Thekkady: "Where Nature Unveils its Majesty",
      Munnar: "South India's Alpine Haven",
      Ooty: "Scenic Hill Station Escape",
      Mysore: "Regal Splendor, Cultural Abundance",
      Hassan: "Cultural Richness, Hoysala Heritage",
      Hampi: "UNESCO Heritage, Ancient Treasures",
    },
    description: {
      Mumbai:
        "Mumbai, often referred to as Bombay, is the heartbeat of India's urban landscape. This bustling metropolis on the western coast is a captivating fusion of diverse cultures and pulsating energy. With its iconic landmarks like the Gateway of India, a rich cosmopolitan lifestyle, and an endless array of experiences, Mumbai is a city that never ceases to amaze, inviting you to explore its vibrant soul.",
      Cochin:
        "Cochin, often hailed as the Queen of the Arabian Sea, is a bustling commercial hub steeped in a rich trading legacy spanning over six centuries. Here, the echoes of Arabian, Dutch, Phoenician, Portuguese, Chinese, and British influences are woven into the city's vibrant tapestry. As the financial, commercial, and industrial heart of Kerala, Cochin invites you to immerse yourself in its captivating blend of history and commerce.",
      Alleppey: (
        <div>
          In the coastal town of Alleppey, a world of unforgettable experiences
          unfolds. From idyllic houseboat stays to basking on pristine beaches,
          the options for creating lasting memories are abundant, especially for
          first-time visitors.
          <br />
          Nestled amid lush greenery, where coconut trees gracefully bow over
          the serene waters, Alleppey's backwaters offer a unique journey. These
          tranquil channels are navigated by a variety of traditional boats,
          most notably the iconic Kerala River boats, promising an enchanting
          exploration like no other.
        </div>
      ),
      Thekkady:
        "In Thekkady, prepare for a sensory feast that includes the majestic presence of elephants, fragrant spice plantations, and picturesque hills. The Periyar forest, a true national treasure, cradles within it the most vibrant array of flora and fauna found anywhere on Earth, including captivating migratory species that grace these lands. Your journey here promises an immersive encounter with the awe-inspiring wonders of nature.",
      Munnar: (
        <div>
          Munnar, the cherished hill-station of Kerala, rests gracefully at an
          elevation of 1600 meters amidst the Western Ghats. Often referred to
          as the "Kashmir of South India", it's a place of sheer natural
          splendor.
          <br />
          Perched along the banks of three pristine rivers - Madupetti,
          Nallathanni, and Periavaru, Munnar also boasts of natural bird
          sanctuaries and vast tea plantations. Prepare to be enchanted by the
          alpine wonders of this picturesque destination.
        </div>
      ),
      Ooty: "The Queen of Hill Stations, offers a blend of Indian vibrancy, Hindu temples, and breathtaking gardens. Enjoy a scenic ride on the iconic miniature train through romantic landscapes.",
      Mysore:
        "A city steeped in history, was the capital of the Kingdom of Mysore for centuries. Explore its opulent palace, adorned with carved wooden doors and mosaic floors. Discover the city's rich cultural heritage, from Mysore Dasara to delectable dishes like Mysore Pak and Mysore Masala Dosa. Don't miss the fragrant Mysore Sandalwood soap and the famous Mysore Mallige jasmine flowers.",
      Hassan:
        "The heart of Karnataka, embraces Hoysala architecture, Carnatic music, vibrant flowers, and delectable cuisine. Discover the influence of 19th-century French missionaries on the region's architecture and immerse yourself in the enchanting magic of Hassan.",
      Hampi:
        "A UNESCO World Heritage Site in Karnataka, beckons with its historical treasures. Explore 500 ancient monuments, temples, markets, and remnants of the Vijayanagar Empire in this open-air museum, offering over 100 locations to uncover the wonders of Indian history.",
    },
    cityImages: {
      Mumbai: stmumbai4,
      Cochin: stcochin4,
      Alleppey: stalleppey4,
      Thekkady: stthekkady4,
      Munnar: stmunnar4,
      Ooty: stooty4,
      Mysore: stmysore4,
      Hassan: sthassan4,
      Hampi: sthampy4,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Mumbai",
        details: (
          <div>
            <br />
            Immerse yourself in the boundless energy of Mumbai, an impressive
            metropolis that never sleeps. Explore the awe-inspiring Elephanta
            Caves, where history whispers through the intricate rock-cut
            temples.
            <br />
            Venture into Dharavi, an area famed for its role in Bollywood
            movies, including the Oscar-winning "Slumdog Millionaire". Here,
            you'll witness the heart and soul of Mumbai's vibrant cinematic
            culture.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Cochin
            <br />A mere 2 hour flight from Mumbai
          </div>
        ),
        image: stmumbaiitinary4,
      },
      {
        day: "Day 3-5",
        city: "Cochin",
        details: (
          <div>
            <br />
            Upon arrival at Cochin Airport, our team will warmly welcome you
            with a personalized sign, ensuring a smooth transition to your
            journey.
            <br />
            For those with early arrivals, the option to explore Cochin awaits,
            tailored to your preferences and schedule. We'll curate your visit
            to match your interests, making the most of your time.
            <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next Stop:</b> Alleppey
            <br />
            Your journey continues with a scenic 1-hour and 20-minute drive,
            covering 55 kilometers, as you set your sights on the tranquil
            beauty of Alleppey.
          </div>
        ),
        image: stcochinitinary4,
      },
      {
        day: "Day 5-6",
        city: "Alleppey",
        details: (
          <div>
            <br />
            Embark on a captivating boat ride along Vembanad Lake, providing an
            intimate glimpse into the enchanting life of Kerala's Backwaters. As
            you traverse this narrow strip of land, you'll witness the
            livelihoods of fishing communities, children gleefully swimming, and
            women immersed in their daily routines.
            <br />
            Feel free to explore any intriguing spot you encounter along the
            way, immersing yourself in the genuine magic of these surroundings.
            <br />
            <b>Next Stop:</b> Thekkady
            <br />
            Your journey continues with a scenic 3-hour and 30-minute drive,
            spanning 140 kilometers, leading you to the pristine allure of
            Thekkady.
          </div>
        ),
        image: stalleppeyitinary4,
      },
      {
        day: "Day 6-8",
        city: "Thekkady",
        details: (
          <div>
            <br />
            Embark on a thrilling boat expedition on Lake Periyar, where you'll
            have the chance to witness captivating wildlife and explore one of
            India's exceptional animal sanctuaries. Immerse yourself in the
            untamed beauty of this unique destination.
            <br />
            Venture into the fragrant spice plantations, where cardamom,
            cinnamon, pepper, and coffee thrive. Don't forget to savor the
            flavors of the tea estates that abound in the region. For a taste of
            Kerala to take home, explore the local market and discover an array
            of natural seasonings.
            <br />
            <b>Next Stop:</b> Munnar
            <br />
            Your journey continues with a scenic 3-hour drive, covering 90
            kilometers, leading you to the breathtaking landscapes of Munnar.
          </div>
        ),
        image: stthekkadyitinary4,
      },
      {
        day: "Day 8-10",
        city: "Munnar",
        details: (
          <div>
            <br />
            Explore one of the world's highest tea-growing regions in Munnar.
            Visit Eravikulam National Park, the sanctuary for the endangered
            Nilgiri Thar (Ibex). Discover the iconic Mattupetty Dam, the Indo
            Swiss Dairy farm, and the serene Kundala Lake.
            <br />
            <b>Next Stop:</b> Ooty
            <br />
            Bid adieu to the lush landscapes of Munnar and prepare for a scenic
            6 hour 45 min drive (250km) to Ooty.
          </div>
        ),
        image: stmunnaritinary4,
      },
      {
        day: "Day 10-12",
        city: "Ooty",
        details: (
          <div>
            <br />
            Explore the Nilgiris' tribal heritage, visit an intriguing museum
            showcasing Nilgiri and Tamil Nadu tribal cultures, stroll through
            lush 22-hectare gardens dating back to 1848, and discover the
            region's rich natural flora. Don't miss the chance to spot a Toda
            mund (village) and a fossilized tree trunk believed to be 20 million
            years old.
            <br />
            <b>Next Stop:</b> Mysore
            <br />A mere 3h drive(125km)
          </div>
        ),
        image: stootyitinary4,
      },
      {
        day: "Day 12-14",
        city: "Mysore",
        details: (
          <div>
            <br />
            Explore the historical city of Mysore, located in the Chamundi Hills
            of Karnataka, and discover its rich heritage. Visit iconic landmarks
            like Mysore Palace, Chamundi Hill, Brindavan Gardens, Sri
            Chamundiswari Temple, and St. Philomena's Church. Don't forget to
            shop for Sandalwood handcrafts, spices, and incense sticks.
            <br />
            <b>Next Stop:</b> Hassan
            <br />
            Distance from Mysore to Hassan: 2 hours and 30 minutes (125 km).
          </div>
        ),
        image: stmysoreitinary4,
      },
      {
        day: "Day 14-15",
        city: "Hassan",
        details: (
          <div>
            <br />
            Explore the ancient city of Hassan, located in Karnataka, known for
            its rich heritage and centuries-old monuments from the Hoysala
            Period (11th to 13th Century). Visit the Gorur Dam on the River
            Hemavathi, Hoysala Temples of Nageshwara and Channakeshava, and be
            mesmerized by the partly submerged Shetihalli Church in the
            Hemavathi River.
            <br />
            <b>Next Stop:</b> Hampi
            <br />A mere 6 hours (330 km) drive from Hassan.
          </div>
        ),
        image: sthassanitinary4,
      },
      {
        day: "Day 15-18",
        city: "Hampi",
        details: (
          <div>
            <br />
            Experience the enchanting world of Hampi, a carnival of intricate
            carvings and an otherworldly landscape filled with captivating
            ruins. Explore sites like the Zenana Enclosure, wander through lush
            palm groves, banana plantations, and paddy fields.
            <br />
            Visit renowned Hindu Temples: Vittala, Virupashka, and Achyutaraya,
            and take a leisurely stroll in Sule Bazaar.
            <br />
            <b>Next Stop:</b> Mumbai
            <br />A convenient 2 hours (Flight) from Hampi.
          </div>
        ),
        image: sthampyitinary4,
      },
      {
        day: "Day 18",
        city: "Mumbai",
        details: (
          <div>
            <br />
            <br />
            Immerse yourself in the vibrant energy of Mumbai as you explore the
            historic Elephanta Caves and venture into Dharavi, the iconic
            setting for the Oscar-winning film <b>"Slumdog Millionaire"</b>.
          </div>
        ),
        image: stmumbaiitinary4,
      },
      {
        day: "Day 19",
        city: "Departure",
        details: (
          <div>
            <br />
            <br />
            With a sense of relaxation and cherished memories, your dedicated
            driver will ensure a smooth journey to the airport for your homeward
            flight, concluding this unforgettable adventure.
            <br />
            <b>Safe travels!</b>
          </div>
        ),
        image: departure2,
      },
    ],
    map: stmap4,
    highlights: [
      "Cochin: A Tapestry of Time and Trade",
      "Serenity on Backwaters",
      "Paradise in the Ghats",
      "Romance Amidst Hills and Gardens",
      "Royalty and Rich Flavors",
      "Hoysala Heritage Journey",
      "UNESCO Marvels of History",
    ],
    inclusions: [
      { icon: faHotel, text: "6 nights, chosen hotels" },
      { icon: faCar, text: "Local driver, guide" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faMoneyBillAlt, text: "All costs covered" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faUtensilSpoon, text: "Cooking class, dinner" },
      { icon: faPlaneDeparture, text: "Airport transfer" },
      { icon: rickshaw, text: "Delhi rickshaw" },
    ],
    recommendedTours: [
      {
        id: "/tour/Expedition-Through-India",
        name: "Expedition Through India",
        image: gt6,
        description:
          "Embark on a 15-day 'Expedition Through India,' from Mumbai's history and modern flair to the ethereal Taj Mahal in Agra, exploring a timeless tapestry of history and modernity in Delhi and more, with a blend of past and present in Hampi and Ajanta & Ellora.",
      },
      {
        id: "/tour/Rajasthani-Heritage-Retreat",
        name: "Rajasthani Heritage Retreat",
        image: rt1,
        description:
          "Indulge in a 16-day 'Rajasthani Heritage Retreat' through Delhi's history, timeless beauty in Mandawa, desert tales in Jaisalmer, and a journey of romance and regal splendor in Udaipur, all while exploring the rich heritage of Rajasthan.",
      },
      {
        id: "/tour/Coastal-Treasures-&-Mountain-Magic",
        name: "Coastal Treasures & Mountain Magic",
        image: st1,
        description:
          "Experience a 10-day journey of 'Coastal Treasures & Mountain Magic' from Cochin's historical charm to the lush greenery of Munnar, all while enjoying coastal delights, the majesty of nature in Thekkady, and the vibrant mosaic of life and dreams in Mumbai.",
      },
    ],
    Affordable: 2145,
    Semi: 2650,
    Luxury: 4025,
  },
];
