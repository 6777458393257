import React from "react";
import Navbar from "../components/navbar";

function Visarules() {
  

  return (
    <div>
      <div className="bg-palette-color-4 relative sticky top-0">
        <Navbar />
      </div>
      <h1 className="mt-6 mb-8 text-4xl leading-tight text-center text-palette-color-18 font-palette-font-1">
        Visa Rules for India
      </h1>

      <h2 className="text-center font-palette-font-2">
        Visa and Permits in India
      </h2>
      <div className="flex flex-col items-center justify-center">
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          Visa serves as permission for individuals to stay in a country for a
          specific period and purpose, typically stamped in their passport. All
          travelers to India, except for Indian and Nepali citizens traveling
          between the two countries, must obtain a visa.
        </p>

        <h2 className="mt-10 text-center font-palette-font-2">
          Visa Durations
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          Tourist visas in India are usually issued for three to six months,
          allowing multiple entries, which is useful for side trips to
          neighboring countries like Nepal. Other options include one-year
          student visas, journalist visas, business visas, and five-year visas
          for non-resident Indians. When applying for a visa, ensure that your
          passport remains valid for at least six months beyond your intended
          return date.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          Obtaining a Visa
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          The visa application process is more efficient when done through the
          Indian embassy in your home country. You&#39;ll need to complete an
          application form and provide your current passport, along with at
          least two passport-sized photographs. Visa applications can be sent
          via mail with payment in the form of money orders. Cash or checks are
          typically not accepted. When applying in person, consulates and
          embassies often accept cash, but money orders are the preferred
          option. The visa requirements, costs, and processing times can vary
          from country to country.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          Special Permits
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          Certain regions in India require special permits in addition to a
          regular Indian visa. These permits can be issued by Indian High
          Commissions and Indian Embassies abroad, the Ministry of Home Affairs
          in Delhi, or Foreigner&#39;s Registration Offices (FRROs) in Indian
          cities.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          These regions include:
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          1. Northeast India: The seven sister states (excluding Assam,
          Meghalaya, and Tripura) require special permits due to tribal
          insurgencies and concerns related to potential conflicts with China. A
          group of at least four people must travel together, sponsored by a
          government-approved travel agency. These permits generally cost Rs.
          300-400 and are valid for 15 days, renewable for an additional 15 days
          at the Foreigner&#39;s Registration Offices in state capitals. The
          processing time varies from 2 days to 2 months depending on the state.
        </p>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          2. Sikkim: Due to its proximity to China, Sikkim is treated as a
          military buffer, and foreign travelers require a permit. The maximum
          stay duration cannot exceed 15 days per year. Permits are free and
          available in major Indian cities. They can be extended in special
          circumstances, but only for 3-5 days and on a limited basis. For North
          Sikkim, an inner line permit is necessary, which is available through
          tour companies for groups of four or more. A guide must accompany the
          group, with a minimum daily charge of US$30-35, including the guide.
          These permits are issued in a day but are obtainable only in Gangtok.
        </p>

        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          3. Andaman and Nicobar Islands: The Nicobar Islands are off-limits,
          and obtaining a permit for the Andaman Islands from the Ministry of
          Home Affairs or Indian Embassies abroad can be time-consuming.
          Arrivals by air can obtain permits upon arrival in Port Blair.
        </p>

        <p className="text-justify font-palette-font-2 w-[50%] mt-10 mb-10">
          4. Lakshadweep: Only Bangaram Island is open to foreigners. You can
          obtain a free permit through the Liaison Officer of Lakshadweep or at
          select hotels in Cochin. You&#39;ll need four passport-sized
          photographs, and the permit can be issued within a day or two.
        </p>
       
      </div>

      {/* <Example/> */}
    </div>
  );
}

export default Visarules;
