import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./blogSlider.scss";
import { Link } from "react-router-dom";

const db = getFirestore();

function YourComponent() {
  const [blogData, setBlogData] = useState([]);

  const settings = {
    slidesToShow: 5,
    autoplay: true,
    slidesToScroll: 1,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Fetch blog data from Firestore and update the state
  const fetchBlogData = async () => {
    try {
      const blogsCollectionRef = collection(db, "blog");
      const querySnapshot = await getDocs(blogsCollectionRef);
      const blogData = [];
      querySnapshot.forEach((doc) => {
        blogData.push({
          id: doc.id,
          ...doc.data().blog,
          imageUrls: doc.data().imageUrls,
        });
      });
      setBlogData(blogData);
      console.log(blogData);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };
  useEffect(() => {
    fetchBlogData();
  }, []);

  return (
    <div className="page">
      <Slider className="card-slider" {...settings}>
        {blogData.map((blog, index) => (
          <div className="card-wrapper" key={index}>
            <Link to={`/blog/${blog.id}`}>
              <article
                style={{
                  backgroundImage: `url(${
                    blog.imageUrls && blog.imageUrls.length > 0
                      ? blog.imageUrls[0]
                      : ""
                  })`,
                }}
                className="card bg-cover bg-center bg-no-repeat flex flex-col items-start justify-end rounded-lg overflow-hidden relative z-10 h-96 shadow-lg"
              >
                <div className="content card-overlay ">
                  <h3 className="title text-xl text-palette-color-3 font-palette-font-1 font-semibold transform -translate-y-2 transition-transform duration-300 pb-2">
                    {blog.title}
                  </h3>
                  <p className="subtitle text-palette-color-3 font-palette-font-2 transform translate-y-0 transition-transform duration-300">
                    {blog.subtitle}
                  </p>
                </div>
              </article>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default YourComponent;
