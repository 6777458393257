// Login.js
import React, { useState } from "react";
import { auth, signInWithEmailAndPassword } from "../firebase";
import { useNavigate } from "react-router-dom";
import Navbar from "./navbar";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Initialize navigate for navigation

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert("Welcome to the Admin Panel");
      navigate("/protected")
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-gray-800 ">
      <Navbar/>
      <div className="h-screen flex justify-center items-center">
      <div className="w-full max-w-md bg-white rounded-lg p-6 shadow-md">
        <div className="text-center">
          <i className="fa fa-key text-5xl bg-gradient-to-r text-transparent bg-clip-text from-palette-color-4 to-palette-color-15"></i>
          <h1 className="text-3xl font-bold text-gray-800 mt-4">ADMIN PANEL</h1>
        </div>
      <form onSubmit={handleLogin} className="mt-6">
      <div className="mb-6">
            <label htmlFor="username" className="block text-sm font-bold text-gray-700" >
              EMAIL
            </label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-2 p-2 w-full bg-gray-800 border-b-2 border-palette-color-4 text-gray-200 focus:outline-none focus:border-palette-color-4"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="password" className="block text-sm font-bold text-gray-700">
              PASSWORD
            </label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-2 p-2 w-full bg-gray-800 border-b-2 border-palette-color-4 text-gray-200 focus:outline-none focus:border-palette-color-4"
            />
          </div>
          <div className="flex justify-center">
            
        <button type="submit" className="px-4 py-2 bg-palette-color-4 text-white font-bold rounded-md hover:bg-palette-color-14">Login</button>
          </div>
      </form>
      </div>
      </div>
    </div>
  );
}

export default Login;
