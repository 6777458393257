import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import Blogging from "../assets/blogs.webp";

const db = getFirestore();

function AllBlogs() {
  const [blogs, setBlogs] = useState([]);

  const fetchBlogs = async () => {
    try {
      const blogsCollectionRef = collection(db, "blog");
      const querySnapshot = await getDocs(blogsCollectionRef);
      const blogList = [];
      querySnapshot.forEach((doc) => {
        blogList.push({
          id: doc.id,
          ...doc.data().blog,
          imageUrls: doc.data().imageUrls,
        });
      });
      setBlogs(blogList);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div>
      <Navbar />
      <section
        className="relative h-screen overflow-hidden"
        style={{ top: "-57px" }}
      >
        <img
          src={Blogging}
          alt="Blogs"
          className="absolute top-0 left-0 z-0 object-cover w-full h-full"
        />
        <div className="absolute inset-0 z-10 flex items-center justify-center text-white text-center">
          <div>
            <h1 className="mb-6 text-4xl font-extrabold leading-tight md:text-6xl lg:text-7xl text-palette-color-3 font-palette-font-1">
              Explore Our Blogs
            </h1>
          </div>
        </div>
      </section>
        <div className="container mx-auto py-10">
          <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {blogs.map((blog) => (
              <li
                key={blog.id}
                className="bg-white p-4 shadow-md transition-transform transform hover:-translate-y-2"
              >
                <Link to={`/blog/${blog.id}`}>
                  <article className="card bg-cover bg-center bg-no-repeat flex flex-col items-start justify-end rounded-lg overflow-hidden relative z-10 h-96 bg-palette-color-4">
                    <div
                      style={{
                        backgroundImage: `url(${ blog.imageUrls[0]
                        })`,
                      }}
                      className="w-full h-64 bg-center bg-cover"
                    ></div>
                    <h2 className="title text-xl text-palette-color-3 font-palette-font-1 font-semibold my-2">
                      {blog.title}
                    </h2>
                    <Link
                      to={`/blog/${blog.id}`}
                      className="text-palette-color-3 hover:underline"
                    >
                      <p className="subtitle text-palette-color-3 font-palette-font-2 my-2">
                        {blog.subtitle}
                      </p>
                    </Link>
                    <p className="btn text-palette-color-1 font-palette-font-2 p-2">
                      Author: {blog.author}
                    </p>
                  </article>
                </Link>
              </li>
            ))}
          </ul>
        </div>
    </div>
  );
}

export default AllBlogs;
