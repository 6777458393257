import React, { useRef, useEffect, useState } from "react";
import WelcomeToITH from "./about/wlecometoith";
import HowWeWork from "./about/HowWeWork";
import PersonalJourney from "./about/PersonalJourney";
import WhereWere from "./about/WhereWere";
import MandawaKothi from "./about/MandawaKothi";
import Navbar from "../components/navbar";
import Logo from "../assets/Logo.png";
import bg from "../assets/aboutus.jpg";

function About() {
  const welcomeToITHRef = useRef(null);
  const howWeWorkRef = useRef(null);
  const personalJourneyRef = useRef(null);
  const mandawaKothiRef = useRef(null);
  const whereWereRef = useRef(null);

  const [activeSection, setActiveSection] = useState(null);

  const scrollToRef = (ref) => {
    if (ref.current) {
      window.scrollTo({
        top: ref.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY + 200;
    if (
      welcomeToITHRef.current &&
      scrollPosition >= welcomeToITHRef.current.offsetTop &&
      scrollPosition < howWeWorkRef.current.offsetTop
    ) {
      setActiveSection("welcomeToITH");
    } else if (
      howWeWorkRef.current &&
      scrollPosition >= howWeWorkRef.current.offsetTop &&
      scrollPosition < personalJourneyRef.current.offsetTop
    ) {
      setActiveSection("howWeWork");
    } else if (
      personalJourneyRef.current &&
      scrollPosition >= personalJourneyRef.current.offsetTop &&
      scrollPosition < mandawaKothiRef.current.offsetTop
    ) {
      setActiveSection("personalJourney");
    } else if (
      mandawaKothiRef.current &&
      scrollPosition >= mandawaKothiRef.current.offsetTop &&
      scrollPosition < whereWereRef.current.offsetTop
    ) {
      setActiveSection("mandawaKothi");
    } else if (
      whereWereRef.current &&
      scrollPosition >= whereWereRef.current.offsetTop
    ) {
      setActiveSection("whereWere");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavClick = (ref) => {
    scrollToRef(ref);
    switch (ref) {
      case welcomeToITHRef:
        setActiveSection("welcomeToITH");
        break;
      case howWeWorkRef:
        setActiveSection("howWeWork");
        break;
      case personalJourneyRef:
        setActiveSection("personalJourney");
        break;
      case mandawaKothiRef:
        setActiveSection("mandawaKothi");
        break;
      case whereWereRef:
        setActiveSection("whereWere");
        break;
      default:
        setActiveSection(null);
        break;
    }
  };

  const handleHeadingClick = (section) => {
    switch (section) {
      case "welcomeToITH":
        handleNavClick(welcomeToITHRef);
        break;
      case "howWeWork":
        handleNavClick(howWeWorkRef);
        break;
      case "personalJourney":
        handleNavClick(personalJourneyRef);
        break;
      case mandawaKothiRef:
        handleNavClick(mandawaKothiRef);
        break;
      case "whereWere":
        handleNavClick(whereWereRef);
        break;
      default:
        break;
    }
  };

  return (
    <div style={{
      backgroundImage: `url(${bg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundAttachment: "fixed"
    }}>
        <div className="bg-palette-color-4 relative z-10 sticky top-0">
        <Navbar />
      </div>
      <div
        className="flex"
      >
        <div className="hidden z-0 md:flex md:w-1/4 sticky top-0 h-screen overflow-y-auto flex-col justify-center items-center">
          <nav>
            <button
              onClick={() => handleNavClick(welcomeToITHRef)}
              className={`flex items-center justify-center py-2 px-4 hover:text-yellow-500 ${
                activeSection === "welcomeToITH" &&
                "text-palette-color-18 font-bold text-xl"
              }`}
            >
              {activeSection === "welcomeToITH" && (
                <img src={Logo} alt="Logo" className="h-12" />
              )}
              <span>Welcome To ITH</span>
            </button>
            <button
              onClick={() => handleNavClick(howWeWorkRef)}
              className={`flex items-center justify-center py-2 px-4 hover:text-yellow-500 ${
                activeSection === "howWeWork" &&
                "text-palette-color-18 font-bold text-xl"
              }`}
            >
              {activeSection === "howWeWork" && (
                <img src={Logo} alt="Logo" className="h-12" />
              )}
              <span>How we work</span>
            </button>
            <button
              onClick={() => handleNavClick(personalJourneyRef)}
              className={`flex items-center justify-center py-2 px-4 hover:text-yellow-500 ${
                activeSection === "personalJourney" &&
                "text-palette-color-18 font-bold text-xl"
              }`}
            >
              {activeSection === "personalJourney" && (
                <img src={Logo} alt="Logo" className="h-12" />
              )}
              <span>My Personal Journey</span>
            </button>
            <button
              onClick={() => handleNavClick(mandawaKothiRef)}
              className={`flex items-center justify-center py-2 px-4 hover:text-yellow-500 ${
                activeSection === "mandawaKothi" &&
                "text-palette-color-18 font-bold text-xl"
              }`}
            >
              {activeSection === "mandawaKothi" && (
                <img src={Logo} alt="Logo" className="h-12" />
              )}
              <span>Mandawa Kothi</span>
            </button>
            <button
              onClick={() => handleNavClick(whereWereRef)}
              className={`flex items-center justify-center py-2 px-4 hover:text-yellow-500 ${
                activeSection === "whereWere" &&
                "text-palette-color-18 font-bold text-xl"
              }`}
            >
              {activeSection === "whereWere" && (
                <img src={Logo} alt="Logo" className="h-12" />
              )}
              <span>Where we’re</span>
            </button>
          </nav>
        </div>
        <div className="mt-10 border-r border-4 border-palette-color-18"></div>
        <div className="md:w-3/4 p-8 text-justified">
          <div ref={welcomeToITHRef} className="mb-12">
            <WelcomeToITH onClick={() => handleHeadingClick("welcomeToITH")} />
          </div>
          <div ref={howWeWorkRef} className="mb-12">
            <HowWeWork onClick={() => handleHeadingClick("howWeWork")} />
          </div>
          <div ref={personalJourneyRef} className="mb-12">
            <PersonalJourney
              onClick={() => handleHeadingClick("personalJourney")}
            />
          </div>
          <div ref={mandawaKothiRef} className="mb-12">
            <MandawaKothi onClick={() => handleHeadingClick("mandawaKothi")} />
          </div>
          <div ref={whereWereRef} className="mb-12">
            <WhereWere onClick={() => handleHeadingClick("whereWere")} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
