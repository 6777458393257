import holi from "../assets/Festivals/banner/holi.webp";
import diwali from "../assets/Festivals/banner/diwali.webp";
import pushkar from "../assets/Festivals/banner/puskar.webp";
import kumbh from "../assets/Festivals/banner/kumbh.webp";
import rickshaw from "../assets/rickshaw.png";
import Camel from "../assets/camel-silhouette.png";
import {
  faHotel,
  faUtensils,
  faWifi,
  faCar,
  faMoneyBillAlt,
  faMoon,
  faTshirt,
  faPaintBrush,
  faShip,
  faTicketAlt,
  faBottleWater,
  faUtensilSpoon,
} from "@fortawesome/free-solid-svg-icons";
import holiagra from "../assets/Festivals/cities/holi/Agra1.webp";
import holidelhi from "../assets/Festivals/cities/holi/delhi.webp";
import holijaipur from "../assets/Festivals/cities/holi/jaipur.webp";
import holijodhpur from "../assets/Festivals/cities/holi/jodhpur.webp";
import holijaisalmer from "../assets/Festivals/cities/holi/jaisalmer.webp";
import holimandawa from "../assets/Festivals/cities/holi/mandawa.webp";
import holipuskar from "../assets/Festivals/cities/holi/pushkar.webp";
import holiudaipur from "../assets/Festivals/cities/holi/udaipur.webp";
import holibikaner from "../assets/Festivals/cities/holi/bikaner.webp";
import holidelhiitinary from "../assets/Festivals/itinenaries/holi/delhiitinerary.webp";
import holimandawaitinary from "../assets/Festivals/itinenaries/holi/mandawaitinerary.webp";
import holibikaneritinary from "../assets/Festivals/itinenaries/holi/bikaneritnary.webp";
import holijaisalmeritinary from "../assets/Festivals/itinenaries/holi/jaisalmeritinerary.webp";
import holijodhpuritinary from "../assets/Festivals/itinenaries/holi/jodhpuritinerary.webp";
import holipuskaritinary from "../assets/Festivals/itinenaries/holi/pushkaritinerary.webp";
import holijaipuritinary from "../assets/Festivals/itinenaries/holi/jaipuritinerary.webp";
import holiagraitinary from "../assets/Festivals/itinenaries/holi/agraitinerary.webp";
import holiudaipuritinary from "../assets/Festivals/itinenaries/holi/udaipuritinerary.webp";
import departure2 from "../assets/departure 2.webp";
import rtmap1 from "../assets/Tours/Maps/Rajasthan Tours/Rajasthani Heritage Retreat/Rajasthani Heritage Retreat.png";
import diwalimap from "../assets/Festivals/maps/diwali/diwali.webp";
import diwaliagra from "../assets/Festivals/cities/diwali/agra.webp";
import diwalibikaner from "../assets/Festivals/cities/diwali/bikaner.webp";
import diwalibundi from "../assets/Festivals/cities/diwali/bundi.webp";
import diwalidelhi from "../assets/Festivals/cities/diwali/delhi.webp";
import diwalijaipur from "../assets/Festivals/cities/diwali/jaipur.webp";
import diwalijaisalmer from "../assets/Festivals/cities/diwali/jaisalmer.webp";
import diwalijodhpur from "../assets/Festivals/cities/diwali/jodhpur.webp";
import diwalikhajuraho from "../assets/Festivals/cities/diwali/khajuraho.webp";
import diwalimandawa from "../assets/Festivals/cities/diwali/mandawa.webp";
import diwaliorchha from "../assets/Festivals/cities/diwali/Orchha.webp";
import diwaliudaipur from "../assets/Festivals/cities/diwali/udaipur.webp";
import diwalivaranasi from "../assets/Festivals/cities/diwali/varanasi.webp";
import diwaliagraitinerary from "../assets/Festivals/itinenaries/diwali/agraitinerary.webp";
import diwalibikaneritinerary from "../assets/Festivals/itinenaries/diwali/bikaneritinerary.webp";
import diwalibundiitinerary from "../assets/Festivals/itinenaries/diwali/bundiitinerary.webp";
import diwalidelhiitinerary from "../assets/Festivals/itinenaries/diwali/delhiitinerary.webp";
import diwalijaipuritinerary from "../assets/Festivals/itinenaries/diwali/jaipuritinerary.webp";
import diwalijaisalmeritinerary from "../assets/Festivals/itinenaries/diwali/jaisalmeritinerary.webp";
import diwalijodhpuritinerary from "../assets/Festivals/itinenaries/diwali/jodhpuritinerary.webp";
import diwalikhajraoitinerary from "../assets/Festivals/itinenaries/diwali/khajraoittanary.webp";
import diwalimandawaitinerary from "../assets/Festivals/itinenaries/diwali/mandawaittanary.webp";
import diwaliorchaiitinerary from "../assets/Festivals/itinenaries/diwali/orchaitinerary.webp";
import diwaliudaipuritinerary from "../assets/Festivals/itinenaries/diwali/udaipuritinerary.webp";
import diwalivaranasiitinerary from "../assets/Festivals/itinenaries/diwali/varanasiitinerary.webp";
import pushkaragra from "../assets/Festivals/cities/puskar/agra.webp";
import pushkardelhi from "../assets/Festivals/cities/puskar/delhi.webp";
import pushkarjaipur from "../assets/Festivals/cities/puskar/jaipur.webp";
import pushkarjodhpur from "../assets/Festivals/cities/puskar/jodhpur.webp";
import pushkarmandawa from "../assets/Festivals/cities/puskar/Mandawa1.webp";
import pushkarpushkar from "../assets/Festivals/cities/puskar/pushkar.webp";
import pushkarudaipur from "../assets/Festivals/cities/puskar/udaipur.webp";
import pushkaragraitinerary from "../assets/Festivals/itinenaries/pushkar/agraitinerary.webp";
import pushkardelhiitinerary from "../assets/Festivals/itinenaries/pushkar/delhiitinerary.webp";
import pushkarjaipuritinerary from "../assets/Festivals/itinenaries/pushkar/jaipuritinerary.webp";
import pushkarjodhpuritinerary from "../assets/Festivals/itinenaries/pushkar/jodhpuritinerary.webp";
import pushkarmandawaitinerary from "../assets/Festivals/itinenaries/pushkar/mandawaitinerary.webp";
import pushkarpushkaritinerary from "../assets/Festivals/itinenaries/pushkar/pushkaritinerary.webp";
import pushkarudaipuritinerary from "../assets/Festivals/itinenaries/pushkar/udaipuritinerary.webp";
import pushkarmap from "../assets/Festivals/maps/pushkar/Pushkar Camel Carnival.webp";
import kumbhmap from "../assets/Festivals/maps/khumbh/Kumbh Mela.webp";
import kumbhagra from "../assets/Festivals/cities/kumbh/agra.webp";
import kumbhdelhi from "../assets/Festivals/cities/kumbh/delhi.webp";
import kumbhjaipur from "../assets/Festivals/cities/kumbh/jaipur.webp";
import kumbhprayagraj from "../assets/Festivals/cities/kumbh/prayagraj.webp";
import kumbhagraitinerary from "../assets/Festivals/itinenaries/kumbh/agraitinerary.webp";
import kumbhdelhiitinerary from "../assets/Festivals/itinenaries/kumbh/delhiitinerary.webp";
import kumbhjaipuritinerary from "../assets/Festivals/itinenaries/kumbh/jaipuritinerary.webp";
import kumbhprayagrajitinerary from "../assets/Festivals/itinenaries/kumbh/prayagrajitinerary.webp";

export const festivalscomponent = [
  {
    id: "holi",
    title: "Colors of Joy: Holi Festival",
    places:
      "Delhi-Mandawa-Bikaner-Jaisalmer-Jodhpur-Udaipur-Pushkar-Jaipur-Agra",
    image: holi,
    days: "16",
    heading: {
      Delhi: "A captivating blend of history and vitality",
      Mandawa: "A town of timeless heritage and beauty",
      Bikaner: "A desert oasis of history and camels",
      Jaisalmer: "Where desert tales come to life",
      Jodhpur: "Where history meets the azure skyline",
      Udaipur: "Where Romance and Royalty Reign",
      Pushkar: "Where desert charm meets sacred serenity",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
    },
    subtitle:
      "A vibrant burst of colors and unity, celebrating the arrival of spring.",
    paragraph: (
      <div>
        Holi, a colorful and joyous festival in India, traces its roots to Hindu
        mythology. It commemorates the story of Prahlada, a devoted prince, and
        the demoness Holika, who tried to burn him but perished herself due to
        her evil intentions. This legend symbolizes the victory of good over
        evil and the arrival of spring, making Holi a celebration of renewal and
        the triumph of positivity.
        <br />
        <br />
        Holi is typically celebrated over two days in India. The first day,
        known as Holika Dahan or Choti Holi, involves lighting bonfires to
        symbolize the burning of evil. The second day, called Rangwali Holi or
        Dhulandi, is when people play with vibrant colors and engage in
        festivities, spreading joy and unity. It's a time for people to come
        together, play with vibrant colors, and strengthen social bonds, making
        it a significant and beloved cultural festival in India.
        <br />
        <br />
        Rajasthan awakens with the arrival of spring, a timeless land of
        traditions. Explore vibrant streets adorned with hand-embroidered
        textiles and marvel at majestic palaces. Holi, the festival of new
        beginnings, invites you to immerse in boundless joy, shedding the old
        and embracing a colorful transformation. Rajasthan, where every moment
        is a canvas of vibrant emotions.
        <br />
        <br />
        Join us in this extraordinary celebration and let your heart dance with
        the colors of Holi!
        <br />
        <br />
      </div>
    ),
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Mandawa:
        "Mandawa: where walls whisper tales of artistry. This Rajasthani gem invites you to stroll through its streets adorned with stunning frescoes and havelis, each a canvas of history and beauty. Step into a living gallery of Rajasthan's architectural splendor and immerse yourself in the stories etched on its walls.",
      Bikaner:
        "Bikaner, a desert gem with history woven into its fortresses and palaces, promises a mesmerizing journey through Rajasthan's northeast. Known as Camel Country, it's a place where camels gracefully traverse the landscape, adding to its unique allure.",
      Jaisalmer:
        "Jaisalmer, where humans and animals have conquered the desert together for centuries. A camel ride transports you to a bygone era, showcasing the resilience and resourcefulness of its people who thrive amidst challenging conditions, turning nature's offerings into treasures.",
      Jodhpur:
        "Jodhpur, where the grand Mehrangarh Fort looms over the Blue City, a 15th-century marvel of indigo-washed buildings. Experience the city's vibrant spectrum, from majestic elephant processions in the hills to the enchanting labyrinth of its historic core.",
      Udaipur:
        "In the heart of India lies Udaipur, a city that combines love and luxury. Discover majestic palaces, ancient temples, and exquisite Havelis. Explore bustling markets, and savor breathtaking sunsets on Lake Pichola. Join us in exploring the magic of Udaipur.",
      Pushkar:
        "Pushkar, a picturesque Rajasthan town, graced by Thar Desert's sand dunes, a sacred lake, intricate temples, lush gardens, and a plethora of camels. The final quarter of each year paints a vibrant tapestry of colors, festivities, and time-honored customs, with its renowned Camel Fair as a highlight.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
    },
    cityImages: {
      Delhi: holidelhi,
      Mandawa: holimandawa,
      Bikaner: holibikaner,
      Jaisalmer: holijaisalmer,
      Jodhpur: holijodhpur,
      Udaipur: holiudaipur,
      Pushkar: holipuskar,
      Jaipur: holijaipur,
      Agra: holiagra,
    },
    itinerary: [
      {
        day: "Day 1-2",
        city: "Heartfelt Welcome to Delhi",
        details: (
          <div>
            <br />
            Touching down at Delhi Airport, a warm welcome awaits with your name
            on a sign, signaling the start of your adventure. Your hotel
            provides a peaceful haven to rest or dive straight into Delhi's
            enchanting experiences. <br />
            Explore Delhi's history, politics, and spirituality, from India Gate
            to Gandhi's memorial. Discover architectural wonders like the Qutub
            Minar and the Lotus Temple, and soak in the spirit of Old Delhi's
            historic lanes.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Mandawa,
            <br />
            Just 5.5 hours’ drive (260 km) from Delhi
          </div>
        ),
        image: holidelhiitinary,
      },
      {
        day: "Day 2-5",
        city: "Mandawa",
        details: (
          <div>
            <br />
            Experience Holi Like Never Before in Mandawa with us!
            <br />
            <b>Pre-Holi Celebrations</b>
            <br />
            Holi in Mandawa is not just a day!!! It's a month-long celebration!
            A month before the main event, locals gather in temples at night to
            play drums, dance, and sing old traditional Holi songs, setting the
            stage for an unforgettable experience.
            <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>The Two-Day Extravaganza</b>
            <br />
            The excitement builds up with a two-day extravaganza before Holi.
            Men dress as women, adding a touch of whimsy to the celebrations.
            They come together in a lively roadshow, dancing and playing
            "Gidad", a unique musical instrument, in a mesmerizing circle of
            rhythm and laughter.
            <br />
            <br />
            <b>Holi Morning Procession</b>
            <br />
            On the morning of Holi, Mandawa awakens in a riot of colors and
            festivities. A grand procession takes over the streets as people
            play with vibrant colors, dance to lively beats, and revel in the
            spirit of unity and joy.
            <br />
            <br />
            <i>So here it begins!!!</i>
            <br />
            <br />
            When you arrive in Mandawa, a charming town in Rajasthan, you'll
            start your Holi adventure. In the evening, after settling into your
            hotel, you can savor a delightful dinner. Then, head out to a local
            gathering where you'll witness traditional Holi festivities like the
            "Gidad" dance and the lively beats of "Dhap" and "Dhol" drums. Join
            the locals in a circle, sing, and dance to feel the festive spirit.
            <br />
            <br />
            The next morning, after a tasty breakfast, put on your white kurta
            to celebrate Holi, a festival of colors. You'll head to the
            courtyard of Hotel Mandawa Kothi, where you'll have a blast playing
            Holi with fellow guests. After that, freshen up and have lunch at
            the hotel. In the evening, you'll venture out to watch the "Julus,"
            a vibrant Holi procession filled with music, dance, and joy.
            <br />
            <br />
            On your final day in Mandawa, start your morning with a traditional
            Holi breakfast featuring special dishes like gujiya and thandai.
            Take some time to relax at the hotel, enjoy the surroundings, and
            have a dip in the pool if you like. After a delicious traditional
            Holi lunch, it's time to check out from the hotel and say goodbye to
            the colorful and lively town of Mandawa as you continue your journey
            to your next destination.
            <br />
            <br />
            <b>Next stop:</b> Bikaner
            <br />
            Nearly 3 hours’ drive (192 km) from Mandawa
          </div>
        ),
        image: holimandawaitinary,
      },
      {
        day: "Day 5-6",
        city: "Bikaner",
        details: (
          <div>
            <br />
            Bikaner was once a vital stop on ancient caravan routes, and its Old
            Town is still surrounded by massive walls. Bikaner's Fort is unique
            in Rajasthan as it wasn't built on a hill. It has witnessed the
            influence of Rajput, Mughal, and British eras. You can also visit
            the Rat's Temple if you're comfortable with a 35km trip. Don't miss
            strolling through the old heritage buildings in Kote Gate Market.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaisalmer,
            <br />
            It's a 5-hour drive (covering 220 km) from Bikaner to the golden
            city of Jaisalmer.
          </div>
        ),
        image: holibikaneritinary,
      },
      {
        day: "Day 6-8",
        city: "Jaisalmer",
        details: (
          <div>
            <br />
            Known as one of India's treasures, Jaisalmer Fort is a must-see,
            offering a glimpse into vibrant local life within its ancient walls.
            <br />
            Visit the Bara Bagh Cenotaphs, Gadisar Lake, and explore the Patwa
            and Salim Singh Havelis. As the sun sets, venture into the Thar
            Desert for a magical camel ride among the sand dunes. Experience the
            starry night sky while enjoying a bonfire, traditional Marwari
            music, and folk dance performances at an outdoor camp. It's a night
            you won't forget.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jodhpur,
            <br />
            Embark on a 4.5-hour journey (covering 285 km) from Jaisalmer to the
            captivating city of Jodhpur.
          </div>
        ),
        image: holijaisalmeritinary,
      },
      {
        day: "Day 8-9",
        city: "Jodhpur",
        details: (
          <div>
            <br />
            Jodhpur, known as the Blue City of Rajasthan, boasts charming
            indigo-hued houses in its Old Town. Perched majestically atop a
            hill, Jodhpur's Fort is a massive and awe-inspiring sight.
            <br />
            Stroll near the Clock Tower, where you can admire old buildings and
            savor the fragrant spices in the market. Depending on your schedule
            and interests, explore the Bishnoi Village, Ummaid Bhawan Palace,
            Jaswant Thada, or Mandore Garden.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Udaipur,
            <br />
            Set off on a 5-hour journey (covering 250 km) from Jodhpur to the
            enchanting city of Udaipur.
          </div>
        ),
        image: holijodhpuritinary,
      },
      {
        day: "Day 9-11",
        city: "Udaipur",
        details: (
          <div>
            <br />
            Udaipur, Rajasthan's Lake City, stands as one of India's most
            stunning and romantic destinations. Immerse yourself in the
            enchantment of its lakes and the awe-inspiring City Palace, where
            grandeur and nostalgia intertwine.
            <br />
            Discover the serene and distinctive Princess Garden, Shilp Gram, and
            the Jagdish Temple. During your Udaipur visit, don't miss a scenic
            boat ride on Lake Pichola, offering captivating views of the city
            and the Jag Mandir, known as the "Lake Garden Palace", gracefully
            perched on an island.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Pushkar,
            <br />
            Embark on a 5-hour journey (covering 280 km) from Udaipur to the
            spiritual haven of Pushkar.
          </div>
        ),
        image: holiudaipuritinary,
      },
      {
        day: "Day 11-12",
        city: "Pushkar",
        details: (
          <div>
            <br />
            Pushkar, one of India's oldest cities, has long been a pilgrimage
            site for devotees nationwide. It boasts a captivating mythological
            history and a heritage of enduring architectural beauty.
            <br />
            Don't miss the opportunity to visit the world's only Lord Brahma
            (The Universe Creator) Temple. Explore the mystical beauty of
            Pushkar's Holy Lake. While the local market may be small, it's
            filled with unique treasures, including handmade gifts, aromatic
            spices, and vibrant clothing.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur,
            <br />
            Set out on a 3-hour journey (covering 150 km) from Pushkar to the
            vibrant city of Jaipur.
          </div>
        ),
        image: holipuskaritinary,
      },
      {
        day: "Day 12-14",
        city: "Jaipur",
        details: (
          <div>
            <br />
            Jaipur, the Pink City and the capital of Rajasthan, is a city
            adorned with historic monuments, palaces, fortresses, and
            enchantment. Explore the majestic City Palace, the serene Jal Mahal,
            the iconic Hawa Mahal (Wind Palace), and the 17th-century Jantar
            Mantar, an astronomical observatory.
            <br />
            Experience the awe-inspiring Amber Fort, steeped in countless tales,
            and the exquisite Birla Temple. Take a leisurely stroll through the
            vibrant markets of Johri Bazar and Choti Chopper, known for their
            unique jewelry.
            <br />
            In the evening, immerse yourself in the charm of the Old Walled City
            and explore the local market.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra,
            <br />
            Embark on a 4.5-hour journey (covering 240 km) from Jaipur to the
            historic city of Agra.
          </div>
        ),
        image: holijaipuritinary,
      },
      {
        day: "Day 14-15",
        city: "Agra",
        details: (
          <div>
            <br />
            A visit to Agra is like stepping into the most romantic story ever
            told, unfolding right before your eyes. Please note that many
            attractions, including the Taj Mahal, are closed on Fridays.
            <br />
            Take in the stunning sunset view of the Taj Mahal from the tranquil
            banks of the Yamuna River. If time allows, you can explore the
            magnificent Red Fort on the same day. Alternatively, consider an
            early morning visit the next day to witness the sunrise at the Taj
            Mahal.
            <br />
            Following your exploration of Agra, you'll be driven to your next
            destination.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi,
            <br />
            Embark on a 4-hour journey (covering 230 km) from Agra to the
            vibrant city of Delhi.
          </div>
        ),
        image: holiagraitinary,
      },
      {
        day: "Day 15",
        city: "Delhi",
        details: (
          <div>
            <br />
            You have the choice to unwind at your hotel, preparing for your
            departure the next day.
            <br />
            Alternatively, if you're feeling energetic and wish to explore,
            Delhi offers a variety of markets to visit: Chandni Chowk Market,
            the vibrant Kinari Bazaar (Wedding Market), the bustling Textile
            Market (Wholesale Market), the Book Market, and the fascinating
            Spice Market, known for both ground and whole spices.
          </div>
        ),
        detailsbelowimage: (
          <div>
            If your interests lean towards art and architecture, you might
            consider visiting the Fateh Puri Mosque, Sis Ganj Gurudwara, Jain
            Temple, and the Shiva Temple. The choice is yours, tailored to your
            pace and preferences.
            <br />
            <b>Next stop:</b> Delhi,
            <br />
            Embark on a 4-hour journey (covering 230 km) from Agra to the
            vibrant city of Delhi.
          </div>
        ),
        image: departure2,
      },
      {
        day: "Day 16",
        city: "Departure",
        details: (
          <div>
            <br />
            With a sense of relaxation and readiness to journey back home, your
            dedicated driver will ensure you reach the airport promptly, in
            accordance with your flight schedule. Safe travels!
            <br />
          </div>
        ),
      },
    ],
    map: rtmap1,
    highlights: [
      "Mandawa Havelis & Holi Festivities",
      "Desert City's Grandeur",
      "Holi Amidst Golden Sands of Jaisalmer",
      "Jodhpur - Blue City's Majesty",
      "Lakeside Romance & Holi Revelry of Udaipur",
      "Spiritual Oasis & Holi Traditions of Pushkar",
      "Pink City's Royalty & Holi Delight",
      "Iconic Taj Mahal & Holi Festivities",
    ],
    inclusions: [
      { icon: faHotel, text: "15 nights chosen hotels" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faCar, text: "Local driver, guide" },
      {
        icon: faMoneyBillAlt,
        text: "Driver expenses, fuel, insurance, and taxes",
      },
      { icon: Camel, text: "Camel ride" },
      { icon: faMoon, text: "Desert night" },
      { icon: faTshirt, text: "Traditional Kurta" },
      { icon: faPaintBrush, text: "Holi celebration" },
      { icon: faUtensilSpoon, text: "Cooking class with dinner" },
      { icon: faShip, text: "Boat ride on Lake Pichola" },
      { icon: faTicketAlt, text: "Entrance fees and taxes" },
      { icon: faBottleWater, text: "Bottled water" },
      { icon: rickshaw, text: "Delhi rickshaw ride" },
    ],
    recommendedTours: [
      {
        id: "/festival/diwali",
        name: "Diwali",
        image: diwali,
        description: "Festival of triumph of light over darkness",
      },
      {
        id: "/festival/kumbh",
        name: "Kumbh Mela",
        image: kumbh,
        description: "Grand religious gathering at holy rivers",
      },
      {
        id: "/festival/pushkar",
        name: "Pushkar Camel Carnival",
        image: pushkar,
        description: "Energetic display of camels in Rajasthan",
      },
    ],
    Affordable: 1475,
    Semi: 1987,
    Luxury: 3437,
  },
  {
    id: "diwali",
    title: "Diwali: Festival of lights",
    places:
      "Delhi-Mandawa-Bikaner-Jaisalmer-Jodhpur-Udaipur-Bundi-Jaipur-Agra-Orchha-Khajuraho-Varanasi",
    image: diwali,
    days: "20",
    heading: {
      Delhi: "A captivating blend of history and vitality",
      Mandawa: "A town of timeless heritage and beauty",
      Bikaner: "A desert oasis of history and camels",
      Jaisalmer: "Where desert tales come to life",
      Jodhpur: "Where history meets the azure skyline",
      Udaipur: "Where Romance and Royalty Reign",
      Bundi: "Rajasthan's Hidden Gem",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
      Orchha: "Echoes of history in its architecture",
      Khajuraho: "Sculpted wonders in stone",
      Varanasi: "The spiritual heart of India by the Ganges",
    },
    subtitle:
      "Festival of Lights & new beginnings, celebrating good over evil.",
    paragraph: (
      <div>
        Diwali, the Festival of Lights, isn't just a celebration; it's an
        emotion that ignites the soul. It's a symphony of joy, a canvas painted
        with the brilliance of light, and an embodiment of hope. Diwali is where
        hearts unite and spirits soar.
        <br />
        <br />
        Diwali's significance is profound—it's the return of Lord Rama to
        Ayodhya, a tale of victory over darkness. It's the blessing of Goddess
        Lakshmi, a symbol of prosperity. It's the time when homes transform into
        havens of radiance, cleaned with love, adorned with the gentle glow of
        diyas.
        <br />
        <br />
        It's a five-day festival, where each day has its own significance.
        <br />
        <b>Day 1:</b> Dhanteras: Start with buying new things and seeking
        blessings for wealth.
        <br />
        <b>Day 2:</b> Choti Diwali: Prepare for the main celebration.
        <br />
        <b>Day 3:</b> Diwali (Main Day): Light lamps, worship, and celebrate
        Lord Rama's return.
        <br />
        <b>Day 4:</b> Govardhan Puja: Express gratitude for nature's bounty.
        <br />
        <b>Day5:</b> Bhai Dooj: Honor the bond between brothers and sisters.
        <br />
        <br />
        The celebrations are a journey into the heart of India's rich culture.
        Families gather, hands folded in prayer, seeking divine blessings. The
        air is filled with the heavenly scent of sweets and snacks, shared with
        love and laughter. As the sun sets, the sky bursts into a mesmerizing
        display of fireworks, like the joyous laughter of the cosmos. Gifts are
        exchanged, sealing bonds of love and goodwill.
        <br />
        <br />
        Join us in embracing the warmth of Diwali's glow in the heart of India!
        <br />
        <br />
      </div>
    ),
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Mandawa:
        "Mandawa: where walls whisper tales of artistry. This Rajasthani gem invites you to stroll through its streets adorned with stunning frescoes and havelis, each a canvas of history and beauty. Step into a living gallery of Rajasthan's architectural splendor and immerse yourself in the stories etched on its walls.",
      Bikaner:
        "Bikaner, a desert gem with history woven into its fortresses and palaces, promises a mesmerizing journey through Rajasthan's northeast. Known as Camel Country, it's a place where camels gracefully traverse the landscape, adding to its unique allure.",
      Jaisalmer:
        "Jaisalmer, where humans and animals have conquered the desert together for centuries. A camel ride transports you to a bygone era, showcasing the resilience and resourcefulness of its people who thrive amidst challenging conditions, turning nature's offerings into treasures.",
      Jodhpur:
        "Jodhpur, where the grand Mehrangarh Fort looms over the Blue City, a 15th-century marvel of indigo-washed buildings. Experience the city's vibrant spectrum, from majestic elephant processions in the hills to the enchanting labyrinth of its historic core.",
      Udaipur:
        "In the heart of India lies Udaipur, a city that combines love and luxury. Discover majestic palaces, ancient temples, and exquisite Havelis. Explore bustling markets, and savor breathtaking sunsets on Lake Pichola. Join us in exploring the magic of Udaipur.",
      Bundi:
        "This enchanting town combines regal splendor with a sea of blue-painted houses, creating a unique visual tapestry. Wander through its historic palaces, intricate step wells, and vibrant markets to immerse yourself in the rich cultural tapestry of Rajasthan. A traveler's paradise waiting to be explored!",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
      Orchha:
        "A rustic village steeped in 300 years of history, adorned with Mughal-inspired Rajput architecture seen in its palaces, temples, and cenotaphs (Chhatris). A sacred hub for Lord Rama devotees.",
      Khajuraho:
        "A captivating town renowned for its intricate and sensual stone sculptures adorning World Heritage Temples, a true delight for art enthusiasts.",
      Varanasi:
        "Where the sacred Ganges River holds the promise of spiritual liberation, and every cremation and puja ceremony is a mystic ritual. This Hindu sanctuary is where devotees seek the renewal of their souls.",
    },
    cityImages: {
      Delhi: diwalidelhi,
      Mandawa: diwalimandawa,
      Bikaner: diwalibikaner,
      Jaisalmer: diwalijaisalmer,
      Jodhpur: diwalijodhpur,
      Udaipur: diwaliudaipur,
      Bundi: diwalibundi,
      Jaipur: diwalijaipur,
      Agra: diwaliagra,
      Orchha: diwaliorchha,
      Khajuraho: diwalikhajuraho,
      Varanasi: diwalivaranasi,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        details: (
          <div>
            <br />
            Arriving at Delhi Airport, we'll pick you up and take you to a comfy
            hotel.
            <br />
            If you're an early riser, you can explore Delhi's history and
            culture. See India Gate, President's House, and Parliament House.
            Learn about Gandhi at Gandhi Smriti and visit the impressive Qutub
            Minar. Take a rickshaw ride in Old Delhi to discover Jama Masjid,
            Red Fort, and Raj Ghat. Don't miss Gurudwara Bangla Sahib and
            Nizamuddin Sufi Saint Mosque. Explore Humayun's Tomb, Lotus Temple,
            and Akshardham Temple. Shop at Chandni Chowk market. We'll plan it
            all according to your schedule and interests. Delhi blends history
            and modern life seamlessly.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Mandawa,
            <br />A mere 5.5-hour drive (260 km) from Delhi.
          </div>
        ),
        image: diwalidelhiitinerary,
      },
      {
        day: "Day 3-5",
        city: "Mandawa",
        details: (
          <div>
            <br />
            An authentic Rajasthan town that immerses you in rural and
            traditional life. It's an open-air museum where each step reveals
            unique architecture and vibrant mural paintings. Don't miss the
            Heritage Walk, where you'll encounter the Silk Route-era mansions
            known as Havelis.
            <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Bikaner
            <br />
            Just 3.5 hours' drive (200 km) from Mandawa
          </div>
        ),
        image: diwalimandawaitinerary,
      },
      {
        day: "Day 5-6",
        city: "Bikaner",
        details: (
          <div>
            <br />
            Bikaner was once a vital stop on ancient caravan routes, and its Old
            Town is still surrounded by massive walls. Bikaner's Fort is unique
            in Rajasthan as it wasn't built on a hill. It has witnessed the
            influence of Rajput, Mughal, and British eras. You can also visit
            the Rat's Temple if you're comfortable with a 35km trip. Don't miss
            strolling through the old heritage buildings in Kote Gate Market.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaisalmer,
            <br />
            It's a 5-hour drive (covering 220 km) from Bikaner to the golden
            city of Jaisalmer.
          </div>
        ),
        image: diwalibikaneritinerary,
      },
      {
        day: "Day 6-8",
        city: "Jaisalmer",
        details: (
          <div>
            <br />
            Known as one of India's treasures, Jaisalmer Fort is a must-see,
            offering a glimpse into vibrant local life within its ancient walls.
            <br />
            Visit the Bara Bagh Cenotaphs, Gadisar Lake, and explore the Patwa
            and Salim Singh Havelis. As the sun sets, venture into the Thar
            Desert for a magical camel ride among the sand dunes. Experience the
            starry night sky while enjoying a bonfire, traditional Marwari
            music, and folk dance performances at an outdoor camp. It's a night
            you won't forget.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jodhpur,
            <br />
            Embark on a 4.5-hour journey (covering 285 km) from Jaisalmer to the
            captivating city of Jodhpur.
          </div>
        ),
        image: diwalijaisalmeritinerary,
      },
      {
        day: "Day 8-9",
        city: "Jodhpur",
        details: (
          <div>
            <br />
            Jodhpur, known as the Blue City of Rajasthan, boasts charming
            indigo-hued houses in its Old Town. Perched majestically atop a
            hill, Jodhpur's Fort is a massive and awe-inspiring sight.
            <br />
            Stroll near the Clock Tower, where you can admire old buildings and
            savor the fragrant spices in the market. Depending on your schedule
            and interests, explore the Bishnoi Village, Ummaid Bhawan Palace,
            Jaswant Thada, or Mandore Garden.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Udaipur,
            <br />
            Set off on a 5-hour journey (covering 250 km) from Jodhpur to the
            enchanting city of Udaipur.
          </div>
        ),
        image: diwalijodhpuritinerary,
      },
      {
        day: "Day 9-11",
        city: "Udaipur",
        details: (
          <div>
            <br />
            Udaipur, Rajasthan's Lake City, stands as one of India's most
            stunning and romantic destinations. Immerse yourself in the
            enchantment of its lakes and the awe-inspiring City Palace, where
            grandeur and nostalgia intertwine.
            <br />
            Discover the serene and distinctive Princess Garden, Shilp Gram, and
            the Jagdish Temple. During your Udaipur visit, don't miss a scenic
            boat ride on Lake Pichola, offering captivating views of the city
            and the Jag Mandir, known as the "Lake Garden Palace", gracefully
            perched on an island.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Bundi,
            <br />
            Set out on a 4-hour journey (covering 265 km) from Udaipur.
          </div>
        ),
        image: diwaliudaipuritinerary,
      },
      {
        day: "Day 11-12",
        city: "Bundi",
        details: (
          <div>
            <br />
            Arrive in Bundi, explore the stunning Bundi Palace, and savor local
            Rajasthani cuisine. Wander through the old town's blue-painted
            houses and visit Rani Ji ki Baori.
            <br />
            Discover Taragarh Fort and take a boat ride on Nawal Sagar Lake.
            Enjoy your final meal by the lake and, if time allows, explore Sukh
            Mahal. Depart from Bundi, enriched by its heritage and natural
            charm.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur,
            <br />
            Set out on a 3.5-hour journey (covering 213 km) from Bundi
          </div>
        ),
        image: diwalibundiitinerary,
      },
      {
        day: "Day 12-14",
        city: "Jaipur",
        detailsaboveimage: (
          <div>
            Jaipur, the Pink City of Rajasthan, awaits with its regal charm and
            captivating monuments.
            <br />
            Start your Diwali journey at the stunning Amer Fort and then visit
            the historic City Palace. In the afternoon, enjoy shopping at the
            bustling Johari Bazaar and Bapu Bazaar, where you can feel the
            festive vibes. As night falls, go to Nahargarh Fort for an
            incredible view of Diwali lights and fireworks.
          </div>
        ),
        details: (
          <div>
            <br />
            On the second day join in the morning Diwali traditions and savor a
            delicious Rajasthani meal. In the afternoon, visit the beautifully
            lit City Palace and the iconic Hawa Mahal for a glimpse of Jaipur's
            Diwali celebrations. Whether you prefer a cultural show or a
            relaxing walk through the well-lit streets, this plan ensures you'll
            take home wonderful memories of Jaipur's culture and the lively
            Diwali spirit.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra,
            <br />
            Your journey continues with a 4.5-hour drive covering 240
            kilometers. Prepare to uncover the iconic city of Agra.
          </div>
        ),
        image: diwalijaipuritinerary,
      },
      {
        day: "Day 14-15",
        city: "Agra",
        details: (
          <div>
            <br />
            Agra, where history's most romantic tales come alive before your
            eyes. Please note, it's closed on Fridays, so plan accordingly. You
            can savor the enchanting sunset view of the Taj Mahal from the
            tranquil banks of the Yamuna River. If time permits, explore the
            majestic Red Fort on the same day, or save it for the day after,
            combining it with a sunrise visit to the Taj Mahal.
            <br />
            Afterward, you'll be on your way to your next destination.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Orchha,
            <br />
            Your journey continues with a 5-hour drive, covering 255 kilometers.
          </div>
        ),
        image: diwaliagraitinerary,
      },
      {
        day: "Day 15-16",
        city: "Orchha",
        details: (
          <div>
            <br />
            Orchha, a historic and one-of-a-kind town frozen in time. It exudes
            a sense of nostalgia that can rekindle your dreams of fairytales.
            <br />
            Explore the enchanting Fort and Palace, step into fascinating
            temples, and wander through royal graveyards that will transport you
            to the grandeur of a bygone era.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Khajuraho,
            <br />
            Your journey continues with a 4.5-hour drive, covering 200
            kilometers, to the mesmerizing destination of Khajuraho.
          </div>
        ),
        image: diwaliorchaiitinerary,
      },
      {
        day: "Day 16-17",
        city: "Khajuraho",
        details: (
          <div>
            <br />
            Discover the awe-inspiring Kama Sutra Temples of Khajuraho, nestled
            within the Eastern, Western, and Southern groups of this UNESCO
            World Heritage sanctuary. Marvel at the intricate and sensual
            artistry that ranks among the world's finest.
            <br />
            As evening descends, choose between two captivating experiences: a
            mesmerizing Cultural Dance Performance or the enchanting Light and
            Sound Show at the renowned erotic shrine.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Varanasi,
            <br />
            Your journey continues with a quick 1-hour flight, transporting you
            to the mystical city of Varanasi.
          </div>
        ),
        image: diwalikhajraoitinerary,
      },
      {
        day: "Day 17-19",
        city: "Varanasi",
        details: (
          <div>
            <br />
            Varanasi, a city steeped in spirituality and culture, offers a
            profound journey. Begin by visiting the Temple of Mother India,
            inaugurated by Mahatma Gandhi, featuring a vast marble map of India
            on the ground. Explore the Buddhist city of Sarnath and the
            prestigious Hindu University.
            <br />
            Start your day early with a boat ride along the Ganges Ghats, where
            you&#39;ll witness the morning rituals of bathing and prayer.
            Consider a visit to the Ram Nagar Fort, known for its impressive
            collection of vintage cars.
            <br />
            In the evening, another boat ride will allow you to experience the
            Ghats and the mesmerizing Aarti and rituals.
            <br />
            On your final day, relish one more sunrise boat ride before aligning
            your activities with your departure schedule.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi,
            <br />
            Your journey continues with a quick 1.6-hour flight to Delhi, where
            you can explore the vibrant capital city before concluding your
            unforgettable adventure.
          </div>
        ),
        image: diwalivaranasiitinerary,
      },
      {
        day: "Day 19",
        city: "Delhi",
        details: (
          <div>
            <br />
            Your final day in Delhi offers flexibility. You can either relax at
            your hotel, preparing for your departure tomorrow, or dive into the
            vibrant city. If you're feeling active, explore the bustling
            markets, from Chandni Chowk to the unique Spice Market, where you
            can discover a world of aromas and flavors.
            <br />
            For those inclined towards art and architecture, explore the
            serenity of Fateh Puri Mosque, Sis Ganj Gurudwara, Jain Temple, and
            the tranquil Shiva Temple. Tailor your day to your desires, finding
            peace and comfort in the heart of Delhi.
          </div>
        ),
        image: departure2,
      },
      {
        day: "Day 20",
        city: "Departure",
        details: (
          <div>
            <br />
            Feeling refreshed and prepared for your journey back home!
            <br />
            Your driver will ensure you arrive at the airport in accordance with
            your flight schedule.
            <br />
          </div>
        ),
      },
    ],
    map: diwalimap,
    highlights: [
      "Dazzling Diwali Lights",
      "Traditional Rituals",
      "Spectacular Fireworks",
      "Festive Markets",
      "Cultural Performances",
    ],
    inclusions: [
      { icon: faHotel, text: "15 nights chosen hotels" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faCar, text: "Local driver, guide" },
      {
        icon: faMoneyBillAlt,
        text: "Driver expenses, fuel, insurance, and taxes",
      },
      { icon: Camel, text: "Camel ride" },
      { icon: faMoon, text: "Desert night" },
      { icon: faTshirt, text: "Traditional Kurta" },
      { icon: faPaintBrush, text: "Diwali celebration" },
      { icon: faUtensilSpoon, text: "Cooking class with dinner" },
      { icon: faShip, text: "Boat ride on Lake Pichola" },
      { icon: faTicketAlt, text: "Entrance fees and taxes" },
      { icon: faBottleWater, text: "Bottled water" },
      { icon: rickshaw, text: "Delhi rickshaw ride" },
    ],
    recommendedTours: [
      {
        id: "/festival/holi",
        name: "Holi",
        image: holi,
        description: "Festival embraces the essence of spring",
      },
      {
        id: "/festival/kumbh",
        name: "Kumbh Mela",
        image: kumbh,
        description: "Grand religious gathering at holy rivers",
      },
      {
        id: "/festival/pushkar",
        name: "Pushkar Camel Carnival",
        image: pushkar,
        description: "Energetic display of camels in Rajasthan",
      },
    ],
    Affordable: 1821,
    Semi: 2480,
    Luxury: 4279,
  },
  {
    id: "pushkar",
    title: "Pushkar Camel Carnival",
    places: "Delhi-Mandawa-Pushkar-Udaipur-Jodhpur-Jaipur-Agra",
    image: pushkar,
    days: "16",
    heading: {
      Delhi: "A captivating blend of history and vitality",
      Mandawa: "A town of timeless heritage and beauty",
      Pushkar: "Where desert charm meets sacred serenity",
      Udaipur: "Where Romance and Royalty Reign",
      Jodhpur: "Where history meets the azure skyline",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
    },
    subtitle:
      "Where the desert comes alive with camels, culture, and camaraderie.",
    paragraph: (
      <div>
        The Pushkar Camel Fair is a renowned annual event in India celebrated
        over five days. It holds great significance as one of the world's
        largest camel fairs, attracting traders and tourists alike. The fair
        features vibrant markets, cultural performances, and the buying and
        selling of thousands of camels and livestock. It's not just a trading
        event but a cultural extravaganza, showcasing the rich heritage of
        Rajasthan and promoting tourism in the region. The fair blends tradition
        and commerce, making it a unique and vibrant experience for all. It
        features cultural highlights like camel dance, folk music, and moustache
        competitions. It showcases Rajasthan's rich heritage and serves as a
        platform for camel trading while drawing tourists from around the world.
        <br />
        <br />
      </div>
    ),
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Mandawa:
        "Mandawa: where walls whisper tales of artistry. This Rajasthani gem invites you to stroll through its streets adorned with stunning frescoes and havelis, each a canvas of history and beauty. Step into a living gallery of Rajasthan's architectural splendor and immerse yourself in the stories etched on its walls.",
      Pushkar:
        "Pushkar, a picturesque Rajasthan town, graced by Thar Desert's sand dunes, a sacred lake, intricate temples, lush gardens, and a plethora of camels. The final quarter of each year paints a vibrant tapestry of colors, festivities, and time-honored customs, with its renowned Camel Fair as a highlight.",
      Udaipur:
        "In the heart of India lies Udaipur, a city that combines love and luxury. Discover majestic palaces, ancient temples, and exquisite Havelis. Explore bustling markets, and savor breathtaking sunsets on Lake Pichola. Join us in exploring the magic of Udaipur.",
      Jodhpur:
        "Jodhpur, where the grand Mehrangarh Fort looms over the Blue City, a 15th-century marvel of indigo-washed buildings. Experience the city's vibrant spectrum, from majestic elephant processions in the hills to the enchanting labyrinth of its historic core.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
    },
    cityImages: {
      Delhi: pushkardelhi,
      Mandawa: pushkarmandawa,
      Pushkar: pushkarpushkar,
      Udaipur: pushkarudaipur,
      Jodhpur: pushkarjodhpur,
      Jaipur: pushkarjaipur,
      Agra: pushkaragra,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Heartfelt Welcome to Delhi",
        details: (
          <div>
            <br />
            As you touch down at Delhi Airport, we extend a warm welcome,
            holding a sign bearing your name, marking the beginning of your
            memorable journey. Your hotel offers a peaceful haven to rest or
            dive straight into Delhi's enchanting experiences.
            <br />
            Feel Delhi's pulse as we explore its history, politics, and
            spirituality. Visit iconic landmarks like India Gate, President's
            House, and Parliament House, where India's democratic story unfolds.
            Pay homage to Gandhi, marvel at the Qutub Minar, and navigate the
            historic lanes of Old Delhi.
            <br />
            Discover spiritual solace at Gurudwara Bangla Sahib and
            architectural wonders like Humayun's Tomb, the Lotus Temple,
            Akshardham Temple, and the mystical Nizamuddin Sufi Saint Mosque.
            Chandni Chowk market awakens your senses with vibrant offerings.
            <br />
            Your Delhi journey is tailored to your interests and time.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Mandawa,
            <br />A 5.5-hour drive (260 km) away, brimming with captivating
            adventures.
          </div>
        ),
        image: pushkardelhiitinerary,
      },
      {
        day: "Day 3-4",
        city: "Mandawa",
        details: (
          <div>
            <br />
            An authentic Rajasthan town that immerses you in rural and
            traditional life. It's an open-air museum where each step reveals
            unique architecture and vibrant mural paintings. Don't miss the
            Heritage Walk, where you'll encounter the Silk Route-era mansions
            known as Havelis.
            <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Pushkar
            <br />
            Nearly 4 hours' drive (221 km) from Mandawa
          </div>
        ),
        image: pushkarmandawaitinerary,
      },
      {
        day: "Day 4-6",
        city: "Pushkar",
        details: (
          <div>
            <br />
            Pushkar, one of India's oldest cities, has long been a pilgrimage
            site for devotees nationwide. It boasts a captivating mythological
            history and a heritage of enduring architectural beauty.
            <br />
            Don't miss the opportunity to visit the world's only Lord Brahma
            (The Universe Creator) Temple. Explore the mystical beauty of
            Pushkar's Holy Lake. While the local market may be small, it's
            filled with unique treasures, including handmade gifts, aromatic
            spices, and vibrant clothing.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Udaipur,
            <br />
            Set out on a 5-hour journey (covering 299 km) from Pushkar.
          </div>
        ),
        image: pushkarpushkaritinerary,
      },
      {
        day: "Day 6-8",
        city: "Udaipur",
        details: (
          <div>
            <br />
            Udaipur, Rajasthan's Lake City, stands as one of India's most
            stunning and romantic destinations. Immerse yourself in the
            enchantment of its lakes and the awe-inspiring City Palace, where
            grandeur and nostalgia intertwine.
            <br />
            Discover the serene and distinctive Princess Garden, Shilp Gram, and
            the Jagdish Temple. During your Udaipur visit, don't miss a scenic
            boat ride on Lake Pichola, offering captivating views of the city
            and the Jag Mandir, known as the "Lake Garden Palace," gracefully
            perched on an island.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jodhpur,
            <br />
            Embark on a 4-hour journey (covering 244 km).
          </div>
        ),
        image: pushkarudaipuritinerary,
      },
      {
        day: "Day 8-10",
        city: "Jodhpur",
        details: (
          <div>
            <br />
            Jodhpur, known as the Blue City of Rajasthan, boasts charming
            indigo-hued houses in its Old Town. Perched majestically atop a
            hill, Jodhpur's Fort is a massive and awe-inspiring sight.
            <br />
            Stroll near the Clock Tower, where you can admire old buildings and
            savor the fragrant spices in the market. Depending on your schedule
            and interests, explore the Bishnoi Village, Ummaid Bhawan Palace,
            Jaswant Thada, or Mandore Garden.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur,
            <br />
            Set off on a 6-hour journey (covering 336 km).
          </div>
        ),
        image: pushkarjodhpuritinerary,
      },
      {
        day: "Day 10-13",
        city: "Jaipur",
        details: (
          <div>
            <br />
            Jaipur, the Pink City and the capital of Rajasthan, is a city
            adorned with historic monuments, palaces, fortresses, and
            enchantment. Explore the majestic City Palace, the serene Jal Mahal,
            the iconic Hawa Mahal (Wind Palace), and the 17th-century Jantar
            Mantar, an astronomical observatory.
            <br />
            Experience the awe-inspiring Amber Fort, steeped in countless tales,
            and the exquisite Birla Temple. Take a leisurely stroll through the
            vibrant markets of Johri Bazar and Choti Chopper, known for their
            unique jewelry.
            <br />
            In the evening, immerse yourself in the charm of the Old Walled City
            and explore the local market.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra,
            <br />
            Embark on a 4.5-hour journey (covering 240 km) from Jaipur to the
            historic city of Agra.
          </div>
        ),
        image: pushkarjaipuritinerary,
      },
      {
        day: "Day 13-15",
        city: "Agra",
        details: (
          <div>
            <br />
            A visit to Agra is like stepping into the most romantic story ever
            told, unfolding right before your eyes. Please note that many
            attractions, including the Taj Mahal, are closed on Fridays.
            <br />
            Take in the stunning sunset view of the Taj Mahal from the tranquil
            banks of the Yamuna River. If time allows, you can explore the
            magnificent Red Fort on the same day. Alternatively, consider an
            early morning visit the next day to witness the sunrise at the Taj
            Mahal.
            <br />
            Following your exploration of Agra, you'll be driven to your next
            destination.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi,
            <br />
            Embark on a 4-hour journey (covering 230 km) from Agra to the
            vibrant city of Delhi.
          </div>
        ),
        image: pushkaragraitinerary,
      },
      {
        day: "Day 15",
        city: "Delhi",
        details: (
          <div>
            <br />
            You have the choice to unwind at your hotel, preparing for your
            departure the next day. Alternatively, if you're feeling energetic
            and wish to explore, Delhi offers a variety of markets to visit:
            Chandni Chowk Market, the vibrant Kinari Bazaar (Wedding Market),
            the bustling Textile Market (Wholesale Market), the Book Market, and
            the fascinating Spice Market, known for both ground and whole
            spices.
            <br />
            If your interests lean towards art and architecture, you might
            consider visiting the Fateh Puri Mosque, Sis Ganj Gurudwara, Jain
            Temple, and the Shiva Temple. The choice is yours, tailored to your
            pace and preferences.
          </div>
        ),
        image: departure2,
      },
      {
        day: "Day 16",
        city: "Departure",
        details: (
          <div>
            <br />
            With a sense of relaxation and readiness to journey back home, your
            dedicated driver will ensure you reach the airport promptly, in
            accordance with your flight schedule. Safe travels!
            <br />
          </div>
        ),
      },
    ],
    map: pushkarmap,
    highlights: [
      "Mandawa Havelis &amp; history",
      "Desert City's Grandeur",
      "Pushkar's camel ride",
      "Jodhpur - Blue City's Majesty",
      "Lakeside Romance of udaipur",
      "Pink City's Royalty",
      "Iconic Taj Mahal",
    ],
    inclusions: [
      { icon: faHotel, text: "15 nights chosen hotels" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faCar, text: "Local driver, guide" },
      {
        icon: faMoneyBillAlt,
        text: "Driver expenses, fuel, insurance, and taxes",
      },
      { icon: faTshirt, text: "Traditional Kurta" },
      { icon: faPaintBrush, text: "Puskar mela celebration" },
      { icon: faUtensilSpoon, text: "Cooking class with dinner" },
      { icon: faShip, text: "Boat ride on Lake Pichola" },
      { icon: faTicketAlt, text: "Entrance fees and taxes" },
      { icon: faBottleWater, text: "Bottled water" },
      { icon: rickshaw, text: "Delhi rickshaw ride" },
    ],
    recommendedTours: [
      {
        id: "/festival/holi",
        name: "Holi",
        image: holi,
        description: "Festival embraces the essence of spring",
      },
      {
        id: "/festival/kumbh",
        name: "Kumbh Mela",
        image: kumbh,
        description: "Grand religious gathering at holy rivers",
      },
      {
        id: "/festival/diwali",
        name: "Diwali",
        image: diwali,
        description: "Festival of triumph of light over darkness",
      },
    ],
    Affordable: 1437,
    Semi: 2062,
    Luxury: 3437,
  },
  {
    id: "kumbh",
    title: "Sacred Path: Kumbh Mela",
    places: "Delhi-Jaipur-Agra-Prayagraj",
    image: kumbh,
    days: "11",
    heading: {
      Delhi: "A captivating blend of history and vitality",
      Jaipur: "The Pink City of regal splendor",
      Agra: "Where history reigns supreme",
      Prayagraj: "City of Sacred Confluence",
    },
    subtitle: "A sacred gathering of millions, cleansing souls in holy waters.",
    paragraph: (
      <div>
        Join us for an extraordinary journey into the heart of India&#39;s
        spiritual soul. Discover the awe- inspiring Kumbh Mela, a sacred
        gathering of millions, held once every 12 years at the holy banks of
        Ganges, Yamuna, and others. People come to take a holy dip in these
        rivers, believing it cleanses their souls. They also engage in prayers,
        processions, and cultural events. Some perform extreme acts of devotion,
        like self-flagellation, as a form of penance. The main idea is to find
        spiritual growth and connect with the divine. Kumbh Mela is a colorful
        and diverse celebration, with millions of pilgrims from all walks of
        life participating in this sacred gathering.
        <br />
        Immerse yourself in this enchanting event, where spirituality, culture,
        and tradition blend seamlessly. Witness the vibrant colors, rich
        traditions, and profound spirituality that define India.
        <br />
        Let us be your guide to this unparalleled spiritual voyage, connecting
        you with an ancient and extraordinary cultural experience.
        <br />
        <br />
      </div>
    ),
    description: {
      Delhi:
        "Discover Delhi, where history and modernity intertwine in a captivating dance. Vibrant, contemporary, and bursting with colors. In Old Delhi, you'll experience the rich tapestry of humanity in all its hues and sensations.",
      Jaipur:
        "Discover the heart of Rajasthan, where the historical and vibrant Pink City, once home to the Jaipur Royal Family, seamlessly blends the past with the present in a truly unique experience.",
      Agra: "Enter a realm where the echoes of the Mughal empire still linger, and uncover a marvel born from a timeless love tale: The Taj Mahal. Explore intriguing tombs, stately forts, and vibrant marketplaces that eagerly await your presence.",
      Prayagraj: "",
    },
    cityImages: {
      Delhi: kumbhdelhi,
      Jaipur: kumbhjaipur,
      Agra: kumbhagra,
      Prayagraj: kumbhprayagraj,
    },
    itinerary: [
      {
        day: "Day 1-3",
        city: "Delhi",
        details: (
          <div>
            <br />
            Touch down at Delhi Airport, where we'll greet you with a sign and
            take you to your well- prepared hotel for a comfortable stay.
            <br />
            If you're an early bird, seize the chance to uncover Delhi's
            history, politics, and spirituality - from the Freedom Struggle to
            iconic landmarks like India Gate, President's House, and Parliament
            House. Immerse yourself in history at Gandhi Smriti and marvel at
            the imposing Qutub Minar. Experience the charm of Old Delhi through
            a rickshaw ride, unveiling treasures such as Jama Masjid, Red Fort,
            and Raj Ghat. Don't forget to explore Gurudwara Bangla Sahib and the
            serene Nizamuddin Sufi Saint Mosque. Enrich your senses at Humayun's
            Tomb, Lotus Temple, and Akshardham Temple, and don't miss the lively
            Chandni Chowk market. We'll curate your journey based on your
            schedule and preferences, inviting you to discover Delhi's seamless
            blend of history and modern vibrancy, where ancient wonders
            harmonize with contemporary marvels.
            <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Jaipur,
            <br />
            Just a 5-hour drive (280 km) from Delhi, a new adventure awaits in
            the Pink City.
          </div>
        ),
        image: kumbhdelhiitinerary,
      },
      {
        day: "Day 3-5",
        city: "Jaipur",
        details: (
          <div>
            <br />
            Experience Jaipur, Rajasthan's enchanting capital founded in 1727 by
            Maharaja Sawai Jai Singh II. Marvel at the architectural splendor of
            Hawa Mahal, City Palace, and the planned layout based on Vastu
            Shastra.
            <br />
            This Pink City seamlessly melds its royal past with modern vibrancy.
            Explore historic treasures like City Palace, Jal Mahal, Hawa Mahal,
            and the 17th-century Jantar Mantar Observatory. Wander through the
            captivating Old Walled City, Amber Fort, and Birla Temple. Indulge
            in the local markets of Johri Bazar and Choti Chopper, renowned for
            unique jewelry. Jaipur - where history, culture, and markets
            intertwine for an unforgettable journey.
            <br />
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Agra
            <br />
            Just a 4-hour and 20-minute drive (240 km) from Jaipur, get ready to
            discover the magic of this historic city.
          </div>
        ),
        image: kumbhjaipuritinerary,
      },
      {
        day: "Day 5-6",
        city: "Agra",
        details: (
          <div>
            <br />
            Exploring Agra means stepping into a world of romance and history.
            Behold the Taj Mahal's sunset view from the tranquil Yamuna
            Riverbanks. If time allows, delve into the Red Fort, either on the
            same day or the following.
            <br />
            Witness the Taj Mahal's enchantment at sunrise and sunset; this
            Wonder of the World unveils its magic in unique ways. Keep in mind
            it's closed on Fridays.
            <br />
            More treasures await: Fatehpur Sikri, Akbar's Tomb, Jama Masjid, and
            Itimad-ud-Daula's Tomb. Agra, the realm of the iconic Taj Mahal,
            intricately weaves the Mughal legacy. From the majestic Agra Fort to
            the intricate allure of Fatehpur Sikri, the city's rich heritage
            unfolds. Agra's timeless allure encapsulates India's imperial
            history.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Haridwar,
            <br />
            Just 6.5 hr drive (402.9 km) from Jaipur.
          </div>
        ),
        image: kumbhagraitinerary,
      },
      {
        day: "Day 6-9",
        city: "Prayagraj",
        details: (
          <div>
            <br />
            Step into a world of spirituality and witness a living tapestry of
            devotion. Be a part of the collective faith, where mysticism and
            sacred rituals come to life.
            <br />
            Experience the incredible energy and transformation that this
            spiritual journey offers.
          </div>
        ),
        detailsbelowimage: (
          <div>
            <b>Next stop:</b> Delhi,
            <br />
            Journey from Prayagraj to Delhi: 5 hours (220 km).
          </div>
        ),
        image: kumbhprayagrajitinerary,
      },
      {
        day: "Day 10",
        city: "Delhi",
        details: (
          <div>
            <br />
            In Delhi, you've got two options. For relaxation, stay at the hotel
            and recharge.
            <br />
            Feeling adventurous? Explore vibrant markets like Chandni Chowk or
            delve into Delhi's heritage at Fateh Puri Mosque or Jain Temple.
            Whether you seek tranquility or excitement, Delhi has something for
            everyone. Enjoy your time here!
            <br />
            Certain places will be explored at your convenience and based on
            your personal preferences and schedule.
          </div>
        ),
        image: departure2,
      },
      {
        day: "Day 11",
        city: "Departure",
        details: (
          <div>
            <br />
            Feeling refreshed and prepared for your journey back home!
            <br />
            Your driver will ensure you arrive at the airport in accordance with
            your flight schedule.
            <br />
          </div>
        ),
      },
    ],
    map: kumbhmap,
    highlights: [
      "Sadhu camps (Akhara)",
      "Shahi Snan (Royal Bath)",
      "Spiritual Discourses",
      "Cultural Performances",
      "Local Markets",
      "Ghats & temples",
      "Yoga & meditation",
      "Boat Rides",
    ],
    inclusions: [
      { icon: faHotel, text: "15 nights chosen hotels" },
      { icon: faUtensils, text: "Daily breakfast" },
      { icon: faWifi, text: "In-car Wi-Fi" },
      { icon: faCar, text: "Local driver, guide" },
      {
        icon: faMoneyBillAlt,
        text: "Driver expenses, fuel, insurance, and taxes",
      },
      { icon: faPaintBrush, text: "Puskar mela celebration" },
      { icon: faUtensilSpoon, text: "Cooking class with dinner" },
      { icon: faBottleWater, text: "Bottled water" },
    ],
    recommendedTours: [
      {
        id: "/festival/diwali",
        name: "Diwali",
        image: diwali,
        description: "Festival of triumph of light over darkness",
      },
      {
        id: "/festival/holi",
        name: "Holi",
        image: holi,
        description: "Festival embraces the essence of spring",
      },
      {
        id: "/festival/pushkar",
        name: "Pushkar Camel Carnival",
        image: pushkar,
        description: "Energetic display of camels in Rajasthan",
      },
    ],
    Affordable: 987,
    Semi: 1437,
    Luxury: 2462,
  },
];
