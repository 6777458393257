import React from "react";
import video from "../assets/intro.mp4";
// import { useTranslator } from "./Translator";

const IntroPage = () => {
  // const { sample1 } = useTranslator();
  return (
    <div className="flex flex-col px-4 py-16 md:flex-row md:px-8" id="about-us">
      <div className="h-full max-w-4xl p-13 md:p-12 md:w-1/2">
        <div className="mb-2 text-4xl text-center text-palette-color-18 font-palette-font-1">
        Namaste!
        </div>
        <h2 className="mb-4 text-2xl font-semibold text-center font-palette-font-1 text-palette-color-18">
        Welcome to Incredible India
        </h2>
        <p className="text-center text-gray-700 font-palette-font-2">
        The land of breathtaking beauty where every corner tells a story will enchant your senses and leave an unforgettable mark on your soul
        </p>

        <p className="mt-2 text-center text-gray-700">
          <p className="text-xl font-semibold text-center font-palette-font-1 text-palette-color-4">
          Feel the warmth
          </p>
          <p className="text-c1aa7f font-palette-font-2">
          and hospitality of us through the vibrant colors flavors of diverse cuisines, and the celebration of the bright and colorful festivals
          </p>
        </p>
        <p className="mt-2 text-center text-gray-700">
          <p className="text-xl font-semibold text-center font-palette-font-1 text-palette-color-4">
          Find your inner self
          </p>
          <p className="text-c1aa7f font-palette-font-2">
          In the deep spiritual resonance and peaceful abodes of meditation and yoga
          </p>
        </p>
        <p className="mt-2 text-center text-gray-700">
          <p className="text-xl font-semibold text-center font-palette-font-1 text-palette-color-4">
          Experience with Us
          </p>
          <p className="text-c1aa7f font-palette-font-2">
          The majestic snow peaks of the Himalayas to the serene backwaters of Karela intricately carved temples beautiful palaces any massive forts as the rich architectural Heritage
          </p>
        </p>
      </div>
      <div className="self-center md:w-1/2 ">
        <video autoPlay loop muted className="z-0 object-cover w-full h-96">
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default IntroPage;
