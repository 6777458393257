import React from "react";
import Aboutus3 from "../../assets/aboutus3.jpg"
import Aboutus4 from "../../assets/aboutus4.jpg"

const WhereWere = ({ whereWereRef }) => {
  return (
    <div ref={whereWereRef} className="justfy-center">
      <div className="mb-2 text-4xl text-palette-color-18 font-palette-font-1">
        Where we are?
      </div>
      <br />
      <div className="mb-2 text-2xl text-palette-color-18 font-palette-font-1">
        Discovering Shekhawati: A Unique Journey Through Havelis, history, and
        hues
      </div>
      <br />
      <p className="text-justify">
        Shekhawati, a region in the northeast of Rajasthan, is a hidden gem with
        a rich history and vibrant heritage. Founded in 1471 by Rao Shekha, it
        was once a flourishing kingdom known as the "Garden of Shekha." Before
        Rao Shekha, the area was ruled by the Kayamkhani Nawabs.
        <br />
        <br />
        During the 17th to 19th centuries, Shekhawati became a major hub for the
        caravan trade routes, making its Marwari merchants incredibly wealthy.
        To showcase their prosperity, they built opulent havelis (mansions)
        adorned with exquisite frescoes. The havelis served as both homes and
        offices for these affluent families.
        <br />
        <br />
        The frescoes, painted by skilled artists called chiteras, depicted a
        wide range of subjects, from Hindu mythology to European themes. The
        havelis had distinctive architecture, featuring courtyards for various
        purposes, intricate entrances, and elaborate decorations.
        <br />
        <br />
        Today, many of these havelis stand as a testament to Shekhawati's
        glorious past. Some have been converted into hotels, while others remain
        abandoned, silently narrating their stories. Prominent towns like
        Mandawa, Fatehpur, Nawalgarh, and Alsisar showcase the architectural
        wonders of Shekhawati. They are flourished with grand mansions,
        stepwells, cenotaphs, and temples. Affluent Marwari merchants and
        landowning thakurs competed to build the most stunning structures,
        adorning them with murals both inside and out.
        <br />
        <br/>
        <div className="flex flex-col md:flex-row">

        <img src={Aboutus3} alt="aboutus3" className="md:w-1/2 md:pr-4"/>
        <img src={Aboutus4} alt="aboutus4" className="md:w-1/2 md:pl-4"/>
        </div>
        <br />
        Exploring Shekhawati is like stepping into an open-air museum, where
        every haveli tells a unique tale through its frescoes. Whether you take
        the southern, southwest, northern, or northeast route, each journey
        offers a glimpse into the region's fascinating history and artistic
        heritage.
        <br />
        <br />
        In the past, Shekhawati was a tough place with scorching summers and
        freezing winter nights. Now known for its incredible outdoor art
        gallery, it boasts the world's largest collection of colorful frescoes.
        Although it might seem like a forgotten kingdom today, Shekhawati was
        once a busy route connecting Delhi and Sindh (now in Pakistan) with the
        bustling ports of Gujarat. It was part of the Dhundar kingdom, ruled by
        descendants of the 15th-century Rajput lord, Rao Shekha.
        <br />
        <br />
        While some havelis have been well-preserved, others are slowly
        succumbing to the passage of time. Yet, the charm of Shekhawati remains
        intact, inviting travelers to unravel its mysteries and appreciate the
        artistic legacy left by the Marwari merchants.
      </p>
    </div>
  );
};

export default WhereWere;
