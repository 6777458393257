import React from "react";
import Logo from "../assets/Logo.png";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaPinterest,
} from "react-icons/fa";
// import { Button } from "@material-tailwind/react";

const Footer = () => {
  return (
    <footer className="bg-black font-palette-font-2">
      <div className="w-full max-w-screen-xl p-4 py-6 mx-auto lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <Link to="/" className="flex items-center">
              <img src={Logo} style={{ height: "12rem" }} alt="ITH Logo" />
            </Link>
          </div>
          <div className="p-4 text-gray-500 sm:p-2">
            <h2>Address:</h2>
            <p>98, Nemi Nagar Extension, Block A, Vaishali Nagar, Jaipur, Rajasthan 302021</p>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-8 sm:grid-cols-4">
            <div>
              {/* <h2 className="mb-6 text-sm font-semibold text-white uppercase">Rajasthan Tours</h2> */}
              <ul className="font-medium text-gray-500 dark:text-gray-400">
                <li className="mb-4">
                  <Link to="/?scrollTo=trips" className="hover:underline">
                    Journeys
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/all-blogs" className="hover:underline">
                    Blogs
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/socialimpact" className="hover:underline">
                    Social Impact
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/about" className="hover:underline">
                    About Us
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/carrental" className="hover:underline">
                    Rent-a-Car
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="font-medium text-gray-500 dark:text-gray-400">
                <li className="mb-4">
                  <Link to="/privacy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/" className="hover:underline">
                    Testimonials
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="faqs" className="hover:underline">
                    FAQs
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <ul className="font-medium text-gray-500 dark:text-gray-400">
                <li className="mb-4">
                  <Link to="/contactus" className="hover:underline">
                    Contact Us
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/Visarules" className="hover:underline">
                    Visa Rules for India
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/traveltips" className="hover:underline">
                    Travel tips
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              {/* <h2 className="mb-6 text-sm font-semibold text-white uppercase">South Tours</h2> */}
              <ul className="font-medium text-gray-500 dark:text-gray-400">
                <li className="mb-4">
                  <Link to="/payment" className="hover:underline">
                    Online Payment
                  </Link>
                </li>
                <li className="mb-4">
                  {/* <Link to="/request">
                    <Button className="px-2 py-1 rounded-full bg-palette-color-4 text-palette-color-3 hover:bg-palette-color-3 hover:text-palette-color-1">
                      Customize your Journey
                    </Button>
                  </Link> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2023{" "}
            <Link to="/" className="hover:underline">
              Indian Treasure House
            </Link>
            . All Rights Reserved.
          </span>
          <div className="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
            <a
              href="https://www.facebook.com/people/INDIAN-TREASURE-HOUSE/100028941830319/"
              className="text-gray-500 hover:text-white dark:hover:text-white"
            >
              <FaFacebook size={24} />
            </a>
            <a
              href="https://twitter.com/treasure_indian"
              className="text-gray-500 hover:text-white-900 dark:hover:text-white"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="https://www.instagram.com/indiantreasurehouse/"
              className="text-gray-500 hover:text-white dark:hover:text-white"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://in.pinterest.com/IndiaTreasureHouse/"
              className="text-gray-500 hover:text-white dark:hover:text-white"
            >
              <FaPinterest size={24} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
