import React from 'react';
import AccordionItem from './accordion'; 

const TourColumn = ({ title, tours }) => {
  return (
    <div className="w-full mx-auto">
      <div className="mb-4 text-3xl text-center font-palette-font-1 text-palette-color-1">
        {title}
      </div>
      {tours.map((tour, index) => (
        <AccordionItem
          key={tour.id} // Use 'tour.id' as the key
          tour={tour}   // Pass the individual 'tour' object to the AccordionItem component
          isFirst={index === 0}
        />
      ))}
    </div>
  );
};

export default TourColumn;
