import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../firebase";
import Navbar from "../components/navbar";

function Payment() {
  // console.log(grandTotal)

  /**========================================================================
   *!                           Payment Intigration
   *========================================================================**/
 
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setemail] = useState("");
  const [grandTotal, setgrandTotal] = useState("");
  const handleSubmit = async () => {
    if (
      name === "" ||
      address === "" ||
      pincode === "" ||
      phoneNumber === "" ||
      email === "" ||
      grandTotal === ""
    ) {
      return toast.error("All fields are required", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    const addressInfo = {
      name,
      address,
      pincode,
      phoneNumber,
      email,
      grandTotal,
      date: new Date().toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    };

    var options = {
      key: "rzp_test_n9fcWeZ2ZtNXb3",
      key_secret: "NgqiCVX6JOcKlpHuNQhOh2oo",
      amount: grandTotal * 100,
      currency: "INR",
      order_receipt: "order_rcptid_" + name,
      name: "Indian Treassure House",
      description: "for testing purpose",
      handler: function (response) {
        console.log(response);
        toast.success("Payment Successful");

        const paymentId = response.razorpay_payment_id;

        const orderInfo = {
          addressInfo,
          date: new Date().toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }),
          paymentId,
        };

        try {
          const orderRef = collection(firestore, "order");
          addDoc(orderRef, orderInfo);
        } catch (error) {
          console.log(error);
        }
      },

      theme: {
        color: "#956e41",
      },
    };
    var pay = new window.Razorpay(options);
    pay.open();
    console.log(pay);
  };
  return (
    <>
    <div className="bg-palette-color-4 relative z-10 sticky top-0">
        <Navbar />
      </div>
        <h1 className="mb-8 mt-6 text-4xl text-center text-palette-color-1 font-palette-font-1">Online Payment</h1>
        <div className="justify-center max-w-5xl px-6 mx-auto md:flex md:space-x-6 xl:px-0 ">
          <div className="h-full p-6 mt-6 mb-6 bg-palette-color-4 border rounded-lg shadow-md md:mt-0 md:w-1/2">
           
           
          <form className="flex flex-col justify-center space-y-5">
              <div>
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                  Enter Full Name
                </label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="name"
                  name="name"
                  id="name"
                  className="border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                  Enter Full Address
                </label>
                <input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  name="address"
                  id="address"
                  className="border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="pincode" className="block mb-2 text-sm font-medium text-gray-900">
                  Enter Pincode
                </label>
                <input
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  type="text"
                  name="pincode"
                  id="pincode"
                  className="border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="mobileNumber" className="block mb-2 text-sm font-medium text-gray-900">
                  Enter Mobile Number
                </label>
                <input
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="text"
                  name="mobileNumber"
                  id="mobileNumber"
                  className="border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="mobileNumbemailer" className="block mb-2 text-sm font-medium text-gray-900">
                  Enter Email Id
                </label>
                <input
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  type="text"
                  name="email"
                  id="email"
                  className="border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                  required
                />
              </div>
              <div>
                <label htmlFor="grandTotal" className="block mb-2 text-sm font-medium text-gray-900">
                  Enter Grand Total
                </label>
                <input
                  type="text"
                  name="grandTotal"
                  id="grandTotal"
                  value={grandTotal}
                  onChange={(e) => setgrandTotal(e.target.value)}
                  className="border outline-0 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-100"
                  required
                />
              </div>
            
            <button
              onClick={handleSubmit}
              type="button"
              className="py-3 px-6 bg-palette-color-18 hover:bg-palette-color-1 text-white font-bold rounded-md"
            >
              Order Now
            </button>
            </form>
          </div>
        </div>
    </>
  );
}

export default Payment;
