import React from "react";

const PersonalJourney = ({ personalJourneyRef }) => {
  return (
    <div ref={personalJourneyRef} className="justify-center">
      <div className="mb-2 text-4xl text-palette-color-18 font-palette-font-1">
        My Personal Journey
      </div>
      <br />
      <p className="text-justify">
        I'm delighted to welcome you to Indian Treasure House, an empire made
        with emotions. Let us take a moment to relive this story.
        <br />
        <br />
        We’ve been surrounded by travel experiences since we were kids. We’re a
        part of Incredible Real India Tours & travels & it’s our family
        business.
        <br />
        <br />
        We're based in Mandawa, a place we hold close to our hearts. Our journey
        started right here at Mandawa Kothi, a lovely boutique property. This
        place has seen us grow, and it's where our inspiration comes from and I
        want people to experience all this. We've been enchanted by the havelis,
        the beautiful frescoes, and the daily life that's painted on the walls
        here in Mandawa. It's a town where many successful businesses have their
        roots.
        <br />
        <br />
        Our love for exploring the world inspired us to help others discover the
        incredible beauty of India.
        <br />
        <br />
        With our deep understanding of travel, we're on a mission to share our
        knowledge and create opportunities for extraordinary adventures in
        India. Our goal is to inspire and guide travelers to find the hidden
        treasures, diverse cultures, and rich heritage that make India so
        fascinating.
        <br />
        <br />
        Let's embark on unforgettable journeys together.
      </p>
    </div>
  );
};

export default PersonalJourney;
