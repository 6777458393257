import React from "react";
import Navbar from "../components/navbar";

function Privacy() {
  

  return (
    <div>
      <div className="bg-palette-color-4 relative sticky top-0">
        <Navbar />
      </div>
      <h1 className="mt-6 mb-8 text-4xl leading-tight text-center text-palette-color-18 font-palette-font-1">
        Privacy Policy
      </h1>

      <h2 className="text-center font-palette-font-2">Copyright Notice</h2>
      <div className="flex flex-col items-center justify-center">
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          This website is operated by Indian Treasure House , based in India,
          and adheres to both Indian and international copyright laws. It is
          designed for use by both Indian and international visitors. All visits
          to this site are subject to Indian and international copyright laws.
        </p>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          No material, graphics, sounds, animations, designs, interactive
          concepts, or conceptual ideas from this site may be copied, uploaded,
          posted, modified, or distributed in any way. You may download a single
          copy of the materials to a single computer for personal,
          non-commercial use, provided you retain all copyright and other
          proprietary notices. Using such materials on any other website or
          computer environment is prohibited.
        </p>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          The words, logo, and artwork of Indian Treasure House Pvt Ltd are
          trademarks of Indian Treasure House. Other trademarks on this site are
          owned by their respective owners, and all rights related to these
          trademarks are reserved.
        </p>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          While Indian Treasure House makes reasonable efforts to provide
          accurate and up-to-date information on the site, it does not provide
          any warranties or representations regarding its accuracy Indian
          Treasure House assumes no liability for errors or omissions in the
          site&#39;s content. All information is subject to change without
          notice.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          Privacy Policy
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          This Privacy Policy governs the collection, use, maintenance, and
          disclosure of information collected from users of the
          https://www.indiantreasuehouse.com/ website (&quot;Site&quot;) by
          Indian Treasure House. This privacy policy applies to the Site and all
          products and services offered by Indian Treasure House.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          Personal Identification Information
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          We may collect personal identification information from users in
          various ways, including when they visit our site, subscribe to the
          newsletter, fill out a form, or engage in other activities, services,
          features, or resources we offer on our Site. Users may be asked for
          their name, email address, and phone number. We collect personal
          identification information only when voluntarily provided by users.
          Users can refuse to provide personal identification information, but
          it may limit their engagement in certain Site-related activities.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          Non Personal Identification Information
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          We may collect non-personal identification information when users
          interact with our Site. This information may include browser names,
          computer types, and technical information about users&#39; means of
          connecting to our Site, such as operating systems and internet service
          providers.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          Web Browser Cookies
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          Our Site may use cookies to enhance the user experience. Users&#39;
          web browsers place cookies on their hard drives for record-keeping and
          tracking purposes. Users can choose to set their web browsers to
          refuse cookies or to receive alerts when cookies are sent. However,
          some parts of the Site may not function properly if they do so.
        </p>

        <h2 className="mt-10 text-center font-palette-font-2">
          How We Use Collected Information
        </h2>

        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          Indian Treasure House collects and uses users&#39; personal
          information for various purposes, including:
          <br />
          <ul>
            <li>Personalizing the user experience</li>
            <li>Improving our Site</li>
            <li>Enhancing customer service</li>
            <li>
              Administering content, promotions, surveys, or other Site features
            </li>
            <li>Sending periodic emails</li>
          </ul>
        </p>

        <h2 className="mt-10 text-center font-palette-font-2">
          How We Protect Your Information
        </h2>

        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          We adopt appropriate data collection, storage, and processing
          practices and security measures to protect personal information from
          unauthorized access, alteration, disclosure, or destruction. This
          includes safeguarding personal information, usernames, passwords,
          transaction data, and stored data on our Site.
        </p>


        <h2 className="mt-10 text-center font-palette-font-2">
          Sharing Your Personal Information
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          We do not sell, trade, or rent users&#39; personal identification
          information to others. We may share generic, aggregated demographic
          information that is not linked to personal identification information
          with our business partners, trusted affiliates, and advertisers for
          the purposes described above. We may use third-party service providers
          to help us operate our business and administer activities on our
          behalf, such as sending newsletters or surveys, with users&#39;
          permission.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          Third-Party Websites
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          Our Site may contain links to third-party websites. We are not
          responsible for the content or practices of these sites. Users&#39;
          interaction with third-party websites is subject to the terms and
          policies of those websites, and these sites may change over time.
          These sites and services may have their own privacy and customer
          service policies.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          Changes to this Privacy Policy
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          Indian Treasure House may update this privacy policy at its
          discretion. Users will be notified of any changes via email. Users are
          encouraged to check this page regularly to stay informed about how
          their personal information is protected. Continued use of the Site
          after changes to this policy are deemed acceptance of those changes.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          Your Acceptance of These Terms
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          By using this Site, you signify your acceptance of this policy. If you
          do not agree with this policy, please do not use our Site. Your
          continued use of the Site following any policy changes indicates your
          acceptance of those changes.
        </p>
        <h2 className="mt-10 text-center font-palette-font-2">
          Contacting Us
        </h2>
        <p className="text-justify font-palette-font-2 w-[50%] mt-10">
          If you have questions about this Privacy Policy, our site practices,
          or your interactions with this Site, please contact us at:
        </p>
        <h2 className="mt-10 text-center font-palette-font-2 mb-10">Address:</h2>
      </div>

    </div>
  );
}

export default Privacy;
