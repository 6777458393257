import React from "react";
import video from "../assets/bg-video1.mp4";
// import { useTranslator } from "./Translator";

const HeroSection = () => {
  // const { sample1 } = useTranslator();
  return (
    <section
      className="relative h-screen overflow-hidden"
      style={{ top: "-57px" }}
    >
      <div className="absolute z-10" style={{ left: "7%", bottom: "98px" }}>
        <h1 className="mb-2 text-3xl font-extrabold md:text-4xl lg:text-5xl text-palette-color-3 font-palette-font-1">
        Connecting the World
          <br />
          to the Roots of Indian Civilization
        </h1>
        <p className="text-xl md:text-2xl text-palette-color-8 font-palette-font-1">Where Every Journey Becomes an Adventure</p>
      </div>
      <video
        autoPlay
        loop
        muted
        className="absolute top-0 left-0 z-0 object-cover w-full h-full"
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className="absolute inset-0 z-0 opacity-50 bg-palette-color-5"></div>
    </section>
  );
};

export default HeroSection;
