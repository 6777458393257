import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { useInView } from "react-intersection-observer";
import emailjs from "emailjs-com";
import Navbar from "../../components/navbar";

const firebaseConfig = {
  apiKey: "AIzaSyCKokSeMaONCsb_XqOQFXe3GRxREXEcZTc",
  authDomain: "ith-novus.firebaseapp.com",
  projectId: "ith-novus",
  storageBucket: "ith-novus.appspot.com",
  messagingSenderId: "1040492127356",
  appId: "1:1040492127356:web:17976e8f4cf9e99bab55f9",
  measurementId: "G-L2QS7QKT32",
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const formsCollectionRef = collection(db, "contact");

function Destination() {
  const [inView] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobNo: "",
    country: "",
    details: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetFormAndCloseModal = () => {
    setFormData({
      name: "",
      email: "",
      mobNo: "",
      country: "",
      details: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(formsCollectionRef, {
        contact: formData,
      });
      const templateParams = {
        name: formData.name,
        email: formData.email,
      };
      // Next, send a request to trigger the Cloud Function
      const response = await emailjs.send(
        "service_g8shg6p",
        "template_3qor7s8",
        templateParams,
        "fT4pN2G_pRf7AWKMk"
      );

      if (response.status === 200) {
        alert("Inquiry has been generated. We will get back to you soon.");
        resetFormAndCloseModal();
      } else {
        console.error("Error sending email:", response);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div>
      <div className="bg-palette-color-4 relative sticky top-0">
        <Navbar />
      </div>
      <h1 className="pt-12 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-1">
        Embark on a Journey of Diversity with Indian Treasure House
      </h1>
      <div className="p-4 text-justify">
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          India, a captivating tapestry of diversity! Imagine a place where over
          a billion people come together, speaking a multitude of languages and
          celebrating various religions. From the majestic Himalayas to the
          sun-kissed beaches, India's landscapes are as diverse as its people.
          Picture savoring a rainbow of flavors, from spicy curries to
          delightful sweets. It's not just a country; it's a vibrant blend of
          traditions, customs, and festivals that paint a unique and enchanting
          picture. Come, explore the kaleidoscope of India's wonders!
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          At Indian Treasure House, we invite you to explore the incredible
          diversity of India's destinations and cultures, offering a unique
          opportunity to witness the rich tapestry of this magnificent country
          from the northern reaches to the southern shores.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Diverse Destinations:
          </span>{" "}
          From the majestic Himalayan peaks to the tranquil backwaters of
          Kerala, our destinations span the entire subcontinent. Experience the
          stunning contrasts in landscapes and ecosystems, from arid deserts to
          lush rainforests. Each destination is handpicked to showcase the
          diversity of India's natural beauty.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Cultural Extravaganza:
          </span>{" "}
          As you traverse from North to South, you'll have the chance to immerse
          yourself in a wide range of traditions, each unique in its customs,
          festivals, and culinary delights. Witness the vibrancy of local
          markets, interact with diverse communities, and gain a profound
          understanding of India's rich cultural heritage.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Variety of Traditions:
          </span>{" "}
          The shift from the northern regions to the southern shores will expose
          you to a fascinating array of traditions, languages, and lifestyles.
          From the grandeur of Rajput architecture in the north to the tranquil
          art of backwater living in the south, every experience will be a
          cultural revelation.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Connecting with People:
          </span>{" "}
          Our journeys aren't just about visiting places; they're about
          connecting with the people who make these destinations come alive.
          Engage in conversations with local communities, participate in their
          rituals, and learn about their way of life.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          <span className="text-2xl text-palette-color-18 font-palette-font-1">
            Tailored Experiences:
          </span>{" "}
          Indian Treasure House crafts experiences that offer you an intimate
          view of India's diversity. Whether you're seeking spiritual
          enlightenment, adventurous escapades, serene escapes, or cultural
          immersions, our itineraries are designed to cater to your unique
          interests.
        </p>
        <p
          className={`text-md justify-center p-7 text-palette-color-5 city-description font-palette-font-2 ${
            inView ? "animate-fade-in" : ""
          }`}
        >
          Join us on a journey where you can witness the vibrant mosaic of
          India's cultures and landscapes, explore the variety of traditions,
          and create lifelong memories. Come and experience India's diversity,
          one destination at a time.
        </p>
      </div>
      <div className="justify-center">
        <div className="h-full mt-6 shadow-md md:mt-0 ">
          <div className="mt-10 p-5">
            <h1 className="mb-8 leading-tight text-3xl text-center text-palette-color-18 font-palette-font-1">
              For more details:
            </h1>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col justify-center space-y-5 w-1/2 mx-auto"
            >
              <div className="flex justify-center">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  name="mobNo"
                  value={formData.mobNo}
                  onChange={handleChange}
                  placeholder="Mobile No."
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <input
                  placeholder="Country"
                  type="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <div className="flex justify-center">
                <textarea
                  placeholder="Message"
                  name="details"
                  value={formData.details}
                  onChange={handleChange}
                  className="border-b-4 w-full p-2"
                  required
                />
              </div>
              <center>
                <button
                  type="submit"
                  className="py-3 px-6 bg-palette-color-4 text-white font-bold rounded-md"
                >
                  Submit
                </button>
              </center>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Destination;
